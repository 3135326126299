import './caixa.css'
import { toMoneyFormat } from '../../utilities/helperFunctions'
import { IPropsResumo as IProps } from './interfaceCaixa'
import {Grid} from '@mui/material'
import CaixaResumoGrapProdutos from './caixaResumoGraphProdutos'
import { useGlobalState } from '../../globalState'

function InfoCaixa(props: IProps) {
  const [empresaAtual] = useGlobalState('empresaAtual')
  //Calculando Media
  const media = +props?.data.tipos[0].VALORTOTAL / props?.data.cupons[0].QUANTIDADE
  console.log(props?.data.cupons[0].QUANTIDADE)
  return (
    <div>
      <div className=" "><i onClick={() => props.return()} className="fad fa-arrow-to-left arrow-left "></i></div>
      <h3 className="text-center">Relatório</h3>

      <div className=" ">
        <Grid container rowSpacing={1} columnSpacing={1} justifyContent={{xs:'center',md:'flex-start'}}>
        <Grid item xs={6} md={3} >
            <div className="card prazo order-card">
              <div className="card-block">
                <h6 className="m-b-20">A PRAZO
                  <span className="f-right">{Number(props.data.tipos[3].PORCFORMA).toFixed(2)}%</span></h6>
                <h3 className="text-right">
                  <span>{toMoneyFormat(props.data.tipos[3].VALORFORMA)}</span></h3>
                <p style={{marginBottom:'0'}}>Vendas: <span className="">{props.data.tipos[3].QUANTIDADELANCAMENTOS}</span></p>
              </div>
            </div>
          </Grid>

           <Grid item xs={6} md={3} >
            <div className="card dinheiro order-card">
              <div className="card-block">
                <h6 className="m-b-20">DINHEIRO <span className="f-right">{Number(props.data.tipos[0].PORCFORMA).toFixed(2)}%</span></h6>
                <h3 className="text-right">
                  <span>{toMoneyFormat(props.data.tipos[0].VALORFORMA)}</span></h3>
                <p style={{marginBottom:'0'}}>Vendas: <span className="">{props.data.tipos[0].QUANTIDADELANCAMENTOS}</span></p>
              </div>
            </div>
          </Grid>

           <Grid item xs={6} md={3} >
            <div className="card credito order-card">
              <div className="card-block">
                <h6 className="m-b-20">CREDITO <span className="f-right">{Number(props.data.tipos[2].PORCFORMA).toFixed(2)}%</span></h6>
                <h3 className="text-right">
                  <span>{toMoneyFormat(props.data.tipos[2].VALORFORMA)}</span></h3>
                <p style={{marginBottom:'0'}}>Vendas: <span className="">{props.data.tipos[2].QUANTIDADELANCAMENTOS}</span></p>
              </div>
            </div>
          </Grid>

           <Grid item xs={6} md={3} >
            <div className="card debito order-card">
              <div className="card-block">
                <h6 className="m-b-20">DEBITO <span className="f-right">{Number(props.data.tipos[1].PORCFORMA).toFixed(2)}%</span></h6>
                <h3 className="text-right">
                  <span>{toMoneyFormat(props.data.tipos[1].VALORFORMA)}</span></h3>
                <p style={{marginBottom:'0'}}>Vendas: <span className="">{props.data.tipos[1].QUANTIDADELANCAMENTOS}</span></p>
              </div>
            </div>
          </Grid>
          {Number(props.data.tipos[4].VALORFORMA) > 0 ?
             <Grid item xs={6} md={3} >
              <div className="card cheque order-card">
                <div className="card-block">
                  <h6 className="m-b-20">OUTROS <span className="f-right">{Number(props.data.tipos[4].PORCFORMA).toFixed(2)}%</span></h6>
                  <h3 className="text-right">
                    <span>{toMoneyFormat(props.data.tipos[4].VALORFORMA)}</span></h3>
                  <p style={{marginBottom:'0'}}>Vendas: <span className="">{props.data.tipos[4].QUANTIDADELANCAMENTOS}</span></p>
                </div>
              </div>
            </Grid>
            : null}
</Grid>
     
      </div>

      <div className="row px-4">
        <div className="col">
          <div className="row  align-items-center p-4">
            <h3>Informações:</h3>
            <div className="col-auto">
              <ul id="transactions" className="transactions">
                {props?.data.vendas.map((dados, index) =>
                  <li className="border-li" key={index}>
                    {dados.DESCRICAO_PRODUTO} 一 {Number(dados.QUANTIDADE).toFixed(empresaAtual.dec.quantidade)} <span>{toMoneyFormat(dados.VALORNEGOCIACAO)}</span>
                  </li>
                )}

              </ul>
            </div>
            {!props.data.caixa.IsMoreThanOneCaixa
              ?
              <div className="col-auto">
                <div className="d-flex">
                  <i className="fas fa-toolbox"></i><p >Caixa: {props.data.caixa.ID}</p>
                </div>
                <div className="d-flex">
                  <i className="fas fa-user-hard-hat"></i><p >{props.data.caixa.NOME_FUNCIONARIO}</p>
                </div>
                <div className="d-flex">
                  <i className="fas fa-calendar-plus"></i> <p> <span style={{ color: '#FFA800' }}>
                    Abertura:᲼</span>{props.data.caixa.DATAABERTURA}</p>

                </div>
                <div className="d-flex">
                  <i className="far fa-clock"></i><p style={{ color: '#1BC5BD' }}>{props.data.caixa.HORAABERTURA}</p>
                </div>
                {props.data.caixa.SITUACAO === 'ABERTO' ? null : <div className="d-flex">
                  <i className="fas fa-calendar-check"></i><p><span style={{ color: '#FFA800' }}>
                    Fechamento:᲼</span>{props.data.caixa.DATAFECHAMENTO}</p>
                </div>}
                {props.data.caixa.SITUACAO === 'ABERTO' ? null :
                  <div className="d-flex">
                    <i className="far fa-stopwatch"></i><p style={{ color: '#F64E60' }}>{props.data.caixa.HORAFECHAMENTO}</p>
                  </div>}


              </div>
              :
              <div className="col-auto">

                <div className="d-flex">
                  <i className="fas fa-toolbox"></i><p >Caixa: {props.data.caixa?.numeros?.map(num => ' - ' + num)}</p>
                </div>
                <div className="d-flex">
                  <i className="fas fa-calendar-plus"></i> <p> <span style={{ color: '#FFA800' }}>
                    Abertura:᲼</span>{props.data.caixa.firstDate.replaceAll('-', '/')}</p>
                </div>
                <div className="d-flex">
                  <i className="far fa-clock"></i><p style={{ color: '#1BC5BD' }}>{props.data.caixa.firstHour}</p>
                </div>
                <div className="d-flex">
                  <i className="fas fa-calendar-check"></i><p><span style={{ color: '#FFA800' }}>
                    Fechamento:᲼</span>{props.data.caixa.lastDate}</p>
                </div>
                <div className="d-flex">
                  <i className="far fa-stopwatch"></i><p style={{ color: '#F64E60' }}>{props.data.caixa.lastHour}</p>
                </div> </div>}


          </div></div>
        <div className="col-auto pt-1">
          <h1>Fechamento</h1>
          <hr />
          <p><span style={{ color: '#FFA800' }}>Cupons: </span>{props.data.cupons[0].QUANTIDADE}</p>
          <p><span style={{ color: '#FFA800' }}> Media por Cupon: </span>{toMoneyFormat(media || 0)}</p>
          <h3 className="text-center">Total:</h3>
          <h1 className="text-center ">{toMoneyFormat(props.data.tipos[0].VALORTOTAL || 0)}</h1>
        </div>
      </div>

      <CaixaResumoGrapProdutos data={props.data.vendas}/>

      <button className="btn btn-success" onClick={() => props.gerarPDF()} >Gerar pdf</button>

    </div>
  )
}

export default InfoCaixa
