import axios from '../../axiosConfig'
import { Modal, Button } from 'react-bootstrap'
import { useEffect } from 'react'
import { useState } from 'react'
import './index.css'
import RelatoriosRoutes from './relatoriosRoutes'
import PDFsRoutes from './PDFsRoutes'
import EmpresaChanger from '../../utilities/changeEmpresa/index'
import { useGlobalState } from '../../globalState'
import { List, ListItemText, ListItemButton, ListItemIcon, useMediaQuery, Tab, Tabs, Box, Paper } from '@mui/material'
import { makeStyles } from "@mui/styles";
import { Description, Storefront, Business } from '@mui/icons-material'
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    padding: 0
  }
}));


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          {children}
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};




function Relatorios() {
  const [PDFData, setPDFData] = useState([])
  const [currentRelatorio, setCurrentRelatorio] = useState('')
  const [fornecedores, setFornecedores] = useState([])
  const [clientes, setClientes] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [empresaAtual] = useGlobalState('empresaAtual')
  const [filialAtual] = useGlobalState('filialAtual')
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:600px)');

  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setCurrentRelatorio('')
    setTabValue(newValue);
  };

  useEffect(() => {
    axios.get('/fornecedores').then(r => setFornecedores(r.data)).catch(err => console.error(err))
    axios.get('/clientes').then(r => setClientes(r.data)).catch(err => console.error(err))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empresaAtual])



  const setRelatorio = (relatorio) => {
    setCurrentRelatorio(relatorio)
  }

  const showPDF = (error, errorMessageP = 'Erro ao realizar tarefa') => {
    if (error) {
      setErrorMessage(errorMessageP)
      return
    }
  }

  const changeToMulti = (tipo) => {
    if (tipo === 'loja') {
      setTabValue(5)
      setCurrentRelatorio('multilojaresumovaloresvenda')
    } else {
      setTabValue(6)
      setCurrentRelatorio('multiempresaresumovaloresvenda')
    }

  }

  return (
    <div>

      <Modal
        animation={true}
        centered
        show={errorMessage !== ''}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          {errorMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setErrorMessage('')}>
            Fechar
          </Button>
        </Modal.Footer>


      </Modal>
      <div className={Object.keys(PDFData).length !== 0 ? 'collapse' : 'container mt-1'}>
        <div className="d-flex title">
          <div className=" p-2"><h1>Relatórios | <span className='empresa-atual'>{empresaAtual.fantasia} - {empresaAtual.cnpj}</span></h1></div>
          <EmpresaChanger></EmpresaChanger>
          <div className="p-2"><i className="fas fa-money-check-alt"></i></div>
        </div>
        {filialAtual.CNPJ && empresaAtual.cnpj !== filialAtual?.CNPJ?.trim() ?
          <div className='ps-3'>
            <p className='fs-6'>
              {filialAtual.FANTASIA + " - " + filialAtual.CNPJ}
            </p>
          </div>

          : null}
        <hr />
        <div className='relatorios-linha'>

          <Tabs value={tabValue} onChange={handleTabChange}
            className="mb-3"
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            sx={{
              '& .MuiTabs-scroller': {
                maxHeight: '50px'
              }
            }}
          >
            <Tab value={0} label="Contas a pagar" />
            <Tab value={1} label="Clientes" />
            <Tab value={2} label="Entrada" />
            <Tab value={3} label="Estoque" />
            <Tab value={4} label="Vendas" />
            {empresaAtual?.filiais?.length > 1
              ? <Tab value={5} label="Multiloja" icon={<Storefront />} iconPosition="start" sx={{ color: 'red' }} />
              : null}

            <Tab value={6} label="Multiempresa" icon={<Business />} iconPosition="start" sx={{ color: 'orange' }} />
            <Tab value={7} label="Exportações" />
          </Tabs>
          <Paper elevation={3} className='p-2' sx={{ backgroundColor: '#11CDEF' }}>
            <TabPanel value={tabValue} index={0}>
              <List className={matches ? classes.root : ''} component="nav" aria-label="nav">
                <ListItemButton dense sx={{ paddingBottom: '0px', paddingTop: '0px', maxWidth: '200px' }}
                  selected={currentRelatorio === 'todasapagar'}
                  onClick={() => setRelatorio('todasapagar')}>
                  <ListItemIcon sx={{ minWidth: '30px' }}><Description /></ListItemIcon>
                  <ListItemText primary={'Todas a pagar'}
                    primaryTypographyProps={{ fontSize: 16, fontWeight: '600' }} />
                </ListItemButton>
                <ListItemButton dense sx={{ paddingBottom: '0px', paddingTop: '0px', maxWidth: '200px' }}
                  selected={currentRelatorio === 'pagamentos'}
                  onClick={() => setRelatorio('pagamentos')}>
                  <ListItemIcon sx={{ minWidth: '30px' }}><Description /></ListItemIcon>
                  <ListItemText primary={'Pagamentos'}
                    primaryTypographyProps={{ fontSize: 16, fontWeight: '600' }} />
                </ListItemButton>
                <ListItemButton dense sx={{ paddingBottom: '0px', paddingTop: '0px', maxWidth: '200px' }}
                  selected={currentRelatorio === 'despesas'}
                  onClick={() => setRelatorio('despesas')}>
                  <ListItemIcon sx={{ minWidth: '30px' }}><Description /></ListItemIcon>
                  <ListItemText primary={'Despesas'}
                    primaryTypographyProps={{ fontSize: 16, fontWeight: '600' }} />
                </ListItemButton>
              </List>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <List className={matches ? classes.root : ''} component="nav" aria-label="nav">
                <ListItemButton dense sx={{ paddingBottom: '0px', paddingTop: '0px', maxWidth: '200px' }}
                  selected={currentRelatorio === 'debitototal'}
                  onClick={() => setRelatorio('debitototal')}>
                  <ListItemIcon sx={{ minWidth: '30px' }}><Description /></ListItemIcon>
                  <ListItemText primary={'Debito Total'}
                    primaryTypographyProps={{ fontSize: 16, fontWeight: '600' }} />
                </ListItemButton></List>
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <List className={matches ? classes.root : ''} component="nav" aria-label="nav">
                <ListItemButton dense sx={{ paddingBottom: '0px', paddingTop: '0px', maxWidth: '200px' }}
                  selected={currentRelatorio === 'notasentrada'}
                  onClick={() => setRelatorio('notasentrada')}>
                  <ListItemIcon sx={{ minWidth: '30px' }}><Description /></ListItemIcon>
                  <ListItemText primary={'Notas entrada'}
                    primaryTypographyProps={{ fontSize: 16, fontWeight: '600' }} />
                </ListItemButton></List>
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
              <List className={matches ? classes.root : ''} component="nav" aria-label="nav">
                <ListItemButton dense sx={{ paddingBottom: '0px', paddingTop: '0px', maxWidth: '200px' }}
                  selected={currentRelatorio === 'estoquefinalmovimento'}
                  onClick={() => setRelatorio('estoquefinalmovimento')}>
                  <ListItemIcon sx={{ minWidth: '30px' }}><Description /></ListItemIcon>
                  <ListItemText primary={'Estoque final do movimento'}
                    primaryTypographyProps={{ fontSize: 16, fontWeight: '600' }} />
                </ListItemButton>
                {empresaAtual.isPosto ?
                  <ListItemButton dense sx={{ paddingBottom: '0px', paddingTop: '0px', maxWidth: '200px' }}
                    selected={currentRelatorio === 'tanque'}
                    onClick={() => setRelatorio('tanque')}>
                    <ListItemIcon sx={{ minWidth: '30px' }}><Description /></ListItemIcon>
                    <ListItemText primary={'Tanques'}
                      primaryTypographyProps={{ fontSize: 16, fontWeight: '600' }} />
                  </ListItemButton> : null
                }
                {empresaAtual.isPosto ?
                  <ListItemButton dense sx={{ paddingBottom: '0px', paddingTop: '0px', maxWidth: '200px' }}
                    selected={currentRelatorio === 'afericao'}
                    onClick={() => setRelatorio('afericao')}>
                    <ListItemIcon sx={{ minWidth: '30px' }}><Description /></ListItemIcon>
                    <ListItemText primary={'Aferição'}
                      primaryTypographyProps={{ fontSize: 16, fontWeight: '600' }} />
                  </ListItemButton> : null
                }
              </List>
            </TabPanel>
            <TabPanel value={tabValue} index={4}>
              <List className={matches ? classes.root : ''} component="nav" aria-label="nav">
                <ListItemButton dense sx={{ paddingBottom: '0px', paddingTop: '0px', maxWidth: '300px' }}
                  selected={currentRelatorio === 'resumovaloresvenda'}
                  onClick={() => setRelatorio('resumovaloresvenda')}>
                  <ListItemIcon sx={{ minWidth: '30px' }}><Description /></ListItemIcon>
                  <ListItemText primary={'Resumo valores de venda'}
                    primaryTypographyProps={{ fontSize: 16, fontWeight: '600' }} />
                </ListItemButton>
                <ListItemButton dense sx={{ paddingBottom: '0px', paddingTop: '0px', maxWidth: '200px' }}
                  selected={currentRelatorio === 'lucro'}
                  onClick={() => setRelatorio('lucro')}>
                  <ListItemIcon sx={{ minWidth: '30px' }}><Description /></ListItemIcon>
                  <ListItemText primary={'Lucro'}
                    primaryTypographyProps={{ fontSize: 16, fontWeight: '600' }} />
                </ListItemButton>
                {empresaAtual.isPosto ?
                  <ListItemButton dense sx={{ paddingBottom: '0px', paddingTop: '0px', maxWidth: '200px' }}
                    selected={currentRelatorio === 'abastecimento'}
                    onClick={() => setRelatorio('abastecimento')}>
                    <ListItemIcon sx={{ minWidth: '30px' }}><Description /></ListItemIcon>
                    <ListItemText primary={'Abastecimento'}
                      primaryTypographyProps={{ fontSize: 16, fontWeight: '600' }} />
                  </ListItemButton> : null
                }
                <ListItemButton dense sx={{ paddingBottom: '0px', paddingTop: '0px', maxWidth: '200px' }}
                  selected={currentRelatorio === 'vendasdia'}
                  onClick={() => setRelatorio('vendasdia')}>
                  <ListItemIcon sx={{ minWidth: '30px' }}><Description /></ListItemIcon>
                  <ListItemText primary={'Vendas do dia'}
                    primaryTypographyProps={{ fontSize: 16, fontWeight: '600' }} />
                </ListItemButton>
                <ListItemButton dense sx={{ paddingBottom: '0px', paddingTop: '0px', maxWidth: '200px' }}
                  selected={currentRelatorio === 'vendasprodutos'}
                  onClick={() => setRelatorio('vendasprodutos')}>
                  <ListItemIcon sx={{ minWidth: '30px' }}><Description /></ListItemIcon>
                  <ListItemText primary={'Vendas produtos'}
                    primaryTypographyProps={{ fontSize: 16, fontWeight: '600' }} />
                </ListItemButton>
              </List>
            </TabPanel>
            <TabPanel value={tabValue} index={5}>
              <List className={matches ? classes.root : ''} component="nav" aria-label="nav">
                <ListItemButton dense sx={{ paddingBottom: '0px', paddingTop: '0px', maxWidth: '200px' }}
                  selected={currentRelatorio === 'multilojaresumovaloresvenda'}
                  onClick={() => setRelatorio('multilojaresumovaloresvenda')}>
                  <ListItemIcon sx={{ minWidth: '30px' }}><Description /></ListItemIcon>
                  <ListItemText primary={'Resumo valores de venda'}
                    primaryTypographyProps={{ fontSize: 16, fontWeight: '600' }} />
                </ListItemButton>
                <ListItemButton dense sx={{ paddingBottom: '0px', paddingTop: '0px', maxWidth: '200px' }}
                  selected={currentRelatorio === 'multilojacombustiveis'}
                  onClick={() => setRelatorio('multilojacombustiveis')}>
                  <ListItemIcon sx={{ minWidth: '30px' }}><Description /></ListItemIcon>
                  <ListItemText primary={'Combustiveis'}
                    primaryTypographyProps={{ fontSize: 16, fontWeight: '600' }} />
                </ListItemButton>
                <ListItemButton dense sx={{ paddingBottom: '0px', paddingTop: '0px', maxWidth: '200px' }}
                  selected={currentRelatorio === 'multilojavendasclientes'}
                  onClick={() => setRelatorio('multilojavendasclientes')}>
                  <ListItemIcon sx={{ minWidth: '30px' }}><Description /></ListItemIcon>
                  <ListItemText primary={'Vendas Clientes'}
                    primaryTypographyProps={{ fontSize: 16, fontWeight: '600' }} />
                </ListItemButton>
                </List>
            </TabPanel>
            <TabPanel value={tabValue} index={6}>
              <List className={matches ? classes.root : ''} component="nav" aria-label="nav">
                <ListItemButton dense sx={{ paddingBottom: '0px', paddingTop: '0px', maxWidth: '200px' }}
                  selected={currentRelatorio === 'multiempresaresumovaloresvenda'}
                  onClick={() => setRelatorio('multiempresaresumovaloresvenda')}>
                  <ListItemIcon sx={{ minWidth: '30px' }}><Description /></ListItemIcon>
                  <ListItemText primary={'Resumo valores de venda'}
                    primaryTypographyProps={{ fontSize: 16, fontWeight: '600' }} />
                </ListItemButton></List>
            </TabPanel>
            <TabPanel value={tabValue} index={7}>
              <List className={matches ? classes.root : ''} component="nav" aria-label="nav">
                <ListItemButton dense sx={{ paddingBottom: '0px', paddingTop: '0px', maxWidth: '200px' }}
                  selected={currentRelatorio === 'exportformaspag'}
                  onClick={() => setRelatorio('exportformaspag')}>
                  <ListItemIcon sx={{ minWidth: '30px' }}><Description /></ListItemIcon>
                  <ListItemText primary={'Resumos'}
                    primaryTypographyProps={{ fontSize: 16, fontWeight: '600' }} />
                </ListItemButton></List>
            </TabPanel>
          </Paper>
        </div>
        <br />
        {currentRelatorio !== '' ? <RelatoriosRoutes
          componentName={currentRelatorio} showPDF={(error, errorMessageP) => showPDF(error, errorMessageP)} setPDFData={setPDFData}
          fornecedores={fornecedores} clientes={clientes} changeToMulti={changeToMulti} /> : null}
      </div>
      {Object.keys(PDFData).length === 0 ? null :
        <PDFsRoutes componentName={currentRelatorio}
          close={() => setPDFData({})} PDFData={PDFData} />
      }

    </div>
  )
}
export default Relatorios
