import { RelatorioDia } from './interfacesPDF'
import { useGlobalState } from '../../../../globalState'
import TableSort from '../../../../utilities/TableSortTS'
import { useMemo } from 'react'
import { toMoneyFormat, toFixedNumber } from '../../../../utilities/helperFunctions'
import PrintFooter from "../../printFooter";
 

interface IProps {
    data: RelatorioDia['data']
    date: { dataInicial: string, dataFinal: string }
    options?: { filiaisSelecionadas: string[] }
}

function ResumoDiaPDF(props: IProps) {
    const [empresaAtual] = useGlobalState('empresaAtual')

    const dias = props.data.reduce((acc,item)=>{
        if(acc.indexOf(item.DIA) === -1){
            acc.push(item.DIA)
            acc.sort(function(a, b){return a - b;})
            return acc
        }else{
            return acc
        }
    },[] as number[])

    const calculateTotal =(dia:number) =>{
       let tot = props.data.find(x=>x.DIA === dia) 
       return toFixedNumber(tot?.TOTALDIA || 0)
    }

    const columns = useMemo(
        () => [
            {
                Header: " ",

                columns: [
                    {
                        Header: "Empresa",
                        accessor: "FANTASIA",
                        Footer:''
                    },
                    {
                        Header: "Porc total%",
                        accessor: "PORCVALOR",
                        Cell: ({ value }: { value: string }) => Number(value).toFixed(2),
                        disableSortBy: true,
                        Footer:''
                    },
                    {
                        Header: "Porc dia %",
                        accessor: "PORCDIA",
                        Cell: ({ value }: { value: string }) => Number(value).toFixed(2),
                        disableSortBy: true,
                        Footer:''
                    },
                    {
                        Header: "Total",
                        accessor: "TOTALEMPRESA",
                        Cell: ({ value }: { value: string }) => toMoneyFormat(value),
                    },
                ],
                Footer: ()=><></>
                
            }
        ], [])

    return (
        <div className='container'>
            <div className='pdf' id='section-to-print'>
                <h5>{empresaAtual.nome}</h5>
                <h6>Multiloja &gt; Resumo valores de venda &gt; Resumido &gt; Dia - Periodo: {props.date.dataInicial} a {props.date.dataFinal}</h6>
                <hr />
                <p style={{ fontSize: '12px' }}>{props.options?.filiaisSelecionadas.map(item => item + ' | ')}</p>
                <hr />
                {dias.map(dia=>
                <>
                <h6 className='pl-4'>Dia: {dia}</h6>
                     <div className="table-responsive">
                     <TableSort columns={columns} data={props.data.filter(item=>item.DIA === dia)}></TableSort>
                 </div>
                 <p className='fw-bold text-end'>Total dia: {toMoneyFormat(calculateTotal(dia))}</p>
                 </>
                    )}
                <h6 className='fw-bold text-end'>Total: {toMoneyFormat(props.data[0].TOTALVALOR)}</h6>
                <PrintFooter/>
            </div>
        </div>
    )
}
export default ResumoDiaPDF