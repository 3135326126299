import { Modal, Spinner } from 'react-bootstrap'
import { TextField, Button, FormControlLabel, Checkbox, FormControl, FormLabel, Radio, RadioGroup, MenuItem, Select, TextFieldProps } from '@mui/material'
import { IClasseNegociacaoProduto as IItem } from '../typesFromDB'
import { useState, useEffect } from 'react'
import axios from '../../axiosConfig'
import Loading from '../loading'
import { handleChangeTextInput, handleChangeSelectInput } from '../inputsUtilities'
import SearchGruposProdutos from '../searchs/searchGruposProdutos'
import SearchProduto from '../searchs/searchProduto'
import { toMoneyFormat } from '../helperFunctions'
import { NumericFormat } from 'react-number-format'
import { Send, Delete } from '@mui/icons-material'

interface IProps {
    setSnackbarData: React.Dispatch<React.SetStateAction<{
        message: string; severity: 'success' | 'error' | 'info';
    }>>;
    classeID: number
}

const CustomTextField = (props: TextFieldProps) => {
    return (
        <TextField
            {...props}
            size={'small'}
        />
    )
}

function UpdateClasseNegociacaoProduto(props: IProps) {
    const [isFullLoading, setIsFullLoading] = useState(false)
    const [isLoading,setIsLoading] = useState(false)
    const [prodSelecionado, setProdSelecionado] = useState(1)
    const [grupoSelecionado, setGrupoSelecionado] = useState(1)
    const [prodClasse, setProdClasse] = useState<IItem[] | undefined>(undefined)
    const [prodClasseSelecionado, setProdClasseSelecionado] = useState<IItem | undefined>(undefined)
    const [itemType, setItemType] = useState('produto')
    const [formData, setFormData] = useState<IItem>({
        ID: 0,
        ID_CLASSENEGOCIACAO: props.classeID,
        CODIGO_PRODUTO: null,
        CODIGO_GRUPOPRODUTO: null,
        TIPOITEM: '',
        TIPOVALORVENDAVISTA: 'C',
        VALORVENDAVISTA: '0,000',
        TIPOVALORVENDAPRAZO: 'C',
        VALORVENDAPRAZO: '0,000',
        TIPOVALORPAGAMENTOANTECIPADO: 'C',
        VALORPAGAMENTOANTECIPADO: '0,000',
        USUARIOCADASTRO: '',
        DATACADASTRO: '',
        EXCLUIDO: '',
        SINCRO: null,
    })

    const getItens = () => {
        setIsLoading(true)
        axios.get(`classenegociacaoproduto/${props.classeID || 0}`).then(r => {
            setProdClasse(r.data)
        }).finally(()=>setIsLoading(false))
    }

    const convertTipoValor = (tipo: string) => {
        if (tipo === 'C') return 'Preço corrente'
        if (tipo === 'F') return 'Preço fixo'
        if (tipo === 'D') return 'Desconto fixo'
        if (tipo === 'E') return 'Desconto percentual'
        if (tipo === 'A') return 'Acréscimo fixo'
        if (tipo === 'P') return 'Acréscimo percentual'
        return ''
    }


    useEffect(() => {
        getItens()
    }, [])


    const createItem = () => {
        setIsFullLoading(true)
        const dataPost = {
            ...formData, CODIGO_PRODUTO: itemType === 'produto' ? prodSelecionado : 0,
            CODIGO_GRUPOPRODUTO: itemType === 'grupo' ? grupoSelecionado : 0
        }
        axios.post('/criar/classenegociacaoproduto', { formData: dataPost }).then(r => {
            props.setSnackbarData({ message: 'Registro criado com sucesso', severity: 'success' })
            setProdClasse(r.data)
        }).catch(r => {
            props.setSnackbarData({ message: 'Erro ao criar registro', severity: 'error' })
        }).finally(() => setIsFullLoading(false))
    }

    const handleDelete = () => {
        prodClasseSelecionado && axios.delete(`/classenegociacaoproduto/${prodClasseSelecionado.ID}`).then(r => {
            props.setSnackbarData({ severity: 'success', message: 'Item excluido com sucesso' })
        }).catch(() => {
            props.setSnackbarData({ severity: 'error', message: 'Erro ao Excluir item' })
        }).finally(() => {
            getItens()
        })
    }

    const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setItemType(e.target.value)
    }

    return (
        <>
            {isFullLoading ? <Loading /> : null}
            <Modal.Body>
                <h5>Adicionar grupo/produto</h5>
                <div className='row m-4'>
                    <div className={itemType === 'grupo' ? 'col' : 'collapse'}><SearchGruposProdutos itemName='Grupo'
                        setCodigoItem={(value: number) => setGrupoSelecionado(value)} codigoItem={grupoSelecionado} /></div>
                    <div className={itemType === 'produto' ? 'col' : 'collapse'}>
                        <SearchProduto setCodigoItem={setProdSelecionado} />
                    </div>
                    <div className="col">
                        <FormControl>
                            <RadioGroup
                                row
                                name="row-radio-buttons-group"
                                value={itemType}
                                onChange={(e) => handleRadioChange(e)}
                            >
                                <FormControlLabel value="produto" control={<Radio />} label="Produto" />
                                <FormControlLabel value="grupo" control={<Radio />} label="Grupo" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
                <div className='row m-4'>
                    <div className="col">
                        <p className='fw-bold'>Vendas a vista:</p>
                    </div>
                    <div className="col-sm-2 text-muted">Tipo:</div>
                    <Select className='col' value={formData.TIPOVALORVENDAVISTA.trim()} size='small' name='TIPOVALORVENDAVISTA'
                        onChange={(event) => {
                            handleChangeSelectInput(event, setFormData)
                            if (event.target.value === "C") setFormData(form => ({ ...form, VALORPAGAMENTOANTECIPADO: '0,000' }))
                        }}>
                        <MenuItem value={"C"}>Preço corrente</MenuItem>
                        <MenuItem value={"F"}>Preço fixo</MenuItem>
                        <MenuItem value={"D"}>Desconto fixo</MenuItem>
                        <MenuItem value={"E"}>Desconto percentual</MenuItem>
                        <MenuItem value={"A"}>Acréscimo fixo</MenuItem>
                        <MenuItem value={"P"}>Acréscimo percentual</MenuItem>
                    </Select>
                    <div className="col-sm-2 text-muted">Valor:</div>
                    <NumericFormat
                        className='col'
                        disabled={formData.TIPOVALORVENDAVISTA.trim() === 'C'}
                        value={formData.VALORVENDAVISTA}
                        name='VALORVENDAVISTA'
                        customInput={CustomTextField}
                        onChange={(event) => handleChangeTextInput(event, setFormData)}
                        thousandSeparator={false}
                        decimalSeparator=','
                        decimalScale={3}
                        fixedDecimalScale
                        prefix="R$"
                        allowNegative={false}
                    />

                </div>
                <div className='row m-4'>
                    <div className="col">
                        <p className='fw-bold'>Vendas a prazo:</p>
                    </div>
                    <div className="col-sm-2 text-muted">Tipo:</div>
                    <Select className='col' value={formData.TIPOVALORVENDAPRAZO.trim()} size='small' name='TIPOVALORVENDAPRAZO'
                        onChange={(event) => {
                            handleChangeSelectInput(event, setFormData)
                            if (event.target.value === "C") setFormData(form => ({ ...form, VALORVENDAPRAZO: '0,000' }))
                        }}>
                        <MenuItem value={"C"}>Preço corrente</MenuItem>
                        <MenuItem value={"F"}>Preço fixo</MenuItem>
                        <MenuItem value={"D"}>Desconto fixo</MenuItem>
                        <MenuItem value={"E"}>Desconto percentual</MenuItem>
                        <MenuItem value={"A"}>Acréscimo fixo</MenuItem>
                        <MenuItem value={"P"}>Acréscimo percentual</MenuItem>
                    </Select>
                    <div className="col-sm-2 text-muted">Valor:</div>
                    <NumericFormat
                        className='col'
                        disabled={formData.TIPOVALORVENDAPRAZO.trim() === 'C'}
                        value={formData.VALORVENDAPRAZO}
                        name='VALORVENDAPRAZO'
                        customInput={CustomTextField}
                        onChange={(event) => handleChangeTextInput(event, setFormData)}
                        thousandSeparator={false}
                        decimalSeparator=','
                        decimalScale={3}
                        fixedDecimalScale
                        prefix="R$"
                        allowNegative={false}
                    />
                </div>
                <div className='row m-4'>
                    <div className="col">
                        <p className='fw-bold'>Pgtos. Antecipados:</p>
                    </div>
                    <div className="col-sm-2 text-muted">Tipo:</div>
                    <Select className='col' value={formData.TIPOVALORPAGAMENTOANTECIPADO.trim()} size='small' name='TIPOVALORPAGAMENTOANTECIPADO'
                        onChange={(event) => {
                            handleChangeSelectInput(event, setFormData)
                            if (event.target.value === "C") setFormData(form => ({ ...form, VALORPAGAMENTOANTECIPADO: '0,000' }))
                        }}>
                        <MenuItem value={"C"}>Preço corrente</MenuItem>
                        <MenuItem value={"F"}>Preço fixo</MenuItem>
                        <MenuItem value={"D"}>Desconto fixo</MenuItem>
                        <MenuItem value={"E"}>Desconto percentual</MenuItem>
                        <MenuItem value={"A"}>Acréscimo fixo</MenuItem>
                        <MenuItem value={"P"}>Acréscimo percentual</MenuItem>
                    </Select>
                    <div className="col-sm-2 text-muted">Valor:</div>
                    <NumericFormat
                        className='col'
                        disabled={formData.TIPOVALORPAGAMENTOANTECIPADO.trim() === 'C'}
                        value={formData.VALORPAGAMENTOANTECIPADO}
                        name='VALORPAGAMENTOANTECIPADO'
                        customInput={CustomTextField}
                        onChange={(event) => handleChangeTextInput(event, setFormData)}
                        thousandSeparator={false}
                        decimalSeparator=','
                        decimalScale={3}
                        fixedDecimalScale
                        prefix="R$"
                        allowNegative={false}
                    />
                </div>
                <Button variant='contained' color='info' endIcon={<Send />} sx={{ m: 0.5 }}
                    onClick={() => {
                        createItem()
                    }}>
                    Adicionar Item
                </Button>
                <div className='row m-4'>
                {isLoading 
                ?   <div className="d-flex justify-content-center text-primary">
                <Spinner variant="primary" animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div> 
                :null}
                    <div className='table-responsive col' style={{ maxHeight: '350px', overflow: 'scroll' }}>
                        <table className="table table-secondary table-hover ">
                        
                            <thead className="table-primary sticky-top">
                                <tr>
                                    <th scope="col">Número</th>
                                    <th scope="col">Descrição grupo/produto</th>
                                </tr>
                            </thead>
                            <tbody>
                                {prodClasse?.map(item =>
                                    <tr className={`tr-size ${prodClasseSelecionado?.ID === item.ID && 'table-active'}`} style={{ cursor: 'pointer' }} onClick={() => {
                                        setProdClasseSelecionado(item)
                                    }}>
                                        <td>{item.ID}</td>
                                        <td>{item.DESCRICAO}</td>
                                    </tr>)}
                            </tbody>

                        </table></div>
                    <div className='col'>
                        <p>Vendas a vista: {prodClasseSelecionado && convertTipoValor(prodClasseSelecionado.TIPOVALORVENDAVISTA) + " " + toMoneyFormat(prodClasseSelecionado.VALORVENDAVISTA, 3)}</p>
                        <p>Vendas a prazo: {prodClasseSelecionado && convertTipoValor(prodClasseSelecionado.TIPOVALORVENDAPRAZO) + " " + toMoneyFormat(prodClasseSelecionado.VALORVENDAPRAZO, 3)}</p>
                        <p>Pagamentos antecipados: {prodClasseSelecionado && convertTipoValor(prodClasseSelecionado.TIPOVALORPAGAMENTOANTECIPADO) + " " + toMoneyFormat(prodClasseSelecionado.VALORPAGAMENTOANTECIPADO, 3)}</p>
                        <Button variant='contained' color='error' endIcon={<Delete />} sx={{ m: 0.5 }}
                    onClick={() => {
                        handleDelete()
                    }}>
                    Excluir item
                </Button>
                    </div>

                </div>

            </Modal.Body>

        </>
    )
}

export default UpdateClasseNegociacaoProduto