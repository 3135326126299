import * as React from 'react';
import { Lucro, totalLucro } from '../interfaceLucro'
import { toFixedNumber,toMoneyFormat } from '../../../../utilities/helperFunctions';
import { useGlobalState } from '../../../../globalState'
import PrintFooter from "../../printFooter";
import LucroGrupoGraph from '../lucroGrupoGraph';

function LucroSimplesGrupoPDF(props: Lucro) {
    const [empresaAtual] = useGlobalState('empresaAtual')

    const gruposProdutos = [...new Map(props.data.map((item) => [item['CODIGO_GRUPOPRODUTO'], item])).values()]

    const totalGrupos = gruposProdutos.map(grupo => {
        const totaisGrupo = props.data.reduce((prev, curr) => {
            if (curr.CODIGO_GRUPOPRODUTO === grupo.CODIGO_GRUPOPRODUTO) {
                prev.TOTAL_QUANTIDADE_SAIDA += toFixedNumber(curr.QUANTIDADE_SAIDA)
                prev.TOTAL_VALORTOTALVENDA += toFixedNumber(curr.VALORTOTALVENDA)
                prev.TOTAL_LUCROBRUTO_PRECOULTIMO += toFixedNumber(curr.LUCROBRUTO_PRECOULTIMO)
                prev.TOTAL_LUCROBRUTO_MEDPERIODO += toFixedNumber(curr.LUCROBRUTO_MEDPERIODO)
                prev.TOTAL_QUANTIDADE_ENTRADA += toFixedNumber(curr.QUANTIDADE_ENTRADA)
                prev.TOTAL_VALORTOTALCOMPRA += toFixedNumber(curr.VALORTOTALCOMPRA)
            }
            return prev
        }, {
            TOTAL_QUANTIDADE_SAIDA: 0,
            TOTAL_VALORTOTALVENDA: 0,
            TOTAL_LUCROBRUTO_PRECOULTIMO: 0,
            TOTAL_LUCROBRUTO_MEDPERIODO: 0,
            TOTAL_QUANTIDADE_ENTRADA: 0,
            TOTAL_VALORTOTALCOMPRA: 0
        } as totalLucro)
        return totaisGrupo
    })
    const totalLucroRelatorio = totalGrupos.reduce((prev, curr) => {
        prev.TOTAL_QUANTIDADE_SAIDA += curr.TOTAL_QUANTIDADE_SAIDA
        prev.TOTAL_VALORTOTALVENDA += curr.TOTAL_VALORTOTALVENDA
        prev.TOTAL_LUCROBRUTO_PRECOULTIMO += curr.TOTAL_LUCROBRUTO_PRECOULTIMO
        prev.TOTAL_LUCROBRUTO_MEDPERIODO += curr.TOTAL_LUCROBRUTO_MEDPERIODO
        prev.TOTAL_QUANTIDADE_ENTRADA += curr.TOTAL_QUANTIDADE_ENTRADA
        prev.TOTAL_VALORTOTALCOMPRA += curr.TOTAL_VALORTOTALCOMPRA
        return prev
    }, {
        TOTAL_QUANTIDADE_SAIDA: 0,
        TOTAL_VALORTOTALVENDA: 0,
        TOTAL_LUCROBRUTO_PRECOULTIMO: 0,
        TOTAL_LUCROBRUTO_MEDPERIODO: 0,
        TOTAL_QUANTIDADE_ENTRADA: 0,
        TOTAL_VALORTOTALCOMPRA: 0
    } as totalLucro)


    return (
        <div className='container'>
            <div className='pdf' id='section-to-print'>
                <h5>{// @ts-ignore
                    empresaAtual?.nome}</h5>
                <h6>Vendas &gt; Lucro &gt; Abertura caixa &gt; Resumido</h6>
                <h6>De: {props.date.dataInicial} Até: {props.date.dataFinal}</h6>
                <br />
                {props.btnTable && <LucroGrupoGraph data={totalGrupos} gruposProdutos={gruposProdutos}/>}
                <div className="table-responsive">
                    <table className="table table-sm">
                        <thead className='thead-wrap'>
                            <tr>
                                <th scope="col">Grupo</th>
                                <th scope="col">Qt.Saida</th>
                                <th scope="col">Vl. total de venda</th>
                                <th scope="col">Lucro Ult. Pr</th>
                                <th scope="col">Lucro Pr.Médio</th>
                                <th scope="col">Qt Entrada</th>
                                <th scope="col">Vl. total de compra</th>
                            </tr>
                        </thead>
                        <tbody>
                            {gruposProdutos.map((grupo, indexG) =>
                                <tr>
                                    <td>{grupo.DESCRICAO_GRUPOPRODUTO}</td>
                                    <td>{totalGrupos[indexG].TOTAL_QUANTIDADE_SAIDA.toFixed(empresaAtual.dec.quantidade)}</td>
                                    <td>{toMoneyFormat(totalGrupos[indexG].TOTAL_VALORTOTALVENDA)}</td>
                                    <td>{toMoneyFormat(totalGrupos[indexG].TOTAL_LUCROBRUTO_PRECOULTIMO)}</td>
                                    <td>{toMoneyFormat(totalGrupos[indexG].TOTAL_LUCROBRUTO_MEDPERIODO)}</td>
                                    <td>{totalGrupos[indexG].TOTAL_QUANTIDADE_ENTRADA.toFixed(empresaAtual.dec.quantidade)}</td>
                                    <td>{toMoneyFormat(totalGrupos[indexG].TOTAL_VALORTOTALCOMPRA)}</td>
                                </tr>
                            )}
                            <tr>
                                <td colSpan={12} className="fw-bold pl-4 "></td>
                            </tr>
                            <tr>
                                <td className="fw-bold pl-4 ">
                                    Total dos produtos: {props.data.length}</td>
                                <td>{totalLucroRelatorio.TOTAL_QUANTIDADE_SAIDA.toFixed(empresaAtual.dec.quantidade)}</td>
                                <td>{toMoneyFormat(totalLucroRelatorio.TOTAL_VALORTOTALVENDA)}</td>
                                <td>{toMoneyFormat(totalLucroRelatorio.TOTAL_LUCROBRUTO_PRECOULTIMO)}</td>
                                <td>{toMoneyFormat(totalLucroRelatorio.TOTAL_LUCROBRUTO_MEDPERIODO)}</td>
                                <td>{totalLucroRelatorio.TOTAL_QUANTIDADE_ENTRADA.toFixed(empresaAtual.dec.quantidade)}</td>
                                <td>{toMoneyFormat(totalLucroRelatorio.TOTAL_VALORTOTALCOMPRA)}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <PrintFooter/>

            </div></div>
    )
}
export default LucroSimplesGrupoPDF