import { RelatorioGrupoProduto, RelatorioFormasPag } from './interfacesPDF'
import { useGlobalState } from '../../../../globalState'
import TableSort from '../../../../utilities/TableSortTS'
import { useMemo } from 'react'
import { toFixedNumber,toMoneyFormat } from '../../../../utilities/helperFunctions'
import PrintFooter from "../../printFooter";

interface IProps {
    data: RelatorioGrupoProduto['data']
    date: { dataInicial: string, dataFinal: string }
    options?: { filiaisSelecionadas: string[] }
}

function ResumoGrupoProdutoPDF(props:IProps){
    const [empresaAtual] = useGlobalState('empresaAtual')

    const gruposProduto = props.data.reduce((acc,item)=>{ 
        if(acc.findIndex(x=>x.NOME_GRUPO === item.NOME_GRUPO) === -1)acc.push({
            NOME_GRUPO:item.NOME_GRUPO,TOTALGRUPO:item.TOTALGRUPO,PORCGRUPO:item.PORCGRUPO
        })
        return acc

    },[] as {NOME_GRUPO:string,TOTALGRUPO:string,PORCGRUPO:string}[])

    const columns = useMemo(
        () => [
            {
                Header: " ",
                columns: [
                    {
                        Header: "Empresa",
                        accessor: "FANTASIA",
                        Footer:''
                    }, 
                    {
                        Header: "Total",
                        accessor: "TOTALGRUPOEMPRESA",
                        Cell: ({ value }: { value: string }) => toMoneyFormat(value)  
                    },
                    {
                        Header: "%Total",
                        accessor: "PORCGRUPOEMPRESA",
                        Cell: ({ value }: { value: string }) => Number(value).toFixed(2),
                        disableSortBy: true,
                        Footer:''
                    },
                    {
                        Header: "%Geral",
                        accessor: "PORCVALOR",
                        Cell: ({ value }: { value: string }) => Number(value).toFixed(2),
                        disableSortBy: true,
                        Footer:''
                    },
                    
                    
                ],
                Footer: ()=><></>
            }
        ], [])


    return(
        <div className='container'>
            <div className='pdf' id='section-to-print'>
                <h5>{empresaAtual.nome}</h5>
                <h6>Multiloja &gt; Resumo valores de venda &gt; Resumido &gt; Grupo de produtos - Periodo: {props.date.dataInicial} a {props.date.dataFinal}</h6>
                <hr />
                <p style={{ fontSize: '12px' }}>{props.options?.filiaisSelecionadas.map(item => item + ' | ')}</p>
                <hr />
                {gruposProduto.map(item=>
                    <>
                    <h6>{item.NOME_GRUPO}</h6>
                    <div className="table-responsive">
                     <TableSort columns={columns} data={props.data.filter(i=>i.NOME_GRUPO === item.NOME_GRUPO)}></TableSort>
                    </div>
                    <p className='fw-bold text-end'>
                       {` Total valor: ${toMoneyFormat(item.TOTALGRUPO)}(${toFixedNumber(item.PORCGRUPO)}%)`}</p>
                    </>
                    )}
                    <h6 className='fw-bold text-end'>Total: {toMoneyFormat(props.data[0].TOTALVALOR)}</h6>
                    <PrintFooter/>

                </div></div>
    )
}

export default ResumoGrupoProdutoPDF