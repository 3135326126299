import 'table2excel';

export const createExcelFile = ()=>{
    const Table2Excel = window.Table2Excel;

    Table2Excel.extend((cell, cellText) => {
        return cellText && cellText.toUpperCase() != cellText.toLowerCase() ? { t: 's', v: cellText } : null;
      });

    const table2excel = new Table2Excel({
      exclude: ".noExl",
      name: "relatorioExcel",
      filename: "relatorioExcel",
      exclude_img: false,
      exclude_links: false,
      exclude_inputs: false
    });
    table2excel.export(document.querySelectorAll("table"));
}

