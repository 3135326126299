import { TextField, TextFieldProps } from "@mui/material"
import { handleChangeTextInput } from "../../../utilities/inputsUtilities"
import { NumericFormat } from "react-number-format"

interface IICMSTab {
    UF_AC: string
    UF_AL: string
    UF_AM: string
    UF_AP: string
    UF_BA: string
    UF_CE: string
    UF_ES: string
    UF_GO: string
    UF_MA: string
    UF_MG: string
    UF_MS: string
    UF_MT: string
    UF_PA: string
    UF_PB: string
    UF_PE: string
    UF_PI: string
    UF_PR: string
    UF_RJ: string
    UF_RN: string
    UF_RO: string
    UF_RR: string
    UF_RS: string
    UF_SC: string
    UF_SE: string
    UF_SP: string
    UF_TO: string
    UF_DF: string
}

interface IProps {
    data: IICMSTab
    setData: React.Dispatch<React.SetStateAction<IICMSTab | any>>
}

const CustomTextField = (props: TextFieldProps) => {
    return (<TextField {...props} size={'small'} />)
}

function TributacaoICMSProduto(props: IProps) {
    const states = Object.keys(props.data) as Array<keyof IICMSTab>
    const produtoICMS = ['UF_AC', 'UF_AL', 'UF_AM', 'UF_AP', 'UF_BA', 'UF_CE', 'UF_ES', 'UF_GO', 'UF_MA', 'UF_MG', 'UF_MS', 'UF_MT', 'UF_PA', 'UF_PB', 'UF_PE', 'UF_PI', 'UF_PR', 'UF_RJ', 'UF_RN', 'UF_RO', 'UF_RR', 'UF_RS', 'UF_SC', 'UF_SE', 'UF_SP', 'UF_TO', 'UF_DF'];
    return (
        <div>
            <h4>ICMS por UF</h4>
            <div className="row mb-3" >
                {produtoICMS.map((state) => (

                    <div className="pt-2 col-sm-2">
                        <NumericFormat className='col'
                            label={state.slice(3)}
                            value={props.data[state as keyof IICMSTab].replace('.', ',') || 0}
                            name={state}
                            customInput={CustomTextField}
                            onChange={(event) => handleChangeTextInput(event, props.setData)}
                            thousandSeparator={false}
                            decimalSeparator=','
                            decimalScale={2}
                            fixedDecimalScale
                            prefix=""
                            allowNegative={false}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default TributacaoICMSProduto
