import React from 'react';
import { Chart } from 'react-google-charts';
import { Paper } from '@mui/material';
import { Data } from './interfaceValoresVenda';

const options = {
    title: 'Venda por Forma de Pagamento - top 10',
    backgroundColor: '#f5f0f0',
    chartArea: { top: 25, bottom: 50, right: 10, left: 60 },
    pointSize: 15,
    legend: { position: 'bottom' }
};

interface Props {
    data: Data[];
}

function ValoresVendaGraph({ data }: Props) {
    const formasPagamento = Array.from(new Set(data.map(item => item.NOME)));
    const anos = Array.from(new Set(data.map(item => item.ANO)));

    const totalSoldPerForma = formasPagamento.map(forma => ({
        forma,
        totalSold: data.reduce((acc, item) => {
            if (item.NOME === forma) {
                acc += parseFloat(item.VALOR.replace(',', '.'));
            }
            return acc;
        }, 0)
    }));

    // Sort formasPagamento by total sold
    const sortedFormasPagamento = totalSoldPerForma.sort((a, b) => b.totalSold - a.totalSold).slice(0, 10);

    // Use only the top ten formasPagamento
    const topTenFormasPagamento = sortedFormasPagamento.map(item => item.forma);

    const convertDataForLineChart = (data: Data[], ano: number): any[][] => {
        const headers = ['Mês', ...topTenFormasPagamento];
        const months = [...new Set(data.filter(item => item.ANO === ano).map(({ MES }) => MES))];
        const chartData: any[][] = [headers];

        months.forEach((month) => {
            const date = new Date(2022, month - 1, 1);
            const monthName = date.toLocaleString('default', { month: 'short' });
            const row: any[] = [monthName];

            topTenFormasPagamento.forEach((forma) => {
                const companyData = data.find(
                    (item) => item.NOME === forma && item.MES === month && item.ANO === ano
                );
                row.push(parseFloat(companyData && companyData.NOME === forma ? companyData.VALOR.replace(',', '.') : '0'));
            });

            chartData.push(row);
        });

        return chartData;
    }; 

    return (
        <Paper elevation={3} className='p-2 mt-2 no-print' sx={{
            overflowX: 'auto',
            xl: { width: '600px' },
            md: { width: '100%' },
            maxWidth: '100%',
            backgroundColor: '#f5f0f0'
        }}>
            {
                 
                anos.map(ano=><>
                 <h5 className='pl-4'>{ano}</h5>
                 <hr />
                  <Chart
                data={convertDataForLineChart(data,ano)}
                chartType="LineChart"
                width="100%"
                height="500px"
                options={options}
            />
                </>)
            }
          
        </Paper>
    );
}

export default ValoresVendaGraph;
