
import React from 'react';
import { Chart } from 'react-google-charts';
import { Paper } from '@mui/material';
import { IAbastecimentoPorHora } from './AbastecimentoPDFs/interfacePDFs'; // Assuming this interface has the necessary fields

const options = {
    title: 'Bicos mais vendidos',
    backgroundColor: '#f5f0f0',
    chartArea: { top: 25, bottom: 50, right: 10, left: 60 },
    pointSize: 15,
};

interface Props {
    data: IAbastecimentoPorHora['data'];
}

export function AbastecimentoHoraGraph({ data }: Props) {
    const hours = Array.from(new Set(data.map(item => item.HORA)));

    const hourSold = hours.map(hour => {
        const totalSold = data.reduce((acc, item) => {
            if (item.HORA === hour) {
                acc += parseFloat(item.TOTALLITROS.replace(',', '.'));
            }
            return acc;
        }, 0);
        return { hour, totalSold };
    });

    const sortedHourSold = hourSold.sort((a, b) => b.totalSold - a.totalSold).slice(0, 24);

    const hourData = [['Hour', 'Litros'], ...sortedHourSold.map(({ hour, totalSold }) => [hour, totalSold])];

    return (
        <Paper elevation={3} className='p-2 mt-2 no-print' sx={{
            overflowX: 'auto',
            xl: { width: '600px' },
            md: { width: '100%' },
            maxWidth: '100%',
            height: '700px', // You can adjust the height here
            backgroundColor: '#f5f0f0'
        }}>
            <Chart
                width={'100%'}
                height={'100%'}
                chartType="ColumnChart"
                loader={<div>Loading Chart</div>}
                data={hourData}
                options={{
                    title: 'Litros vendidos por hora',
                    hAxis: {
                        title: 'Hora',
                        minValue: 0,
                    },
                    legend: { position: 'none' },
                }}
                // For tests
                rootProps={{ 'data-testid': '1' }}
            />
        </Paper>
    );
}
