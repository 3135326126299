import { useState } from 'react'
import axios from '../../../axiosConfig'
import SearchData from "../../../utilities/searchs/searchData"
import Loading from '../../../utilities/loading'
import SearchFiliais from '../../../utilities/searchs/searchFiliais'
import { formatDate } from '../../../utilities/helperFunctions'
import { Button, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Paper } from '@mui/material'
import { Send } from '@mui/icons-material'
import { IfilialAtual, useGlobalState } from '../../../globalState'

interface IProps {
  showPDF: (error: boolean, errorMessageP?: string) => void
  setPDFData: React.Dispatch<React.SetStateAction<{}>>
}
type IRelatorio = 'Detalhado' | 'Resumido'
type IOrdenacao = 'Geral' | 'Dia' | 'Mes' | 'Ano'

function MultiResumoValoresVenda(props: IProps) {
  const [firstDate, setFirstDate] = useState(new Date)
  const [lastDate, setLastDate] = useState(new Date)
  const [relatorio, setRelatorio] = useState<IRelatorio>('Detalhado')
  const [ordenacao, setOrdenacao] = useState<IOrdenacao>('Geral')
  const [isFullLoading, setIsFullLoading] = useState(false)
  const [isSelectingFilial, setIsSelectingFilial] = useState(false)
  const [filialAtual] = useGlobalState('filialAtual')
  const [usedFiliais, setUsedFiliais] = useState<IfilialAtual[]>([filialAtual])


  const getTipoRelatorio = () => {
    if (relatorio === 'Detalhado') return 'relatorioDetalhado'
    return `relatorio${ordenacao}`
  }

  const getResumo = (filiais: IfilialAtual[]) => {
    setUsedFiliais(filiais)
    setIsSelectingFilial(false)
    setIsFullLoading(true)
    const postData = {
      dataInicial: `${formatDate(firstDate, 'db')}`,
      dataFinal: `${formatDate(lastDate, 'db')}`,
      empresas: filiais.map(i => i.NUMERO),
      tipo: getTipoRelatorio()
    }
    axios.post('caixa/resumo/multiloja/valores', postData)
      .then(r => {
        if (r.data.length < 1) return props.showPDF(true, 'Sem registros para a data informada')

        let dataResumo = {
          relatorio: { data: r.data, tipo: getTipoRelatorio() },
          date: {
            dataInicial: formatDate(firstDate, 'front'), dataFinal: formatDate(lastDate, 'front'),
          },
          options: { filiaisSelecionadas: filiais.map(i => i.FANTASIA) }
        }
        console.log(dataResumo)
        props.setPDFData(dataResumo)
        props.showPDF(false)
      })
      .catch(err => props.showPDF(true, 'Erro ao buscar informação'))
      .finally(() => setIsFullLoading(false))
  }

  return (
    <div>
      {isFullLoading ? <Loading></Loading> : null}
      <Paper elevation={3} className='p-2' sx={{ backgroundColor: '#f5f0f0' }}>
        <h4 className='relatorio-title'>Relatório resumo de valores de venda:</h4>
        <hr></hr>
        <SearchData setFirstDate={setFirstDate} setLastDate={setLastDate}
          firstDate={firstDate} lastDate={lastDate} />
        <FormControl>
          <FormLabel id="demo-row-radio-buttons-group-label">Tipo:</FormLabel>
          <RadioGroup
            row
            name="row-radio-buttons-group"
            value={relatorio}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRelatorio(e.target.value as IRelatorio)}
          >
            <FormControlLabel value="Detalhado" control={<Radio />} label="Detalhado" />
            <FormControlLabel value="Resumido" control={<Radio />} label="Resumido" />
          </RadioGroup>
        </FormControl>
        <br />
        {relatorio === 'Resumido' ?
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">Ordenação:</FormLabel>
            <RadioGroup
              row
              name="row-radio-buttons-group"
              value={ordenacao}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOrdenacao(e.target.value as IOrdenacao)}
            >
              <FormControlLabel value="Geral" control={<Radio />} label="Nenhuma" />
              <FormControlLabel value="Dia" control={<Radio />} label="Dia" />
              <FormControlLabel value="Mes" control={<Radio />} label="Mes" />
              <FormControlLabel value="Ano" control={<Radio />} label="Ano" />
              <FormControlLabel value="FormasPag" control={<Radio />} label="Formas pagamento" />
              <FormControlLabel value="DiaSemana" control={<Radio />} label="Dia da semana" />
              <FormControlLabel value="GrupoProduto" control={<Radio />} label="Grupo de produtos" />
            </RadioGroup>
          </FormControl> : null}
        <br />
        <Button variant="contained" endIcon={<Send />} onClick={() => setIsSelectingFilial(true)}>Gerar</Button>
        {isSelectingFilial ? <SearchFiliais getFiliais={getResumo}
          close={(filiais: IfilialAtual[]) => { setIsSelectingFilial(false); setUsedFiliais(filiais) }} lastSelectedFiliais={usedFiliais} /> : null}
      </Paper>
    </div>
  )
}

export default MultiResumoValoresVenda
