import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Spinner } from 'react-bootstrap';

interface IProps {
  confirm: Function
  close: React.Dispatch<React.SetStateAction<boolean>>
  open: boolean
  customMessage?: string
}

export default function AlertDialog(props: IProps) {
  const [isLoading, setIsLoading] = useState(false)

  useEffect(()=>{
    if(isLoading)setIsLoading(false)
  },[props.open])

  return (
    <Dialog disableScrollLock
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Verificação"}
      </DialogTitle>
      <DialogContent>

        {!isLoading && <DialogContentText id="alert-dialog-description">
          {props.customMessage || 'Confirmar ação?'}
        </DialogContentText>}
        {isLoading &&
          <div className="d-flex justify-content-center text-primary">
            <Spinner variant="primary" animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.close(false)}>Cancelar</Button>
        <Button disabled={isLoading} onClick={()=>{
          props.confirm()
          setIsLoading(true)
          }} autoFocus>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>

  );
}