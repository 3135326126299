import { Modal } from "react-bootstrap";
import { useState,useEffect } from "react";
import { Button } from "@mui/material";
import { Send } from "@mui/icons-material";
import axios from '../axiosConfig'
import {AxiosError } from 'axios';
import PrecoUpdateProduto from "../views/produtos/tabs/precoUpdate";

interface IProps{
    close:Function
}
interface IProdutos {
    CODIGO: number,
    DESCRICAO: string,
    TIPOPRODUTO: string

}

export function HomeDashboardAlterarPreco(props:IProps) {
    const [produtos,setProdutos] = useState<IProdutos[] | undefined>(undefined)
    const [produtoSelecionado, setProdutoSelecionado] = useState<IProdutos | undefined>(undefined)
    const [searchResults, setSearchResults] = useState<IProdutos[] | undefined>(undefined)
    const [searchTerm, setSearchTerm] = useState('')

    const getProdutos = ()=>{
        axios.get('/produtos/pesquisar/listar',)
        .then(r => setProdutos(r.data))
        .catch(error=>{
            const err = error as AxiosError
            console.log(err.response?.data)
        })
    }


    useEffect(()=>{
        getProdutos()
    },[])

    useEffect(()=>{
        if(searchTerm.trim() === '')return setSearchResults(produtos?.filter(item => item.TIPOPRODUTO.trim() === 'C' ))
        const result = produtos?.filter(item =>{
            if(item.TIPOPRODUTO.trim() === 'C'){
                return item.DESCRICAO.includes(searchTerm.toUpperCase().trim()) ||
                item.CODIGO.toString().includes(searchTerm.trim())
            }
        })
        setSearchResults(result)

    },[searchTerm,produtos])

    return (
        <Modal
            animation={false}
            centered
            show={true}
            backdrop="static"
            keyboard={true}
            size='lg'
        >
            <Modal.Body>
            <input type="text" className="form-control" placeholder='Pesquisar por produto'
                        value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <hr />
                    <select style={{ height: '250px' }} className='form-select' multiple 
                    onChange={(e: React.ChangeEvent<HTMLSelectElement> )=> {
                        if (Number(e.target.value) === 0) return setProdutoSelecionado(undefined)
                        setProdutoSelecionado(searchResults?.find(x=>x.CODIGO === Number(e.target.value)))
                    }} id="selectF">
                        <option value={0}>Todos</option>
                        {searchResults?.map(produto => 
                            <option value={produto.CODIGO}>{produto.CODIGO + " - " + produto.DESCRICAO}</option>
                            )}
                        
                    </select>
                    <hr />

                {produtoSelecionado ?
                <PrecoUpdateProduto produto={produtoSelecionado.CODIGO}/>
                : <p className="text-center">*Selecione um produto para alterar o preço*</p>
            }
            </Modal.Body>
            
            <Modal.Footer>
            <Button variant='contained' color='success' endIcon={<Send />} sx={{ m: 0.5 }}
                                    onClick={() => {
                                        props.close()
                                    }}>
                                    Concluído
                                </Button>
            </Modal.Footer>
        </Modal>
    )
}