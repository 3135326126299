import axios from '../../axiosConfig'
import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import ClienteUpdate from './clienteUpdate'
import ClienteInfo from './clientesInfo'
import ErrorAlert from '../../utilities/errorAlert'
import { useGlobalState } from '../../globalState'
import HandleEmpresas from '../../utilities/changeEmpresa'
import { TextField, InputAdornment, Paper, Button, Box } from '@mui/material'
import { Search, AddCircle, Edit, Visibility, Delete } from '@mui/icons-material'
import ICliente from './clientesInterface'
import ClienteCreate from './clienteCreate'
import SearchGrupoClientes from '../../utilities/searchs/searchGrupoClientes'
import SearchClasseNegociacao from '../../utilities/searchs/searchClasseNegociacao'
import AlertDialog from '../../utilities/dialog'
import { AxiosError } from 'axios'
import { showSnackbar } from '../../utilities/snackbarShow'


type Actions = 'search' | 'create' | 'edit' | 'info'
type Screens = '' | 'grupos' | 'regioes' | 'classes'

function Clientes() {
    const [isLoading, setIsLoading] = useState(true)
    const [errorAlert, setErrorAlert] = useState('')
    ///////////////////////////////////////////////////////////////////
    const [rawData, setRawData] = useState<ICliente[] | undefined>()
    const [searchResults, setSearchResults] = useState<ICliente[]>()
    const [searchTerm, setSearchTerm] = useState("");
    const [currentAction, setCurrentAction] = useState<Actions>('search')
    const [currentScreen, setCurrentScreen] = useState<Screens>('')
    const [itemSelecionado, setItemSelecionado] = useState<ICliente | undefined>()
    const [alertOpen, setAlertOpen] = useState(false)
    ///////////////////////////////////////////////////////////////////
    const [empresaAtual] = useGlobalState('empresaAtual')

    const getClientes = () => {
        setIsLoading(true)
        axios.get(`clientes/listar`).then(r => {
            setRawData(r.data)
        }).catch(error => {
            if (!error.response?.status) setErrorAlert('Erro ao conectar com banco de dados')
            else setErrorAlert('Erro ao listar registros')
        }).finally(() => setIsLoading(false))
    }

    ///////////////////////////////////////////////////////////////////////////////

    const handleDelete = () => {
        itemSelecionado && axios.delete(`/clientes/${itemSelecionado.NUMERO}`).then(r => {
            setRawData(r.data)
            showSnackbar('Item excluido com sucesso')
        }).catch((error) => {
            const err = error as AxiosError
            if (err.response?.status === 422) showSnackbar('Produto possui pendências', 'error')
            else showSnackbar('Erro ao Excluir item', 'error')
        }).finally(() => {
            setAlertOpen(false)
            getClientes()
        })
    }

    useEffect(() => {
        getClientes()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [empresaAtual])

    useEffect(() => {
        if (searchTerm.trim() === '') return setSearchResults(rawData)
        const result = rawData?.filter(person => {
            return person.NOME?.toLowerCase().includes(searchTerm) ||
                person.NUMERO.toString().includes(searchTerm) ||
                person.CNPJ?.includes(searchTerm)
        })
        setSearchResults(result)

    }, [searchTerm, rawData])


    return (
        <div className="container mt-1">
            {errorAlert.length > 0
                ? <ErrorAlert error={errorAlert} clearError={() => setErrorAlert('')} />
                : null
            }
            <div className="d-flex title">
                <div className="p-2 "><h1>Clientes | <span className='empresa-atual'>{empresaAtual.fantasia} - {empresaAtual.cnpj}</span></h1></div>
                <HandleEmpresas />
                <div className="p-2 "><i className="fas fa-user-friends"></i></div>
            </div>
            <hr className='hr-title' />
            <AlertDialog open={alertOpen} close={setAlertOpen} confirm={() => handleDelete} />
            {currentAction === 'create' && <ClienteCreate return={() => setCurrentAction('search')} setClientes={setRawData} />}
            {currentAction === 'edit' && <ClienteUpdate return={() => { setCurrentAction('search'); setItemSelecionado(undefined) }} setClientes={setRawData} data={itemSelecionado!} />}
            {currentAction === 'info' && <ClienteInfo return={() => setCurrentAction('search')} data={itemSelecionado!} />}
            {currentAction === 'search' && <main>
                {currentScreen === 'grupos' && <SearchGrupoClientes itemName='Grupo' return={() => setCurrentScreen('')} notSelecting={true} />}
                {currentScreen === 'classes' && <SearchClasseNegociacao itemName='Classes' return={() => setCurrentScreen('')} notSelecting={true} />}
                <div>
                    <span>Cadastros: </span>
                    <Button variant='contained' color='info' sx={{ m: 0.5 }}
                        onClick={() => {
                            setCurrentScreen('classes')
                        }}>
                        Classe negociação
                    </Button>
                    <Button variant='contained' color='info' sx={{ m: 0.5 }}
                        onClick={() => {
                            setCurrentScreen('grupos')
                        }}>
                        Grupo de clientes
                    </Button>
                </div>

                <Paper elevation={3} className='p-2 mb-2' sx={{ backgroundColor: '#f5f0f0' }}>
                    <div className="mb-1">
                        <TextField type="text" className="form-control" size='small'
                            variant='outlined' placeholder="Pesquisar por nome, número,razão social, CPF e CNPJ"
                            value={searchTerm}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search color='success' />
                                    </InputAdornment>
                                ),
                            }}
                        ></TextField>
                    </div>
                    <p className='mb-0'>Total: <span className='text-success'>{searchResults?.length}</span></p>
                </Paper>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Button variant='contained' color='success' endIcon={<Visibility />} sx={{ m: 0.5 }}
                        onClick={() => {
                            itemSelecionado ? setCurrentAction('info') : showSnackbar('Nenhum item foi selecionado', 'error')
                        }}>
                        Consultar
                    </Button>
                    <Button variant='contained' color='info' endIcon={<AddCircle />} sx={{ m: 0.5 }}
                        onClick={() => {
                            setCurrentAction('create')
                        }}>
                        Novo
                    </Button>
                    <Button variant='contained' color='info' endIcon={<Edit />} sx={{ m: 0.5 }}
                        onClick={() => {
                            itemSelecionado ? setCurrentAction('edit') : showSnackbar('Nenhum item foi selecionado', 'error')
                        }}>
                        Alterar
                    </Button>
                    <Button variant='contained' color='error' endIcon={<Delete />} sx={{ m: 0.5,marginLeft: 'auto'}}
                        onClick={() => {
                            itemSelecionado ? setAlertOpen(true) : showSnackbar('Nenhum item foi selecionado', 'error')
                        }}>
                        Excluir
                    </Button>
                </Box>
                <div>

                    {isLoading &&
                        <div className="d-flex justify-content-center text-primary">
                            <Spinner variant="primary" animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>}
                    <div className='table-responsive' style={{ maxHeight: '450px', overflow: 'scroll' }}>
                        <table className="table table-secondary table-hover">
                            <thead className="table-primary sticky-top">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Nome</th>
                                    <th scope="col">Estado</th>
                                    <th scope="col">Grupo</th>
                                </tr>
                            </thead>
                            <tbody>
                                {searchResults?.map(dados =>
                                    <tr key={dados.NUMERO} className={`tr-size ${itemSelecionado?.NUMERO === dados.NUMERO && 'table-active'}`} style={{ cursor: 'pointer' }}
                                        onClick={() => setItemSelecionado(dados)} >
                                        <th scope="row">{dados.NUMERO}</th>
                                        <td className={dados.BLOQUEADO.trim() === 'S' && 'text-danger' || ''}>{dados.NOME}</td>
                                        <td>{dados.ESTADO ? dados.ESTADO : 'Estado não cadastrado'}</td>
                                        <td>{dados.NOME_GRUPO ? dados.NOME_GRUPO : 'Grupo não inserido'}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                </div>
            </main>}

        </div>
    )
}
export default Clientes