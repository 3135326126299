import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ptBR } from 'date-fns/locale'

interface IProps{
  placeHolder:string
  date:Date
  setDate: React.Dispatch<React.SetStateAction<Date>> | Function
}

export default function BasicDatePicker(props:IProps) {

  return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
        <DatePicker
            label={props.placeHolder}
            value={props.date}
            format="dd/MM/yyyy"
            onChange={(newValue) => {
              newValue &&
                   props.setDate(newValue)
                   }}
            slotProps={{ textField: { size: 'small', className: 'small-DatePicker' } }}
        />
    </LocalizationProvider>
  );
}
