import GeralPDF from "./geralPDF";
import GeralMesPDF from "./geralMesPDF";
import CicloPDF from "./cicloPDF";
import CicloMesPDF from "./cicloMesPDF";
import {CombustiveisData as IProps,Geral,GeralMes,Ciclo,CicloMes} from '../interfacesPDF'


export default function CombustiveisPDF(props:IProps){
    const PDF = ()=>{
        if(props.tipo === 'Ciclo' && props.ordenar)return <CicloMesPDF  {...props} data={props.data as CicloMes[]} date={props.date} options={props.options}/>
        else if(props.tipo === 'Ciclo' && !props.ordenar)return <CicloPDF data={props.data as Ciclo[]} date={props.date} options={props.options}/>
        else if(props.ordenar)return <GeralMesPDF data={props.data as GeralMes[]} date={props.date} options={props.options}/>
        else return <GeralPDF data={props.data as Geral[]} date={props.date} options={props.options}/>
    }

    return(
        <div>{PDF()}</div>
    )

}