import { useState } from 'react'
import { Button, Paper } from '@mui/material'
import { Send } from '@mui/icons-material'
import axios from '../../../axiosConfig'
import SearchData from "../../../utilities/searchs/searchData"
import { formatDate } from '../../../utilities/helperFunctions'
import Loading from '../../../utilities/loading'

interface IProps {
    showPDF: (error: boolean, errorMessageP?: string) => void
    setPDFData: React.Dispatch<React.SetStateAction<{}>>
}

function Afericao(props: IProps) {
    const [firstDate, setFirstDate] = useState(new Date)
    const [lastDate, setLastDate] = useState(new Date)
    const [isFullLoading, setIsFullLoading] = useState(false)

    const getAfericoes = () => {
        setIsFullLoading(true)
        const postData = {
            dataInicial: `${formatDate(firstDate, 'db')}`,
            dataFinal: `${formatDate(lastDate, 'db')}`,
          }
        axios.post('relatorios/estoque/afericoes',postData,
            )
            .then(r => {
                if (r.data.length < 1) return props.showPDF(true, 'Sem registros para a data informada')
                props.setPDFData({ data: r.data })
                props.showPDF(false)
            }).catch(err => {
                props.showPDF(true, 'Erro ao buscar dados')
            }).finally(() => setIsFullLoading(false))
}

    const getPostAfericoes = ()=>{
        setIsFullLoading(true)
        const postData = {

        }
    }


return (
    <div>
        {isFullLoading ? <Loading></Loading> : null}
        <Paper elevation={3} className='p-2' sx={{ backgroundColor: '#f5f0f0' }}>
            <SearchData setFirstDate={setFirstDate} setLastDate={setLastDate}
                firstDate={firstDate} lastDate={lastDate} />
            <Button variant='contained' endIcon={<Send />} onClick={() => getAfericoes()}>Gerar</Button>
        </Paper>
    </div>
)
}
export default Afericao