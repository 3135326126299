/* eslint-disable */

import { useEffect, useState } from 'react';
import BasicDatePicker from '../../../utilities/dateComponent';
import axios from '../../../axiosConfig'
import { Spinner } from 'react-bootstrap'
import Loading from '../../../utilities/loading'
import {formatDate} from '../../../utilities/helperFunctions'
import { useGlobalState } from '../../../globalState'
import {Button, Select, MenuItem,TextField,Paper} from '@mui/material'
import {Send} from '@mui/icons-material'

function EstoqueFinalMovimento(props) {
    const [firstDate, setFirstDate] = useState('')
    const [grupos, setGrupos] = useState([])
    const [selectedGrupos, setSelectedGrupos] = useState([])
    const [estoque, setEstoque] = useState([])
    const [selectedEstoque, setSelectedEstoque] = useState('0')
    const [checkboxEstoque, setCheckboxEstoque] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [isFullLoading, setIsFullLoading] = useState(false)
    const [alertData, setAlertData] = useState(false)
    const [empresaAtual] = useGlobalState('empresaAtual')

    useEffect(() => {
        let date = new Date()
        let yesterday = new Date(date.getTime());
        yesterday.setDate(date.getDate() - 1);
        setFirstDate(yesterday)

        setIsLoading(true)
        axios.get('produtos/grupos', ).then((r) => {
            setGrupos(r.data)
            const codigoGrupos = r.data.map((item) => item.CODIGO)
            setIsLoading(false)
            setSelectedGrupos(codigoGrupos)
        }).catch(err => console.log(err))
        axios.get('localestoque', ).then((r) => {
            setEstoque(r.data)
        })

    }, [empresaAtual])


    const handleSelectedGrupos = (CODIGO) => {
        if (selectedGrupos.find(g => g === CODIGO) === undefined) {
            setSelectedGrupos(grupos => [...grupos, CODIGO])
        } else {
            setSelectedGrupos(selectedGrupos.filter(grupo => grupo !== CODIGO))
        }
    }

    const checkAllSelectGrupos = () => {
        setSelectedGrupos(grupos.map(grupo => grupo.CODIGO))
    }
    const clearSelectedGrupos = () => {
        setSelectedGrupos([])
    }

    const verifyData = () => {
        
        if (firstDate instanceof Date && !isNaN(firstDate))return true
        else{
            setAlertData(true)
            return false
        }
    }

    function roundDecimals(num, decimals) {
        num = Number(num)
        num = num.toFixed(decimals)
        return num
      }
    useEffect(() => {
        setAlertData(false)
        console.log(alertData)
    }, [firstDate])

    const getEstoqueFinal = () => {

        if (!verifyData()) return

        const postData = {
            dataMovimento: `${formatDate(firstDate,'db')}`,
            grupoProduto: selectedGrupos,
            codigoEstoqueInicial: selectedEstoque == 0 ? 0 : Number(selectedEstoque),
            codigoEstoqueFinal: selectedEstoque == 0 ? 999 : Number(selectedEstoque)
        }
        setIsFullLoading(true)

        axios.post('relatorios/estoquefinal', postData, )
            .then((r) => {
                if(r.data.length < 1)return props.showPDF(true,'Sem registros para essa data')
              
                
                if (checkboxEstoque === true) {
                   r.data = r.data.filter((itemP) => Number(itemP.ESTOQUEATUAL) > 0 
                    )
                }

                let gruposProdutos = []
                grupos.map(item => {
                    let totalGrupoAtual = 0
                    let totalGrupoMedio = 0
                    let produtos = r.data.filter((itemP) => {

                        if (checkboxEstoque === true && Number(itemP.ESTOQUEATUAL) <= 0) return
                        if (item.CODIGO != itemP.CODIGO_GRUPO) return
                        if(Number(itemP.ULTIMOCUSTO) === 0)itemP.ULTIMOCUSTO = itemP.CUSTOATUAL
  
                        totalGrupoAtual += Number((itemP.ESTOQUEATUAL * roundDecimals(itemP.ULTIMOCUSTO,3)).toFixed(2))
                        
                        totalGrupoMedio += Number((roundDecimals(itemP.MEDIACUSTO,3) * itemP.ESTOQUEATUAL).toFixed(2))
                       

                        return itemP

                    })
                    gruposProdutos.push({
                        nome: item.DESCRICAO, numero: item.CODIGO, produtos: produtos,
                        totalGrupoAtual: totalGrupoAtual, totalGrupoMedio: totalGrupoMedio
                    })
                    return

                })

                let data = { data: gruposProdutos, date: formatDate(firstDate,'front') }
                props.setPDFData(data)
                props.showPDF(false)


            }).catch((err) => {
                console.log(err)

            }).finally(() => setIsFullLoading(false))
    }
    return (
        <div>
            {isFullLoading ? <Loading></Loading> : null}
            <Paper elevation={3} className='p-3' sx={{backgroundColor:'#f5f0f0'}}>
            <h4 className='relatorio-title'>Relatório de estoque final do movimento:</h4>
            <div className="row">
                <div className='col-md-2'>
                    <BasicDatePicker 
                        date={firstDate} setDate={setFirstDate} placeHolder='Data'
                        />
                </div>
                <label className='col-md-1 col-form-label' htmlFor="selectF">Estoque:</label>
                <div className="col-md-1"><TextField readOnly type="text" value={selectedEstoque} 
                size='small' label='Numero'
                style={{ maxWidth: '90px' }} className="form-control"/>
                </div>

                <div className='col-md-1'><Select size='small' value={selectedEstoque} label='Estoque'
                    onChange={e => {
                        setSelectedEstoque(e.target.value)
                    }} id="selectF">
                    <MenuItem value="0">Todos</MenuItem>
                    {estoque.map((e) =>
                        <MenuItem key={e.NUMERO} value={e.NUMERO}>{e.DESCRICAO}</MenuItem>
                    )}
                </Select>
                </div>
                <div className='col-md-4'>
                    <input className="form-check-input" type="checkbox" value={checkboxEstoque}
                       id='apenas-produtos' onChange={() => setCheckboxEstoque(!checkboxEstoque)}></input>
                    <label className="form-check-label" htmlFor="apenas-produtos">ㅤApenas produtos com estoque</label>
                </div>
            </div>
            <span className={alertData ? 'text-danger' : 'collapse'}>data informada incorretamente</span>

            <table className="table table-secondary table-hover mt-1" >
                <thead className="table-primary">
                    <tr>
                        <th style={{ width: '100px' }} scope="col">Selecionado</th>
                        <th style={{ width: '100px' }} scope="col">Código</th>
                        <th scope="col">Descrição</th>
                    </tr>
                </thead> </table>

            {isLoading ?

                <div className="d-flex justify-content-center text-primary">
                    <Spinner variant="primary" animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div> :
                <div className='table-scroll'>

                    <table className="table table-secondary" >
                        <tbody>
                            {grupos.map(dados =>
                                <tr key={dados.CODIGO}>
                                    <td style={{ width: '100px' }} scope="row" className='max'>
                                        <div className="zero-padding"><i style={{ paddingBottom: '20px' }}
                                            className={selectedGrupos.indexOf(dados.CODIGO) === -1 ? "fas fa-thin fa-plus" : "fas fa-thin fa-check"}
                                            onClick={() => handleSelectedGrupos(dados.CODIGO)}></i></div>
                                    </td>
                                    <td style={{ width: '100px' }}> {dados.CODIGO}</td>
                                    <td>{dados.DESCRICAO}</td>
                                </tr>)}
                        </tbody>
                    </table></div>
            }
            <div className='text-center'></div>
            <div className='row m-2'>
                <Button className="col-sm-1" variant='contained' endIcon={<Send/>} onClick={() => getEstoqueFinal()} >Gerar</Button>
                <div className="col-sm-1" style={{ maxWidth: '30px' }}><i style={{ cursor: 'pointer' }} onClick={() => checkAllSelectGrupos()} className="fas fa-check-double"></i></div>
                <div className="col-sm-1" style={{ maxWidth: '30px' }}><i style={{ color: 'red', cursor: 'pointer', maxWidth: '20px' }} onClick={() => clearSelectedGrupos()} className="fas fa-window-close"></i></div>
            </div>
            </Paper>
        </div>
    )
}

export default EstoqueFinalMovimento