import { useTable } from "react-table";
import { useSortBy } from "react-table/dist/react-table.development";

export default function Table({ columns, data }) {
  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    footerGroups,
    rows, // rows for the table based on the data passed
    prepareRow // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable({
    columns,
    data
  },useSortBy)

  return (
    
    <table className="table table-striped table-sm" {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th  scope="col"{...column.getHeaderProps(column.getSortByToggleProps())}
              className={
                column.isSorted
                  ? column.isSortedDesc
                    ? "sort-desc"
                    : "sort-asc"
                  : ""
              }>{column.render("Header")} 
              {column.canSort ? <i className="fa-solid fa-arrow-down-arrow-up no-print"></i> : null }
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody style={{fontSize:'12px'}} {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row); // This line is necessary to prepare the rows and get the row props from react-table dynamically

          // Each row can be rendered directly as a string using the react-table render method
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>

      <tfoot>
        {footerGroups.map(group => (
          <tr {...group.getFooterGroupProps()}>
            {group.headers.map(column => (
              <td {...column.getFooterProps()}>{column.render('Footer')}</td>
            ))}
          </tr>
        ))}
      </tfoot>


    </table>
    
  );
}