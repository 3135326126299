import './homeADM.css'
import { useNavigate } from 'react-router-dom'
import Home from './home';
import { useGlobalState } from '../globalState';

function HomeCadastro() {
  const [usuarioConectado] = useGlobalState('usuarioConectado')
  const [usuarioFirebirdConectado] = useGlobalState('usuarioFirebirdConectado')
  const navigate = useNavigate();

  return (
    <div className="container mt-3">
      <Home />
      <h5>Email: {usuarioConectado.email}</h5>
      <h5>Usuario: {usuarioFirebirdConectado?.USUARIO}</h5>
      <div className="row1-container">
        <div className="box cyan" onClick={() => navigate('/adm/clientes')}>
          <h2>Clientes</h2>
          <p>Gerenciar clientes, grupos de clientes e classes de negociações</p>
        </div>

        <div className="box  blue" onClick={() => navigate('/adm/produtos')}>
          <h2>Produtos</h2>
          <p>Gerenciar produtos, tipos e grupos de produtos, tabelas de preço e brindes</p>
        </div>
      </div>
    </div>
  )
}

export default HomeCadastro