import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { useGlobalState } from '../globalState'
import { Paper, Box, CircularProgress } from "@mui/material";
import axios from '../axiosConfig'

export const options = {
    legend: { position: "none" },
    chartArea: { top: 10, bottom: 30, right: 18 },
    backgroundColor: '#f5f0f0',
    diff: {
        innerCircle: {
            radiusFactor: 0.3
        },
        oldData: {
            color: '#bdc3c7',
            tooltip: {
                prefix: 'uta'
            }
        },
        newData: {
            color: '#2980b9',
            tooltip: {
                prefix: 'Atual'
            }
        }
    }
}

interface Estoque {
    CODIGO_PRODUTO: number;
    DESCRICAO_PRODUTO: string;
    NUMERO_TANQUE: number;
    CAPACIDADE_TANQUE: string;
    QUANTIDADE_TANQUE: string;
    PORC_TANQUE: string;
    DATA_MEDICAO: string;
    HORA_MEDICAO: string | null;
}
type EstoqueLocal = 'estoque' | 'lmc' | 'med' | ''

interface IProps {
    estoque: Estoque[]
    estoqueLocal: EstoqueLocal
}

export default function GraphTanques({estoque,estoqueLocal}: IProps) {
    const [isLoading, setIsLoading] = useState(false)
    const [empresaAtual] = useGlobalState('empresaAtual')
    const [filialAtual] = useGlobalState('filialAtual')

    const generateEstoqueData = (estoqueEmp: Estoque[]) => {
        const template = ["Name", "Estoque"]
        const estoqueTotal = estoqueEmp?.map(item => {
            return [item.DESCRICAO_PRODUTO, Number(item.CAPACIDADE_TANQUE)]
        })
        const estoqueTotal2 = [template, ...estoqueTotal]

        const estoqueAtual = estoqueEmp?.map(item => {
            return [item.DESCRICAO_PRODUTO, Number(item.QUANTIDADE_TANQUE)]
        })
        const estoqueAtual2 = [template, ...estoqueAtual]

        const DiffData = {
            old: estoqueTotal2,
            new: estoqueAtual2,
        }

        return DiffData
    }
    if (estoque !== null) generateEstoqueData(estoque)

    if (empresaAtual.filiais?.length > 1 && empresaAtual.cnpj === filialAtual.CNPJ?.trim()) return (
        <Paper elevation={3} className='p-2 mt-2' sx={{ xl: { width: '400px' }, md: { width: '500px' }, maxWidth: '600px', height: '400px', backgroundColor: '#f5f0f0' }}>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight={'100%'}
            >
                <p className="text-center">Selecione uma filial para ver o gráfico</p>
            </Box>

        </Paper>)

    return (
        <Paper elevation={3} className='p-2 mt-2' sx={{ xl: { width: '400px' }, md: { width: '500px' }, maxWidth: '600px', height: '450px', backgroundColor: '#f5f0f0' }}>
            <p style={{ marginBottom: '0px' }} className='text-center'>Tanques <i className="fa-solid fa-gas-pump" style={{ color: 'blue' }} /></p>
            {isLoading ?
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight={'80%'}
                >
                    <CircularProgress />
                </Box> : estoque !== null && estoque.length > 0 ? <Chart
                    chartType="BarChart"
                    width='100%'
                    height='80%'
                    diffdata={generateEstoqueData(estoque)}
                    options={options}
                />
                    : <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        minHeight={'80%'}
                    ><p className="text-center">Sem informações para o grafico de tanques</p></Box>}
            {
            }
            <p className='text-center'>Atual: <i className="fa-solid fa-square" style={{ color: '#2980b9' }} /> | máximo: <i className="fa-solid fa-square" style={{ color: '#bdc3c7' }} /></p>
            <p>*Retirado {estoqueLocal === 'med' ? 'da medição de tanque' : estoqueLocal === 'lmc' ? 'do lmc' : 'do estoque de tanque'}</p>
        </Paper>

    );
}
