import { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { useGlobalState } from '../globalState'
import { Paper, Box, CircularProgress } from "@mui/material";
import axios from '../axiosConfig'
import { formatDate } from '../utilities/helperFunctions'

interface Produto {
    NUMERO_GRUPOPRODUTO: number;
    TIPOPRODUTO: string;
    DESCRICAO: string;
    NUMERO_PRODUTO: number;
    DESCRICAO_PRODUTO: string;
    QUANTIDADE: string;
    PRECO_MEDIO: string;
    TOTALCOMDESCACR: string;
    TOTALDESCACRAPLICADO: string;
}

const options = {
    legend: { position: "none" },
    backgroundColor: '#f5f0f0',
    chartArea: { top: 10, bottom: 90,right:5,left:60 },
}

interface IProps {
    produtos:Produto[]
}

function GraphVendaProdutos({produtos}:IProps) {
    const [isLoading, setIsLoading] = useState(false)

    const generateProdutosGraph = (produtosData: Produto[]) => {
        const template = ["Element", ""]
        const MoreSoldProdutos = produtosData.sort(function (a, b) { return Number(a.TOTALCOMDESCACR) - Number(b.TOTALCOMDESCACR) }).reverse()
        .map(item=>[item.DESCRICAO_PRODUTO,Number(item.TOTALCOMDESCACR)])
        return [template,...MoreSoldProdutos.slice(0,5)]
    }

    return (
        <Paper elevation={3} className='p-2 mt-2' sx={{ xl: { width: '400px' }, md: { width: '500px' }, maxWidth: '600px', height: '450px', backgroundColor: '#f5f0f0' }}>
            <p>Mais vendidos ultimos 30 dias</p>
            {isLoading ?
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight={'100%'}
        >
          <CircularProgress />
        </Box> : produtos !== null && produtos.length > 1 ?
                <Chart chartType="ColumnChart" width="100%" height="400px" data={generateProdutosGraph(produtos)} options={options} />
                : <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight={'100%'}
              ><p className="text-center">Sem informações para o grafico de vendas de produtos</p></Box>}
        </Paper>
    )
}

export default GraphVendaProdutos