import { IProduto } from "./produtosInterface"; 
import { showSnackbar } from "../../utilities/snackbarShow";

interface IProdutoCheck extends IProduto {
  PRECO?: string;
}

export function isValidMoneyValue(input: string): boolean {
  // Check if the input is a valid numeric value with up to two decimal places
  if (input.trim().replace('R$','') === '') return false;
  const validFormat = /^\d+(\,\d{1,3})?$/.test(input.replace('R$','').trim());
  
  if (!validFormat)return false;
  const numericValue = parseFloat(input);
  if (numericValue === 0)return false;
  return true;
}

export const CheckFields = (item:IProdutoCheck):boolean =>{
    console.log(item)
    const invalidCodes = ['010', '030', '060', '070', '201', '202', '203', '500'];
    if(item.CODIGO_CEST?.trim() === '' && invalidCodes.includes(item.CODIGO_ST)){
      showSnackbar('Codigo CEST inválido','error')
      return false
    }
    if(item.DESCRICAO?.trim() === '' || item.NOMEFANTASIA?.trim() === '' ){
      showSnackbar('Informações necessarias não inseridas','error')
      return false
    }
    if(item.PRECO && !isValidMoneyValue(item.PRECO)){
      showSnackbar('Preço inserido incorretamente','error')
      return false
    }
    
    return true
  }

