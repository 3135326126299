import { useState } from 'react'
import {NavLink, useLocation} from 'react-router-dom'
import { HomeDashboardAlterarPreco } from '../../home/homeDashboardAlterarPreco'
import { useGlobalState } from '../../globalState'

function RelatorioNav(){
    const [usuarioConectado] = useGlobalState('usuarioConectado')
    const location = useLocation()

    const [alterarPreco,setAlterarPreco] = useState(false)

    return(
        <>
         {alterarPreco && <HomeDashboardAlterarPreco close={()=>setAlterarPreco(false)}/>}
      <li className="nav-item-side">
      <NavLink to="/dashboard/caixas" className={location.pathname === "/dashboard/caixas" ? "nav-link-side" : "nav-link-side grayscale"}>
                        <i className="fas fa-sack-dollar fa-2x"></i>
                        <span className="link-text">
                            Caixas
                        </span>
                </NavLink>
      </li>

      <li className="nav-item-side">
      <NavLink to="/dashboard/relatorios" className={location.pathname === "/dashboard/relatorios" ? "nav-link-side" : "nav-link-side grayscale"}>
                        <i className="fas fas fa-ballot-check fa-2x"></i>
                        <span className="link-text">
                            Relatórios
                        </span>
                </NavLink>
      </li> 
      <li className="nav-item-side">
        {
            usuarioConectado?.permissoes?.cadastro === 2 && 
            <NavLink to="#" className={"nav-link-side grayscale"} onClick={() => {setAlterarPreco(true) }}>
            <i className="fas fas fa-edit fa-2x"></i>
            <span className="link-text">
                Preço produto
            </span>
    </NavLink>
        }
            
      </li> 
        </>
    )
}
export default RelatorioNav