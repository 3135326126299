import { useState, useCallback } from "react"
import { handleChangeCheckInputNumber, handleChangeTextInput } from "../../utilities/inputsUtilities"
import { Button, Checkbox, FormControlLabel, Grid, IconButton, InputAdornment, Paper, Radio, TextField, Typography } from "@mui/material"
import { Send, Visibility, VisibilityOff } from "@mui/icons-material"
import axios from "../../axiosConfig"
import { IUser, IUserPermissao } from "../../utilities/typesFromDB"
import { useGlobalState } from "../../globalState"
import { showSnackbar } from "../../utilities/snackbarShow"
import Loading from "../../utilities/loading"

interface IUsuarioCreate {
  return: Function,
}

function UsuarioCreate(props: IUsuarioCreate) {
  const [empresas] = useGlobalState('empresas')
  const [empresaAtual] = useGlobalState('empresaAtual')
  const [showPassword, setShowPassword] = useState(false);
  const [isFullLoading, setIsFullLoading] = useState(false)
  const [user, setUser] = useState<IUser>({
    id: 0,
    name: '',
    email: '',
    password: '',
    master: 0,
    ativo: 1,
    dashboard: 0,
    sistema: 0,
  });
  const [userPermissao, setUserPermissao] = useState<IUserPermissao>({
    cadastro: 0, lancamento: 0, financeiro: 0, fiscal: 0,
  });
  const [selectedEmpresas, setSelectedEmpresas] = useState<number[]>([empresaAtual.id]);
  const handleEmpresaCheckboxChange = (id: number) => {
    if (selectedEmpresas.includes(id)) {
      setSelectedEmpresas(selectedEmpresas.filter((item) => item !== id));
    } else {
      setSelectedEmpresas([...selectedEmpresas, id]);
    }
  };
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleChangeRadio = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setUserPermissao(formData => ({ ...formData, [name]: Number(value) }))
  }, [])

  const createUsuario = () => {
    if (user.name.trim() === '' || user.email.trim() === '') {
      showSnackbar('Campos vazios', 'error')
      return
    }
    if (selectedEmpresas.length < 1) {
      showSnackbar('Nenhuma empresa selecionada', 'error')
      return
    }
    setIsFullLoading(true)
    axios.post('criar/mysql/usuario', { user, userPermissao, selectedEmpresas }).then(r => {
      showSnackbar('Registro criado', 'success')
      props.return()
    }).catch(err => {
      showSnackbar('Erro ao criar registro', 'error')
    }).finally(() => setIsFullLoading(false))
  }

  return (
    <div className="">
      {isFullLoading ? <Loading /> : null}
      <div className=".arrow-left "><i onClick={() => props.return()} className="fad fa-arrow-to-left arrow-left "></i></div>
      <Paper elevation={3} sx={{ backgroundColor: '#f5f0f0', p: 3 }}>
        <h5 className='fw-bolder'>Usuario:</h5> <hr />
        <div className="row mb-3">
          <div className="col-auto">Nome:</div>
          <TextField hiddenLabel className='col' size="small" name='name'
            value={user.name} onChange={(event) => handleChangeTextInput(event, setUser)}
            inputProps={{ maxLength: 100 }}
          />
          <div className="col-auto">Email:</div>
          <TextField hiddenLabel className='col' size="small" name='email' type="email"
            value={user.email} onChange={(event) => handleChangeTextInput(event, setUser)}
            inputProps={{ maxLength: 100 }}
          />
        </div>
        <div className="row mb-3">
          <FormControlLabel className='col' control={<Checkbox size='small' name='dashboard'
            checked={user.dashboard === 1} onChange={(event) => handleChangeCheckInputNumber(event, setUser)} />}
            label="Acesso Dashboard" />
          <FormControlLabel className='col' control={<Checkbox size='small' name='sistema'
            checked={user.sistema === 1} onChange={(event) => handleChangeCheckInputNumber(event, setUser)} />}
            label="Acesso ADM" />
          <FormControlLabel className='col' control={<Checkbox size='small' name='ativo'
            checked={user.ativo === 1} onChange={(event) => handleChangeCheckInputNumber(event, setUser)} />}
            label="Ativo" />
        </div>
        <h5 className='fw-bolder'>Permissões:</h5> <hr />
        <div>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <h6>Cadastro:</h6>
              <FormControlLabel
                control={<Radio size='small' name='cadastro' value={0} checked={userPermissao.cadastro === 0} id='1' onChange={handleChangeRadio} />}
                label="Sem acesso"
              />
              <FormControlLabel
                control={<Radio size='small' name='cadastro' value={1} checked={userPermissao.cadastro === 1} id='1' onChange={handleChangeRadio} />}
                label="Visualizar"
              />
              <FormControlLabel
                control={<Radio size='small' name='cadastro' value={2} checked={userPermissao.cadastro === 2} id='1' onChange={handleChangeRadio} />}
                label="Editar"
              />
            </Grid>
            <Grid item xs={6}>
              <h6>Lançamento:</h6>
              <FormControlLabel
                control={<Radio size='small' name='lancamento' value={0} checked={userPermissao.lancamento === 0} id='1' onChange={handleChangeRadio} />}
                label="Sem acesso"
              />
              <FormControlLabel
                control={<Radio size='small' name='lancamento' value={1} checked={userPermissao.lancamento === 1} id='1' onChange={handleChangeRadio} />}
                label="Visualizar"
              />
              <FormControlLabel
                control={<Radio size='small' name='lancamento' value={2} checked={userPermissao.lancamento === 2} id='1' onChange={handleChangeRadio} />}
                label="Editar"
              />
            </Grid>
            <Grid item xs={6}>
              <h6>Financeiro:</h6>
              <FormControlLabel
                control={<Radio size='small' name='financeiro' value={0} checked={userPermissao.financeiro === 0} id='1' onChange={handleChangeRadio} />}
                label="Sem acesso"
              />
              <FormControlLabel
                control={<Radio size='small' name='financeiro' value={1} checked={userPermissao.financeiro === 1} id='1' onChange={handleChangeRadio} />}
                label="Visualizar"
              />
              <FormControlLabel
                control={<Radio size='small' name='financeiro' value={2} checked={userPermissao.financeiro === 2} id='1' onChange={handleChangeRadio} />}
                label="Editar"
              />
            </Grid>
            <Grid item xs={6}>
              <h6>Fiscal:</h6>
              <FormControlLabel
                control={<Radio size='small' name='fiscal' value={0} checked={userPermissao.fiscal === 0} id='1' onChange={handleChangeRadio} />}
                label="Sem acesso"
              />
              <FormControlLabel
                control={<Radio size='small' name='fiscal' value={1} checked={userPermissao.fiscal === 1} id='1' onChange={handleChangeRadio} />}
                label="Visualizar"
              />
              <FormControlLabel
                control={<Radio size='small' name='fiscal' value={2} checked={userPermissao.fiscal === 2} id='1' onChange={handleChangeRadio} />}
                label="Editar"
              />
            </Grid>
          </Grid>
        </div>
        <h5 className='fw-bolder'>Empresas:</h5> <hr />
        <div>
          {empresas.map((empresa) => (
            <FormControlLabel
              key={empresa.id}
              control={
                <Checkbox
                  checked={selectedEmpresas.includes(empresa.id)}
                  onChange={() => handleEmpresaCheckboxChange(empresa.id)}
                />
              }
              label={<Typography>
                {empresa.fantasia} - <span style={{ color: 'orange' }}>{empresa.cnpj}</span>
              </Typography>}
            />
          ))}
        </div>
        <Button variant='contained' size='small' color='success' endIcon={<Send />}
          onClick={() => createUsuario()}
        >Aplicar</Button>
      </Paper>

    </div>
  )
}
export default UsuarioCreate