import { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {useGlobalState,IfilialAtual} from '../../globalState'

interface IProps{
    getFiliais:(filiais: IfilialAtual[]) => void,
    lastSelectedFiliais?: IfilialAtual[],
    close:(filiais: IfilialAtual[])=>void
}

function SearchEmpresas(props:IProps) { 
    const [empresa] = useGlobalState('empresaAtual')
    const [filialAtual] =  useGlobalState('filialAtual')
    const [selectedFiliais,setSelectedFiliais] = useState(props.lastSelectedFiliais ? props.lastSelectedFiliais : [filialAtual])

    const handleSelectedFiliais = (item: IfilialAtual)=>{
        if (selectedFiliais.indexOf(item) === -1)setSelectedFiliais(filiais=> [...filiais,item])
        else setSelectedFiliais(selectedFiliais.filter(filial => filial !== item))
     }
     
    const selectAllFiliais = () =>{
        setSelectedFiliais(empresa.filiais)
    }
    const clearFiliais = () =>{
        setSelectedFiliais([])
    }
     
 
    return (
        <Modal
        animation={false}
        centered
        show={true}
        backdrop="static"
        keyboard={true}
    >
        <Modal.Body>

            <div>
            {empresa.filiais?.map((item)=>
                <div className="form-check">
                <input className="form-check-input" type="checkbox" value={item.NUMERO} id={item.NUMERO.toString()}
                checked={selectedFiliais.findIndex(x=> x.NUMERO === item.NUMERO) === -1 ? false : true}
                onClick={()=>handleSelectedFiliais(item)}/>
                <label className="form-check-label" htmlFor={item.NUMERO.toString()}>
                {item.FANTASIA + " - " + item.CNPJ + " "} 
          
                </label>
            </div>
            )}
            </div>
            
        </Modal.Body>
        <Modal.Footer>
        <div data-tooltip="Selecionar todos" data-tooltip-location="top"><i style={{ cursor: 'pointer' }} onClick={() => selectAllFiliais()} className="fas fa-check-double"></i></div>
    <div data-tooltip="Remover todos" data-tooltip-location="top"><i style={{ color: 'red', cursor: 'pointer' }} onClick={() => clearFiliais()} className="fas fa-window-close"></i></div>

    
  <Button variant='danger' onClick={()=>props.close(selectedFiliais)}>
    Fechar
  </Button>
  <Button variant='success' onClick={()=>props.getFiliais(selectedFiliais.filter(i => Object.keys(i).length > 0))}>
    Concluir
  </Button>
                             
</Modal.Footer>

    </Modal>
    )

}
export default SearchEmpresas