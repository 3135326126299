import { useState } from 'react'
import axios from '../../../../axiosConfig'
import { formatDate } from '../../../../utilities/helperFunctions'
import Loading from '../../../../utilities/loading'
import { Button, FormControl, FormControlLabel, FormLabel, Paper, Radio, RadioGroup,Checkbox } from '@mui/material'
import { Send } from '@mui/icons-material'
import SearchData from "../../../../utilities/searchs/searchData"
import SearchFiliais from '../../../../utilities/searchs/searchFiliais'
import { IfilialAtual, useGlobalState } from '../../../../globalState'

interface IProps {
    showPDF: (error: boolean, errorMessageP?: string) => void
    setPDFData: React.Dispatch<React.SetStateAction<{}>>
}
type IRelatorio = 'Geral' | 'Ciclo'

function Combustiveis(props: IProps) {
    const [firstDate, setFirstDate] = useState(new Date)
    const [lastDate, setLastDate] = useState(new Date)
    const [isFullLoading, setIsFullLoading] = useState(false)
    const [isSelectingFilial, setIsSelectingFilial] = useState(false)
    const [filialAtual] = useGlobalState('filialAtual')
    const [usedFiliais, setUsedFiliais] = useState< IfilialAtual[]>([filialAtual])
    const [relatorio, setRelatorio] = useState<IRelatorio>('Ciclo')
    const [ordenar,setOrdenar] = useState(false)
    

    const getCombustivel = (filiais: IfilialAtual[]) => {
        setIsSelectingFilial(false)
        setUsedFiliais(filiais.filter(item => item !== null))
        setIsFullLoading(true)
        const postData = {
            dataInicial: `${formatDate(firstDate, 'db')}`,
            dataFinal: `${formatDate(lastDate, 'db')}`,
            empresas: filiais.map(i => i.NUMERO),
            tipo:relatorio,
            ordenar:ordenar
        }
        axios.post('/relatorios/combustiveis', postData)
            .then(r => {
                if (r.data.length < 1) return props.showPDF(true, 'Sem registros para a data informada')
                props.setPDFData({date: {
                    dataInicial: formatDate(firstDate, 'front'), dataFinal: formatDate(lastDate, 'front'),
                  },
                  data:r.data,
                  tipo:relatorio,
                  ordenar:ordenar,
                  options: { filiaisSelecionadas: filiais.map(i => i.FANTASIA) }
                })
                props.showPDF(false)
            })
            .finally(() => setIsFullLoading(false))
    }
    return (
        <div>
            {isFullLoading ? <Loading /> : null}
            <Paper elevation={3} className='p-2' sx={{ backgroundColor: '#f5f0f0' }}>
                <h4 className='relatorio-title'>Relatório de combustiveis:</h4>
                <SearchData setFirstDate={setFirstDate} setLastDate={setLastDate}
                    firstDate={firstDate} lastDate={lastDate} />
                <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">Tipo:</FormLabel>
                    <RadioGroup
                        row
                        name="row-radio-buttons-group"
                        value={relatorio}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRelatorio(e.target.value as IRelatorio)}
                    >
                        <FormControlLabel value="Geral" control={<Radio />} label="Geral" />
                        <FormControlLabel value="Ciclo" control={<Radio />} label="Ciclo otto" />
                    </RadioGroup>
                    <FormControlLabel control={<Checkbox checked={ordenar} 
                    onChange={event =>setOrdenar(event.target.checked)}/>} label="Agrupar por mês" />
                </FormControl>
                <br />
                <Button variant='contained' endIcon={<Send />} onClick={() => setIsSelectingFilial(true)}>Gerar</Button>
            </Paper>
            {isSelectingFilial ? <SearchFiliais getFiliais={getCombustivel}
                close={(filiais: IfilialAtual[]) => { setIsSelectingFilial(false); setUsedFiliais(filiais) }} lastSelectedFiliais={usedFiliais} /> : null}
        </div>
    )
}
export default Combustiveis