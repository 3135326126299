import { CicloMes, IDate } from '../interfacesPDF'
import { useGlobalState } from '../../../../../globalState'
import { toMonthName, toMoneyFormat } from '../../../../../utilities/helperFunctions'
import CicloMesGraph from '../graphs/cicloMesGraph'
import TableSort from '../../../../../utilities/TableSortTS'
import {  useMemo } from 'react'
import PrintFooter from "../../../printFooter";

interface IProps {
    data: CicloMes[]
    date: IDate
    options?: { filiaisSelecionadas: string[] }
    btnTable?:boolean
}
interface ICiclosPorMes {
    [month: string]: { dieselData: CicloMes[]; ottoData: CicloMes[] };
}


export default function CicloMesPDF(props: IProps) {
    const [empresaAtual] = useGlobalState('empresaAtual')

    const columns = useMemo(
        () => [
            {
                Header: " ",
                columns: [
                    {
                        Header: "Empresa",
                        accessor: "FANTASIA",
                        Footer: ''
                    },
                    {
                        Header: "Quantidade",
                        accessor: "QTDCICLOEMPRESA",
                        Cell: ({ value }: { value: string }) => Number(value).toFixed(3),
                        Footer: ''
                    },
                    {
                        Header: "Total",
                        accessor: "VALORCICLOEMPRESA",
                        Cell: ({ value }: { value: string }) => toMoneyFormat(value),
                    },
                ],
                Footer: () => <></>
            }
        ], [])

    const groupedData = props.data.reduce((accumulator: ICiclosPorMes, currentValue: CicloMes) => {
        const month = toMonthName(currentValue.MES)
        if (!accumulator[month]) {
          accumulator[month] = { ottoData: [], dieselData: [] }
        }
    
        if (currentValue.CICLO.trim() === 'CICLO OTTO') {
          accumulator[month].ottoData.push(currentValue)
        } else {
          accumulator[month].dieselData.push(currentValue)
        }
    
        return accumulator
      }, {})
    console.log(groupedData)

    const months = Object.keys(groupedData).map(String)


    return (
        <div className='container'>
            <div className='pdf' id='section-to-print'>
                <h5>{empresaAtual.nome}</h5>
                <h6>Multiloja &gt; Ciclo otto - Periodo: {props.date.dataInicial} a {props.date.dataFinal}</h6>
                <hr />

                <p style={{ fontSize: '12px' }}>{props.options?.filiaisSelecionadas.map(item => item + ' | ')}</p>
                <hr />
                {props.btnTable ? <div className=''><CicloMesGraph data={props.data}/> </div>: null} 
                {months.map((month) => {
                    const ottoData = groupedData[month].ottoData
                    const dieselData = groupedData[month].dieselData
                    return(
                        <div>
                            <h5 className='text-uppercase'>{month}</h5>
                        <div >
                            <h6>Ciclo diesel</h6>
                            <TableSort columns={columns} data={dieselData}/>
                            <p className='text-end fw-bold'>Total ciclo | Quantidade: {Number(dieselData[0]?.TOTALQTDMESCICLO).toFixed(3)}  Valor: {toMoneyFormat(dieselData[0]?.TOTALVALORMESCICLO, 2)}</p>
                        </div>
                        <div >
                            <h6>Ciclo otto</h6>
                            <TableSort columns={columns} data={ottoData}/>
                            <p className='text-end fw-bold'>Total ciclo | Quantidade: {Number(ottoData[0]?.TOTALQTDMESCICLO).toFixed(3)}  Valor: {toMoneyFormat(ottoData[0]?.TOTALVALORMESCICLO, 2)}</p>
                        </div>
                        <hr />
                    </div>
    
                    )

                })}
               
                <h6 className='text-end fw-bold'>Total | Quantidade: {Number(props.data[0].TOTALQTD).toFixed(3)} Valor: {toMoneyFormat(props.data[0].TOTALVALOR)}</h6>
                <PrintFooter/>
            </div>
        </div>
    )
}