import { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { useGlobalState } from '../globalState'
import { toMoneyFormat } from '../utilities/helperFunctions'
import axios from '../axiosConfig'
import { ICaixaResumo, ICaixa } from '../views/caixa/interfaceCaixa'
import { Paper, CircularProgress, Box } from "@mui/material";

export const data = [
  ["Tipo", "Quantidade"],
  ["Dinheiro", 11],  ["A prazo", 2],  ["Credito", 2],  ["Debito", 2],  ["Outros", 7],
];

export const options = {
  title: "Vendas - formas de pagamento",
  pieHole: 0.4,
  is3D: false,
  chartArea: { left: 10, right: 50, bottom: 10, top: 35 },
  backgroundColor: '#f5f0f0',
};

interface IProps {
  formasPag:ICaixaResumo[]
  cupons:number
}

export default function GraphFormasPag({formasPag,cupons}:IProps) {

  const generateFormasPagGraph = (formas: ICaixaResumo[]) => {
    const template = ["Tipo", "Quantidade"]
    const formasPagObj = formas.map(item => [item.DESCRICAO.trim(), Number(item.VALORFORMA)])
    return [template, ...formasPagObj]
  }

  return (

    <Paper elevation={3} className='p-2 mt-2' sx={{ xl: { width: '400px' }, md: { width: '500px' }, maxWidth: '600px', height: '450px', backgroundColor: '#f5f0f0' }}>
        <>
          <Chart
            chartType="PieChart"
            width="450px"
            height="300px"
            data={generateFormasPagGraph(formasPag)}
            options={options}
          />{
            cupons !== null ? <div className="card credito order-card">
              <div className="card-block">
                <h5 className="text-right">Total: {toMoneyFormat(formasPag[0].VALORTOTAL)}</h5>
                <h6>Cupons: {cupons} | Media cupom: {toMoneyFormat(Number(formasPag[0].VALORTOTAL) / cupons)}</h6>
              </div>
            </div> : null
          }
          <p>*Retirado do ultimo caixa fechado</p>
        </>
    </Paper>
  );
}