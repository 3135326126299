import { Paper, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from "@mui/material"
import { useGlobalState } from "../../globalState"
import HandleEmpresas from "../../utilities/changeEmpresa"
import { Edit } from "@mui/icons-material"
import { useEffect, useState } from "react"
import { showSnackbar } from "../../utilities/snackbarShow"
import axios from "../../axiosConfig"
import Loading from "../../utilities/loading"
import { AxiosError } from "axios"
import fs from 'fs'

function PerfilUsuario(){
    const [empresaAtual] = useGlobalState('empresaAtual')
    const [empresas] = useGlobalState('empresas')
    const [usuarioConectado] = useGlobalState('usuarioConectado')
    const [senhaAtual, setSenhaAtual] = useState('')
    const [senhaNova, setSenhaNova] = useState('')
    const [senhaCheck, setSenhaCheck] = useState('')
    const [isFullLoading, setIsFullLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const gitVersion = process.env.REACT_APP_VERSION;

    

    const updateSenha = () => {
        if(senhaNova !== senhaCheck){
            showSnackbar('As senhas digitadas não coincidem','error')
            setOpen(true)
            return
        }
        setIsFullLoading(true)
        axios.patch('mysql/usuario/senha', { senhaAtual, senhaNova}).then(r => {
            showSnackbar('Registro atualizado', 'success')
            setSenhaAtual('')
            setSenhaNova('')
            setSenhaCheck('')
        }).catch(error => {
            const err = error as AxiosError
            if(err.response?.status === 400)showSnackbar('Senha atual incorreta','error')
            else showSnackbar('Erro ao atualizar registro', 'error')
            setOpen(true)
        }).finally(() => setIsFullLoading(false))
    }

    const handleConfirm = () => {
        if(senhaAtual.trim() === '' || senhaNova.trim() === ''){
            showSnackbar('Preencha todos os campos','error')
            return
        }
        setOpen(false)
        updateSenha()
    }

    return (
        <div className="container">
             {isFullLoading ? <Loading /> : null}
            <div className="d-flex title">
                <div className="p-2 "><h1>Perfil | <span className='empresa-atual'>{empresaAtual.fantasia} - {empresaAtual.cnpj}</span></h1></div>
                <HandleEmpresas />
                <div className="p-2 "><i className="fas fa-user-friends"></i></div>
            </div>
            <hr className='hr-title mb-3' />
            <Paper elevation={3} sx={{ backgroundColor: '#f5f0f0', p: 3 }}>
                <h5 className="fw-bolder">Informações:</h5> <hr />
                <div className="row mb-3">
                    <div className="col-auto">Nome:</div>
                    <Typography className="col">{usuarioConectado.name}</Typography>
                    <div className="col-auto">Email:</div>
                    <Typography className="col">{usuarioConectado.email}</Typography>
                </div>
                <Button
                    variant='contained'
                    color='info'
                    endIcon={<Edit />}
                    sx={{ m: 0.5 }}
                    onClick={() => setOpen(true)}
                >
                    Alterar senha
                </Button>
                <h5 className="fw-bolder">Minhas empresas:</h5> <hr />
                <div>
                        {empresas.map((empresa) => <Typography key={empresa.id}>
                                        {empresa.fantasia} - <span style={{ color: 'orange' }}>{empresa.cnpj}</span>
                                    </Typography>
                                )
                            }
                    </div>

                <Dialog open={open} onClose={() => setOpen(false)}>
                    <DialogTitle>Alterar Senha</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Senha Atual"
                            type="password"
                            fullWidth
                            value={senhaAtual}
                            onChange={(e) => setSenhaAtual(e.target.value)}
                        />
                        <TextField
                            margin="dense"
                            label="Nova Senha"
                            type="password"
                            fullWidth
                            value={senhaNova}
                            onChange={(e) => setSenhaNova(e.target.value)}
                        />
                            <TextField
                            margin="dense"
                            label="Repetir senha"
                            type="password"
                            fullWidth
                            value={senhaCheck}
                            onChange={(e) => setSenhaCheck(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)}>Cancelar</Button>
                        <Button onClick={handleConfirm}>Confirmar</Button>
                    </DialogActions>
                </Dialog>
            </Paper>
            <p>Versão:{gitVersion}</p>
        </div>
    )
}

export default PerfilUsuario
