export const dateToString = (inputData: Date) => {
    //Transforma Date Object para string
    const stringData = `${inputData.getDate().toString().length === 1 ? '0' : ''}
        ${inputData.getDate()}/
        ${(inputData.getMonth() + 1).toString().length === 1 ? '0' : ''}
        ${inputData.getMonth() + 1}/
        ${inputData.getFullYear()}`
    return stringData.replace(/\s/g, '')
}

export const fixDate = (array: any[], dates: string[]) => {
    let dataFixed = array.map((item, index) => {
        let newObject = { ...item }
        dates.forEach(keyName => {
            let parts = item[keyName]?.substring(0, 10).split('-')
            let objectDate = new Date(parts[0], parts[1] - 1, parts[2]);
            newObject[keyName] = objectDate
        })
        return newObject
    })
    return dataFixed
}

function padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
}
export const formatDate = (date: Date | number | string, format: string) => {
    const dateFormatted = new Date(date);
    const year = dateFormatted.getUTCFullYear();
    const month = dateFormatted.getUTCMonth();
    const day = dateFormatted.getUTCDate();

    return format === 'db'
        ? [year, padTo2Digits(month + 1), padTo2Digits(day)].join('-')
        : [padTo2Digits(day), padTo2Digits(month + 1), year].join('/');
};

export const formatHour = (date: Date | number) => {
    const dateFormated = new Date(date)

    const hour = dateFormated.getHours() > 9 ? dateFormated.getHours() : `0${dateFormated.getHours()}`
    const minute = dateFormated.getMinutes() > 9 ? dateFormated.getMinutes() : `0${dateFormated.getMinutes()}`

    return `${hour}:${minute}`
}


export const toFixedNumber = (num: number | string, digits = 2) => {
    const pow = Math.pow(10, digits);
    return Math.round(Number(num) * pow) / pow;
}

export const toMoneyFormat = (value: number | string, digits = 2) => {
    return Number(value).toLocaleString('pt-br', {
        style: 'currency', currency: "BRL", currencyDisplay: 'symbol',
        minimumFractionDigits: digits, maximumFractionDigits: digits
    })

}

export const toMonthName = (monthNumber: number) => {

    const date = new Date();
    date.setMonth(monthNumber - 1);
    return date.toLocaleString('pt-BR', {
        month: 'long',
    });
}
export function calculateHoursPassed(initialDate: Date, initialHour: number, finalDate: Date, finalHour: number): number {
    // Calculate the difference between the initial and final dates in milliseconds
    const dateDiff = finalDate.getTime() - initialDate.getTime();

    // Calculate the difference between the initial and final hours in milliseconds
    const hourDiff = (finalHour - initialHour) * 60 * 60 * 1000;

    // Calculate the total time difference in milliseconds
    const totalDiff = dateDiff + hourDiff;

    // Calculate the number of hours that have passed
    const hoursPassed = totalDiff / (60 * 60 * 1000);

    return hoursPassed;
}

export const isValidCPF = (cpf: string): boolean => {
    // Remove any non-digit characters from the input string
    const cleanedCPF = cpf.replace(/\D/g, '');

    // CPF must have 11 digits after cleaning
    if (cleanedCPF.length !== 11) {
        return false;
    }

    // Check for known invalid CPF patterns
    const invalidCPFPatterns = [
        '00000000000', '11111111111', '22222222222', '33333333333', '44444444444',
        '55555555555', '66666666666', '77777777777', '88888888888', '99999999999'
    ];

    if (invalidCPFPatterns.includes(cleanedCPF)) {
        return false;
    }

    // Calculate the first verification digit
    let sum = 0;
    for (let i = 0; i < 9; i++) {
        sum += parseInt(cleanedCPF.charAt(i)) * (10 - i);
    }
    let remainder = 11 - (sum % 11);
    let verificationDigit1 = (remainder >= 10) ? 0 : remainder;

    // Calculate the second verification digit
    sum = 0;
    for (let i = 0; i < 10; i++) {
        sum += parseInt(cleanedCPF.charAt(i)) * (11 - i);
    }
    remainder = 11 - (sum % 11);
    let verificationDigit2 = (remainder >= 10) ? 0 : remainder;

    // Compare the calculated verification digits with the actual digits in the CPF
    if (
        verificationDigit1 === parseInt(cleanedCPF.charAt(9)) &&
        verificationDigit2 === parseInt(cleanedCPF.charAt(10))
    ) {
        return true;
    }
    return false;
}

export const isValidCNPJ = (cnpj: string): boolean => {
    const cleanedCNPJ = cnpj.replace(/\D/g, '');
    if (cleanedCNPJ.length !== 14) return false

    const invalidCNPJPatterns = [
        '00000000000000', '11111111111111', '22222222222222', '33333333333333',
        '44444444444444', '55555555555555', '66666666666666', '77777777777777',
        '88888888888888', '99999999999999'
    ];

    if (invalidCNPJPatterns.includes(cleanedCNPJ)) return false

    let sum = 0;
    let weight = 5;
    for (let i = 0; i < 12; i++) {
        sum += parseInt(cleanedCNPJ.charAt(i)) * weight;
        weight = (weight === 2) ? 9 : weight - 1;
    }
    let remainder = sum % 11;
    let verificationDigit1 = (remainder < 2) ? 0 : 11 - remainder;

    sum = 0;
    weight = 6;
    for (let i = 0; i < 13; i++) {
        sum += parseInt(cleanedCNPJ.charAt(i)) * weight;
        weight = (weight === 2) ? 9 : weight - 1;
    }
    remainder = sum % 11;
    let verificationDigit2 = (remainder < 2) ? 0 : 11 - remainder;

    if (
        verificationDigit1 === parseInt(cleanedCNPJ.charAt(12)) &&
        verificationDigit2 === parseInt(cleanedCNPJ.charAt(13))
    ) {
        return true;
    }
    return false;
}

export const isValidIE = (inscricaoEstadual: string, uf: string): boolean => {
    let ie = inscricaoEstadual.replace(/[^\d]/g, ''); // Remove caracteres não numéricos
    const ufUpper = uf.toUpperCase();

    switch (ufUpper) {
        case 'AC':
            return ie.length === 13 && ie.startsWith('01');
        case 'AL':
            return ie.length === 9 && (ie.startsWith('24') || ie.startsWith('25') || ie.startsWith('28'));
        case 'AM':
            return ie.length === 9 && ie.startsWith('04');
        case 'AP':
            return ie.length === 9 && ie.startsWith('03');
        case 'BA':
            return ie.length === 8 || ie.length === 9;
        case 'CE':
            return ie.length === 9 && ie.startsWith('06');
        case 'DF':
            return ie.length === 13;
        case 'ES':
            return ie.length === 9 && (ie.startsWith('08') || ie.startsWith('20') || ie.startsWith('29'));
        case 'GO':
            return ie.length === 9 && ie.startsWith('10');
        case 'MA':
            return ie.length === 9 && ie.startsWith('12');
        case 'MG':
            return ie.length === 13;
        case 'MS':
            return ie.length === 9 && ie.startsWith('28');
        case 'MT':
            return ie.length === 11;
        case 'PA':
            return ie.length === 9 && (ie.startsWith('15') || ie.startsWith('19'));
        case 'PB':
            return ie.length === 9 && ie.startsWith('08');
        case 'PE':
            return ie.length === 14;
        case 'PI':
            return ie.length === 9 && ie.startsWith('19');
        case 'PR':
            return ie.length === 10;
        case 'RJ':
            return ie.length === 8 && (ie.startsWith('19') || ie.startsWith('20'));
        case 'RN':
            return ie.length === 9 && ie.startsWith('20');
        case 'RO':
            return ie.length === 14;
        case 'RR':
            return ie.length === 9 && ie.startsWith('24');
        case 'RS':
            return ie.length === 10 && (ie.startsWith('00') || ie.startsWith('01') || ie.startsWith('02'));
        case 'SC':
            return ie.length === 9;
        case 'SE':
            return ie.length === 9 && ie.startsWith('27');
        case 'SP':
            return ie.length === 12;
        case 'TO':
            return ie.length === 11;
        default:
            return false;
    }
}

export const isValidRG = (rg: string): boolean => {
    // Remove non-numeric characters
    const cleanRG = rg.replace(/[^\d]/g, '');

    // RG must have 9 digits
    if (cleanRG.length !== 9) {
        return false;
    }

    // Check if the first 8 digits are numbers
    const digits = cleanRG.substring(0, 8);
    if (!/^\d+$/.test(digits)) {
        return false;
    }

    // Calculate the verification digit
    const soma = parseInt(digits.charAt(0)) * 2 +
                 parseInt(digits.charAt(1)) * 3 +
                 parseInt(digits.charAt(2)) * 4 +
                 parseInt(digits.charAt(3)) * 5 +
                 parseInt(digits.charAt(4)) * 6 +
                 parseInt(digits.charAt(5)) * 7 +
                 parseInt(digits.charAt(6)) * 8 +
                 parseInt(digits.charAt(7)) * 9;

    const resto = soma % 11;
    const dv = resto < 2 ? 0 : 11 - resto;

    // Check if the verification digit matches
    return dv === parseInt(cleanRG.charAt(8));
}

export const formatDateToFront = (item:string):string =>{
    return item.replaceAll('-', '/').split('/').reverse().join('/')
}

export const isLogged = ()=>{
    const usuarioExists = localStorage.getItem('usuario');
    const usuarioCheckExists = localStorage.getItem('usuarioCheck');
    return usuarioExists || usuarioCheckExists
}