import { useState } from "react"
import { Modal, Spinner } from 'react-bootstrap'
import { IEmpresa, setGlobalState } from '../../globalState'
import { Button, TextField } from '@mui/material'
import { Send, Close } from '@mui/icons-material'
import axios from '../../axiosConfig'
import { AxiosError } from "axios"
import { showSnackbar } from "../snackbarShow"

interface IProps {
  return: Function
  success?: Function
  empresa: IEmpresa
  disableFechar?: boolean
}
interface MyResponseData {
  message: string;
}


export function LoginUsuarioFirebird(props: IProps) {
  const [usuarioFirebird, setUsuarioFirebird] = useState('')
  const [senhaFirebird, setSenhaFirebird] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const changeEmpresa = () => {
    setIsLoading(true)
    axios.post('login/firebird', { usuarioFirebird: usuarioFirebird, senhaFirebird: senhaFirebird, empresa: props.empresa.filiais[0].NUMERO })
      .then(r => {
        localStorage.setItem('usuarioFirebird', JSON.stringify(r.data[1]))
        window.localStorage.setItem('empresaID', JSON.stringify(props.empresa.id))
        setGlobalState('usuarioFirebirdConectado',r.data[0])
        setGlobalState('empresaAtual', props.empresa)
        props?.success ? props.success() : props.return()
      }).catch(error => {
        const err = error as AxiosError<MyResponseData>
        showSnackbar(err.response?.data?.message!, 'error')
        setIsLoading(false)
      })

  }

  return (
    <>
      <Modal
        animation={false}
        centered
        show={true}
        backdrop="static"
        keyboard={true}
      >
        <Modal.Body>
          {!isLoading && <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h6>Entre com o usuário</h6>
            <TextField hiddenLabel className='' size="small" name='NOME' placeholder="Usuario"
              value={usuarioFirebird} onChange={(event) => setUsuarioFirebird(event.target.value.toUpperCase())}
              inputProps={{ style: { textTransform: "uppercase" } }}
            />
            <br />
            <TextField hiddenLabel className='' size="small" name='NOME' placeholder="Senha" type="password"
              value={senhaFirebird} onChange={(event) => setSenhaFirebird(event.target.value)}
              inputProps={{ style: { textTransform: "uppercase" } }}
            />
          </div>}
          {isLoading &&
            <div className="d-flex justify-content-center text-primary">
              <Spinner variant="primary" animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>}

        </Modal.Body>

        <Modal.Footer style={{ padding: '0.15rem', backgroundColor: '#bdc3c7' }}>
          <>
            <Button variant='contained' color='error' disabled={props.disableFechar} endIcon={<Close />} sx={{ m: 0.5 }}
              onClick={() => props.return()}>
              Fechar
            </Button>
            <Button disabled={isLoading} variant='contained' color='success' endIcon={<Send />} sx={{ m: 0.5 }}
              onClick={() => changeEmpresa()}>
              Concluir
            </Button>
          </>

        </Modal.Footer>
      </Modal></>
  )
}