import * as React from 'react';
import { toFixedNumber,toMoneyFormat } from '../../../../utilities/helperFunctions'


interface Abastecimentos {
    data: [{
        ID: number;
        NUMERO_EMPRESA: number;
        ID_BOMBA: number;
        ID_CAIXA: number;
        TOTALDINHEIRO: string;
        TOTALLITROS: string;
        TEMPOABASTECIMENTO?: any;
        DATAABASTECIMENTO: string;
        HORAABASTECIMENTO: string;
        ENCERRANTEREAL: string;
        INSERIDO: string;
        PENDENTE: string;
        PRECOUNITARIO: string;
        REGISTRO: number;
        ID_DADOSFATURAMENTO: number;
        NUMERO_FUNCIONARIO?: any;
        NOME_FUNCIONARIO?: any;
        NUMERO_BICO: number;
        CODIGO_PRODUTO: number;
        DESCRICAO_PRODUTO: string;
        NUMERONOTA: number;
    }]
}

function TotalAbastecimentoPDF(props: Abastecimentos) {

    const produtos = props.data.reduce((acc, item) => {
        if (acc.indexOf(item.CODIGO_PRODUTO) === -1) {
            acc.push(item.CODIGO_PRODUTO)
            acc.sort(function (a, b) { return a - b; })
            return acc
        } else {
            return acc
        }
    }, [] as number[])

    const abast = produtos.map(produto => {
        return props.data.reduce((acc, item) => {
            if (item.CODIGO_PRODUTO === produto) acc++
            return acc
        }, 0)
    })
    const valor = produtos.map(produto => {
        return props.data.reduce((acc, item) => {
            if (item.CODIGO_PRODUTO === produto) acc += toFixedNumber(item.TOTALDINHEIRO, 2)
            return acc
        }, 0)
    })
    const litros = produtos.map(produto => {
        return props.data.reduce((acc, item) => {
            if (item.CODIGO_PRODUTO === produto) acc += toFixedNumber(item.TOTALLITROS, 3)
            return acc
        }, 0)
    })

    const valorPorAbastecimento = produtos.map((item, i) => {
        const media = valor[i] / abast[i]
        return media
    })
    const LitrosPorAbastecimento = produtos.map((item, i) => {
        const media = litros[i] / abast[i]
        return media
    })

    return (
        <>
            <p className='infoPDF fw-bold pr-3 text-end'>Total abastecimentos: {props.data.length}
            | Total valor: {(toMoneyFormat(valor.reduce((acc,item)=>acc+=item,0)))}
             | Total litros: {(litros.reduce((acc,item)=>acc+=item,0)).toFixed(3)}</p>
            <div className="table-responsive">
                <table className="table table-sm">
                    <thead className='thead-wrap'>
                        <tr>
                            
                            <th scope="col">Produto</th>
                            <th scope="col">Abastecimentos</th>
                            <th scope="col">Valor</th>
                            <th scope="col">Litros</th>
                            <th scope="col">Valor/Abast</th>
                            <th scope="col">Litros/Abast</th>
                        </tr>
                    </thead>
                    <tbody>
                        {produtos.map((item, index) =>
                            <tr>
                                <td>{props.data.find(i => i.CODIGO_PRODUTO === item)?.DESCRICAO_PRODUTO}</td>
                                <td>{abast[index]}</td>
                                <td>{toMoneyFormat(valor[index])}</td>
                                <td>{litros[index].toFixed(3)}</td>
                                <td>{toMoneyFormat(valorPorAbastecimento[index])}</td>
                                <td>{LitrosPorAbastecimento[index].toFixed(3)}</td>
                            </tr>
                        )}


                    </tbody>
                </table>
            </div></>
    )


}
export default TotalAbastecimentoPDF

