import './sidebar.css'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react';
import axios from '../../axiosConfig'
import CadastroNav from './cadastro';
import RelatorioNav from './relatorios';
import { resetGlobalState,useGlobalState } from '../../globalState'
import axios1, { AxiosError } from 'axios';

type ScreenType = 'adm' | 'dashboard';

interface MyResponseData {
  error: string;
  // add other properties if necessary
}

function Sidebar() {
  const location = useLocation()
  const [classConnection, setClassConnection] = useState('fa-wifi-slash')
  const [textConnection, setTextConnection] = useState('Sem conexão')
  const [usuarioConectado] = useGlobalState('usuarioConectado')
  const navigate = useNavigate();
  const logOut = () => {
    localStorage.clear();
    resetGlobalState()
  }

  const connectionHandler = async () => {
    try {
      const empresaID = JSON.parse(localStorage.getItem('empresaID') || '')
      if (!empresaID) {
        throw new Error('No token found');
      }
      let path = '/ping'
      const usuario = JSON.parse(window.localStorage.getItem('usuario') || 'null')
      const usuarioCheck = JSON.parse(window.localStorage.getItem('usuarioCheck') || 'null')
      if ((usuario || usuarioCheck) && location.pathname.startsWith('/adm')) path = `/ping/${usuario}/${usuarioCheck}`
      const dateBefore = new Date().getTime()

      await axios.get(path);

      const dateAfter = new Date().getTime()
      let ping = dateAfter - dateBefore
      if (ping <= 3500) {
        setClassConnection('fa-wifi')
        setTextConnection('Conexão estavel')
      } else{
        setClassConnection('fa-wifi-exclamation')
        setTextConnection('Conexão instavel')
      }
    } catch (error) {
      if (axios1.isAxiosError(error)) {
        const axiosError = error as AxiosError<MyResponseData>;
        console.log(axiosError?.response)
        if(axiosError?.response?.data?.error?.includes('-logout')){
          navigate('/login')
          logOut()
        }
        else if (axiosError.response?.status === 403) {
          localStorage.removeItem("usuario");
          localStorage.removeItem("usuarioCheck");
          navigate('/adm')
        } else {
          setClassConnection('fa-wifi-slash')
          setTextConnection('Sem conexão')
        }
      }

    } finally {
      setTimeout(() => connectionHandler(), 10000)
    }
  }


  const changeScreen = () => {
    const newScreen = location.pathname.includes('adm') ? 'dashboard' : 'adm'
    navigate(`/${newScreen}`)
  }

  useEffect(() => {
    connectionHandler()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <div className={location.pathname === "/login" ? "collapse" : ""}>
      <nav className="navbar">
        <ul className="navbar-nav">
          <li className="logo">

            <NavLink to="/" className="nav-link-side">
              <span className="link-text logo-text">Alive </span>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fad"
                data-icon="angle-double-right"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                className="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
              >
                <g className="fa-group">
                  <path
                    fill="currentColor"
                    d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                    className="fa-secondary"
                  ></path>
                  <path
                    fill="currentColor"
                    d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                    className="fa-primary"
                  ></path>
                </g>
              </svg>
            </NavLink>
          </li>
          <li className="collapse-mobile">
            <span className="link-text" style={{ fontSize: '11px', color: 'white' }}>{usuarioConectado.email}</span>
          </li>
          <li className="nav-item-side">
            <NavLink to={location.pathname.includes('adm') ? '/adm' : '/dashboard'} className={location.pathname === "/" ? "nav-link-side" : "nav-link-side grayscale"}>
              <i className=" fa fas fa-home fa-2x"></i>
              <span className="link-text">
                Inicio
              </span>
            </NavLink>
          </li>

          {location.pathname.includes('adm') ? <CadastroNav />
            : <RelatorioNav />}

{usuarioConectado.master === 1 && <li className="nav-item-side">
      <NavLink to="/restrito" className={location.pathname === "/restrito" ? "nav-link-side" : "nav-link-side grayscale"}>
                        <i className="fas fa-objects-column fa-2x"></i>
                        <span className="link-text">
                            Restrito
                        </span>
                </NavLink>
      </li>
      }
      <li className="nav-item-side">
      <NavLink to="/perfil" className={location.pathname === "/perfil" ? "nav-link-side" : "nav-link-side grayscale"}>
                        <i className="fas fa-user fa-2x"></i>
                        <span className="link-text">
                            Perfil
                        </span>
                </NavLink>
      </li>
          {(usuarioConectado.dashboard === 1 && usuarioConectado.sistema === 1) &&
            <li className="nav-item-side" onClick={() => changeScreen()}>
              <span className="nav-link-side" style={{ cursor: 'pointer' }}>
                <i className={`fa ${location.pathname.includes('adm') ? 'fa-file-invoice-dollar' : 'fa-address-card'}`} style={{ color: '#9b59b6' }}></i>
                <span style={{ color: '#81ecec' }} className="link-text">
                  {location.pathname.includes('adm') ? 'Dashboard' : 'ADM'}
                </span>
              </span>
            </li>}
          <li className="nav-item-side" >
            <span className="nav-link-side" style={{ cursor: 'pointer' }}>
              <i className={`fa ${classConnection}`}></i>
              <span style={{ color: '#81ecec' }} className="link-text">
                {textConnection}
              </span>
            </span>
          </li>

          <li className="nav-item-side" onClick={() => logOut()}>
            <NavLink to="/login" className="nav-link-side"
              onClick={() => logOut()}
            >
              <i className="fa fa-power-off"></i>
              <span className="link-text">
                Sair
              </span>
            </NavLink>
          </li>



        </ul>
      </nav>


    </div>
  )
}

export default Sidebar