import Routes from './routes'
import { BrowserRouter } from 'react-router-dom'
import { useEffect} from "react"
import Sidebar from './views/nav/sidebar'
import {setGlobalState,useGlobalState} from './globalState'
import axios from './axiosConfig'
import './utilities/PDFsStyle.css'

const token =  JSON.parse(localStorage.getItem('id'))
const empresaID = JSON.parse(localStorage.getItem('empresaID'))

export function getEmpresas(){
  if(window.localStorage.getItem('id')){
    axios.get(`usuario/listar/${JSON.parse(window.localStorage.getItem('id'))}`, { headers: { Authorization: `Bearer ${token}` } })
    .then((r) => {
        setGlobalState('empresas',r.data[0])
        setGlobalState('empresaAtual', r.data[0].filter(empresa => empresa.id === empresaID)[0])
        setGlobalState('usuarioConectado', r.data[1])
      }).catch(err=>console.log(err))
  }
  if(window.localStorage.getItem('usuarioFirebird')){
    axios.get(`firebird/usuario/${window.localStorage.getItem('usuarioFirebird')}`).then(r=>{
      setGlobalState('usuarioFirebirdConectado',r.data)
    })
  }
}

function App(){
  const [usuarioConectado] = useGlobalState('usuarioConectado')
  const [empresaAtual] = useGlobalState('empresaAtual')
  useEffect(()=>{
    getEmpresas()
  },[])


  useEffect(()=>{
    const checkFiliais = ()=>{
      return empresaAtual?.filiais?.length === 1 
      ? empresaAtual.filiais[0]
      : empresaAtual?.filiais?.find(x => x.HABILITARRETORNO.trim() === "M") || {} 
    }
    const filial = checkFiliais()
  setGlobalState('filialAtual',filial)
  if(filial.NUMERO)window.localStorage.setItem('filial',JSON.stringify(filial.NUMERO))
  },[empresaAtual])

  return (
    <div className="App">
      <BrowserRouter>
        {usuarioConectado?.id && <Sidebar/>}
        <Routes></Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
