import { MenuItem, Select, TextField } from "@mui/material"
import { handleChangeSelectInput, handleChangeTextInput } from "../../../utilities/inputsUtilities"
import SearchTiposProdutos from '../../../utilities/searchs/searchTiposProdutos';
import SearchGruposProdutos from '../../../utilities/searchs/searchGruposProdutos';
import SearchPlanoContas from '../../../utilities/searchs/searchPlanoContas';
import axios from '../../../axiosConfig'
import { useState } from "react";

interface IGeralTab {
    CODIGO: number
    DESCRICAO: string
    NOMEFANTASIA: string
    TIPOPRODUTO: string
    ATIVO: string
    UNIDADEENTRADA: string
    UNIDADE: string
    UNIDADECONVERSAO: string
    CODIGO_GRUPOPRODUTO: number
    CODIGO_TIPOPRODUTO: number
    ALTERACAOGRUPO: string
    COMISSIONADO: string
    VENDAFRENTECAIXA: string
    BLOQUEARVENDAESTOQUEZERADO: string
    ARREDONDAMENTOTRUNCAMENTO: string
    PRODUCAOPROPRIA: string
    ESTOQUEREGULADOR: string
    ACESSO_CONTACREDITO: string
    ACESSO_CONTADEBITO: string
}

interface IProps {
    data: IGeralTab
    setData: React.Dispatch<React.SetStateAction<IGeralTab | any>>
}

function GeralProduto(props: IProps) {
    const [erroCodigo, setErroCodigo] = useState(false)

    const checkCodigo = (cod: string) => {
        if(cod === '0'){
            setErroCodigo(false)
            return
        }
        axios.get(`/produto/codigo/${cod}`).then(r => {
            console.log(r.data)
           r.data ? setErroCodigo(true) : setErroCodigo(false)
        })
    }

    return (
        <div>
            <div className="row mb-3">
                <div className="col-auto">Descrição:</div>
                <TextField hiddenLabel className='col' size="small" name='DESCRICAO'
                    value={props.data.DESCRICAO} onChange={(event) => handleChangeTextInput(event, props.setData)}
                    inputProps={{ maxLength: 100, style: { textTransform: "uppercase" } }}
                    onBlur={() => { props.setData((value: IGeralTab) => ({ ...value, NOMEFANTASIA: props.data.DESCRICAO })) }}
                />
                <div className="col-auto">Nome Fantasia:</div>
                <TextField hiddenLabel className='col' size="small" name='NOMEFANTASIA'
                    value={props.data.NOMEFANTASIA} onChange={(event) => handleChangeTextInput(event, props.setData)}
                    inputProps={{ maxLength: 100, style: { textTransform: "uppercase" } }}
                />
                <div className="col-auto">Ativo:</div>
                <Select className='col-sm-2' value={props.data.ATIVO.trim()} size='small' name='ATIVO'
                    onChange={(event) => handleChangeSelectInput(event, props.setData)}>
                    <MenuItem value={"N"}>Não</MenuItem>
                    <MenuItem value={"S"}>Sim</MenuItem>
                </Select>
            </div>
            <div className="row mb-3">
                <div className="col-auto">Codigo:</div>
                <TextField hiddenLabel className='col' size="small" name='CODIGO'
                    value={props.data.CODIGO} onChange={(event) => handleChangeTextInput(event, props.setData)}
                    inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }} error={erroCodigo} helperText={erroCodigo ? 'Codigo já existe' : ''}
                    onBlur={() => checkCodigo(props.data.CODIGO.toString())}
                />
                <div className="col-auto">Tipo:</div>
                <Select className='col' value={props.data.TIPOPRODUTO.trim()} size='small' name='TIPOPRODUTO'
                    onChange={(event) => handleChangeSelectInput(event, props.setData)}>
                    <MenuItem value={"C"}>Combustível</MenuItem>
                    <MenuItem value={"P"}>Produto</MenuItem>
                    <MenuItem value={"S"}>Serviço</MenuItem>
                </Select>
                <div className="col-auto">Unidade venda:</div>
                <TextField hiddenLabel className='col' size="small" name='UNIDADE'
                    value={props.data.UNIDADE} onChange={(event) => handleChangeTextInput(event, props.setData)}
                    inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
                />
                <div className="col-auto">Unidade compra:</div>
                <TextField hiddenLabel className='col' size="small" name='UNIDADEENTRADA'
                    value={props.data.UNIDADEENTRADA} onChange={(event) => handleChangeTextInput(event, props.setData)}
                    inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
                />
                <div className="col-auto">Itens embalagem:</div>
                <TextField hiddenLabel className='col' size="small" name='UNIDADECONVERSAO'
                    value={props.data.UNIDADECONVERSAO} onChange={(event) => handleChangeTextInput(event, props.setData)}
                    inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
                />
            </div>
            <div className="row mb-3">
                <div className='col'><SearchTiposProdutos itemName='Grupo tipo' codigoItem={props.data.CODIGO_TIPOPRODUTO}
                    setCodigoItem={(value: number) => props.setData((formData: any) => ({ ...formData, 'CODIGO_TIPOPRODUTO': value }))} /></div>
                <div className='col'><SearchGruposProdutos itemName='Grupo produto' codigoItem={props.data.CODIGO_GRUPOPRODUTO}
                    setCodigoItem={(value: number) => props.setData((formData: any) => ({ ...formData, 'CODIGO_GRUPOPRODUTO': value }))} /></div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <SearchPlanoContas itemName='Conta compra' codigoItem={props.data.ACESSO_CONTACREDITO}
                        setCodigoItem={(value: string) => props.setData((formData: any) => ({ ...formData, 'ACESSO_CONTACREDITO': value }))} />
                </div>
                <div className="col">
                    <SearchPlanoContas itemName='Conta venda' codigoItem={props.data.ACESSO_CONTADEBITO}
                        setCodigoItem={(value: string) => props.setData((formData: any) => ({ ...formData, 'ACESSO_CONTADEBITO': value }))} />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-sm-2">Permite alteração grupo:</div>
                <Select className='col' value={props.data.ALTERACAOGRUPO.trim()} size='small' name='ALTERACAOGRUPO'
                    onChange={(event) => handleChangeSelectInput(event, props.setData)}>
                    <MenuItem value={"N"}>Não</MenuItem>
                    <MenuItem value={"S"}>Sim</MenuItem>
                </Select>

                <div className="col-sm-2">Comissionado:</div>
                <Select className='col' value={props.data.COMISSIONADO.trim()} size='small' name='COMISSIONADO'
                    onChange={(event) => handleChangeSelectInput(event, props.setData)}>
                    <MenuItem value={"N"}>Não</MenuItem>
                    <MenuItem value={"S"}>Sim</MenuItem>
                </Select>
            </div>
            <div className="row mb-3">
                <div className="col-sm-2">Venda frente de caixa:</div>
                <Select className='col' value={props.data.VENDAFRENTECAIXA.trim()} size='small' name='VENDAFRENTECAIXA'
                    onChange={(event) => handleChangeSelectInput(event, props.setData)}>
                    <MenuItem value={"N"}>Não</MenuItem>
                    <MenuItem value={"S"}>Sim</MenuItem>
                </Select>
                <div className="col-sm-2">Bloquear estoque zerado:</div>
                <Select className='col' value={props.data.BLOQUEARVENDAESTOQUEZERADO.trim()} size='small' name='BLOQUEARVENDAESTOQUEZERADO'
                    onChange={(event) => handleChangeSelectInput(event, props.setData)}>
                    <MenuItem value={"N"}>Não</MenuItem>
                    <MenuItem value={"S"}>Sim</MenuItem>
                </Select>
            </div>
            <div className="row mb-3">
                <div className="col-sm-2">Arredondamento:</div>
                <Select className='col' value={props.data.ARREDONDAMENTOTRUNCAMENTO.trim()} size='small' name='ARREDONDAMENTOTRUNCAMENTO'
                    onChange={(event) => handleChangeSelectInput(event, props.setData)}>
                    <MenuItem value={"A"}>Arredondamento</MenuItem>
                    <MenuItem value={"T"}>Truncamento</MenuItem>
                </Select>
                <div className="col-sm-2">Produção:</div>
                <Select className='col' value={props.data.PRODUCAOPROPRIA.trim()} size='small' name='PRODUCAOPROPRIA'
                    onChange={(event) => handleChangeSelectInput(event, props.setData)}>
                    <MenuItem value={"P"}>Própria</MenuItem>
                    <MenuItem value={"T"}>Terceiros</MenuItem>
                </Select>
            </div>
            <div className="row mb-3">
                <div className="col-sm-2">Estoque Mínimo:</div>
                <TextField hiddenLabel className='col' size="small" name='ESTOQUEREGULADOR'
                    value={props.data.ESTOQUEREGULADOR} onChange={(event) => handleChangeTextInput(event, props.setData)}
                    inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }} />
            </div>
        </div>
    )
}

export default GeralProduto