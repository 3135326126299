import * as React from 'react';
import { useState } from 'react'
import { useGlobalState } from '../../globalState'
import { Modal, Spinner } from 'react-bootstrap'
import axios from '../../axiosConfig'
import { AxiosError } from 'axios';
import { TextField, IconButton, Button } from '@mui/material'
import { Search, Send, AddCircle, Delete,Edit } from '@mui/icons-material'
import CreateGruposClientes from '../create/gruposClientes';
import UpdateGruposClientes from '../update/gruposClientes';
import {IGruposClientes as IItem} from '../typesFromDB'
import Snackbar from '../snackbar';
import AlertDialog from '../dialog';


interface IProps {
    setCodigoItem?: Function
    itemName: string
    codigoItem?: number 
    notSelecting?: boolean
    return?:Function
}

type Actions = 'search' | 'create' | 'edit'

function SearchGrupoClientes(props: IProps) {
    const [itemQuery, setItemQuery] = useState<IItem[] | undefined>(undefined)
    const [itemSelecionado, setItemSelecionado] = useState<IItem | undefined>(undefined)
    const [isSearching, setIsSearching] = useState(props.notSelecting ? true : false)
    const [searchTerm, setSearchTerm] = useState('')
    const [searchResults, setSearchResults] = useState<IItem[] | undefined>(undefined)
    const [empresaAtual] = useGlobalState('empresaAtual')
    const [currentAction, setCurrentAction] = useState<Actions>('search')
    const [snackbarData, setSnackbarData] = React.useState<
    { message: string, severity: 'success' | 'error' | 'info'}>({ message: '', severity: 'success' })
    const [isLoading,setIsLoading] = useState(false)
    const [alertOpen,setAlertOpen] = useState(false)

   

    const getItens = () => {//
        setIsLoading(true)
        axios.get('/clientes/grupos',)
            .then(r => {
                setItemQuery(r.data)
                setItemSelecionado(r.data[0])
            })
            .catch(error => {
                const err = error as AxiosError
                console.log(err.response?.data)
            }).finally(()=>setIsLoading(false))
    }

    if(itemSelecionado && props.codigoItem && itemSelecionado.NUMERO !== props.codigoItem)setItemSelecionado(itemQuery?.filter(r=>r.NUMERO === props.codigoItem)[0])

    const handleDelete = () => {
        itemSelecionado && axios.delete(`/gruposclientes/${itemSelecionado.NUMERO}`).then(r => {
            setSnackbarData({severity:'success', message:'Item excluido com sucesso'})
      }).catch(()=>{
        setSnackbarData({severity:'error', message:'Erro ao Excluir item'})
      }).finally(()=>{
        setAlertOpen(false)
        getItens()
    })
    }

    React.useEffect(() => {
        getItens()
    }, [empresaAtual])

    React.useEffect(() => {
        if (searchTerm.trim() === '') return setSearchResults(itemQuery)
        const result = itemQuery?.filter(item => {
            return item.NOME.includes(searchTerm.toUpperCase().trim()) ||
                item.NUMERO.toString().includes(searchTerm.trim())

        })
        setSearchResults(result)


    }, [searchTerm, itemQuery])

    return (
        <div>
             <Snackbar snackbarData={snackbarData} close={setSnackbarData} />
             <AlertDialog open={alertOpen} close={setAlertOpen} confirm={handleDelete}/>
             
            <Modal
                animation={false}
                centered
                show={isSearching}
                backdrop="static"
                keyboard={true}
                size='lg'
            >
                {isLoading 
                ?   <div className="d-flex justify-content-center text-primary">
                <Spinner variant="primary" animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div> 
                :null}
             
                {currentAction === 'create'
                    ? <CreateGruposClientes setSnackbarData={setSnackbarData} back={()=>setCurrentAction('search')} refresh={()=>getItens()}/>
                    : currentAction === 'search'
                        ? <>
                            <Modal.Body>
                            <input type="text" className="form-control" placeholder={`Pesquisar por ${props.itemName}`}
                                value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <hr />
                            <div className='table-responsive' style={{ maxHeight: '350px', overflow: 'scroll' }}>
                                <table className="table table-secondary table-hover ">
                                    <thead className="table-primary sticky-top">
                                        <tr>
                                            <th scope="col">Número</th>
                                            <th scope="col">Nome</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {searchResults?.map(item =>
                                            <tr className={`tr-size ${itemSelecionado?.NUMERO === item.NUMERO && 'table-active'}`} style={{ cursor: 'pointer' }} onClick={() => {
                                                props?.setCodigoItem && props.setCodigoItem(item.NUMERO)
                                                setItemSelecionado(item)
                                            }}>
                                                <td>{item.NUMERO}</td>
                                                <td>{item.NOME}</td>
                                            </tr>
                                        )}

                                    </tbody>
                                </table>
                            </div>

                            </Modal.Body>
                            <Modal.Footer>
                            <Button variant='contained' color='error' endIcon={<Delete />} sx={{ m: 0.5 }}
                                    onClick={() => {
                                        itemSelecionado && setAlertOpen(true)
                                    }}>
                                    Excluir
                                </Button>
                                <Button variant='contained' color='info' endIcon={<AddCircle />} sx={{ m: 0.5 }}
                                    onClick={() => {
                                        setCurrentAction('create')
                                    }}>
                                    Novo
                                </Button>
                                <Button variant='contained' color='info' endIcon={<Edit />} sx={{ m: 0.5 }}
                                    onClick={() => {
                                        itemSelecionado && setCurrentAction('edit')
                                    }}>
                                    Alterar
                                </Button>
                                <Button variant='contained' color='success' endIcon={<Send />} sx={{ m: 0.5 }}
                                    onClick={() => {
                                        setIsSearching(false)
                                        props?.return && props.return()
                                    }}>
                                    Concluído
                                </Button>
                            </Modal.Footer>
                        </>                    
                        : itemSelecionado ? <UpdateGruposClientes setSnackbarData={setSnackbarData} back={()=>setCurrentAction('search')} refresh={()=>getItens()} data={itemSelecionado}/> : null}


            </Modal>
            {
                !props.notSelecting && 
                <div className='row'>
                <label className='col-auto col-form-label' htmlFor="select-produto">{props.itemName}:</label>
                <div className="col-auto"><TextField type="text" value={itemSelecionado ? itemSelecionado.NUMERO + " - " + itemSelecionado.NOME : '-'} size='small'
                    InputProps={{ readOnly: true, }}
                    style={{ maxWidth: '200px' }} className="form-control" /></div>
                <div className="col-auto">
                    <div style={{ display: 'inline-block' }} data-tooltip="Visualizar todos" data-tooltip-location="right">
                        <IconButton size='large' color='primary'
                            onClick={() => setIsSearching(true)} >
                            <Search /></IconButton>
                    </div>
                </div>
            </div>
            }
        </div>
    )
}

export default SearchGrupoClientes