import { Chart } from "react-google-charts";
import { Paper } from "@mui/material";
import { RelatorioMes, ResumoData } from '../PDFs/interfacesPDF'

export const options = {

    title: "Vendas das empresas por mês",
    backgroundColor: '#f5f0f0',
    chartArea: { top: 25,bottom:50,right:10,left:60 }, pointSize: 15,
    legend: { position: "bottom" }

};

interface IProps {
    data: RelatorioMes['data']
}

function ResumoMesGraph(props: IProps) {

    const convertDataForLineChart = (data: RelatorioMes['data']): any[][] => {
        const headers = ['Mes', ...new Set(data.map(({ FANTASIA }) => FANTASIA))];
        const months = [...new Set(data.map(({ MES }) => MES))];
        const chartData: any[][] = [headers];

        months.forEach((month) => {
            const date = new Date(2022, month - 1, 1);
            const monthName = date.toLocaleString('default', { month: 'short' });
            const row: any[] = [monthName];

            headers.forEach((FANTASIA, i) => {
                if (i === 0) return;

                const companyData = data.find(
                    (item) => item.FANTASIA === FANTASIA && item.MES === month
                );
                row.push(companyData ? Number(companyData.TOTALEMPRESA) : 0);
            });

            chartData.push(row);
        });

        return chartData;
    };

    const convertedData = convertDataForLineChart(props.data)

    return (
        <Paper elevation={3} className='p-2 mt-2 no-print' sx={{overflow:'scroll',
            xl: { width: '600px' }, md: { width: '100%' }, maxWidth: '100%',
            height: convertedData[0].length > 10 ? '800px' : '500px', backgroundColor: '#f5f0f0'
        }}>
            <Chart
                data={convertDataForLineChart(props.data)}
                chartType={'LineChart'}
                width="100%"
                height="100%"
                options={options}
            /></Paper>
    );
}

export default ResumoMesGraph