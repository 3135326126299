import { Button, Checkbox, FormControlLabel, Grid, IconButton, TextField, TextFieldProps } from "@mui/material"
import { handleChangeCheckInput, handleChangeTextInput } from "../../../utilities/inputsUtilities"
import { useEffect, useState } from "react"
import axios from '../../../axiosConfig'
import { Close, Delete, Edit, Send } from "@mui/icons-material"
import Loading from '../../../utilities/loading'
import { IPrecoVenda } from "../../../utilities/typesFromDB"
import { NumericFormat } from "react-number-format"
import { toMoneyFormat } from "../../../utilities/helperFunctions"
import Snackbar from '../../../utilities/snackbar'
import { Modal, Spinner } from "react-bootstrap"

interface IProps {
    produto: number
}

const CustomTextField = (props: TextFieldProps) => {
    return (<TextField {...props} size={'small'} />)
}

function PrecoUpdateProduto(props: IProps) {
    const defaultPrecoVenda: IPrecoVenda = {
        ID: 0,
        NUMERO_EMPRESAPRODUTO: 0,
        CODIGO_PRODUTO: props.produto,
        CODIGO: 0,
        DESCRICAO: '',
        PRECOVENDA: '0',
        EXCLUIDO: 'N',
        TIPOVISTA: 'N',
        TIPOPRAZO: 'N',
        TIPOANTECIPADO: 'N',
        SINCRO: null,
    };
    const [newPreco, setNewPreco] = useState<IPrecoVenda>(defaultPrecoVenda)
    const [precoList, setPrecoList] = useState<IPrecoVenda[] | undefined>(undefined)
    const [selectedPreco, setSelectedPreco] = useState<IPrecoVenda | undefined>(undefined)
    const [isFullLoading, setIsFullLoading] = useState(false)
    const [snackbarData, setSnackbarData] = useState<
        { message: string, severity: 'success' | 'error' | 'info' }>({ message: '', severity: 'success' })
    const [isCreating, setIscreating] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    const getPreco = () => {
        setIsLoading(true)
        axios.get(`/produto/precos/${props.produto}`).then(r => {
            setPrecoList(r.data)
        }).finally(() => setIsLoading(false))
    }
    useEffect(() => {
        setSelectedPreco(undefined)
        getPreco()
    }, [props.produto])


    const getChanges = () => {
        if (!selectedPreco || !precoList) {
            setSnackbarData({ message: 'erro ao atualizar preço ', severity: 'error' });
            return
        }
        const index = precoList?.findIndex(i => i.ID === selectedPreco.ID)
        if (index === -1) {
            setSnackbarData({ message: 'erro ao atualizar preço - 2', severity: 'error' });
            return
        }
        if (parseFloat(selectedPreco.PRECOVENDA.replace(/[^\d]/g, '')) === 0) {
            setSnackbarData({ message: 'O preço de venda não pode ser zero', severity: 'error' });
            return;
        }

        const keysToCompare = Object.keys(selectedPreco) as Array<keyof IPrecoVenda>;
        const alteredKeys = keysToCompare.filter((item) => selectedPreco[item] !== precoList[index][item]);
        if (alteredKeys.length === 0) {
            setSnackbarData({ message: 'Nenhuma informação alterada', severity: 'error' });
            return;
        }

        const fieldsNameToCheck: (keyof IPrecoVenda)[] = ['TIPOANTECIPADO', 'TIPOPRAZO', 'TIPOVISTA'];
        const invalidChanges = fieldsNameToCheck.filter(
            (field) => selectedPreco[field] === 'N' && precoList[index][field].trim() === 'S'
        );
        console.log(invalidChanges)

        if (invalidChanges.length > 0) {
            setSnackbarData({ message: 'Não é permitdo desativar esse preço', severity: 'error' });
            return;
        }

        const alteredData: { [key: string]: any } = {}; // Initialize an empty object to store altered key-value pairs
        alteredKeys.forEach(item => {
            alteredData[item] = selectedPreco[item as keyof typeof selectedPreco];
        });
        return alteredData
    }

    const createPreco = () => {
        if (newPreco.DESCRICAO.trim() === '' || parseFloat(newPreco.PRECOVENDA.replace(/[^\d]/g, '')) === 0) {
            setSnackbarData({ message: 'Campo inválido', severity: 'error' });
            return
        }
        setIsFullLoading(true)
        axios.post('/criar/precovenda', {
            formData: newPreco
        }).then(r => {
            setPrecoList(r.data)
            setNewPreco(defaultPrecoVenda)
            setIscreating(false)
            setSnackbarData({ message: 'Ação finalizado com sucesso', severity: 'success' })
        }).finally(() => setIsFullLoading(false))
    }

    const updatePreco = () => {
        const updateData = getChanges()
        if (!updateData) return
        setIsFullLoading(true)
        console.log(updateData)
        axios.patch(`/precovenda/${selectedPreco?.ID}/${selectedPreco?.CODIGO_PRODUTO}`, {
            formData: updateData,
            oldData: selectedPreco
        }).then(r => {
            setPrecoList(r.data)
            setSelectedPreco(undefined)
            setIsUpdating(false)
            setSnackbarData({ message: 'Ação finalizado com sucesso', severity: 'success' })
        }).finally(() => setIsFullLoading(false))
    }

    const deletePreco = (id: number) => {
        const item = precoList?.find(x => x.ID === id)
        if (item?.TIPOVISTA.trim() === 'S' || item?.TIPOPRAZO.trim() === 'S' || item?.TIPOANTECIPADO.trim() === 'S') {
            setSnackbarData({ message: 'Item contem um preço ativo', severity: 'error' });
            return
        }
        setIsFullLoading(true)
        axios.delete(`/precovenda/${selectedPreco?.ID}/${selectedPreco?.CODIGO_PRODUTO}`).then(r => {
            setPrecoList(r.data)
            setSnackbarData({ message: 'Ação finalizado com sucesso', severity: 'success' })
        }).finally(() => setIsFullLoading(false))
    }

    return (
        <div>
            {isFullLoading ? <Loading /> : null}
            <Snackbar snackbarData={snackbarData} close={setSnackbarData} />

            <Modal
                animation={false}
                centered
                show={isCreating}
                backdrop="static"
                keyboard={true}
                size='lg'
            >
                <Modal.Body>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1 }}>
                        <Grid xs={3.2}>
                            <p className="">Descrição:</p>
                        </Grid>
                        <Grid xs={8}>
                            <TextField hiddenLabel className='' size="small" name="DESCRICAO"
                                value={newPreco?.DESCRICAO || ''} onChange={(event) => handleChangeTextInput(event, setNewPreco)}
                                inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
                            />
                        </Grid>
                        <Grid xs={3.2}>
                            <div className="col-auto">Preço:</div>
                        </Grid>
                        <Grid xs={8}>
                            <NumericFormat className=''
                                value={newPreco?.PRECOVENDA.replace('.', ',') || 0}
                                name='PRECOVENDA'
                                customInput={CustomTextField}
                                onChange={(event) => handleChangeTextInput(event, setNewPreco)}
                                thousandSeparator={false}
                                decimalSeparator=','
                                decimalScale={3}
                                fixedDecimalScale
                                prefix="R$"
                                allowNegative={false}
                            />
                        </Grid>
                        <Grid xs={10}>
                            <FormControlLabel className='' control={<Checkbox size='small' name='TIPOVISTA'
                                checked={newPreco?.TIPOVISTA.trim() === "S"} onChange={(event) => handleChangeCheckInput(event, setNewPreco)} />}
                                label="A vista" />
                        </Grid>
                        <Grid xs={10}>
                            <FormControlLabel className='' control={<Checkbox size='small' name='TIPOPRAZO'
                                checked={newPreco?.TIPOPRAZO.trim() === "S"} onChange={(event) => handleChangeCheckInput(event, setNewPreco)} />}
                                label="A prazo" />
                        </Grid>
                        <Grid xs={10}>
                            <FormControlLabel className='' control={<Checkbox size='small' name='TIPOANTECIPADO'
                                checked={newPreco?.TIPOANTECIPADO.trim() === "S"} onChange={(event) => handleChangeCheckInput(event, setNewPreco)} />}
                                label="Antecipado" />
                        </Grid>
                    </Grid>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='contained' color='error' endIcon={<Close />} sx={{ m: 0.5 }}
                        onClick={() => setIscreating(false)}>
                        Fechar
                    </Button>
                    <Button variant='contained' size='small' color='success' endIcon={<Send />}
                        onClick={() => createPreco()}
                    >Novo</Button>
                </Modal.Footer>
            </Modal>

            <Modal
                animation={false}
                centered
                show={isUpdating}
                backdrop="static"
                keyboard={true}
                size='lg'
            >
                <Modal.Body>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1 }}>
                        <Grid xs={3.2}>
                            <p className="">Descrição:</p>
                        </Grid>
                        <Grid xs={8}>
                            <TextField hiddenLabel className='' size="small" name="DESCRICAO"
                                 value={selectedPreco?.DESCRICAO || ''} onChange={(event) => handleChangeTextInput(event, setSelectedPreco)}
                                inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
                            />
                        </Grid>
                        <Grid xs={3.2}>
                            <div className="col-auto">Preço:</div>
                        </Grid>
                        <Grid xs={8}>
                            <NumericFormat className=''
                                value={selectedPreco?.PRECOVENDA.replace('.', ',') || 0}
                                name='PRECOVENDA'
                                customInput={CustomTextField}
                                onChange={(event) => handleChangeTextInput(event, setSelectedPreco)}
                                thousandSeparator={false}
                                decimalSeparator=','
                                decimalScale={3}
                                fixedDecimalScale
                                prefix="R$"
                                allowNegative={false}
                            />
                        </Grid>
                        <Grid xs={10}>
                            <FormControlLabel className='' control={<Checkbox size='small' name='TIPOVISTA'
                              checked={selectedPreco?.TIPOVISTA.trim() === "S"} onChange={(event) => handleChangeCheckInput(event, setSelectedPreco)} />}
                                label="A vista" />
                        </Grid>
                        <Grid xs={10}>
                            <FormControlLabel className='' control={<Checkbox size='small' name='TIPOPRAZO'
                                 checked={selectedPreco?.TIPOPRAZO.trim() === "S"} onChange={(event) => handleChangeCheckInput(event, setSelectedPreco)} />}
                                label="A prazo" />
                        </Grid>
                        <Grid xs={10}>
                            <FormControlLabel className='' control={<Checkbox size='small' name='TIPOANTECIPADO'
                                checked={selectedPreco?.TIPOANTECIPADO.trim() === "S"} onChange={(event) => handleChangeCheckInput(event, setSelectedPreco)} />}
                                label="Antecipado" />
                        </Grid>
                    </Grid>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='contained' color='error' endIcon={<Close />} sx={{ m: 0.5 }}
                        onClick={() => setIsUpdating(false)}>
                        Fechar
                    </Button>
                    <Button variant='contained' color='info' endIcon={<Edit />} sx={{ m: 0.5 }}
                onClick={() => {updatePreco()}}>
                Alterar
            </Button>  
                </Modal.Footer>
            </Modal>



            <Button sx={{mr:1}} variant='contained' size='small' color='success' endIcon={<Send />}
                onClick={() => setIscreating(true)}
            >Criar</Button>
            <Button variant='contained' size='small' color='info' endIcon={<Edit />}
                onClick={() => selectedPreco && setIsUpdating(true)}
            >Editar</Button>

            <div className='table-responsive' style={{ maxHeight: '350px', overflow: 'scroll' }}>
                <table className="table table-secondary table-hover ">
                    <thead className="table-primary sticky-top">
                        <tr>
                            <th scope="col">Código</th>
                            <th scope="col">Descrição</th>
                            <th scope="col">Preço venda</th>
                            <th scope="col">A vista</th>
                            <th scope="col">A prazo</th>
                            <th scope="col">Antecipado</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {precoList?.map(item =>
                            <tr className={`tr-size ${selectedPreco?.CODIGO === item.CODIGO && 'table-active'}`} style={{ cursor: 'pointer' }} onClick={() => {
                                setSelectedPreco(item)
                            }}>
                                <td>{item.CODIGO}</td>
                                <td>{item.DESCRICAO}</td>
                                <td>{toMoneyFormat(item.PRECOVENDA, 3)}</td>
                                <td>{item.TIPOVISTA}</td>
                                <td>{item.TIPOPRAZO}</td>
                                <td>{item.TIPOANTECIPADO}</td>
                                <td>      <IconButton size='small' color='error'
                                    onClick={() => deletePreco(item.ID)} >
                                    <Delete /></IconButton></td>
                            </tr>)}
                    </tbody></table></div>
            {isLoading &&
                <div className="d-flex justify-content-center text-primary">
                    <Spinner variant="primary" animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>}

        </div>
    )
}

export default PrecoUpdateProduto