import { ArrowBack, Print } from "@mui/icons-material"
import { Stack, IconButton, Select, MenuItem } from "@mui/material"
import PrintFooter from "../relatorios/printFooter"
import { toMoneyFormat, formatDateToFront } from "../../utilities/helperFunctions"
import { useGlobalState } from "../../globalState"
import { useState } from "react"
import React from "react"


export interface IPerdasSobras {
    ID: number
    NUMERO: number
    DATAABERTURA: string
    HORAABERTURA: string
    DATAFECHAMENTO: string
    HORAFECHAMENTO: string
    NUMERO_FUNCIONARIO: number
    NOME_FUNCIONARIO: string
    TOTALVENDA: string
    TOTALPAGAMENTO: string
    PERDASSOBRAS: string
}

interface IProps {
    data: IPerdasSobras[]
    back: () => void
}

export default function CaixaPerdasSobrasPDF(props: IProps) {
    const [nomeEmpresa] = useGlobalState('empresaAtual')
    const [tipoRelatorio,setTipoRelatorio] = useState('Nenhum')
    const totalVenda = props.data.reduce((acc, curr) => acc + parseFloat(curr.TOTALVENDA), 0);
    const totalPagamento = props.data.reduce((acc, curr) => acc + parseFloat(curr.TOTALPAGAMENTO), 0);
    const totalPerdasSobras = props.data.reduce((acc, curr) => acc + parseFloat(curr.PERDASSOBRAS), 0);
 
    const renderDetailedReport = () => {
        type GroupedData = { [key: string]: IPerdasSobras[] };
        const groupedData: GroupedData = props.data.reduce((acc: GroupedData, item: IPerdasSobras) => {
            if (!acc[item.NOME_FUNCIONARIO]) {
                acc[item.NOME_FUNCIONARIO] = [];
            }
            acc[item.NOME_FUNCIONARIO].push(item);
            return acc;
        }, {});
    
        return Object.keys(groupedData).map((key) => {
            const dataGroup = groupedData[key];
            const totalVenda = dataGroup.reduce((acc, curr) => acc + parseFloat(curr.TOTALVENDA), 0);
            const totalPagamento = dataGroup.reduce((acc, curr) => acc + parseFloat(curr.TOTALPAGAMENTO), 0);
            const totalPerdasSobras = dataGroup.reduce((acc, curr) => acc + parseFloat(curr.PERDASSOBRAS), 0);
    
            return(
                <React.Fragment key={key}>
                    <tr>
                        <th colSpan={6}>{key}</th>
                    </tr>
                    {dataGroup.map((item) => (
                        <tr key={item.ID}>
                            <td>{item.NUMERO}</td>
                            <td>{formatDateToFront(item.DATAABERTURA)}</td>
                            <td>{formatDateToFront(item.DATAFECHAMENTO)}</td>
                            <td>{toMoneyFormat(item.TOTALVENDA, 2)}</td>
                            <td>{toMoneyFormat(item.TOTALPAGAMENTO, 2)}</td>
                            <td>{toMoneyFormat(item.PERDASSOBRAS, 2)}</td>
                        </tr>
                    ))}
                    <tr>
                        <td colSpan={3}>Total</td>
                        <td className="fw-bold">{toMoneyFormat(totalVenda.toString(), 2)}</td>
                        <td className="fw-bold">{toMoneyFormat(totalPagamento.toString(), 2)}</td>
                        <td className="fw-bold">{toMoneyFormat(totalPerdasSobras.toString(), 2)}</td>
                    </tr>
                    <tr></tr>
                </React.Fragment>
            );
        });
    };
    

    
    return (
        <div className='container'>
            <Stack direction="row" alignItems="center" spacing={0.5} >
                <div style={{ display: 'inline-block' }} data-tooltip="Voltar" data-tooltip-location="bottom" >
                    <IconButton size="large" color="success" onClick={() => props.back()}>
                        <ArrowBack fontSize="inherit" /></IconButton>
                </div><br />
                <div style={{ display: 'inline-block' }} data-tooltip="Imprimir" data-tooltip-location="bottom">
                    <IconButton size="large" color="primary" onClick={() => window.print()}>
                        <Print fontSize="inherit" /></IconButton> </div></Stack>
                        <>
            <span>Agrupamento: </span>
                <Select size='small' value={tipoRelatorio} onChange={e=>setTipoRelatorio(e.target.value)}>
                    <MenuItem value="Nenhum">Nenhum</MenuItem>
                    <MenuItem value="Funcionario">Funcionario</MenuItem>
                </Select></>
                        <div className='pdf' id='section-to-print'>
                        <h5>{nomeEmpresa.nome}</h5>
                    <h6>Relatorio de perdas e sobras</h6>
                    <hr />

                <div className="table-responsive">
                    <table className="table table-sm">
                        <thead className='thead-wrap'>
                            <tr>
                                <th scope="col">Nº Caixa</th>
                                <th scope="col">Abertura</th>
                                <th scope="col">Fechamento</th>
                                <th scope="col">Total Venda</th>
                                <th scope="col">Total Pago</th>
                                <th scope="col">Perdas/Sobras</th>
                            </tr>
                        </thead>
                        <tbody>
                        {tipoRelatorio === "Nenhum" ? (
        props.data.map((item: IPerdasSobras) => (
            <tr key={item.ID}>
                <td>{item.NUMERO}</td>
                <td>{formatDateToFront(item.DATAABERTURA)}</td>
                <td>{formatDateToFront(item.DATAFECHAMENTO)}</td>
                <td>{toMoneyFormat(item.TOTALVENDA, 2)}</td>
                <td>{toMoneyFormat(item.TOTALPAGAMENTO, 2)}</td>
                <td>{toMoneyFormat(item.PERDASSOBRAS, 2)}</td>
            </tr>
        ))
    ) : (
        renderDetailedReport()
    )}
                        </tbody>
                    </table>
                </div>
                <p className="infoPDF fw-bold pr-3 text-end">
                Total Venda: {toMoneyFormat(totalVenda.toString(), 2)} | 
                Total Pagamento: {toMoneyFormat(totalPagamento.toString(), 2)} | 
                Total Perdas/Sobras: {toMoneyFormat(totalPerdasSobras.toString(), 2)}
            </p>
                <PrintFooter/>

            </div>
        </div>
    )
}