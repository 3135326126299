import axios from 'axios';
import jsonUrl from '../url.json';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom'
import { useState } from 'react';
import './login.css'
import { List, ListItemText, ListItemButton, ListItemIcon, ListItemSecondaryAction, Radio, Button, TextField, Avatar, Typography, Box, IconButton, InputAdornment, Grid, Link, Tooltip } from '@mui/material'
import { Send, LockOutlined, Visibility, VisibilityOff, Wifi,WifiOff } from '@mui/icons-material'
import { setGlobalState, IEmpresa,IUsuarioConectado } from '../globalState'
import { Spinner } from 'react-bootstrap';

interface EmpresaData {
  cnpj: string,
  fantasia: string,
  numerodeserie: number,
  md5: string,
  id: string,
  nome: string,
  master: number,
  conexao: boolean
}
type ScreenType = 'adm' | 'dashboard';

function Login() {
  const [loginStage, setLoginStage] = useState(0)
  const [email, setEmail] = useState('')
  const [senha, setSenha] = useState('')
  const [empresaID, setEmpresaID] = useState('')
  const [empresaData, setEmpresaData] = useState<EmpresaData[] | undefined>()
  const [showError, setShowError] = useState('')
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate()
  const gitVersion = process.env.REACT_APP_VERSION;

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const checkUsuario = () => {
    if (email.trim() === '' || senha.trim() === '') return setShowError('Campo vazio')
    setLoginStage(-1)
    axios.post(`${jsonUrl.URL}autenticacao/usuario`, { email: email, password: senha },
      { headers: { 'Content-Type': 'application/json', } },
    ).then(r => {
      localStorage.setItem('id', JSON.stringify(r.data[0].id))
      setShowError('')
      setEmpresaData(r.data[1])
      setEmpresaID(r.data[1][0]?.id)
      setLoginStage(1)
    }).catch(error => {
      const err = error as AxiosError
      if(err.response?.status === 401)setShowError('Credenciais erradas')
      else if(err.response?.status === 403)setShowError('Usuario sem permissão de acesso')
      else setShowError('Erro ao realizar operação')
      setLoginStage(0)
    })
  }

  const login = async () => {
    localStorage.setItem('empresaID', JSON.stringify(empresaID))
    setLoginStage(-1)
    await axios.get<[IEmpresa[],IUsuarioConectado]>(`${jsonUrl.URL}usuario/listar/${JSON.parse(window.localStorage.getItem('id') || '')}`, { headers: { Authorization: `Bearer ${empresaID}` } })
      .then((r) => {
        const empresaAtual = r.data[0].filter(empresa => empresa.id === Number(empresaID))[0]
        setGlobalState('empresas', r.data[0])
        setGlobalState('empresaAtual', empresaAtual)
        setGlobalState('usuarioConectado', r.data[1])
        navigate(r.data[1].dashboard === 1 ? '/dashboard' : '/adm')
      }).catch(err => setLoginStage(1))
  }


  return (
    <section className="pt-5 pb-5 mt-0 align-items-center d-flex flex-column bg-dark">
      <img src="/images/alive.png" alt="alive logo" className='mb-3'/>
      {loginStage === 0 &&
        <div className='container-fluid'>
          <div className="row  justify-content-center align-items-center d-flex-row text-center h-100">
            <div className="col-12 col-md-4 h-50 " style={{ maxWidth: '450px' }} >
              <div className="card shadow">
                <Box
                  sx={{
                    padding: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlined />
                  </Avatar>
                  <Typography component="h1" variant="h5">
                    Entrar
                  </Typography>

                  <TextField
                    margin="normal" required fullWidth size='small' id="email"
                    label="Email" name="email" autoComplete="email" autoFocus
                    value={email} onChange={event => {
                      setEmail(event.target.value)
                    }}
                  />

                  <TextField
                    margin="normal" required fullWidth name="password" label="Senha" type={showPassword ? "text" : "password"}
                    id="password" autoComplete="current-password" size='small' value={senha}
                    onChange={event => {
                      setSenha(event.target.value)
                    }} onKeyPress={(e) => {
                      if (e.key === "Enter") checkUsuario()
                    }}
                    InputProps={{ // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  <br /><p className={showError.length > 0 ? 'text-danger' : 'collapse'}>{showError}</p>
                  <Button variant='contained' fullWidth color='primary' endIcon={<Send />} sx={{ m: 0.5 }}
                    onClick={() => checkUsuario()}>
                    Login
                  </Button>
                  <Link href="https://alivesolucoes.com.br/#contato" target='_blank' rel='noreferrer noopener' variant="body2">
                    {"Precisa de ajuda? contate-nos"}
                  </Link>
                </Box>
              </div>
            </div>
          </div>
        </div>
      }

      {loginStage === 1 &&
        <div className='container-fluid'>
          <div className="row  justify-content-center align-items-center d-flex-row text-center h-100">
            <div className="col-12 col-md-4   h-50 " style={{ maxWidth: '450px' }}>
              <div className="card shadow">
                <div className="card-body">
                  <div className="mt-5 mb-3">
                    <h4>Selecione a empresa</h4><hr style={{ color: "#be2edd", opacity: '1' }} className="p-1" />
                    <List>
                      {empresaData?.map(dados =>
                        <ListItemButton dense sx={{ paddingBottom: '0px', paddingTop: '0px' }}  onKeyDown={(e) => {
                          if (e.key === "Enter") login()
                        }}
                        tabIndex={0}
                          selected={dados.id === empresaID}
                          onClick={() => setEmpresaID(dados.id)}
                        >
                          <ListItemIcon >
                            <Radio checked={dados.id === empresaID} />
                          </ListItemIcon>
                          <ListItemText primary={dados.fantasia}
                            primaryTypographyProps={{
                              fontSize: 14, fontWeight: '600'
                            }} secondary={dados.cnpj}
                            secondaryTypographyProps={{
                              color: 'orange'
                            }} />
                          <ListItemSecondaryAction>
                            {dados.conexao ?
                              <Tooltip title="Conectado com a empresa" arrow>
                                <Wifi color='success' /></Tooltip>
                              :
                              <Tooltip title="Sem Conexão com a empresa" arrow>
                                <WifiOff color='error' /></Tooltip>
                            }

                          </ListItemSecondaryAction>
                        </ListItemButton>
                      )}</List>
                    <Button variant='contained' color='primary' endIcon={<Send />} sx={{ m: 0.5 }}
                      onClick={() => login()}>
                      Entrar
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}


      {loginStage === -1 &&
        <div className='container-fluid'>
          <div className="row  justify-content-center align-items-center d-flex-row text-center h-100">
            <div className="col-12 col-md-4   h-50 " style={{ maxWidth: '400px' }}>
              <div className="card shadow">
                <div className="card-body">
                  <div className="d-flex justify-content-center text-primary">
                    <Spinner variant="primary" animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      }
      <br />
       <p className='fw-bold'>Versão: {gitVersion}</p>


    </section>
  )
}

export default Login;