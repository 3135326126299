import { Button, IconButton, TextField } from "@mui/material"
import { handleChangeTextInput } from "../../../utilities/inputsUtilities"
import { useEffect, useState } from "react"
import axios from '../../../axiosConfig'
import { formatDate } from '../../../utilities/helperFunctions'
import BasicDatePicker from "../../../utilities/dateComponent"
import { Delete, Send } from "@mui/icons-material"
import Loading from '../../../utilities/loading'

interface IRastreioTab {
    NUMERO_EMPRESA: number
    CODIGO_PRODUTO: number
    NUMERO_LOTE: string
    DATA_VALIDADE: string | Date
    NUMERO: number
    SERIE: string
    DATAEMISSAO: string | Date
    QUANTIDADE: string
}

interface IProps {
    produto: number
}

function RastreioProduto(props: IProps) {
    const emptyRastreio = {
        NUMERO_EMPRESA: 0, CODIGO_PRODUTO: props.produto, NUMERO_LOTE: '', DATA_VALIDADE: new Date(), NUMERO: 0, SERIE: '', DATAEMISSAO: new Date(), QUANTIDADE: ''
    }
    const [newRastreio, setNewRastreio] = useState(emptyRastreio)
    const [rastreioList, setRastreioList] = useState<IRastreioTab[] | undefined>(undefined)
    const [isFullLoading, setIsFullLoading] = useState(false)

    const getRastreios = () => {
        axios.get(`/produtos/validade/${props.produto}`).then(r => {
            setRastreioList(r.data)
        })
    }
    useEffect(() => {
        getRastreios()
    }, [])

    const createValidade = () => {
        setIsFullLoading(true)
        axios.post('/criar/produtovencimento', {
            formData: {
                ...newRastreio,
                DATA_VALIDADE: formatDate(newRastreio.DATA_VALIDADE, 'db'), DATAEMISSAO: formatDate(newRastreio.DATAEMISSAO, 'db')
            }
        }).then(r => {
            setRastreioList(r.data)
            setNewRastreio(emptyRastreio)
        }).finally(()=>setIsFullLoading(false))
    }

    const deleteValidade = (item: IRastreioTab) => {
        setIsFullLoading(true)
        axios.delete('/produtovencimento',{
            data: { formData:{
                ...item,
                DATA_VALIDADE: formatDate(item.DATA_VALIDADE, 'db'), DATAEMISSAO: formatDate(item.DATAEMISSAO, 'db')
            }}
        }).then(r=>{
            setRastreioList(r.data)
        }).finally(()=>setIsFullLoading(false))
    }

    return (
        <div>
                  {isFullLoading ? <Loading /> : null}
            <div className="row mb-3">
                <div className="col-sm-2">Data de vencimento:</div>
                <BasicDatePicker date={newRastreio.DATA_VALIDADE} setDate={(newDate: Date) => setNewRastreio((prev) => ({ ...prev, DATA_VALIDADE: newDate }))} placeHolder='Data' />
                <div className="col-auto">Lote:</div>
                <TextField hiddenLabel className='col-auto' size="small" name="NUMERO_LOTE"
                    value={newRastreio.NUMERO_LOTE} onChange={(event) => handleChangeTextInput(event, setNewRastreio)}
                    inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
                />
                <div className="col-auto">Número da nota:</div>
                <TextField hiddenLabel className='col-auto' size="small" name="NUMERO"
                    value={newRastreio.NUMERO} onChange={(event) => handleChangeTextInput(event, setNewRastreio)}
                    inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
                />
            </div>
            <div className="row mb-3">
                <div className="col-auto">Série da nota:</div>
                <TextField hiddenLabel className='col-auto' size="small" name="SERIE"
                    value={newRastreio.SERIE} onChange={(event) => handleChangeTextInput(event, setNewRastreio)}
                    inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
                />
                <div className="col-sm-2">Data emissão da nota:</div>
                <BasicDatePicker date={newRastreio.DATAEMISSAO} setDate={(newDate: Date) => setNewRastreio((prev) => ({ ...prev, DATAEMISSAO: newDate }))} placeHolder='Data' />
                <div className="col-auto">Quantidade produto:</div>
                <TextField hiddenLabel className='col-auto' size="small" name="QUANTIDADE"
                    value={newRastreio.QUANTIDADE} onChange={(event) => handleChangeTextInput(event, setNewRastreio)}
                    inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
                />
            </div>
            <Button variant='contained' size='small' color='success' endIcon={<Send />}
                onClick={() => createValidade()}
            >Criar</Button>

            <div className='table-responsive' style={{ maxHeight: '350px', overflow: 'scroll' }}>
                <table className="table table-secondary table-hover ">
                    <thead className="table-primary sticky-top">
                        <tr>
                            <th scope="col">Vencimento</th>
                            <th scope="col">Lote</th>
                            <th scope="col">Quantidade</th>
                            <th scope="col">Número nota</th>
                            <th scope="col">Série</th>
                            <th scope="col">Emissão</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {rastreioList?.map(item =>
                            <tr>
                                <td>{formatDate(item.DATA_VALIDADE, 'front')}</td>
                                <td>{item.NUMERO_LOTE}</td>
                                <td>{Number(item.QUANTIDADE).toFixed(3)}</td>
                                <td>{item.NUMERO}</td>
                                <td>{item.SERIE}</td>
                                <td>{formatDate(item.DATAEMISSAO, 'front')}</td>
                                <td>      <IconButton size='small' color='error'
                                    onClick={() => deleteValidade(item)} >
                                    <Delete /></IconButton></td>
                            </tr>)}
                    </tbody></table></div>
        </div>
    )
}

export default RastreioProduto