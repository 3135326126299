import { Modal } from 'react-bootstrap'
import { TextField, Button, FormControlLabel, Checkbox } from '@mui/material'
import { Send, ArrowBack } from '@mui/icons-material'
import { IRegioesClientes as IItem } from '../typesFromDB'
import { useState } from 'react'
import axios from '../../axiosConfig'
import Loading from '../loading'
import { handleChangeTextInput } from '../inputsUtilities'

interface IProps {
    back: () => void
    refresh: () => void
    setSnackbarData: React.Dispatch<React.SetStateAction<{
        message: string; severity: 'success' | 'error' | 'info';
    }>>;
}

function CreateRegioesClientes(props: IProps) {
    const [isFullLoading, setIsFullLoading] = useState(false)
    const [formData, setFormData] = useState<IItem>({
        NUMERO: 0,
        NOME: '',
        EXCLUIDO: '',
        NUMERO_EMPRESA: 0,
        SINCRO: null
    })

    const createItem = () => {
        setIsFullLoading(true)
        axios.post('/criar/regioesclientes', { formData: formData }).then(r => {
            props.setSnackbarData({ message: 'Registro criado com sucesso', severity: 'success' })
            props.refresh()
            props.back()
        }).catch(r => {
            props.setSnackbarData({ message: 'Erro ao criar registro', severity: 'error' })
        }).finally(() => setIsFullLoading(false))
    }

    return (
        <>
            {isFullLoading ? <Loading /> : null}
            <Modal.Body>
                <div className='row m-4'>
                    <div className="col-sm-2">Nome:</div>
                    <TextField hiddenLabel className='col' size="small" name='NOME'
                        value={formData.NOME} onChange={(event) => handleChangeTextInput(event, setFormData)}
                        inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='contained' color='success' endIcon={<ArrowBack />} sx={{ m: 0.5 }}
                    onClick={() => {
                        props.back()
                    }}>
                    Voltar
                </Button>
                <Button variant='contained' color='info' endIcon={<Send />} sx={{ m: 0.5 }}
                    onClick={() => {
                        createItem()
                    }}>
                    Criar
                </Button>
            </Modal.Footer>
        </>
    )
}

export default CreateRegioesClientes