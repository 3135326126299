import AbastecimentoBicoPDF from './abastecimentoPDF'
import AbastecimentoHoraPDF from './abastecimentoHoraPDF'
import { IAbastecimentos, IAbastecimentoPorHora } from './interfacePDFs'


interface Abastecimentos {
    data: IAbastecimentos['data'] | IAbastecimentoPorHora['data']
    date: {
        firstDate: string, lastDate: string
        firstHour: string, lastHour: string
    }
    btnTable?: boolean
}

interface IProps extends Abastecimentos {
    options: {
        agrupamento: string
    }
}


function AbastecimentoPDF(props: IProps) {

    const PDF = () => {
        return props.options.agrupamento === 'bico' ?
            <AbastecimentoBicoPDF data={props.data as IAbastecimentos['data']} date={props.date} btnTable={props.btnTable} />
            : <AbastecimentoHoraPDF data={props.data as IAbastecimentoPorHora['data']} date={props.date} btnTable={props.btnTable}/>
    }

    return (
        <div>{PDF()}</div>
    )

}
export default AbastecimentoPDF