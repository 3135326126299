import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, Grid, Paper, Radio, Typography } from '@mui/material';
import axios from '../../axiosConfig';
import { IUser, IUserPermissao } from '../../utilities/typesFromDB';
import { showSnackbar } from '../../utilities/snackbarShow';
import Loading from '../../utilities/loading';
import { useGlobalState } from '../../globalState';

interface IUsuarioInfo {
    id: number;
    return: Function;
}


function UsuarioInfo(props: IUsuarioInfo) {
    const [empresas] = useGlobalState('empresas');
    const [user, setUser] = useState<IUser>({
        id: props.id,
        name: '',
        email: '',
        password: '',
        master: 0,
        ativo: 1,
        dashboard: 0,
        sistema: 0,
        created_at: '',
        updated_at: ''
    });
    const [userPermissao, setUserPermissao] = useState<IUserPermissao>({
        cadastro: 0,
        lancamento: 0,
        financeiro: 0,
        fiscal: 0,
    });
    const [selectedEmpresas, setSelectedEmpresas] = useState<number[]>([]);
    const [isFullLoading, setIsFullLoading] = useState(true);


    useEffect(() => {
        setIsFullLoading(true);
        axios
            .get(`/mysql/usuario/${props.id}`)
            .then((response) => {
                const userData = response.data.user;
                const userPermsData = response.data.permissions;
                const userEmpresasData = response.data.empresas;

                setUser(userData);
                setUserPermissao(userPermsData);
                setSelectedEmpresas(userEmpresasData);
            })
            .catch((err) => {
                showSnackbar('Erro ao buscar usuário', 'error');
            })
            .finally(() => setIsFullLoading(false));
    }, [props.id]);

    return (
        <div>
            {isFullLoading ? <Loading /> : null}
            <div>
                <div className=".arrow-left ">
                    <i onClick={() => props.return()} className="fad fa-arrow-to-left arrow-left "></i>
                </div>
                <Paper elevation={3} sx={{ backgroundColor: '#f5f0f0', p: 3 }}>
                    <h5 className="fw-bolder">Usuario:</h5> <hr />
                    <div className="row mb-3">
                        <div className="col-auto">Nome:</div>
                        <Typography className="col">{user.name}</Typography>
                        <div className="col-auto">Email:</div>
                        <Typography className="col">{user.email}</Typography>

                    </div>
                    <div className="row mb-3">
                        <div className="col-auto">Criado em:</div>
                        <Typography className="col">{user.created_at ? new Date(user.created_at).toLocaleString('pt-BR') : "-"}</Typography>
                        <div className="col-auto">Atualizado em:</div>
                        <Typography className="col">{user.updated_at ? new Date(user.updated_at).toLocaleString('pt-BR') : "-"}</Typography>
                        <div className="col-auto">Ultimo acesso:</div>
                        <Typography className="col">{user.ultimoacesso ? new Date(user.ultimoacesso).toLocaleString('pt-BR') : "-"}</Typography>
                    </div>
                    <div className="row mb-3">
                        <FormControlLabel
                            className="col"
                            control={<Checkbox size="small" name="dashboard" checked={user.dashboard === 1} />}
                            label="Acesso Dashboard"
                        />
                        <FormControlLabel
                            className="col"
                            control={<Checkbox size="small" name="sistema" checked={user.sistema === 1} />}
                            label="Acesso ADM"
                        />
                        <FormControlLabel
                            className="col"
                            control={<Checkbox size="small" name="sistema" checked={user.ativo === 1} />}
                            label="Ativo"
                        />
                    </div>
                    <h5 className="fw-bolder">Permissões:</h5> <hr />
                    <div>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <h6>Cadastro:</h6>
                                <Typography>{userPermissao.cadastro === 0 ? 'Sem acesso' : userPermissao.cadastro === 1 ? 'Visualizar' : 'Editar'}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <h6>Lançamento:</h6>
                                <Typography>{userPermissao.lancamento === 0 ? 'Sem acesso' : userPermissao.lancamento === 1 ? 'Visualizar' : 'Editar'}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <h6>Financeiro:</h6>
                                <Typography>{userPermissao.financeiro === 0 ? 'Sem acesso' : userPermissao.financeiro === 1 ? 'Visualizar' : 'Editar'}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <h6>Fiscal:</h6>
                                <Typography>{userPermissao.fiscal === 0 ? 'Sem acesso' : userPermissao.fiscal === 1 ? 'Visualizar' : 'Editar'}</Typography>
                            </Grid>
                        </Grid>
                    </div>
                    <h5 className="fw-bolder">Empresas:</h5> <hr />
                    <div>
                        {selectedEmpresas.map((empresaId) => {
                            const empresa = empresas.find((emp: { id: number; }) => emp.id === empresaId);
                            if (empresa) {
                                return (
                                    <Typography key={empresa.id}>
                                        {empresa.fantasia} - <span style={{ color: 'orange' }}>{empresa.cnpj}</span>
                                    </Typography>
                                );
                            }
                            return null;
                        })}
                    </div>
                </Paper>
            </div>

        </div>
    );
}

export default UsuarioInfo;


