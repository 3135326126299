import { toMoneyFormat } from '../../../utilities/helperFunctions'
import { ShowEmpresa } from '../showEmpresa';
import PrintFooter from "../printFooter";
import { useGlobalState } from '../../../globalState';

function EstoqueFinalMovimentoPDF(props) {
    const [empresaAtual] = useGlobalState('empresaAtual')
    const totalMedio = props.data.reduce((acc, item) => {
        return acc += item.totalGrupoMedio
    }, 0)


    const totalAtual = props.data.reduce((acc, item) => {
        return acc += item.totalGrupoAtual
    }, 0)

    let codProdutoAtual = 0
    let totalProdutoAtual = 0
    let totalMedioProdutoAtual = 0
    const checkProdutoEstoque = (newCod)=>{

        if(codProdutoAtual === newCod){
            codProdutoAtual = newCod
            return true
        }else{
            codProdutoAtual = newCod
            return false
        }
    }
    const lastProductHandler = (cod,index,produtos)=>{
        if(codProdutoAtual === cod && produtos[index-1]?.CODIGO_PRODUTO === cod){
            totalProdutoAtual += Number((produtos[index].ESTOQUEATUAL * roundDecimals(produtos[index].ULTIMOCUSTO,3)).toFixed(2))
            totalMedioProdutoAtual += Number((produtos[index].ESTOQUEATUAL * roundDecimals(produtos[index].MEDIACUSTO,3)).toFixed(2))
        }
        if(codProdutoAtual === cod && produtos[index-1]?.CODIGO_PRODUTO === cod
            &&  produtos[index+1]?.CODIGO_PRODUTO !== cod ){

                return(
                    <tr>
                        <td className='fw-bold text-end' colSpan={5}>Total produto:</td>
                        <td className='fw-bold'>{toMoneyFormat(totalProdutoAtual)}</td>
                        <td className='fw-bold'>{toMoneyFormat(totalMedioProdutoAtual)}</td>
                    </tr>
                )
            }
            else if(codProdutoAtual === cod && produtos[index-1]?.CODIGO_PRODUTO !== cod){
                totalProdutoAtual = Number((produtos[index].ESTOQUEATUAL * roundDecimals(produtos[index].ULTIMOCUSTO,3)).toFixed(2))
                totalMedioProdutoAtual = Number((produtos[index].ESTOQUEATUAL * roundDecimals(produtos[index].MEDIACUSTO,3)).toFixed(2))
                return
            }
    }


    function roundDecimals(num, decimals) {
        num = Number(num)
        num = num.toFixed(decimals)
        return num
      }

    return (
        <div className='container'>
            <div className='pdf' id='section-to-print'>
            <ShowEmpresa/>
                <h6>Estoque &gt; Estoque final do movimento - {props.date}</h6>
                <hr />

                <br />
                <div className="table-responsive">
                <table className="table table-sm">
                    <thead>
                        <tr>
                            <th scope="col">Nome</th>
                            <th scope="col">Local</th>
                            <th scope="col">Estoque</th>
                            <th scope="col">Ult.Custo</th>
                            <th scope="col">Custo Médio</th>
                            <th scope="col">Total C.Unit</th>
                            <th scope="col">Total C.Médio</th>
                        </tr>
                    </thead>
                    
                    <tbody style={{ fontSize: '12px' }}>
                        {props.data.map((item, index) =>
                            <>
                                {item.produtos.length > 0 ? <tr key={'g' + index}>
                                    <td colSpan={7} className="fw-bold pl-4"> {'一> Grupo: '} {item.numero} {'-'} {item.nome}</td>
                                </tr> : null}

                                {item?.produtos?.map((itemP,indexP,produtos) =>
                                    <>   
                                            <tr key={indexP + "-"}>
                                                {checkProdutoEstoque(itemP.CODIGO_PRODUTO) ? <td>{"------->"}</td> : <td>{itemP.CODIGO_PRODUTO + ' - ' + itemP.DESCRICAO_PRODUTO}</td>}
                                                {itemP.NUMERO_TANQUE ? <td>{itemP.NUMERO_TANQUE + " [TANQUE]"}</td>
                                                : <td>{itemP.NUMERO_LOCALESTOQUE + " - " + itemP.DESCRICAO_LOCALESTOQUE}</td>}
                                                <td>{Number(itemP.ESTOQUEATUAL).toFixed(empresaAtual.dec.quantidade)}</td>
                                                <td>{ toMoneyFormat(itemP.ULTIMOCUSTO,empresaAtual.dec.valor)}</td>
                                                <td>{toMoneyFormat(itemP.MEDIACUSTO,4)}</td>
                                                <td>{toMoneyFormat(itemP.ESTOQUEATUAL * roundDecimals(itemP.ULTIMOCUSTO,3))}</td>
                                                <td>{toMoneyFormat(itemP.ESTOQUEATUAL * roundDecimals(itemP.MEDIACUSTO,3))}</td>
                                            </tr>
                                        {lastProductHandler(itemP.CODIGO_PRODUTO,indexP,produtos)}


                                    </>

                                )}
                                {item.produtos.length > 0 ? <tr key={'t' + index}>
                                    <td colSpan={7} className="fw-bold pl-4 text-end"> {'Total Grupo: '} {toMoneyFormat(item.totalGrupoAtual)} {'- Total Medio:'} {toMoneyFormat(item.totalGrupoMedio)}</td>
                                </tr> : null}
                                {item.produtos.length > 0 ? <tr key={'controle' + index}>
                                    <td colSpan={7} className="fw-bold pl-4 "></td>
                                </tr> : null}
                            </>

                        )}
                    </tbody>
                </table>
                </div>
                <p className='infoPDF fw-bold pr-3 text-end'>Total : {toMoneyFormat(totalAtual)} | {toMoneyFormat(totalMedio)}  </p>
                <PrintFooter/>
            </div> </div>
    )
}

export default EstoqueFinalMovimentoPDF
