import { TextField } from "@mui/material"
import { handleChangeTextInput } from "../../../utilities/inputsUtilities"
import SearchProdutosSefaz from "../../../utilities/searchs/searchProdutosSefaz";

interface ICodigoTab {
    CODIGOESTENDIDO: string
    CODIGOBARRAS: string | undefined
    CODIGO_PRODUTOSEFAZ: string
    CODIGOFABRICANTE: string | undefined
    CODIGO_NBMSH: string | undefined
    CODIGOTICKET: string  | undefined
    CODIGOTIPOSERVICO: string
    CODIGONATUREZARECEITA: string | undefined
    CODIGO_SAP: string
    CODIGO_CEST: string | undefined
    CODIGOETIQUETA: string
    CODIGOBARRASESTENDIDO: string | undefined
}

interface IProps {
    data: ICodigoTab
    setData: React.Dispatch<React.SetStateAction<ICodigoTab | any>>
}

function CodigoProduto(props: IProps) {

    const formatMultipleLines = (value: string) => {
        let formattedValue = value;
        if (formattedValue.startsWith("|"))formattedValue = formattedValue.slice(1);
        if (formattedValue.endsWith("|"))  formattedValue = formattedValue.slice(0, -1);
        return formattedValue.replace(/\|/g, "\n");
      };

    return (
        <div>
            <div className="row mb-3">
                <div className="col-sm-2">Cod. Estendido:</div>
                <TextField hiddenLabel className='col' size="small" name='CODIGOESTENDIDO'
                    value={props.data.CODIGOESTENDIDO} onChange={(event) => handleChangeTextInput(event, props.setData)}
                    inputProps={{ maxLength: 14, style: { textTransform: "uppercase" } }}
                />
                <div className="col-sm-2">Cod. Barras:</div>
                <TextField hiddenLabel className='col' size="small" name='CODIGOBARRAS'
                    value={props.data.CODIGOBARRAS} onChange={(event) => handleChangeTextInput(event, props.setData)}
                    inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
                />
            </div>
            <div className="row mb-3">
                <div className="col-sm-2">Cod. Fabricante:</div>
                <TextField hiddenLabel className='col' size="small" name='CODIGOFABRICANTE'
                    value={props.data.CODIGOFABRICANTE} onChange={(event) => handleChangeTextInput(event, props.setData)}
                    inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
                />
                <div className="col-sm-2">Cod. NCM:</div>
                <TextField hiddenLabel className='col' size="small" name='CODIGO_NBMSH'
                    value={props.data.CODIGO_NBMSH} onChange={(event) => handleChangeTextInput(event, props.setData)}
                    inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
                />
            </div>
            <div className="row mb-3">
                <div className="col-sm-2">Cod. Ticket:</div>
                <TextField hiddenLabel className='col' size="small" name='CODIGOTICKET'
                    value={props.data.CODIGOTICKET} onChange={(event) => handleChangeTextInput(event, props.setData)}
                    inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
                />
                <div className="col-sm-2">Cod. Tp. Serviço:</div>
                <TextField hiddenLabel className='col' size="small" name='CODIGOTIPOSERVICO'
                    value={props.data.CODIGOTIPOSERVICO} onChange={(event) => handleChangeTextInput(event, props.setData)}
                    inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
                />
            </div>
            <div className="row mb-3">
                <div className="col-sm-2">Cod. SAP:</div>
                <TextField hiddenLabel className='col' size="small" name='CODIGO_SAP'
                    value={props.data.CODIGO_SAP} onChange={(event) => handleChangeTextInput(event, props.setData)}
                    inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
                />
                <div className="col-sm-2">Cod. CEST:</div>
                <TextField hiddenLabel className='col' size="small" name='CODIGO_CEST'
                    value={props.data.CODIGO_CEST} onChange={(event) => handleChangeTextInput(event, props.setData)}
                    inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
                />
            </div>
            <div className="row mb-3">
                <div className="col-sm-2">Cod. Etiqueta:</div>
                <TextField hiddenLabel className='col' size="small" name='CODIGOETIQUETA'
                    value={props.data.CODIGOETIQUETA} onChange={(event) => handleChangeTextInput(event, props.setData)}
                    inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
                />
            </div>
            <div className="row mb-3">
                <div className="col-sm-2">Cod. Nat. Receita:</div>
                <TextField hiddenLabel className='col' size="small" name='CODIGONATUREZARECEITA'
                    value={props.data.CODIGONATUREZARECEITA} onChange={(event) => handleChangeTextInput(event, props.setData)}
                    inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
                />
                <div className='col'><SearchProdutosSefaz itemName='Código SEFAZ' codigoItem={props.data.CODIGO_PRODUTOSEFAZ}
                    setCodigoItem={(value: string) => props.setData((formData: any) => ({ ...formData, 'CODIGO_PRODUTOSEFAZ': value }))} /></div>
            </div>
            <div className="row mb-3">
                <div className="col-sm-2">Cod. barras estendido:</div>
                <TextField
                    hiddenLabel
                    className="col"
                    size="small"
                    name="CODIGOBARRASESTENDIDO"
                    value={props.data.CODIGOBARRASESTENDIDO}
                    onChange={(event) =>{
                        handleChangeTextInput(event, props.setData)
                        console.log(event.target.value)
                    }
                    }
                    inputProps={{
                        maxLength: 50,
                        style: { textTransform: "uppercase" },
                    }}
                    multiline // Permite várias linhas de texto
                    InputProps={{
                        // Adiciona uma função para formatar o valor
                        value: formatMultipleLines(props.data.CODIGOBARRASESTENDIDO || ''),
                    }}
                />
                <p>*Um codigo por linha</p>
            </div>

        </div>
    )
}

export default CodigoProduto