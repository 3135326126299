import React from 'react';
import { Paper } from '@mui/material';
import { Chart } from 'react-google-charts';

interface DataItem {
    DESCRICAO: string;
    TOTALCOMDESCACR: string;
}

interface Props {
    data: DataItem[];
}

const VendasProdutosGraph: React.FC<Props> = ({ data }) => {
    const grupos: { [key: string]: number } = {};
    data.forEach((item) => {
        const grupo = item['DESCRICAO'];
        const total = parseFloat(item['TOTALCOMDESCACR'].replace(',', '.'));
        grupos[grupo] = grupos[grupo] ? grupos[grupo] + total : total;
    });

    const chartData: Array<Array<string | number>> = [['Grupo', 'Total:']];
    Object.keys(grupos).forEach((grupo) => {
        chartData.push([grupo, grupos[grupo]]);
    });

    return (
        <Paper elevation={3} className='p-2 mt-2 no-print' sx={{
            overflow: 'scroll',
            xl: { width: '600px' },
            md: { width: '100%' },
            maxWidth: '100%',
            height: '500px',
            backgroundColor: '#f5f0f0'
        }}>
            <Chart
                width={'100%'}
                height={'100%'}
                chartType="ColumnChart"
                loader={<div>Carregando gráfico...</div>}
                data={chartData}
                options={{
                    title: 'Total de Vendas por Grupo de Produtos',
                    legend: { position: 'none' },
                    chartArea: { width: '70%', height: '70%' }
                }}
                rootProps={{ 'data-testid': '1' }}
            />
        </Paper>
    );
}

export default VendasProdutosGraph;
