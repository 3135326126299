import axios from '../../../axiosConfig'
import { useState } from "react"
import SearchFornecedor from '../../../utilities/searchs/searchFornecedor'
import Loading from '../../../utilities/loading'
import { Button, Paper } from '@mui/material'
import { Send } from '@mui/icons-material'

function TodasAPagar(props) {
    const [numeroFornecedor, setNumeroFornecedor] = useState(props?.fornecedores[0]?.NUMERO)
    const [isFullLoading, setIsFullLoading] = useState(false)

    const getTodasAPagar = () => {
        setIsFullLoading(true)
        const postData = {
            fornecedorInicial: numeroFornecedor === "00" ? '0' : numeroFornecedor,
            fornecedorFinal: numeroFornecedor === "00" ? '999' : numeroFornecedor
        }
        axios.post('/fornecedores/todasapagar', postData, )
            .then(r => {
                if (r.data.length < 1) return props.showPDF(true, 'Sem contas a pagar')
                props.setPDFData({ data: r.data })
                props.showPDF(false)

            })
            .catch(err => console.error(err)).finally(() => setIsFullLoading(false))

    }

    return (
        <div>
            {isFullLoading ? <Loading></Loading> : null}
            <Paper elevation={3} className='p-2' sx={{ backgroundColor: '#f5f0f0' }}>
                <h4 className='relatorio-title'>Todas a pagar</h4>
                <SearchFornecedor fornecedores={props.fornecedores}
                    setNumeroFornecedor={setNumeroFornecedor}
                ></SearchFornecedor>
                <Button variant='contained' endIcon={<Send />} onClick={() => getTodasAPagar()}>Gerar</Button>
            </Paper>
        </div>
    )

}

export default TodasAPagar