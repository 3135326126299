import { Modal } from 'react-bootstrap'
import { TextField, Button, FormControlLabel, Checkbox } from '@mui/material'
import { Send, ArrowBack } from '@mui/icons-material'
import { ITiposProdutos as IItem } from '../typesFromDB'
import { useState } from 'react'
import axios from '../../axiosConfig'
import Loading from '../loading'
import SearchPlanoContas from '../searchs/searchPlanoContas'
import { handleChangeTextInput } from '../inputsUtilities'
import { handleChangeCheckInput } from '../inputsUtilities'

interface IProps {
    back: () => void
    refresh: () => void
    setSnackbarData: React.Dispatch<React.SetStateAction<{
        message: string; severity: 'success' | 'error' | 'info';
    }>>;
}

function CreateTiposProdutos(props: IProps) {
    const [isFullLoading, setIsFullLoading] = useState(false)
    const [formData, setFormData] = useState<IItem>({
        NUMERO: 0,
        DESCRICAO: '',
        EXCLUIDO: 'N',
        ENTRADAS: 'N',
        SAIDAS: 'N',
        ACESSO_SUBCONTA: undefined,
        SINCRO: null
    })
    const [isACESSO_SUBCONTA,setIsACESSO_SUBCONTA] = useState(false)
    const createItem = () => {
        setIsFullLoading(true)
        axios.post('/criar/tiposprodutos', { formData: {...formData,ACESSO_SUBCONTA: isACESSO_SUBCONTA ? formData.ACESSO_SUBCONTA : null} }).then(r => {
            props.setSnackbarData({ message: 'Registro criado com sucesso', severity: 'success' })
            props.refresh()
            props.back()
        }).catch(r => {
            props.setSnackbarData({ message: 'Erro ao criar registro', severity: 'error' })
        }).finally(() => setIsFullLoading(false))
    }

    return (
        <>
            {isFullLoading ? <Loading /> : null}
            <Modal.Body>
                <div className='row m-4'>
                    <div className="col-sm-2">Descrição:</div>
                    <TextField hiddenLabel className='col' size="small" name='DESCRICAO'
                        value={formData.DESCRICAO} onChange={(event) => handleChangeTextInput(event, setFormData)}
                        inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
                    />
                </div>
                <div className='row m-4'>
                <div className="col-sm-2">Adicionar sub-conta?:</div>
                <FormControlLabel className='col' control={<Checkbox size='small' name=''
                        checked={isACESSO_SUBCONTA} onChange={() => setIsACESSO_SUBCONTA(value => !value)} />}
                        label="" />
                </div>
                {isACESSO_SUBCONTA && 
                  <div className='row m-4'>
                  <SearchPlanoContas itemName='Sub-Conta' codigoItem={formData.ACESSO_SUBCONTA}
                    setCodigoItem={(value: string) => setFormData(formData => ({ ...formData, 'ACESSO_SUBCONTA': value }))} />
                  </div>}
              
                <div className='row m-4'>
                    <div className="col-sm-2">Apresenta entrada:</div>
                    <FormControlLabel className='col' control={<Checkbox size='small' name='ENTRADAS'
                        checked={formData.ENTRADAS.trim() === "S"} onChange={(event) => handleChangeCheckInput(event, setFormData)} />}
                        label="" />
                    <div className="col-sm-2">Apresenta saída:</div>
                    <FormControlLabel className='col' control={<Checkbox size='small' name='SAIDAS'
                        checked={formData.SAIDAS.trim() === "S"} onChange={(event) => handleChangeCheckInput(event, setFormData)} />}
                        label="" />
                </div>


            </Modal.Body>
            <Modal.Footer>
                <Button variant='contained' color='success' endIcon={<ArrowBack />} sx={{ m: 0.5 }}
                    onClick={() => {
                        props.back()
                    }}>
                    Voltar
                </Button>
                <Button variant='contained' color='info' endIcon={<Send />} sx={{ m: 0.5 }}
                    onClick={() => {
                        createItem()
                    }}>
                    Criar
                </Button>
            </Modal.Footer>
        </>
    )
}

export default CreateTiposProdutos