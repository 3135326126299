import { useState } from 'react'
import axios from '../../../axiosConfig'
import SearchData from "../../../utilities/searchs/searchData"
import Loading from '../../../utilities/loading'
import { formatDate } from '../../../utilities/helperFunctions'
import { Button, Paper,IconButton } from '@mui/material'
import { Send,Storefront,Business } from '@mui/icons-material'
import { useGlobalState } from '../../../globalState'

interface IProps {
  showPDF: (error: boolean, errorMessageP?: string) => void
  setPDFData: React.Dispatch<React.SetStateAction<{}>>
  changeToMulti: (tipo:string)=>void
}

function ResumoValoresVenda(props:IProps) {
  const [firstDate, setFirstDate] = useState(new Date)
  const [lastDate, setLastDate] = useState(new Date)
  const [relatorio, setRelatorio] = useState('abertura')
  const [isFullLoading, setIsFullLoading] = useState(false)
  const [empresaAtual] = useGlobalState('empresaAtual')

  const getResumo = () => {
    setIsFullLoading(true) 
    const postData = {
      dataInicial: `${formatDate(firstDate, 'db')}`,
      dataFinal: `${formatDate(lastDate, 'db')}`
    }
    relatorio === 'abertura' ?
      axios.post('caixa/resumo/valores/abertura', postData, )
        .then(r => {
          if (r.data.length < 1) return props.showPDF(true, 'Sem registros para a data informada')
          let dataResumo = { data: r.data, date: { firstDate: formatDate(firstDate, 'front'), lastDate: formatDate(lastDate, 'front') } }
          props.setPDFData(dataResumo)
          props.showPDF(false)
        })
        .catch(err => props.showPDF(true, 'Erro ao buscar informação'))
        .finally(() => setIsFullLoading(false))
      :
      axios.post('caixa/resumo/valores/cupom', postData, )
        .then(r => {
          if (r.data.length < 1) return props.showPDF(true, 'Sem registros para a data informada')
          let dataResumo = { data: r.data, date: { firstDate: formatDate(firstDate, 'front'), lastDate: formatDate(lastDate, 'front') } }
          props.setPDFData(dataResumo)
          props.showPDF(false)
        })
        .catch(err => props.showPDF(true, 'Erro ao buscar informação'))
        .finally(() => setIsFullLoading(false))
  }
  return (
    <div>
      {isFullLoading ? <Loading></Loading> : null}
      <Paper elevation={3} className='p-2' sx={{ backgroundColor: '#f5f0f0' }}>
        <h4 className='relatorio-title'>Relatório resumo de valores de venda:</h4>
        <div className="form-check">
          <input onClick={() => {
            setRelatorio('abertura')
          }}
            className="form-check-input" type="radio" name="selectTipoRelatorio" id="radioAbertura" defaultChecked />
          <label className="form-check-label " htmlFor="radioAbertura">
            Abertura caixa
          </label></div>
        <div className="form-check">
          <input onClick={() => {
            setRelatorio('cupom')
          }}
            className="form-check-input" type="radio" name="selectTipoRelatorio" id="radioEmissao" />
          <label className="form-check-label " htmlFor="radioEmissao">
            Emissão Cupom
          </label></div>
        <hr></hr>
        <SearchData setFirstDate={setFirstDate} setLastDate={setLastDate}
          firstDate={firstDate} lastDate={lastDate} />

        <Button variant='contained' endIcon={<Send />} onClick={() => getResumo()}>Gerar</Button>
        {empresaAtual.filiais?.length > 1 ? <div style={{ display: 'inline-block' }} data-tooltip="Multi Loja" data-tooltip-location="bottom">
                    <IconButton size="large" color="error" onClick={() => props.changeToMulti('loja')}>
                        <Storefront fontSize="inherit" /></IconButton> </div>: null}
        
        <div style={{ display: 'inline-block' }} data-tooltip="Multi Empresa" data-tooltip-location="bottom">
                    <IconButton size="large" color="warning" onClick={() => props.changeToMulti('empresa')}>
                        <Business fontSize="inherit" /></IconButton> </div>
                  
      </Paper>
    </div>
  )
}

export default ResumoValoresVenda
