import { Chart } from "react-google-charts";
import { Paper, Box } from "@mui/material";
import { useEffect } from "react";
import { IVendasResumo } from './interfaceCaixa'

interface Props {
  data: IVendasResumo[];
}

const options = {
  legend: { position: "none" },
  backgroundColor: "#f5f0f0",
};

function CaixaResumoGrapProdutos({ data }: Props) {
  useEffect(() => {}, [data]);

  const generateProdutosGraph = (produtosData: Props["data"]) => {
    const template = ["Element", ""];
    const MoreSoldProdutos = produtosData
      .sort(function (a, b) {
        return Number(a.VALORNEGOCIACAO) - Number(b.VALORNEGOCIACAO);
      })
      .reverse()
      .map((item) => [item.DESCRICAO_PRODUTO, Number(item.VALORNEGOCIACAO)]);
    console.log(MoreSoldProdutos);
    return [template, ...MoreSoldProdutos.slice(0, 5)];
  };

  return (
    <Paper
      elevation={3}
      className="p-2 mt-2"
      sx={{
        xl: { width: "400px" },
        md: { width: "500px" },
        maxWidth: "600px",
        height: "400px",
        backgroundColor: "#f5f0f0",
      }}
    >
      <p>Mais vendidos dos caixas selecionados</p>
      {data && data.length > 1 ? (
        <Chart
          chartType="ColumnChart"
          width="100%"
          height="300px"
          data={generateProdutosGraph(data)}
          options={options}
        />
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight={"100%"}
        >
          <p className="text-center">
            Sem informações para o grafico de vendas de produtos
          </p>
        </Box>
      )}
    </Paper>
  );
}

export default CaixaResumoGrapProdutos;