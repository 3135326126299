import { Modal } from 'react-bootstrap'
import { TextField, Button, FormControlLabel, Checkbox, InputBaseComponentProps, Select, MenuItem } from '@mui/material'
import { Send, ArrowBack } from '@mui/icons-material'
import { IPlanoContas as IItem } from '../typesFromDB'
import { useState } from 'react'
import axios from '../../axiosConfig'
import Loading from '../loading'
import { handleChangeTextInput, handleChangeCheckInput, handleChangeSelectInput } from '../inputsUtilities'
import InputMask from 'react-input-mask'

interface IProps {
    back: () => void
    refresh: () => void
    setSnackbarData: React.Dispatch<React.SetStateAction<{
        message: string; severity: 'success' | 'error' | 'info';
    }>>;
}

function CreatePlanoContas(props: IProps) {
    const [isFullLoading, setIsFullLoading] = useState(false)
    const [formData, setFormData] = useState<IItem>({
        TIPO: '',
        ULTIMONIVEL: 'N',
        DESCRICAO: '',
        EXCLUIDO: 'N',
        ACESSO: '',
        SINCRO: null
    })

    const createItem = () => {
        setIsFullLoading(true)
        axios.post('/criar/planocontas', { formData: formData }).then(r => {
            props.setSnackbarData({ message: 'Registro criado com sucesso', severity: 'success' })
            props.refresh()
            props.back()
        }).catch(r => {
            props.setSnackbarData({ message: 'Erro ao criar registro', severity: 'error' })
        }).finally(() => setIsFullLoading(false))
    }


    return (
        <>
            {isFullLoading ? <Loading /> : null}
            <Modal.Body>
                <div className='row m-4'>
                    <div className="col-sm-2">Acesso:</div>
                    <InputMask
                        mask="9.9.99.999"
                        maskChar="_"
                        value={formData.ACESSO}
                        onChange={(event) => handleChangeTextInput(event, setFormData)}
                    >
                        {(inputProps: InputBaseComponentProps | undefined) => (
                            <TextField
                                hiddenLabel
                                className="col"
                                size="small"
                                name="ACESSO"
                                inputProps={{ ...inputProps, maxLength: 11, style: { textTransform: "uppercase" } }}
                            />
                        )}
                    </InputMask>
                </div>
                <div className='row m-4'>
                    <div className="col-sm-2">Tipo:</div>
                    <Select className='col' value={formData.TIPO.trim()} size='small' name='TIPO'
                        onChange={(event) => handleChangeSelectInput(event, setFormData)}>
                        <MenuItem value={"C"}>Crédito</MenuItem>
                        <MenuItem value={"D"}>Débito</MenuItem>
                    </Select>
                    <div className="col-sm-2">Último nível:</div>
                    <Select className='col' value={formData.ULTIMONIVEL.trim()} size='small' name='ULTIMONIVEL'
                        onChange={(event) => handleChangeSelectInput(event, setFormData)}>
                        <MenuItem value={"S"}>Sim</MenuItem>
                        <MenuItem value={"N"}>Não</MenuItem>
                    </Select>
                </div>
                <div className='row m-4'>
                    <div className="col-sm-2">Descrição:</div>
                    <TextField hiddenLabel className='col' size="small" name='DESCRICAO'
                        value={formData.DESCRICAO} onChange={(event) => handleChangeTextInput(event, setFormData)}
                        inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
                    />
                </div>


            </Modal.Body>
            <Modal.Footer>
                <Button variant='contained' color='success' endIcon={<ArrowBack />} sx={{ m: 0.5 }}
                    onClick={() => {
                        props.back()
                    }}>
                    Voltar
                </Button>
                <Button variant='contained' color='info' endIcon={<Send />} sx={{ m: 0.5 }}
                    onClick={() => {
                        createItem()
                    }}>
                    Criar
                </Button>
            </Modal.Footer>
        </>
    )
}

export default CreatePlanoContas