import * as React from 'react';
import { useState } from 'react'
import { useGlobalState } from '../../globalState'
import { Modal } from 'react-bootstrap'
import axios from '../../axiosConfig'
import {AxiosError } from 'axios';
import {TextField, IconButton,Button } from '@mui/material'
import { Search, Send } from '@mui/icons-material'

interface IProps{
    setCodigoProduto:React.Dispatch<React.SetStateAction<number>>
    
}

interface IProdutos {
        CODIGO: number,
        DESCRICAO: string,
        TIPOPRODUTO: string
    
}

function SearchProdutos(props: IProps) {
    const [produtos,setProdutos] = useState<IProdutos[] | undefined>(undefined)
    const [produtoSelecionado, setProdutoSelecionado] = useState<IProdutos | undefined>(undefined)
    const [isSearching, setIsSearching] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')
    const [searchResults, setSearchResults] = useState<IProdutos[] | undefined>(undefined)
    const [empresaAtual] = useGlobalState('empresaAtual')

    const getProdutos = ()=>{
        axios.get('/produtos/pesquisar/listar',)
        .then(r => setProdutos(r.data))
        .catch(error=>{
            const err = error as AxiosError
            console.log(err.response?.data)
        })
    }


    React.useEffect(()=>{
        getProdutos()
    },[empresaAtual])

    React.useEffect(()=>{
        if(searchTerm.trim() === '')return setSearchResults(produtos?.filter(item => item.TIPOPRODUTO.trim() === 'C' ))
        const result = produtos?.filter(item =>{
            if(item.TIPOPRODUTO.trim() === 'C'){
                return item.DESCRICAO.includes(searchTerm.toUpperCase().trim()) ||
                item.CODIGO.toString().includes(searchTerm.trim())
            }
        })
        setSearchResults(result)

    },[searchTerm,produtos])

    return (
        <div>
        <Modal
                animation={false}
                centered
                show={isSearching}
                backdrop="static"
                keyboard={true}
            >
                <Modal.Body>
                    <input type="text" className="form-control" placeholder='Pesquisar por produto'
                        value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <hr />
                    <select style={{ height: '250px' }} className='form-select' multiple 
                    onChange={(e: React.ChangeEvent<HTMLSelectElement> )=> {
                        if (Number(e.target.value) === 0) return setProdutoSelecionado(undefined)
                        setProdutoSelecionado(searchResults?.find(x=>x.CODIGO === Number(e.target.value)))
                    }} id="selectF">
                        <option value={0}>Todos</option>
                        {searchResults?.map(produto => 
                            <option value={produto.CODIGO}>{produto.CODIGO + " - " + produto.DESCRICAO}</option>
                            )}
                        
                    </select>
                </Modal.Body>
                <Modal.Footer>
          <Button variant='contained' color='success' endIcon={<Send/>} sx={{m:0.5}} 
          onClick={() => {
            if(produtoSelecionado === undefined)props.setCodigoProduto(0)
            else props.setCodigoProduto(produtoSelecionado.CODIGO)
            setIsSearching(false)
            }}>
            Selecionar
          </Button>
                </Modal.Footer>
            </Modal>

            <div className='row'>
                <label className='col-auto col-form-label' htmlFor="select-produto">Produto:</label>
                <div className="col-auto"><TextField type="text" value={produtoSelecionado? produtoSelecionado.DESCRICAO : 'Todos'} size='small'
                    InputProps={{ readOnly: true, }}
                    style={{ maxWidth: '200px' }} className="form-control" /></div>
                <div className="col-auto">
                    <div style={{ display: 'inline-block' }} data-tooltip="Visualizar todos" data-tooltip-location="right">
                        <IconButton size='large' color='primary' 
                        onClick={()=>setIsSearching(true)} >
                            <Search /></IconButton>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default SearchProdutos