import {  TextField, TextFieldProps } from "@mui/material"
import {  handleChangeTextInput } from "../../../utilities/inputsUtilities"
import { NumericFormat } from "react-number-format"

interface IPrecoTab {
    PRECO: string
}

interface IProps {
    data: IPrecoTab
    setData: React.Dispatch<React.SetStateAction<IPrecoTab>>
}

const CustomTextField = (props: TextFieldProps) => {
    return (
        <TextField
            {...props}
            size={'small'}
        />
    )
}

function PrecoProduto(props: IProps) {
    return (
        <div>
            <div className="row mb-3">
                <div className="col-sm-2">Preço:</div>
                <NumericFormat  className='col'
                        value={props.data.PRECO}
                        name='PRECO'
                        customInput={CustomTextField}
                        onChange={(event) => handleChangeTextInput(event, props.setData)}
                        thousandSeparator={false}
                        decimalSeparator=','
                        decimalScale={3}
                        fixedDecimalScale
                        prefix="R$"
                        allowNegative={false}
                />
            </div>
        </div>
    )
}

export default PrecoProduto