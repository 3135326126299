import { Modal } from 'react-bootstrap'
import { TextField, Button, FormControlLabel, Checkbox } from '@mui/material'
import { Send, ArrowBack } from '@mui/icons-material'
import {  ICondicaoPagamento as IItem } from '../typesFromDB'
import { useState } from 'react'
import axios from '../../axiosConfig'
import Loading from '../loading'
import { handleChangeTextInput } from '../inputsUtilities'
import { handleChangeCheckInput } from '../inputsUtilities'

interface IProps {
    data:IItem
    back: () => void
    refresh: () => void
    setSnackbarData: React.Dispatch<React.SetStateAction<{
      message: string; severity: 'success' | 'error' | 'info';}>>;
}

function UpdateCondicaoPagamento(props: IProps) {
    const [isFullLoading, setIsFullLoading] = useState(false)
    const [formData, setFormData] = useState<IItem>({...props.data})


    const createItem = () => {
        setIsFullLoading(true)
        axios.patch('/condicaopagamento', { formData: formData }).then(r => {
            props.setSnackbarData({ message: 'Registro alterado com sucesso', severity: 'success' })
            props.refresh()
            props.back()
        }).catch(r => {
            props.setSnackbarData({ message: 'Erro ao criar registro', severity: 'error' })
        }).finally(()=>setIsFullLoading(false))
    }

    return (
        <>
        {isFullLoading ? <Loading/> : null}
            <Modal.Body>
                <div className='row m-4'>
                    <div className="col-sm-2">Descrição:</div>
                    <TextField hiddenLabel className='col' size="small" name='DESCRICAO'
                        value={formData.DESCRICAO} onChange={(event) => handleChangeTextInput(event, setFormData)}
                        inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
                    />
                </div>
                <div className='row m-4'>
                    <div className="col-sm-2">Número parcelas:</div>
                    <TextField hiddenLabel className='col' size="small" name='NUMEROPARCELAS'
                        value={formData.NUMEROPARCELAS} onChange={(event) => handleChangeTextInput(event, setFormData)}
                        inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
                    />
                    <div className="col-sm-2">Vencimento fixo:</div>
                    <TextField hiddenLabel className='col' size="small" name='VENCIMENTOFIXO'
                        value={formData.VENCIMENTOFIXO} onChange={(event) => handleChangeTextInput(event, setFormData)}
                        inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
                    />
                </div>
                <div className='row m-4'>
                    <div className="col-sm-2">Dias prazo:</div>
                    <TextField hiddenLabel className='col' size="small" name='DIASPRAZO'
                        value={formData.DIASPRAZO} onChange={(event) => handleChangeTextInput(event, setFormData)}
                        inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
                    />
                    <div className="col-sm-2">Intervalo vencimentos:</div>
                    <TextField hiddenLabel className='col' size="small" name='INTERVALOVENCIMENTO'
                        value={formData.INTERVALOVENCIMENTO} onChange={(event) => handleChangeTextInput(event, setFormData)}
                        inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
                    />
                </div>
                <div className='row m-4'>
                    <div className="col-sm-2">Solicita vencimentos:</div>
                    <FormControlLabel className='col' control={<Checkbox size='small' name='SOLICITAVENCIMENTO'
                        checked={formData.SOLICITAVENCIMENTO.trim() === "S"} onChange={(event) => handleChangeCheckInput(event, setFormData)} />}
                        label="" />
                        <div className="col-sm-2">Primeira a vista:</div>
                    <FormControlLabel className='col' control={<Checkbox size='small' name='PRIMEIRAVISTA'
                        checked={formData.PRIMEIRAVISTA.trim() === "S"} onChange={(event) => handleChangeCheckInput(event, setFormData)} />}
                        label="" />
                </div>


            </Modal.Body>
            <Modal.Footer>
                <Button variant='contained' color='success' endIcon={<ArrowBack />} sx={{ m: 0.5 }}
                    onClick={() => {
                        props.back()
                    }}>
                    Voltar
                </Button>
                <Button variant='contained' color='info' endIcon={<Send />} sx={{ m: 0.5 }}
                    onClick={() => {
                        createItem()
                    }}>
                    Alterar
                </Button>
            </Modal.Footer>
        </>
    )
}

export default UpdateCondicaoPagamento