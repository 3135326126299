import React from 'react';
import { Paper } from '@mui/material';
import { Chart } from 'react-google-charts';
import { totalLucro } from './interfaceLucro';
import { toMoneyFormat } from '../../../utilities/helperFunctions';

interface Props {
    data: totalLucro[];
    tiposProdutos: { DESCRICAO_TIPOPRODUTO: string }[];
}

function LucroTipoGraph({ data, tiposProdutos }: Props) {
    const chartData: Array<Array<string | number>> = [['Tipo', 'Preço Médio', 'Último Preço']];
    data.forEach((tipo, index) => {
        const nomeTipo = tiposProdutos[index].DESCRICAO_TIPOPRODUTO;
        chartData.push([
            nomeTipo,
            tipo.TOTAL_LUCROBRUTO_MEDPERIODO,
            tipo.TOTAL_LUCROBRUTO_PRECOULTIMO
        ]);
    });

    return (
        <Paper elevation={3} className='p-2 mt-2 no-print' sx={{
            overflow: 'scroll',
            xl: { width: '600px' },
            md: { width: '100%' },
            maxWidth: '100%',
            height: '800px',
            backgroundColor: '#f5f0f0'
        }}>
            <Chart
                width={'100%'}
                height={'100%'}
                chartType="ColumnChart"
                loader={<div>Carregando gráfico...</div>}
                data={chartData}
                options={{
                    title: 'Preço Médio e Último Preço por Tipo de Produto',
                    legend: { position: 'top' },
                    chartArea: { width: '70%', height: '70%' },
                    bars: 'horizontal',
                    hAxis: {
                        title: 'Valor',
                        format: 'currency'
                    },
                    vAxis: {
                        title: 'Tipo'
                    }
                }}
                rootProps={{ 'data-testid': '1' }}
            />
        </Paper>
    );
}

export default LucroTipoGraph;
