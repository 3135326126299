import * as React from 'react';
import { useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import axios from '../../axiosConfig'
import { AxiosError } from 'axios';
import { TextField, IconButton, Button } from '@mui/material'
import { Search, Send, AddCircle, Edit, ExpandMore,ChevronRight } from '@mui/icons-material'
import CreatePlanoContas from '../create/planoContas';
import UpdatePlanoContas from '../update/planoContas';
import { IPlanoContas as IItem } from '../typesFromDB'
import Snackbar from '../snackbar';
import { TreeItem, TreeView } from '@mui/lab';


interface IProps {
    setCodigoItem: Function
    itemName: string
    codigoItem: string | undefined
}

type Actions = 'search' | 'create' | 'edit'

function SearchPlanoContas(props: IProps) {
    const [itemQuery, setItemQuery] = useState<IItem[] | undefined>(undefined)
    const [itemSelecionado, setItemSelecionado] = useState<IItem | undefined>(undefined)
    const [isSearching, setIsSearching] = useState(false)
    const [currentAction, setCurrentAction] = useState<Actions>('search')
    const [snackbarData, setSnackbarData] = React.useState<
        { message: string, severity: 'success' | 'error' | 'info' }>({ message: '', severity: 'success' })
    const [isLoading, setIsLoading] = useState(false)



    const getItens = () => {
        setIsLoading(true)
        axios.get<IItem[]>('/planocontas',)
            .then(r => {
                setItemQuery(r.data)
                setItemSelecionado(r.data.find(item => item.ULTIMONIVEL.trim() === 'S'))
            })
            .catch(error => {
                const err = error as AxiosError
                console.log(err.response?.data)
            }).finally(() => setIsLoading(false))
    }

    if (itemSelecionado && props.codigoItem && itemSelecionado.ACESSO !== props.codigoItem) setItemSelecionado(itemQuery?.find(item => item.ACESSO.trim() === props.codigoItem?.trim()))


    const treeItem = (data:IItem[]) =>{
        const result1 = data.filter(item => {
            const acesso = item.ACESSO.split('.');
            return Number(acesso[0]) > 0 && acesso[1] === '0' && acesso.length === 4;
        });
        const result2 = data.filter(item => {
            const acesso = item.ACESSO.split('.');
            return Number(acesso[1]) > 0 && acesso[2] === '00' && acesso.length === 4;
        });
        const result3 = data.filter(item => {
            const acesso = item.ACESSO.split('.');
            return Number(acesso[2]) > 0 && acesso[3] === '000' && acesso.length === 4;
        });
        const result4 = data.filter(item => {
            const acesso = item.ACESSO.split('.');
            return Number(acesso[3]) > 0 && acesso.length === 4;
        });

        return [result1,result2,result3,result4] 
    }

    const generateTree = ()=>{
        if(!itemQuery)return
        const itens = treeItem(itemQuery)
        return(
            <>
            {itens[0].map(i1 =>
                <TreeItem nodeId={i1.ACESSO} label={i1.DESCRICAO}>
                    {itens[1].map(i2 => i1.ACESSO[0] === i2.ACESSO[0] &&
                        <TreeItem nodeId={i2.ACESSO} label={i2.DESCRICAO}>
                            {itens[2].map(i3 => i1.ACESSO[0] === i3.ACESSO[0] && i2.ACESSO[2] === i3.ACESSO[2] &&
                                <TreeItem nodeId={i3.ACESSO} label={i3.DESCRICAO}>
                                    {itens[3].map(i4 => i1.ACESSO[0] === i4.ACESSO[0] && i2.ACESSO[2] === i4.ACESSO[2] &&
                                        i3.ACESSO[4] === i4.ACESSO[4] && i3.ACESSO[5] === i4.ACESSO[5] && 
                                        <TreeItem nodeId={i4.ACESSO} label={i4.DESCRICAO} 
                                        onClick={()=>{
                                            props.setCodigoItem(i4.ACESSO)
                                            setItemSelecionado(i4)}}>
                                        </TreeItem>
                                        )}
                                </TreeItem>
                                )}
                        </TreeItem>
                        )}
                </TreeItem>
                )}
            </>
        )
    }


    React.useEffect(() => {
        getItens()
    }, [])

    return (
        <div>
            <Snackbar snackbarData={snackbarData} close={setSnackbarData} />

            <Modal
                animation={false}
                centered
                show={isSearching}
                backdrop="static"
                keyboard={true}
                size='lg'
            >
                {isLoading &&
                    <div className="d-flex justify-content-center text-primary">
                        <Spinner variant="primary" animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                }

                {currentAction === 'create'
                    ? <CreatePlanoContas setSnackbarData={setSnackbarData} back={() => setCurrentAction('search')} refresh={() => getItens()} />
                    : currentAction === 'search'
                        ? <>
                            <Modal.Body>
                                <div>
            <p>Conta receber</p>
            <TreeView
                aria-label="file system navigator"
                defaultCollapseIcon={<ExpandMore />}
                defaultExpandIcon={<ChevronRight />}
                sx={{ height: 240, flexGrow: 1, maxWidth: 600, overflowY: 'auto' }}
            >
                {generateTree()}
            </TreeView>
        </div>

                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant='contained' color='info' endIcon={<AddCircle />} sx={{ m: 0.5 }}
                                    onClick={() => {
                                        setCurrentAction('create')
                                    }}>
                                    Novo
                                </Button>
                                <Button variant='contained' color='info' endIcon={<Edit />} sx={{ m: 0.5 }}
                                    onClick={() => {
                                        itemSelecionado && setCurrentAction('edit')
                                    }}>
                                    Alterar
                                </Button>
                                <Button variant='contained' color='success' endIcon={<Send />} sx={{ m: 0.5 }}
                                    onClick={() => {
                                        setIsSearching(false)
                                    }}>
                                    Selecionar
                                </Button>
                            </Modal.Footer>
                        </>
                        : itemSelecionado ? <UpdatePlanoContas setSnackbarData={setSnackbarData} back={()=>setCurrentAction('search')} refresh={()=>getItens()} data={itemSelecionado}/> : null}


            </Modal>
                                    
            <div className='row'>
                <label className='col-auto col-form-label' htmlFor="select-produto">{props.itemName}:</label>
                <div className="col-auto"><TextField type="text" value={itemSelecionado ? itemSelecionado.ACESSO + " - " + itemSelecionado.DESCRICAO : '-'} size='small'
                    InputProps={{ readOnly: true, }}
                    style={{ maxWidth: '200px' }} className="form-control" /></div>
                <div className="col-auto">
                    <div style={{ display: 'inline-block' }} data-tooltip="Visualizar todos" data-tooltip-location="right">
                        <IconButton size='large' color='primary'
                            onClick={() => setIsSearching(true)} >
                            <Search /></IconButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchPlanoContas