import { Chart } from 'react-google-charts';
import { CicloMes } from '../interfacesPDF';
import { toMonthName } from '../../../../../utilities/helperFunctions'

interface IProps {
  data: CicloMes[];
}

export const options = {
    title: "Vendas ciclos por mês",
    backgroundColor: '#f5f0f0',
    chartArea: { top: 25,bottom:50,right:10,left:60 }, pointSize: 15,
    legend: { position: "bottom" }

};


export default function CicloMesGraph(props: IProps) {

  function convertData(data: Array<CicloMes>): Array<CicloMes> {
    let dieselData: Array<any> = [];
    let ottoData: Array<any> = [];
    let outputData: Array<any> = [["CICLO", "CICLO DIESEL", "CICLO OTTO"]];
   
    data.forEach((item: CicloMes) => {
      let month: number = item.MES;
      let cycle: string = item.CICLO;
      let value: number = Number(item.TOTALVALORMESCICLO)
   
      if (cycle.trim() === "CICLO DIESEL") {
          dieselData[month] = value;
      } else if (cycle.trim() === "CICLO OTTO") {
          ottoData[month] = value;
      }
    });
   
    for (let i = 1; i <= 12; i++) {
        if (dieselData[i] || ottoData[i]) {
      outputData.push([toMonthName(i), dieselData[i] || 0, ottoData[i] || 0]);
    }}
   
    return outputData;
  }


  return (
    <div>
    <Chart
      width={'100%'}
      height={'400px'}
      chartType="LineChart"
      loader={<div>Loading Chart...</div>}
      data={convertData(props.data)}
      options={options}
      rootProps={{ 'data-testid': '1' }}
    /></div>
  );
}

