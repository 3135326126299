import { Ciclo, IDate } from '../interfacesPDF'
import { useGlobalState } from '../../../../../globalState'
import { toMoneyFormat } from '../../../../../utilities/helperFunctions'
import TableSort from '../../../../../utilities/TableSortTS'
import {  useMemo } from 'react'
import PrintFooter from "../../../printFooter";

interface IProps {
    data: Ciclo[]
    date: IDate
    options?: { filiaisSelecionadas: string[] }
}


export default function CicloPDF(props: IProps) {
    const [empresaAtual] = useGlobalState('empresaAtual')
    const dieselData = props.data.filter(item => item.CICLO.trim() === 'CLICO DIESEL')
    const ottoData = props.data.filter(item => item.CICLO.trim() === 'CICLO OTTO')

    const columns = useMemo(
        () => [
            {
                Header: " ",
                columns: [
                    {
                        Header: "Empresa",
                        accessor: "FANTASIA",
                        Footer: ''
                    },
                    {
                        Header: "% Ciclo",
                        accessor: "PORCVALORCICLO",
                        Cell: ({ value }: { value: string }) =>  Number(value).toFixed(2),
                        Footer: ''
                    },
                    {
                        Header: "% Total",
                        accessor: "PORCVALORTOTAL",
                        Cell: ({ value }: { value: string }) =>  Number(value).toFixed(2),
                        Footer: ''
                    },
                    {
                        Header: "Quantidade",
                        accessor: "QTDCICLOEMPRESA",
                        Cell: ({ value }: { value: string }) => Number(value).toFixed(3),
                        Footer: ''
                    },
                    {
                        Header: "Total",
                        accessor: "VALORCICLOEMPRESA",
                        Cell: ({ value }: { value: string }) => toMoneyFormat(value),
                    },
                ],
                Footer: () => <></>
            }
        ], [])

    return (
        <div className='container'>
            <div className='pdf' id='section-to-print'>
                <h5>{empresaAtual.nome}</h5>
                <h6>Multiloja &gt; Ciclo otto - Periodo: {props.date.dataInicial} a {props.date.dataFinal}</h6>
                <hr />

                <p style={{ fontSize: '12px' }}>{props.options?.filiaisSelecionadas.map(item => item + ' | ')}</p>
                <hr />
                <div >
                    <h6>Ciclo Diesel</h6>
                    <TableSort columns={columns} data={dieselData}/>
                    <p className='text-end fw-bold'>Total ciclo | Quantidade: {Number(dieselData[0]?.QTDTOTALCICLO).toFixed(3)}  Valor: {toMoneyFormat(dieselData[0]?.VALORTOTALCICLO, 2)}</p>
                </div>
                <div >
                    <h6>Ciclo otto</h6>
                    <TableSort columns={columns} data={ottoData}/>
                    <p className='text-end fw-bold'>Total ciclo | Quantidade: {Number(ottoData[0]?.QTDTOTALCICLO).toFixed(3)}  Valor: {toMoneyFormat(ottoData[0]?.VALORTOTALCICLO, 2)}</p>
                </div>
                <h6 className='text-end fw-bold'>Total| {toMoneyFormat(props.data[0].VALORTOTAL)}</h6>
                <PrintFooter/>
            </div>
        </div>
    )
}