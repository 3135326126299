import { useGlobalState } from '../../../../globalState'
import {toMoneyFormat } from '../../../../utilities/helperFunctions'
import TableSort from '../../../../utilities/TableSortTS'
import {  useMemo } from 'react'

interface RecordSet {
    NUMERO_CLIENTE: number
    NOME_CLIENTE: string
    NUMERO_EMPRESA: number
    FANTASIA: string
    CODIGO_PRODUTO: number
    NOME_PRODUTO: string
    QUANTIDADE: number
    TOTALPRODUTO: string
    TOTALDESCAPLICADO: string
  }

interface IProps {
    data: RecordSet[]
    date: {dataInicial: string , dataFinal: string}
    options?: { filiaisSelecionadas: string[] }
}

export default function VendasClientesPDF(props: IProps) {
    const [empresaAtual] = useGlobalState('empresaAtual')

    const columns = useMemo(
        () => [
            {
                Header: " ",
                columns: [
                    {
                        Header: "N. Cli",
                        accessor: "NUMERO_CLIENTE",
                        Footer: '',
                        width: 30,
                    },
                    {
                        Header: "Cliente",
                        accessor: "NOME_CLIENTE",
                        Footer: '',
                        width: 250,
                    }, 
                    {
                        Header: "Empresa",
                        accessor: "FANTASIA",
                        Footer: ''
                    },
                    {
                        Header: "Cod. Prod.",
                        accessor: "CODIGO_PRODUTO",
                        Footer: ''
                    },
                    {
                        Header: "Prod.",
                        accessor: "NOME_PRODUTO",
                        Footer: ''
                    },
                    {
                        Header: "Quant.",
                        accessor: "QUANTIDADE",
                        Cell: ({ value }: { value: string }) => Number(value).toFixed(3),
                        Footer: ''
                    },
                    {
                        Header: "Total",
                        accessor: "TOTALPRODUTO",
                        Cell: ({ value }: { value: string }) => toMoneyFormat(value,2),
                    },
                    {
                        Header: "Tot. desc. apli.",
                        accessor: "TOTALDESCAPLICADO",
                        Cell: ({ value }: { value: string }) => toMoneyFormat(value,2),
                    }
                ],
                Footer: () => <></>
            }
        ], [])

    // Group data by product name
    const totalOfTotalProduto: number = props.data.reduce((total, record) => total + Number(record.TOTALPRODUTO), 0);
    const totalOfTotalDesc: number = props.data.reduce((total, record) => total + Number(record.TOTALDESCAPLICADO), 0);
    return (
        <div className='container'>
            <div className='pdf' id='section-to-print'>
                <h5>{empresaAtual.nome}</h5>
                <h6>Multiloja Multiloja &gt; Vendas clientes - Periodo: {props.date.dataInicial} a {props.date.dataFinal}</h6>
                <hr />
                <p style={{ fontSize: '12px' }}>{props.options?.filiaisSelecionadas.map(item => item + ' | ')}</p>
                <hr />
                <TableSort columns={columns} data={props.data}></TableSort>
                <h6 className='text-end fw-bold'>Total: {toMoneyFormat(totalOfTotalProduto)} | Total Desconto Aplicado: {toMoneyFormat(totalOfTotalDesc)}</h6>
              </div>  
        </div>
    )
}
