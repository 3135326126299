import { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { useGlobalState, IEmpresa } from '../../globalState'


interface IProps {
    getResumo: (empresas: IEmpresa[]) => void,
    close: () => void
}

function SearchEmpresas(props: IProps) {
    const [empresas] = useGlobalState('empresas')
    const [empresaAtual] = useGlobalState('empresaAtual')
    const [selectedEmpresas, setSelectedEmpresas] = useState([empresaAtual])


    const handleSelectedEmpresas = (item: IEmpresa) => {
        if (selectedEmpresas.indexOf(item) === -1) setSelectedEmpresas(empresas => [...empresas, item])
        else setSelectedEmpresas(selectedEmpresas.filter(empresa => empresa !== item))
    }





    return (
        <Modal
            animation={false}
            centered
            show={true}
            backdrop="static"
            keyboard={true}
        >
            <Modal.Body>
                <div>
                    {empresas.map((item) =>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value={item.id} id={item.id.toString()}
                                checked={selectedEmpresas.indexOf(item) === -1 ? false : true}
                                onClick={() => handleSelectedEmpresas(item)} />
                            <label className="form-check-label" htmlFor={item.id.toString()}>
                                {item.fantasia + " - " + item.cnpj + " "}

                            </label>
                        </div>
                    )}
                </div>


            </Modal.Body>
            <Modal.Footer>
                <Button variant='danger' onClick={() => props.close()}>
                    Fechar
                </Button>
                <Button variant='success' onClick={() => props.getResumo(selectedEmpresas)}>
                    Concluir
                </Button>
            </Modal.Footer>

        </Modal>
    )
}

export default SearchEmpresas