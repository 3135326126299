import { useState } from "react"
import axios from '../../../axiosConfig'
import SearchCliente from "../../../utilities/searchs/searchCliente"
import SearchData from "../../../utilities/searchs/searchData"
import Loading from '../../../utilities/loading'
import {formatDate} from '../../../utilities/helperFunctions'
import {Button,Paper} from '@mui/material'
import {Send} from '@mui/icons-material'


function DebitoTotal(props) {
    const [firstDate, setFirstDate] = useState(new Date)
    const [lastDate, setLastDate] = useState(new Date)
    const [numeroCliente, setNumeroCliente] = useState(props?.clientes[0]?.NUMERO)
    const [isFullLoading, setIsFullLoading] = useState(false)



    const getDebito = () => {
        setIsFullLoading(true)
        // eslint-disable-next-line 
        let url = numeroCliente == '00' ? 'relatorios/clientes/debito' : 'relatorios/cliente/debito'
        const postData = {
            dataInicial:`${formatDate(firstDate,'db')}`,
            dataFinal:`${formatDate(lastDate,'db')}`,
            cliente: `${numeroCliente}`
        }
        axios.post(url, postData, ).then((r) => {
            if (r.data[0].length < 1 && r.data[2].length < 1) return props.showPDF(true, 'Sem registros para a data informada')
            let dataDebito = { data: r.data,
                 date: {firstDate:formatDate(firstDate,'front'),lastDate:formatDate(lastDate,'front')} }
            props.setPDFData({data:dataDebito})
            props.showPDF(false)
        }).catch(err =>  props.showPDF(true, 'Erro ao buscar informação')).finally(() => setIsFullLoading(false))
    }

    return (
        <div>
            {isFullLoading ? <Loading></Loading> : null}
            <Paper elevation={3} className='p-2' sx={{backgroundColor:'#f5f0f0'}}>
            <h4 className='relatorio-title'>Débito total:</h4>
            <SearchData setFirstDate={setFirstDate} setLastDate={setLastDate}
            firstDate={firstDate} lastDate={lastDate}/>
            <SearchCliente clientes={props.clientes} setNumeroCliente={setNumeroCliente}></SearchCliente>
            <Button variant='contained' endIcon={<Send/>} onClick={() => getDebito()}>Gerar</Button>
            </Paper>
        </div>
    )
}

export default DebitoTotal