import { Geral, IDate } from '../interfacesPDF'
import { useGlobalState } from '../../../../../globalState'
import {toMoneyFormat } from '../../../../../utilities/helperFunctions'
import TableSort from '../../../../../utilities/TableSortTS'
import {  useMemo } from 'react'
import PrintFooter from "../../../printFooter";

interface IProps {
    data: Geral[]
    date: IDate
    options?: { filiaisSelecionadas: string[] }
}

export default function GeralPDF(props: IProps) {
    const [empresaAtual] = useGlobalState('empresaAtual')

    const columns = useMemo(
        () => [
            {
                Header: " ",
                columns: [
                    {
                        Header: "Empresa",
                        accessor: "FANTASIA",
                        Footer: ''
                    },
                    {
                        Header: "P medio",
                        accessor: "PREMEDDIA",
                        Cell: ({ value }: { value: string }) => toMoneyFormat(value,4),
                        Footer: ''
                    },
                    {
                        Header: "P medio periodo",
                        accessor: "PREMEDPERIODO",
                        Cell: ({ value }: { value: string }) => toMoneyFormat(value,4),
                        Footer: ''
                    },
                    {
                        Header: "Quantidade",
                        accessor: "QTDPROD",
                        Cell: ({ value }: { value: string }) => Number(value).toFixed(3),
                        Footer: ''
                    },
                    {
                        Header: "Total",
                        accessor: "VALORPROD",
                        Cell: ({ value }: { value: string }) => toMoneyFormat(value),
                    },
                ],
                Footer: () => <></>
            }
        ], [])

    // Group data by product name
    const products = props.data.reduce((acc: Record<string, Record<string, Geral[]>>, item) => {
        const productName = item.NOME_PRODUTO;
        const date = item.DATA_EMISSAO.replaceAll('-', '/').split('/').reverse().join('/')
        
        // Create a new product entry if it doesn't exist
        if (!acc[productName]) {
            acc[productName] = {};
        }
    
        // Create a new date entry for the product if it doesn't exist
        if (!acc[productName][date]) {
            acc[productName][date] = [];
        }
        
        // Add the item to the product and date entry
        acc[productName][date].push(item);
    
        return acc;
    }, {});

    return (
        <div className='container'>
            <div className='pdf' id='section-to-print'>
                <h5>{empresaAtual.nome}</h5>
                <h6>Multiloja &gt; Geral - Periodo: {props.date.dataInicial} a {props.date.dataFinal}</h6>
                <hr />

                <p style={{ fontSize: '12px' }}>{props.options?.filiaisSelecionadas.map(item => item + ' | ')}</p>
                <hr />

                {Object.keys(products).map(product => (
  <div key={product}>
    <h6>{product}</h6>

    {Object.keys(products[product]).map(date => (
      <div key={date}>
        <h6>{date}</h6>

        <TableSort columns={columns} data={products[product][date]}/>

        <p className='text-end fw-bold'>Total dia | Quantidade: {products[product][date].reduce((acc,item)=>{
            return acc += Number(item.QTDPROD)
        },0).toFixed(3)} Valor: {toMoneyFormat(products[product][date].reduce((acc,item)=>{
            return acc += Number(item.VALORPROD)
        },0))}</p>
      </div>
    ))}

    <h6 className='text-end fw-bold'>Total produto | Quantidade: {toMoneyFormat(products[product][Object.keys(products[product])[0]][0].TOTALQTDPROD)} Valor: {toMoneyFormat(products[product][Object.keys(products[product])[0]][0].TOTALVALORPROD)}</h6>
    <hr />
  </div>
))}
                <h6  className='text-end fw-bold'>Total | Quantidade:{toMoneyFormat(props.data[0].TOTALQTD)}   Valor: {toMoneyFormat(props.data[0].TOTALVALOR)}</h6>
                <PrintFooter/>
            </div>
        </div>
    )
}
