import {useState } from "react"
import Caixa from "./caixa"
import CaixaPDF from "./caixaPDF"
import {ICaixaPDF} from './interfaceCaixa'
import CaixaPerdasSobrasPDF from './caixaPerdasSobrasPDF'

function Index(){
    const [PDFType,setPDFType] = useState('')
    const [PDFData,setPDFData] = useState<any>(null)

    const back = ()=>{
        setPDFType('')
        setPDFData(null)
    }

 return(
     <div>
         <div className={PDFData !== null ? "collapse" : ''} ><Caixa setPDFData={setPDFData} setPDFType={setPDFType}/></div>
         {PDFType === 'pdf' && <CaixaPDF data={PDFData} back={back}/>  }
         {PDFType === 'perdassobras' && <CaixaPerdasSobrasPDF data={PDFData} back={back}/>}
      
         
     </div>
 )
}

export default Index