import ReactDOM from "react-dom";
import LucroBrutoGrupoPDF from "./lucroBrutoGrupoPDF"
import LucroBrutoTipoPDF from "./lucroBrutoTipoPDF"
import LucroSimplesGrupoPDF from "./lucroSimplesGrupoPDF"
import LucroSimplesTipoPDF from "./lucroSimplesTipoPDF"
import { Lucro } from "../interfaceLucro"
import { Select, MenuItem } from '@mui/material'
import { useEffect, useState } from "react"


interface PortalProps {
    agrupamento:string,setAgrupamento:React.Dispatch<React.SetStateAction<string>>
    ordenamento:string,setOrdenamento:React.Dispatch<React.SetStateAction<string>>
    estilo:string,setEstilo:React.Dispatch<React.SetStateAction<string>>
}

export const OptionsPortal = (props:PortalProps) => {
    const [isRendered, setIsRendered] = useState(false)

    useEffect(() => {
        setIsRendered(true)
    }, [])

    const divP = document.getElementById('agrupamentoOptions')
    if (divP === null || isRendered === false) return (<div></div>)
    return ReactDOM.createPortal(
        (
            <>
            <span>Ordenar: </span>
                <Select size='small' value={props.estilo} onChange={e=>props.setEstilo(e.target.value)}>
                    <MenuItem value="Detalhado">Detalhado</MenuItem>
                    <MenuItem value="Resumido">Resumido</MenuItem>
                </Select>
                <Select size='small' value={props.ordenamento} onChange={e=>props.setOrdenamento(e.target.value)}>
                    <MenuItem value="Codigo">Codigo</MenuItem>
                    <MenuItem value="Descricao">Descriçao</MenuItem>
                </Select>
                <Select size='small' value={props.agrupamento} onChange={e=>props.setAgrupamento(e.target.value)}>
                    <MenuItem value="Tipo">Tipo</MenuItem>
                    <MenuItem value="Grupo">Grupo</MenuItem>
                </Select>
            </>
        ),
        divP
    )
}

const LucroPDF = (props: Lucro) => {
    const [agrupamento, setAgrupamento] = useState('Grupo')
    const [ordenamento, setOrdenamento] = useState('Codigo')
    const [estilo, setEstilo] = useState('Resumido')
    const sortProdutos = () => {
        if (ordenamento === 'Codigo') {
            return props.data.sort((a, b) => a.NUMERO_PRODUTO - b.NUMERO_PRODUTO)
        } else {
            console.log(props.data.sort((a, b) => a.DESCRICAO_PRODUTO.localeCompare(b.DESCRICAO_PRODUTO)))
            return props.data.sort((a, b) => a.DESCRICAO_PRODUTO.trim().localeCompare(b.DESCRICAO_PRODUTO.trim()))
        }
    }
    const PDF = () => {
        if (agrupamento === 'Tipo') {
            if (estilo === 'Detalhado') return <LucroBrutoTipoPDF data={sortProdutos()} date={props.date} btnTable={props.btnTable}/>
            else return <LucroSimplesTipoPDF data={sortProdutos()} date={props.date} btnTable={props.btnTable} />
        } else {
            if (estilo === 'Detalhado') return <LucroBrutoGrupoPDF data={sortProdutos()} date={props.date} btnTable={props.btnTable} />
            else return <LucroSimplesGrupoPDF data={sortProdutos()} date={props.date} btnTable={props.btnTable}/>
        }
    }
    return (
        <div>
            <OptionsPortal estilo={estilo} setEstilo={setEstilo} 
            agrupamento={agrupamento} setAgrupamento={setAgrupamento}
            ordenamento={ordenamento} setOrdenamento={setOrdenamento}/>
            {PDF()}
        </div>
    )

}
export default LucroPDF