import {  TextField } from "@mui/material"
import {  handleChangeTextInput } from "../../../utilities/inputsUtilities"

interface IMedicamentosTab {
    CODIGO_ANVISA: string
}

interface IProps {
    data: IMedicamentosTab
    setData: React.Dispatch<React.SetStateAction<IMedicamentosTab | any>>
}

function MedicamentosProduto(props: IProps) {
    return (
        <div>
            <div className="row mb-3">
                <div className="col-sm-2">Código ANVISA:</div>
                <TextField hiddenLabel className='col' size="small" name='CODIGO_ANVISA'
                    value={props.data.CODIGO_ANVISA} onChange={(event) => handleChangeTextInput(event, props.setData)}
                    inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
                />
            </div>
        </div>
    )
}

export default MedicamentosProduto