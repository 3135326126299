import React from 'react';
import { useGlobalState } from '../../../globalState'
import { toFixedNumber} from '../../../utilities/helperFunctions';
import PrintFooter from "../printFooter";

interface Estoque {
    CODIGO_PRODUTO: number;
    DESCRICAO_PRODUTO: string;
    NUMERO_TANQUE: number;
    CAPACIDADE_TANQUE: string;
    QUANTIDADE_TANQUE: string;
    PORC_TANQUE: string;
    DATA_MEDICAO: string;
    HORA_MEDICAO: string | null;
}
interface IProps {
    data: [Estoque[],string]
}


function EstoqueTanquePDF(props: IProps) {
    const [empresaAtual] = useGlobalState('empresaAtual')

    const convertDate = (date: string) => {
        return date.replaceAll('-', '/').split('/').reverse().join('/')
    }

    let codProdutoAtual = 0
    let totalCapacidadeAtual = 0
    let totalQuantidadeProdutoAtual = 0

    const checkProdutoEstoque = (newCod: number) => {
        if (codProdutoAtual === newCod) {
            codProdutoAtual = newCod
            return true
        } else {
            codProdutoAtual = newCod
            return false
        }
    }

    const handleSameItem = (codI: number, index: number) => {
        if (codI === props.data[0][index - 1]?.CODIGO_PRODUTO && codProdutoAtual === codI) {
            totalCapacidadeAtual += toFixedNumber(props.data[0][index].CAPACIDADE_TANQUE, 3)
            totalQuantidadeProdutoAtual += toFixedNumber(props.data[0][index].QUANTIDADE_TANQUE, 3)
        }

        if (codI === props.data[0][index - 1]?.CODIGO_PRODUTO && codProdutoAtual === codI
            && codI !== props.data[0][index + 1]?.CODIGO_PRODUTO) {
            return (
                <>
                <tr>
                    <td className='fw-bold text-end' colSpan={2}>Total estoque:</td>
                    <td className='fw-bold'>{Number(totalCapacidadeAtual).toFixed(3)}</td>
                    <td className='fw-bold' colSpan={1}>{totalQuantidadeProdutoAtual.toFixed(3)}</td>
                    <td className='fw-bold' colSpan={3}>{((totalQuantidadeProdutoAtual/totalCapacidadeAtual)*100).toFixed(2)}</td>
                </tr></>
            )
        }
        else if (codProdutoAtual === codI && codI !== props.data[0][index - 1]?.CODIGO_PRODUTO) {
            totalCapacidadeAtual = toFixedNumber(props.data[0][index].CAPACIDADE_TANQUE, 3)
            totalQuantidadeProdutoAtual = toFixedNumber(props.data[0][index].QUANTIDADE_TANQUE, 3)
        }



    }


    return (
        <div className='container'>
            <div className='pdf' id='section-to-print'>
                <h4>{empresaAtual.nome}</h4>
                <h5>Estoque &gt; Tanques</h5>
                <hr />
                <br />
                <div className="table-responsive">
                <table className="table table-sm">
                    <thead>
                        <tr>
                            <th scope="col">Produto</th>
                            <th scope="col">Num tanque</th>
                            <th scope="col">Capacidade</th>
                            <th scope="col">Quantidade</th>
                            <th scope="col">Porc %</th>
                            <th scope="col">Data {props.data[1] === 'med' ? 'medição' : props.data[1] === 'lmc' ? 'LMC' : 'estoque'}</th>
                            {/* {props.data[0][0].HORA_MEDICAO ? <th scope="col">Hora</th> : null} */}
                        </tr>
                    </thead>
                    <tbody>
                        {props.data[0].map((item, index) =>
                            <>
                            {checkProdutoEstoque(item.CODIGO_PRODUTO) ? null
                            :<tr>
                            <td colSpan={7} className='fw-bold'style={{borderColor:'black'}}>{item.DESCRICAO_PRODUTO}</td>
                        </tr> 
                            
                            }
                            
                                <tr>
                                   <td>{'------>'}</td>
                                    <td colSpan={1} className=''>{item.NUMERO_TANQUE}</td>
                                    <td>{Number(item.CAPACIDADE_TANQUE).toFixed(3)}</td>
                                    <td>{Number(item.QUANTIDADE_TANQUE).toFixed(3)}</td>
                                    <td>{toFixedNumber(item.PORC_TANQUE, 2)}</td>
                                    <td>{convertDate(item.DATA_MEDICAO)}</td>
                                    {/* {props.data[0][0].HORA_MEDICAO ? <td>{item.HORA_MEDICAO}</td> : null} */}
                                </tr>
                                {handleSameItem(item.CODIGO_PRODUTO, index)}
                            </>
                        )}
                    </tbody>
                </table></div>
                <PrintFooter/>
            </div></div>
    )

}
export default EstoqueTanquePDF