import { useMemo, useState, useEffect } from "react";
import TableSort from '../../../utilities/TableSort'
import { toMoneyFormat } from '../../../utilities/helperFunctions';
import { ShowEmpresa } from '../showEmpresa';
import PrintFooter from "../printFooter";

function DebitoTotalPDF(props){
    const [dataTable,setDataTable] = useState([])

    
    let totalDebito = 0

    useEffect(()=>{
        const FormatClients = ()=>{
            let clientesFormated = []
            
            props.data.data[0].forEach((conta)=>{
                let indexClientes = clientesFormated.findIndex(i => i.nome === conta.NOME) 
               // eslint-disable-next-line 
                            if(indexClientes != -1){
                    clientesFormated[indexClientes].totalContasAReceber += Number(conta.VALOR)
                    totalDebito += Number(conta.VALOR)
                    
                }else{
                    clientesFormated.push({nome:conta.NOME,numero:conta.NUMERO,totalContasAReceber:Number(conta.VALOR)
                    ,totalFaturas:0,totalCupom:0})
                    totalDebito += Number(conta.VALOR)
                }
               
            })
            props.data.data[2].forEach(conta=>{
                let indexClientes = clientesFormated.findIndex(i => i.nome === conta.NOME)
                // eslint-disable-next-line 
                if(indexClientes != -1){
                    clientesFormated[indexClientes].totalCupom += Number(conta.VALORTOTALCUPOM)
                    totalDebito += Number(conta.VALORTOTALCUPOM)
                }else{
                    clientesFormated.push({nome:conta.NOME,numero:conta.NUMERO,totalContasAReceber:0
                    ,totalFaturas:0,totalCupom:Number(conta.VALORTOTALCUPOM)})
                    totalDebito += Number(conta.VALORTOTALCUPOM)
                }
            })
    
            clientesFormated.sort((a, b) => {
                let fa = a.nome.toLowerCase(),
                    fb = b.nome.toLowerCase();
            
                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });
    
          setDataTable(clientesFormated)
        }
        FormatClients()
    },[])

    const columns = useMemo(
        () => [
           { 
            Header: " ",
           
            columns: [
                {
                     Header: "Num",
                    accessor: "numero", 
                },
                  {
                    Header: "Nome",
                    accessor: "nome"
                  },
                  {
                    Header: "Cupons",
                    accessor: "totalCupom",
                    Cell: ({value}) => toMoneyFormat(value)
                    
                  },{
                    Header: "Faturas",
                    accessor: "totalFaturas",
                    Cell: ({value}) => toMoneyFormat(value)
                  },
                  {
                    Header: "C.Receber",
                    accessor: "totalContasAReceber",
                    Cell: (props) => {
                        return toMoneyFormat(props.value)
                    }
                  }, 
                  {
                    Header: "Total",
                    accessor: (row)=>{
                        return (Number(row.totalCupom.toFixed(2)) + Number(row.totalFaturas.toFixed(2)) + Number(row.totalContasAReceber.toFixed(2)))
                    },
                    Cell: (props) => {
                        return toMoneyFormat(props.value)
                    }
                    
                  } 
            ],
            Footer:()=> <p className='infoPDF fw-bold pr-3 text-end'> Total débito: R${toMoneyFormat(totalDebito)}</p> 
        }
         ],[])

    
    

 

    return(
            <div className='container'>
<div className='pdf' id='section-to-print'>
    <ShowEmpresa/>
    <h6>Clientes &gt; Relatório de débitos - Periodo: {props.data.date.firstDate} a {props.data.date.lastDate}</h6>
    <hr />
    <br />
    <div className="table-responsive">
    <TableSort columns={columns} data={dataTable}></TableSort>
   </div>
   <PrintFooter/>
   
    
</div></div>

    )
}

export default DebitoTotalPDF