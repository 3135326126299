
import { Backdrop, CircularProgress } from '@mui/material';

function StartLoadScreen() {
  return (
    <Backdrop open={true} style={{ zIndex: 9999, color: '#fff' }}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default StartLoadScreen