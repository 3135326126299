import { useMemo, useState, useEffect } from "react";
import TableSort from '../../../utilities/TableSort'
import {dateToString,fixDate,toMoneyFormat} from '../../../utilities/helperFunctions'
import { ShowEmpresa } from '../showEmpresa';
import PrintFooter from "../printFooter";

function NotasEntradaPDF(props){
    const [dataTable,setDataTable] = useState([])



    useEffect(()=>{
             setDataTable(fixDate(props.data,['DATAEMISSAO','DATAENTRADA']))
     },[])


    const columns = useMemo(
        () => [
           { 
            Header: " ",
           
            columns: [
                {
                  Header: "Nº",
                  accessor: "NUMERO",
                  
                 
                },
                {
                    Header: "Série",
                    accessor: "SERIE",
                    disableSortBy: true,
                   
                },
                  {
                    Header: "Fornecedor",
                    accessor: "NOME_FORNECEDOR",
                   
                    
                  },
                  {
                    Header: "Emissão",
                    accessor: "DATAEMISSAO",
                    Cell: ({value}) => dateToString(value),
                    sortType: 'datetime',
                    
                  },
                  {
                    Header: "Entradas",
                    accessor: "DATAENTRADA",
                    Cell: ({value}) => dateToString(value),
                    sortType: 'datetime',     
                  }, {
                    Header: "Valor frete",
                    accessor: "FRETETERCEIROS",
                    Cell: ({value}) => toMoneyFormat(value),
                  },
                  {
                    Header: "Outras despesas",
                    accessor: "OUTRASDESPESAS",
                    Cell: ({value}) => toMoneyFormat(value),
                  },
                  {
                    Header: "Valor seguro",
                    accessor: "VALORSEGURO",
                    Cell: ({value}) => toMoneyFormat(value),
                    
                  },
                  {
                    Header: "Total nota",
                    accessor: "TOTALNOTA",
                   Cell: ({value}) => toMoneyFormat(value),
                  
                  },
                        
            ],
            Footer:()=><p className='infoPDF fw-bold pr-3 text-end'>Total de lançamentos: {props.data.length} |  Total: R${toMoneyFormat(total)}</p>
        }
         ])
  

    
   

    const total = props.data.reduce((acc,{TOTALNOTA}) =>{
        return acc + Number(TOTALNOTA)
    },0)

    

    return(
        <div className='container'>
            <div className='pdf' id='section-to-print'>
        
            <div>
            <ShowEmpresa/>
            <h6>Entrada &gt; Notas entrada - Periodo: {props.date.firstDate} a {props.date.lastDate}</h6>
            </div>
            <hr/>
            <br />
            <div className="table-responsive">
            <TableSort columns={columns} data={dataTable}></TableSort>
            </div>
            <PrintFooter/>
        </div></div>
    )
}

export default NotasEntradaPDF