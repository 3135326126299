import { useGlobalState } from "../../globalState"



export function ShowEmpresa() {
    const [empresaAtual] = useGlobalState('empresaAtual')
    const [filialAtual] = useGlobalState('filialAtual')

    return (
        <>
            <h5>{empresaAtual.nome}</h5>
            <h6>{filialAtual.FANTASIA}</h6>
        </>
    )
}