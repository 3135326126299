import { Spinner } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import ProdutoInfo from './produtosInfo'
import { IGruposProdutos, ITiposProdutos } from '../../utilities/typesFromDB'
import { IProduto } from './produtosInterface'
import axios from '../../axiosConfig'
import { useGlobalState } from '../../globalState'
import HandleEmpresas from '../../utilities/changeEmpresa'
import { Select, MenuItem, TextField, InputAdornment, Paper, Grid, Box, Button } from '@mui/material'
import { AddCircle, Delete, Edit, Search, Visibility } from '@mui/icons-material'
import ErrorAlert from '../../utilities/errorAlert'
import { toMoneyFormat } from '../../utilities/helperFunctions'
import SearchTiposProdutos from '../../utilities/searchs/searchTiposProdutos'
import SearchGruposProdutos from '../../utilities/searchs/searchGruposProdutos'
import SearchSituacoesTributarias from '../../utilities/searchs/searchSituacoesTributarias'
import ProdutoCreate from './produtoCreate'
import ProdutoUpdate from './produtoUpdate'
import AlertDialog from '../../utilities/dialog'
import Loading from '../../utilities/loading'
import {AxiosError } from 'axios';
import { showSnackbar } from '../../utilities/snackbarShow'

interface IProps {
  setProduto?: Function
  codigoItem?: number
}


type Actions = 'search' | 'create' | 'edit' | 'info'
type Screens = '' | 'tipos' | 'grupos' | 'st'

function Produtos(props: IProps) {

  const [isLoading, setIsLoading] = useState(true)
  const [errorAlert, setErrorAlert] = useState('')
  ///////////////////////////////////////////////////////////////////
  const [rawData, setRawData] = useState<IProduto[] | undefined>()
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<IProduto[] | undefined>()
  const [grupos, setGrupos] = useState<IGruposProdutos[] | undefined>()
  const [tipos, setTipos] = useState<ITiposProdutos[] | undefined>()
  const [grupoFilter, setGrupoFilter] = useState('0')
  const [tipoFilter, setTipoFilter] = useState('0')
  const [situacaoFilter, setSituacaoFilter] = useState('0')
  const [itemSelecionado, setItemSelecionado] = useState<IProduto | undefined>()
  const [currentAction, setCurrentAction] = useState<Actions>('search')
  const [currentScreen, setCurrentScreen] = useState<Screens>('')
  const [empresaAtual] = useGlobalState('empresaAtual')
  const [usuarioConectado] = useGlobalState('usuarioConectado')
  const [alertOpen, setAlertOpen] = useState(false)
  const [desativarAlert, setDesativarAlert] = useState(false)
  const [isFullLoading, setIsFullLoading] = useState(false)

  const getProdutos = () => {
    setIsLoading(true)
    axios.get(`produtos/listar`,).then(r => {
      setRawData(r.data[0])//produtos
      setGrupos(r.data[1])
      setTipos(r.data[2])
      if (errorAlert.length > 0) setErrorAlert('')
    })
      .catch(error => {
        setRawData([])
        setGrupos([])
        setTipos([])
        if (!error.response?.status) setErrorAlert('Erro ao conectar com banco de dados')
        else setErrorAlert('Erro ao listar produtos')
      }).finally(() => setIsLoading(false))
  }
  useEffect(() => {
    getProdutos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empresaAtual])

  /////////////////////////////////////////////////////////////////////////////////

  const handleDelete = ()=>{
    setAlertOpen(false)
    setIsFullLoading(true)
    itemSelecionado && axios.delete(`/produto/${itemSelecionado.CODIGO}`).then(r => {
      setRawData(r.data[0])//produtos
      setGrupos(r.data[1])
      setTipos(r.data[2])
      showSnackbar('Item excluido com sucesso','success')
}).catch(error=>{
    const err = error as AxiosError
    if(err.response?.status === 422)setDesativarAlert(true)
    else showSnackbar('Erro ao Excluir item','error')
}).finally(()=>{
  setIsFullLoading(false)
})
  }

const handleDesativar = ()=>{
  setDesativarAlert(false)
  setIsFullLoading(true)
  axios.patch('/produtos', {formData:{ATIVO:'N',VENDAFRENTECAIXA:'N'},numero:itemSelecionado?.CODIGO},
  ).then(r => {
    showSnackbar('Registro desativado')
    setItemSelecionado(undefined)
    setRawData(r.data[0])
  }).catch(error=>{
    const err = error as AxiosError
    console.log(err.response?.status)
}).finally(() => setIsFullLoading(false))
}

  useEffect(() => {
    if (searchTerm.trim() === '') return setSearchResults(rawData)
    let results = rawData?.filter(product => {
      return product.CODIGO.toString().toLowerCase().includes(searchTerm) ||
        product.DESCRICAO.toString().toLowerCase().includes(searchTerm) ||
        product.NOMEFANTASIA?.includes(searchTerm) ||
        product.CODIGOBARRAS?.toString().toLowerCase().includes(searchTerm) ||
        product.CUSTOATUAL?.toString().includes(searchTerm)
    })
    results = results?.filter((product) => {
      // eslint-disable-next-line
      if (Number(grupoFilter) !== 0 && Number(grupoFilter) !== product.CODIGO_GRUPOPRODUTO) return
      // eslint-disable-next-line
      if (Number(tipoFilter) !== 0 && Number(tipoFilter) !== product.CODIGO_TIPOPRODUTO) return
      // eslint-disable-next-line
      if (Number(situacaoFilter) !== 0 && situacaoFilter !== product.ATIVO.trim()) return
      return product
    })
    setSearchResults(results)
  }, [searchTerm, grupoFilter, tipoFilter, situacaoFilter, rawData])
  ///////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    props.setProduto && props.setProduto(itemSelecionado)
  }, [itemSelecionado])

  return (
    <div className={props.setProduto ? 'mt-1' : 'container mt-1'}>
       {isFullLoading ? <Loading /> : null}
        <AlertDialog open={alertOpen} close={setAlertOpen} confirm={handleDelete}/>
        <AlertDialog customMessage="O produto não pode ser excluído, pois possui dependências. Deseja desativá-lo?" open={desativarAlert} close={setDesativarAlert} confirm={()=>handleDesativar}/>
      {errorAlert.length > 0
        ? <ErrorAlert error={errorAlert} clearError={() => setErrorAlert('')} />
        : null
      }
      {!props.setProduto && <div className="d-flex title">
        <div className=" p-2 "><h1>Produtos | <span className='empresa-atual'>{empresaAtual.fantasia} - {empresaAtual.cnpj}</span></h1>
        </div> 
        <HandleEmpresas ></HandleEmpresas>
        <div className="p-2 "><i className="fas fa-boxes"></i></div>
      </div>}

      <hr className='hr-title' />
      {currentAction === 'info' && <ProdutoInfo return={() => setCurrentAction('search')} data={itemSelecionado!} />}
      {currentAction === 'create' && <ProdutoCreate return={() => {setCurrentAction('search');getProdutos()}}  setProdutos={setRawData} />}
      {currentAction === 'edit' && <ProdutoUpdate return={() => {setCurrentAction('search');setItemSelecionado(undefined)}} item={itemSelecionado!} setProdutos={setRawData}/>}
      {currentAction === 'search' && <main>
  
        {currentScreen === 'tipos' && <SearchTiposProdutos itemName='Tipo' return={() => setCurrentScreen('')} notSelecting={true} />}
        {currentScreen === 'grupos' && <SearchGruposProdutos itemName='Grupo' return={() => setCurrentScreen('')} notSelecting={true} />}
        {currentScreen === 'st' && <SearchSituacoesTributarias itemName='Situações Tributárias' return={() => setCurrentScreen('')} notSelecting={true} />}
       
        {!props.setProduto &&
        <div>
          <span>Cadastros: </span>
          <Button variant='contained' color='info' sx={{ m: 0.5 }}
            onClick={() => {
              setCurrentScreen('tipos')
            }}>
            Tipo produto
          </Button>
          <Button variant='contained' color='info' sx={{ m: 0.5 }}
            onClick={() => {
              setCurrentScreen('grupos')
            }}>
            Grupo produto
          </Button>
          <Button variant='contained' color='info' sx={{ m: 0.5 }}
            onClick={() => {
              setCurrentScreen('st')
            }}>
            Situação tributária
          </Button>
        </div>}
        <Paper elevation={3} className='p-2 mb-2' sx={{ backgroundColor: '#f5f0f0' }}>
          <div className="mb-1">
            <TextField type="text" className="form-control" size='small'
              variant='outlined' placeholder="Pesquisar por número, funcionario, usuario e data "
              value={searchTerm} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search color='success' />
                  </InputAdornment>
                ),
              }}
            ></TextField> </div>
          <Box >
            <Grid container spacing={2} justifyContent="flex-start">
              <Grid item xs={5} md={3} lg={2}>
                <Select
                  value={situacaoFilter}
                  size="small"
                  label="Situações"
                  onChange={(e) => {
                    setSituacaoFilter(e.target.value);
                  }}
                >
                  <MenuItem value="0">SITUAÇÕES</MenuItem>
                  <MenuItem value="S">ATIVO</MenuItem>
                  <MenuItem value="N">INATIVO</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={5} md={3} lg={2}>
                <Select
                  size="small"
                  label="grupos"
                  value={grupoFilter}
                  onChange={(e) => {
                    setGrupoFilter(e.target.value);
                  }}
                >
                  <MenuItem value="0">GRUPOS</MenuItem>
                  {grupos?.map((grupo) => (
                    <MenuItem key={grupo.CODIGO} value={grupo.CODIGO}>
                      {grupo.DESCRICAO}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={5} md={3} lg={2}>
                <Select
                  size="small"
                  label="grupos"
                  value={tipoFilter}
                  onChange={(e) => {
                    setTipoFilter(e.target.value);
                  }}
                >
                  <MenuItem value="0">TIPOS</MenuItem>
                  {tipos?.map((tipo) => (
                    <MenuItem key={tipo.NUMERO} value={tipo.NUMERO}>
                      {tipo.DESCRICAO}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>

          </Box>
          <p className='mb-0'>Total: <span className='text-success'>{searchResults?.length}</span></p>
        </Paper>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Button variant='contained' color='success' endIcon={<Visibility />} sx={{ m: 0.5 }}
            onClick={() =>{
              itemSelecionado ? setCurrentAction('info') : showSnackbar('Nenhum item foi selecionado')
          }}>
            Consultar
          </Button>
          <Button variant='contained' color='info' endIcon={<AddCircle />} sx={{ m: 0.5 }}
            onClick={() => {
              setCurrentAction('create')
            }}>
            Novo
          </Button>
          <Button variant='contained' color='info' endIcon={<Edit />} sx={{ m: 0.5 }}
            onClick={() => {
              itemSelecionado ? setCurrentAction('edit') : showSnackbar('Nenhum item foi selecionado','error')
            }}>
            Alterar
          </Button>
          <Button variant='contained' color='error' endIcon={<Delete />} sx={{ m: 0.5,marginLeft: 'auto'}}
            onClick={() => {
              itemSelecionado ? setAlertOpen(true) : showSnackbar('Nenhum item foi selecionado','error')
            }}>
            Excluir
          </Button>
        </Box>

        <div>

          {isLoading &&
            <div className="d-flex justify-content-center text-primary">
              <Spinner variant="primary" animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>}

          <div className='table-responsive' style={{ maxHeight: props.setProduto ? '300px' :'450px', overflow: 'scroll' }}>
            <table className="table table-secondary table-hover">
              <thead className="table-primary sticky-top">
                <tr>
                  <th scope="col">Codigo</th>
                  <th scope="col">Descricao</th>
                  <th scope="col">Custo atual</th>
                  <th scope="col">% Lucro</th>
                  <th scope="col">Vista</th>
                  <th scope="col">Cod. Barras</th>
                </tr>
              </thead>
              <tbody>
                {searchResults?.map(dados =>
                  <tr key={dados.CODIGO} className={`tr-size ${itemSelecionado?.CODIGO === dados.CODIGO && 'table-active'}`} style={{ cursor: 'pointer' }}
                    onClick={() => setItemSelecionado(dados)} >
                    <th scope="row">{dados.CODIGO}</th>
                    <td className={dados.ATIVO.trim() === 'N' && 'text-danger' || ''}>{dados.DESCRICAO}</td>
                    <td>{toMoneyFormat(dados.CUSTOATUAL, empresaAtual.dec.valor)}</td>
                    <td>{Number(dados.CCLUCRO).toFixed(2)}</td>
                    <td>{toMoneyFormat(dados.CPRECOVENDAVISTA, empresaAtual.dec.valor)}</td>
                    <td>{dados.CODIGOBARRAS}</td>
                  </tr>
                )}

              </tbody>
            </table></div>

        </div>
      </main>}

    </div>

  )
}
export default Produtos
