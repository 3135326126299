import { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { Select, MenuItem, InputLabel, TextField } from '@mui/material'


function SearchFornecedor(props) {
    const [numeroFornecedor, setNumeroFornecedor] = useState('00')
    const [isSearching, setIsSearching] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')
    const [searchResults, setSearchResults] = useState([])
    const searchFornecedores = () => {
        setIsSearching(true)
    }

    useEffect(() => {
        if (searchTerm.trim() === '') return setSearchResults(props.fornecedores)
        const result = props.fornecedores.filter((item) => {
            return item.NOME.includes(searchTerm.toUpperCase().trim()) ||
                item.NUMERO.toString().includes(searchTerm.trim())
        })
        setSearchResults(result)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm])

    useEffect(() => {
        props.setNumeroFornecedor(numeroFornecedor)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [numeroFornecedor])


    return (
        <div>

            <Modal
                animation={false}
                centered
                show={isSearching}
                backdrop="static"
                keyboard={true}
            >
                <Modal.Body>
                    <input type="text" className="form-control" placeholder='Pesquisar por fornecedor'
                        value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <hr />
                    <select style={{ height: '250px' }} className='form-select' multiple onChange={e => {
                        if (e.target.value === '00') return setNumeroFornecedor('0')
                        setNumeroFornecedor(e.target.value)
                    }} id="selectF">
                        <option value="00">Todos</option>
                        {searchResults.map((fornecedor) =>
                            <option key={fornecedor.NUMERO} value={fornecedor.NUMERO}>{fornecedor.NUMERO + ' - ' + fornecedor.NOME}</option>
                        )}
                    </select>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant='success' onClick={() => setIsSearching(false)}>
                        Concluir
                    </Button>
                </Modal.Footer>
            </Modal>




            <div className='row'>
                <label className='col-auto col-form-label' htmlFor="selectF">Fornecedor:</label>
                <div className="col-md-1">
                    <TextField readOnly type="text" value={"00" + numeroFornecedor} size='small'
                        InputProps={{ readOnly: true, }}
                        style={{ maxWidth: '90px' }} className="form-control" label='Numero' /></div>

                <div className='col-auto'><Select value={numeroFornecedor} size='small' label='Fornecedores'
                    onChange={e => {
                        setNumeroFornecedor(e.target.value)

                    }} id="selectF">
                    <MenuItem value="00">Todos</MenuItem>
                    {props.fornecedores.map((fornecedor) =>
                        <MenuItem size='small' key={fornecedor.NUMERO} value={fornecedor.NUMERO}>{"-" + fornecedor.NOME}</MenuItem>
                    )}
                </Select>


                </div>
                <div className="col-auto">
                    <div style={{ display: 'inline-block' }} data-tooltip="Visualizar todos" data-tooltip-location="bottom">
                        <i className="far fa-search" onClick={() => searchFornecedores()} style={{ cursor: 'pointer' }}></i>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default SearchFornecedor