import { Button, Paper } from '@mui/material'
import { Send } from '@mui/icons-material'
import axios from '../../../axiosConfig'
import Loading from '../../../utilities/loading'
import { useState } from 'react'


interface IProps {
    showPDF: (error: boolean, errorMessageP?: string) => void
    setPDFData: React.Dispatch<React.SetStateAction<{}>>
  }



function EstoqueTanque(props:IProps){
    const [isFullLoading, setIsFullLoading] = useState(false)

    const getEstoque = ()=>{
        setIsFullLoading(true)
        axios.post('relatorios/estoque/tanque')
        .then(r =>{
            console.log(r.data)
            props.setPDFData({data:r.data})
            props.showPDF(false)
        }).catch(err=>{
        }).finally(()=>setIsFullLoading(false))
      }
      

    return(
        <div>
            {isFullLoading ? <Loading /> : null}
            <Paper elevation={3} className='p-2' sx={{ backgroundColor: '#f5f0f0' }}>
                
                <Button variant='contained' endIcon={<Send />} onClick={() => getEstoque()}>Gerar</Button>
            </Paper>
        </div>
    )
}

export default EstoqueTanque