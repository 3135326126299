import * as React from 'react';
import { Lucro, totalLucro } from '../interfaceLucro'
import { useGlobalState } from '../../../../globalState'
import '../lucro.css'
import { toFixedNumber, toMoneyFormat } from '../../../../utilities/helperFunctions';
import PrintFooter from "../../printFooter";
import LucroTipoGraph from '../lucroTipoGraph';

function LucroBrutoTipoPDF(props: Lucro) {
    const [empresaAtual] = useGlobalState('empresaAtual')

    const tiposProdutos = [...new Map(props.data.map((item) => [item['CODIGO_TIPOPRODUTO'], item])).values()]

    const totalTipos = tiposProdutos.map(tipo => {
        const totaisGrupo = props.data.reduce((prev, curr) => {
            if (curr.CODIGO_TIPOPRODUTO === tipo.CODIGO_TIPOPRODUTO) {
                prev.TOTAL_QUANTIDADE_SAIDA += toFixedNumber(curr.QUANTIDADE_SAIDA)
                prev.TOTAL_VALORTOTALVENDA += toFixedNumber(curr.VALORTOTALVENDA)
                prev.TOTAL_LUCROBRUTO_PRECOULTIMO += toFixedNumber(curr.LUCROBRUTO_PRECOULTIMO)
                prev.TOTAL_LUCROBRUTO_MEDPERIODO += toFixedNumber(curr.LUCROBRUTO_MEDPERIODO)
                prev.TOTAL_QUANTIDADE_ENTRADA += toFixedNumber(curr.QUANTIDADE_ENTRADA)
                prev.TOTAL_VALORTOTALCOMPRA += toFixedNumber(curr.VALORTOTALCOMPRA)
            }
            return prev
        }, {
            TOTAL_QUANTIDADE_SAIDA: 0,
            TOTAL_VALORTOTALVENDA: 0,
            TOTAL_LUCROBRUTO_PRECOULTIMO: 0,
            TOTAL_LUCROBRUTO_MEDPERIODO: 0,
            TOTAL_QUANTIDADE_ENTRADA: 0,
            TOTAL_VALORTOTALCOMPRA: 0
        } as totalLucro)
        return totaisGrupo
    })
    const totalLucroRelatorio = totalTipos.reduce((prev, curr) => {
        prev.TOTAL_QUANTIDADE_SAIDA += curr.TOTAL_QUANTIDADE_SAIDA
        prev.TOTAL_VALORTOTALVENDA += curr.TOTAL_VALORTOTALVENDA
        prev.TOTAL_LUCROBRUTO_PRECOULTIMO += curr.TOTAL_LUCROBRUTO_PRECOULTIMO
        prev.TOTAL_LUCROBRUTO_MEDPERIODO += curr.TOTAL_LUCROBRUTO_MEDPERIODO
        prev.TOTAL_QUANTIDADE_ENTRADA += curr.TOTAL_QUANTIDADE_ENTRADA
        prev.TOTAL_VALORTOTALCOMPRA += curr.TOTAL_VALORTOTALCOMPRA
        return prev
    }, {
        TOTAL_QUANTIDADE_SAIDA: 0,
        TOTAL_VALORTOTALVENDA: 0,
        TOTAL_LUCROBRUTO_PRECOULTIMO: 0,
        TOTAL_LUCROBRUTO_MEDPERIODO: 0,
        TOTAL_QUANTIDADE_ENTRADA: 0,
        TOTAL_VALORTOTALCOMPRA: 0
    } as totalLucro)


    console.log(tiposProdutos)
    return (
        <div className='container'>
            <div className='pdf' id='section-to-print'>
                <h5>{// @ts-ignore
                    empresaAtual?.nome}</h5>
                <h6>Vendas &gt; Lucro &gt; Abertura caixa &gt; Detalhado</h6>
                <h6>De: {props.date.dataInicial} Até: {props.date.dataFinal}</h6>
                <br />
                {props.btnTable && <LucroTipoGraph data={totalTipos} tiposProdutos={tiposProdutos}/>}
                <div className="table-responsive">
                    <table className="table table-sm">
                        <thead className='thead-wrap'>
                            <tr>
                                <th scope="col">Cod</th>
                                <th scope="col">Descriçao</th>
                                <th scope="col">Qt.Saida</th>
                                <th scope="col">Ult.Preço</th>
                                <th scope="col">Preço Médio</th>
                                <th scope="col">Vl. total de venda</th>
                                <th scope="col">Lucro Ult. Pr</th>
                                <th scope="col">Lucro Pr.Médio</th>
                                <th scope="col">Qt Entrada</th>
                                <th scope="col">Vl total compra</th>
                                <th scope="col">Ult custo</th>
                                <th scope="col">Custo medio</th>
                            </tr>
                        </thead>
                        <tbody style={{ fontSize: '12px' }}>
                            {tiposProdutos.map((tipo, indexG) =>
                                <>
                                    < tr>
                                        <td colSpan={12} className="fw-bold pl-4"> {'一> Tipo: ' + tipo.CODIGO_TIPOPRODUTO + ' - ' + tipo.DESCRICAO_TIPOPRODUTO} </td>
                                    </tr>
                                    {props.data.map(produto =>
                                        produto.CODIGO_TIPOPRODUTO === tipo.CODIGO_TIPOPRODUTO
                                            ?
                                            <tr>
                                                <td>{produto.NUMERO_PRODUTO}</td>
                                                <td>{produto.DESCRICAO_PRODUTO}</td>
                                                <td>{produto.QUANTIDADE_SAIDA}</td>
                                                <td>{toMoneyFormat(produto.PRECO_ULTIMO,empresaAtual.dec.valor)}</td>
                                                <td>{toMoneyFormat(produto.PRECO_MEDIO,4)}</td>
                                                <td>{toMoneyFormat(produto.VALORTOTALVENDA)}</td>
                                                <td>{`${(toMoneyFormat(produto.LUCROBRUTO_PRECOULTIMO))} (${produto.PORC_PRECOULTIMO}%)`}</td>
                                                <td>{`${toMoneyFormat(produto.LUCROBRUTO_MEDPERIODO)} (${produto.PORC_MEDPERIODO}%)`} </td>
                                                <td>{produto.QUANTIDADE_ENTRADA}</td>
                                                <td>{toMoneyFormat(produto.VALORTOTALCOMPRA)}</td>
                                                <td>{toMoneyFormat(produto.CUSTO_ULTIMO,empresaAtual.dec.valor)}</td>
                                                <td>{toMoneyFormat(produto.CUSTO_MEDIO_PERIODO,4)}</td>
                                            </tr>
                                            : null                                
                                    )}

                                    <tr>
                                        <td colSpan={2} className="fw-bold pl-4 "> {'Total Tipo: '} </td>
                                        <td colSpan={3} className="fw-bold pl-4 ">{totalTipos[indexG].TOTAL_QUANTIDADE_SAIDA.toFixed(3)}</td>
                                        <td colSpan={1} className="fw-bold pl-4 ">  {toMoneyFormat(totalTipos[indexG].TOTAL_VALORTOTALVENDA)}</td>
                                        <td colSpan={1} className="fw-bold pl-4 ">  {toMoneyFormat(totalTipos[indexG].TOTAL_LUCROBRUTO_PRECOULTIMO)}</td>
                                        <td colSpan={1} className="fw-bold pl-4 ">  {toMoneyFormat(totalTipos[indexG].TOTAL_LUCROBRUTO_MEDPERIODO)}</td>
                                        <td colSpan={1} className="fw-bold pl-4 ">  {toMoneyFormat(totalTipos[indexG].TOTAL_QUANTIDADE_ENTRADA,3)}</td>
                                        <td colSpan={3} className="fw-bold pl-4 ">  {toMoneyFormat(totalTipos[indexG].TOTAL_VALORTOTALCOMPRA)}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={12} className="fw-bold pl-4 "></td>
                                    </tr>

                                </>
                            )}
                            <tr className=''>
                                <td colSpan={2} className="fw-bold pl-4 ">
                                    Total dos produtos: {props.data.length}</td>
                                <td colSpan={3} className="fw-bold pl-4 ">{totalLucroRelatorio.TOTAL_QUANTIDADE_SAIDA.toFixed(3)}</td>
                                <td colSpan={1} className="fw-bold pl-4 ">  {toMoneyFormat(totalLucroRelatorio.TOTAL_VALORTOTALVENDA)}</td>
                                <td colSpan={1} className="fw-bold pl-4 ">  {toMoneyFormat(totalLucroRelatorio.TOTAL_LUCROBRUTO_PRECOULTIMO)}</td>
                                <td colSpan={1} className="fw-bold pl-4 ">  {toMoneyFormat(totalLucroRelatorio.TOTAL_LUCROBRUTO_MEDPERIODO)}</td>
                                <td colSpan={1} className="fw-bold pl-4 ">  {toMoneyFormat(totalLucroRelatorio.TOTAL_QUANTIDADE_ENTRADA,3)}</td>
                                <td colSpan={3} className="fw-bold pl-4 ">  {toMoneyFormat(totalLucroRelatorio.TOTAL_VALORTOTALCOMPRA)}</td>
                            </tr>
                            <tr>
                                <td colSpan={12} className="fw-bold pl-4 "></td>
                            </tr>

                        </tbody>
                    </table></div>
                    <PrintFooter/>

            </div>
        </div>
    )
}
export default LucroBrutoTipoPDF