import { useState } from "react"
import axios from '../../../axiosConfig'
import Loading from '../../../utilities/loading'
import SearchFornecedor from "../../../utilities/searchs/searchFornecedor"
import SearchData from "../../../utilities/searchs/searchData"
import { formatDate } from '../../../utilities/helperFunctions'
import { Button, Paper } from '@mui/material'
import { Send } from '@mui/icons-material'


function Pagamentos(props) {
    const [numeroFornecedor, setNumeroFornecedor] = useState(props?.fornecedores[0]?.NUMERO)
    const [firstDate, setFirstDate] = useState(new Date)
    const [lastDate, setLastDate] = useState(new Date)
    const [isFullLoading, setIsFullLoading] = useState(false)

    const getPagamentos = () => {
        const postData = {
            fornecedorInicial: numeroFornecedor === "00" ? '0' : numeroFornecedor,
            fornecedorFinal: numeroFornecedor === "00" ? '999' : numeroFornecedor,
            dataPagamentoInicial: `${formatDate(firstDate, 'db')}`,
            dataPagamentoFinal: `${formatDate(lastDate, 'db')}`
        }
        setIsFullLoading(true)
        axios.post('/fornecedores/pagamentos', postData, )
            .then((r) => {
                if (r.data.length < 1) return props.showPDF(true, 'Sem pagamentos')
                props.setPDFData({ data: r.data })
                props.showPDF(false)
            }).catch(err => {
                props.showPDF(true, 'Erro ao buscar informação')
            }).finally(() => setIsFullLoading(false))
    }

    return (
        <div>
            {isFullLoading ? <Loading></Loading> : null}
            <Paper elevation={3} className='p-2' sx={{ backgroundColor: '#f5f0f0' }}>
                <h4 className='relatorio-title'>Pagamentos</h4>
                <SearchFornecedor fornecedores={props.fornecedores} setNumeroFornecedor={setNumeroFornecedor} />
                <SearchData firstDate={firstDate} lastDate={lastDate}
                    setFirstDate={setFirstDate} setLastDate={setLastDate} />
                <Button variant='contained' endIcon={<Send />} onClick={() => getPagamentos()}>Gerar</Button>
            </Paper>
        </div>
    )
}

export default Pagamentos