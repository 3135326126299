import { useGlobalState } from '../globalState'
import GraphTanques from './homeGraphTanque'
import GraphFormasPag from './homeGraphFormaPag'
import GraphVendaProdutos from './homeGraphVendaProduto'
import { Box, Button, CircularProgress, Grid, Paper } from '@mui/material'
import Home from './home'
import { HomeDashboardAlterarPreco } from './homeDashboardAlterarPreco'
import { useEffect, useState } from 'react'
import { LoginUsuarioFirebird } from '../utilities/changeEmpresa/loginUsuarioFirebird'
import axios from '../axiosConfig'
import { formatDate } from '../utilities/helperFunctions'
import { useTheme } from '@mui/material/styles';


function HomeDashboard() {
  const [empresaAtual] = useGlobalState('empresaAtual')
  const theme = useTheme()
  const [filialAtual] = useGlobalState('filialAtual')
  const [alterarPreco, setAlterarPreco] = useState(false)
  const [isLogging, setIsLogging] = useState(false)
  const [dashboardData, setDashboardData] = useState<[Array<any>,Array<any>,string,Array<any>,string] | undefined>(undefined)
  const [dashboardDataPDV,setDashboardDataPDV] = useState<[string] | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (empresaAtual.filiais?.length > 1 && empresaAtual.cnpj === filialAtual.CNPJ?.trim()) return
    setIsLoading(true)
    setDashboardData(undefined)
    setDashboardDataPDV(undefined)
    const postData = {
      dataInicial: formatDate(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000), 'db'),
      dataFinal: formatDate(new Date(), 'db')
    }
    axios.post('/dashboard', postData).then(r => {
      setDashboardData(r.data)
    }).finally(() => setIsLoading(false))
    axios.post('dashboard/pdv').then(r=>{
      setDashboardDataPDV(r.data)
    })
  }, [empresaAtual, filialAtual])




  if (empresaAtual === undefined || filialAtual === undefined) return <div></div>
  return (

    <div className="container mt-3">
      {isLogging && <LoginUsuarioFirebird return={() => setIsLogging(false)} success={() => {
        setIsLogging(false)
        setAlterarPreco(true)
      }} empresa={empresaAtual} />}
      {alterarPreco && <HomeDashboardAlterarPreco close={() => setAlterarPreco(false)} />}
      <Home />
      <h4 className='text-center pt-2 fw-bold'>
        Plataforma Dashboard
      </h4>
      {isLoading ? (
        <Grid container justifyContent="center">
          <CircularProgress />
        </Grid>
      ) :
        dashboardData &&
        <>
<Box
  sx={{
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: '5px',
      width: '100%', // Default width for small screens
      [theme.breakpoints.up('sm')]: {
        width: 'auto', // Set width to auto for screens larger than small
      },
    },
  }}
>
  <Paper elevation={3} className='p-2 mt-2' sx={{ backgroundColor: '#f5f0f0', '& > *': { padding: '10px' } }}>
    <h5>{`Caixas fechados - ${new Date().toLocaleString('default', { month: 'long' })}`}</h5>
    <h4 className='fw-bold'>{dashboardData[4]}</h4>
  </Paper>
  <Paper elevation={3} className='p-2 mt-2' sx={{ backgroundColor: '#f5f0f0', '& > *': { padding: '10px' } }}>
    <h5>Caixas abertos:</h5>
    <h4 className='fw-bold'>{dashboardDataPDV ? dashboardDataPDV[0] : "-"}</h4>
  </Paper>
  <Paper />
  <Paper elevation={3} />
</Box>
          <Grid container rowSpacing={1} columnSpacing={1} justifyContent={{ xs: 'center', md: 'left' }}>
           {dashboardData[1].length > 0 &&
           <Grid item xs={13} md={5} >
              <GraphFormasPag formasPag={dashboardData[1]} cupons={Number(dashboardData[2])} />
            </Grid>}
           {dashboardData[3].length > 0 &&
            <Grid item xs={13} md={7} >
              <GraphVendaProdutos produtos={dashboardData[3]} />
            </Grid>}
            {empresaAtual.isPosto && dashboardData[0][0].length > 0 && <Grid item xs={13} md={7} >
              <GraphTanques estoque={dashboardData[0][0]} estoqueLocal={dashboardData[0][1]} />
            </Grid> }
          </Grid>
        </>
      }


    </div>
  )
}

export default HomeDashboard