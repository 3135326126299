import { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { Select, MenuItem, TextField } from '@mui/material'
import { IClientes } from '../typesFromDB'

interface IProps {
    clientes: IClientes[]
    setNumeroCliente: React.Dispatch<React.SetStateAction<number>>
}

function Searchcliente(props: IProps) {
    const [numeroCliente, setNumeroCliente] = useState('0')
    const [isSearching, setIsSearching] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')
    const [searchResults, setSearchResults] = useState<IClientes[]>([])
    const searchclientes = () => {
        setIsSearching(true)
    }

    useEffect(() => {
        if (searchTerm.trim() === '') return setSearchResults(props.clientes)
        const result = props.clientes.filter((item) => {
            return item.NOME.includes(searchTerm.toUpperCase().trim()) ||
                item.NUMERO.toString().includes(searchTerm.trim())
        })
        setSearchResults(result)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm])

    useEffect(() => {
        props.setNumeroCliente(Number(numeroCliente))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [numeroCliente])


    return (
        <div>

            <Modal
                animation={false}
                centered
                show={isSearching}
                backdrop="static"
                keyboard={true}
            >
                <Modal.Body>
                    <input type="text" className="form-control" placeholder='Pesquisar por cliente'
                        value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <hr />
                    <select style={{ height: '250px' }} className='form-select' multiple onChange={e => {
                        if (e.target.value === '00') return setNumeroCliente('0')
                        setNumeroCliente(e.target.value)
                    }} id="selectF">
                        <option value="00">Todos</option>
                        {searchResults.map((cliente) =>
                            <option key={cliente.NUMERO} value={cliente.NUMERO}>{cliente.NUMERO + ' - ' + cliente.NOME}</option>
                        )}
                    </select>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant='success' onClick={() => setIsSearching(false)}>
                        Concluir
                    </Button>
                </Modal.Footer>
            </Modal>




            <div className='row'>
                <label className='col-auto col-form-label' htmlFor="selectF">Cliente:</label>
                <div className="col-auto"><TextField type="text" value={numeroCliente.toString().padStart(6, '0')}
                    size='small' InputProps={{ readOnly: true, }}
                    style={{ maxWidth: '100px' }} className="form-control" label='Numero' /></div>
                <div className="col-auto"><TextField type="text" value={props.clientes.find(i=>i.NUMERO.toString() === numeroCliente)?.NOME || 'Todos'}
                    size='small' InputProps={{ readOnly: true, }}  className="form-control"/></div>

                <div className="col">
                    <div style={{ display: 'inline-block' }} data-tooltip="Visualizar todos" data-tooltip-location="bottom">
                        <i className="far fa-search" onClick={() => searchclientes()} style={{ cursor: 'pointer' }}></i>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default Searchcliente