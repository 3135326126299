import { createGlobalState } from 'react-hooks-global-state';

interface IFilial {
  CNPJ: string;
  NUMERO: number;
  NOME: string;
  FANTASIA: string;
  HABILITARRETORNO: string;
}

interface IEmpresa {
  cnpj: string;
  fantasia: string;
  numerodeserie: number;
  md5: string;
  id: number;
  nome: string;
  host: string;
  porta: string;
  caminho: string;
  filiais: IFilial[];
  isPosto: boolean;
  dec: { valor: number; quantidade: number };
  permissoes:{
    cadastro: number,
    lancamento: number,
    financeiro: number,
    fiscal: number,
  }
}

interface IUsuarioConectado{
  id: number,
  name: string,
  email: string,
  master: number,
  dashboard:number,
  sistema:number,
  permissoes:{cadastro:number,lancamento:number,financeiro:number,fiscal:number}
}

interface IUsuarioFirebirdConectado{
  NUMERO:number
  USUARIO:string
  CNPJ:string
  EMAIL:string
}


const { setGlobalState, useGlobalState } = createGlobalState({
  empresas: [{} as IEmpresa],
  empresaAtual: {} as IEmpresa,
  filialAtual: {} as IFilial,
  usuarioConectado:{} as IUsuarioConectado,
  usuarioFirebirdConectado:{} as IUsuarioFirebirdConectado
});

export const resetGlobalState = () => {
  setGlobalState('empresas', [{} as IEmpresa]);
  setGlobalState('empresaAtual', {} as IEmpresa);
  setGlobalState('filialAtual', {} as IFilial);
};

export { useGlobalState, setGlobalState, type IFilial as IfilialAtual, type IEmpresa, type IUsuarioConectado };
