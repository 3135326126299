import {Modal,Spinner} from 'react-bootstrap'

function Loading(){
  return(
        <Modal  animation={false}
      aria-labelledby="contained-modal-title-vcenter" className="text-center"
      centered show={true}  backdrop="static" keyboard={false}>
          
        <Modal.Body><Spinner variant="primary"  animation="border" role="status">
  <span className="visually-hidden">Loading...</span>
</Spinner><p>Carregando</p></Modal.Body>
      
      </Modal>)
}

export default Loading
