import { MenuItem, Select, TextField } from "@mui/material"
import { handleChangeSelectInput, handleChangeTextInput } from "../../../utilities/inputsUtilities"
import SearchSituacoesTributarias from "../../../utilities/searchs/searchSituacoesTributarias"

interface ITributacaoTab {
    CALCULAPIS: string
    CALCULACOFINS: string
    CSTPIS: string
    CSTCOFINS: string
    CSTPISENTRADA: string
    CSTCOFINSENTRADA: string
    ALIQUOTAPIS: string
    ALIQUOTACOFINS: string
    MVAST: string | undefined
    ALIQUOTAISS: string
    ALIQUOTAFCP: string | undefined
    ALIQUOTAICMSREDUZIDA: string
    ALIQUOTAICMS:string
    VALORUNITARIOBCICMSST: string
    CODIGO_ST: string
    CODIGO_STENTRADA: string
}

interface IProps {
    data: ITributacaoTab
    setData: React.Dispatch<React.SetStateAction<ITributacaoTab | any>>
}

function TributacaoProduto(props: IProps) {
    return (
        <div>
               <div className="row mb-3">
                <div className="col-auto">
                <SearchSituacoesTributarias itemName='Código ST. Saída' codigoItem={props.data.CODIGO_ST}
                  setCodigoItem={(value: string) => props.setData((formData: any) => ({ ...formData, 'CODIGO_ST': value }))} />
                </div>
                <div className="col-auto">
                <SearchSituacoesTributarias itemName='Código ST. Entrada' codigoItem={props.data.CODIGO_STENTRADA}
                  setCodigoItem={(value: string) => props.setData((formData: any) => ({ ...formData, 'CODIGO_STENTRADA': value }))} /> 
                </div> 
            <div className="col-auto">Alíquota IPI:</div>
                <TextField hiddenLabel className='col' size="small" name='ALIQUOTAFCP'
                    value={props.data.ALIQUOTAFCP} onChange={(event) => handleChangeTextInput(event, props.setData)}
                    inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
                />
            </div>
            <h4>Por Empresa</h4>
            <div className="row mb-3">
            <div className="col-auto">Calcula PIS:</div>
                <Select className='col-auto' value={props.data.CALCULAPIS.trim()} size='small' name='CALCULAPIS'
                    onChange={(event) => handleChangeSelectInput(event, props.setData)}>
                    <MenuItem value={"N"}>Não</MenuItem>
                    <MenuItem value={"S"}>Sim</MenuItem>
                </Select>
                <div className="col-auto">CST PIS:</div>
                <TextField hiddenLabel className='col' size="small" name='CSTPIS'
                    value={props.data.CSTPIS} onChange={(event) => handleChangeTextInput(event, props.setData)}
                    inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
                />
                <div className="col-auto">CST PIS entrada:</div>
                <TextField hiddenLabel className='col' size="small" name='CSTPISENTRADA'
                    value={props.data.CSTPISENTRADA} onChange={(event) => handleChangeTextInput(event, props.setData)}
                    inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
                />
                <div className="col-auto">Alíquota PIS:</div>
                <TextField hiddenLabel className='col' size="small" name='ALIQUOTAPIS'
                    value={props.data.ALIQUOTAPIS} onChange={(event) => handleChangeTextInput(event, props.setData)}
                    inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
                />
            </div>
            <div className="row mb-3">
            <div className="col-auto">Calcula COFINS:</div>
                <Select className='col-auto' value={props.data.CALCULACOFINS.trim()} size='small' name='CALCULACOFINS'
                    onChange={(event) => handleChangeSelectInput(event, props.setData)}>
                    <MenuItem value={"N"}>Não</MenuItem>
                    <MenuItem value={"S"}>Sim</MenuItem>
                </Select>
                <div className="col-auto">CST COFINS:</div>
                <TextField hiddenLabel className='col' size="small" name='CSTCOFINS'
                    value={props.data.CSTCOFINS} onChange={(event) => handleChangeTextInput(event, props.setData)}
                    inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
                />
                <div className="col-auto">CST COFINS entrada:</div>
                <TextField hiddenLabel className='col' size="small" name='CSTCOFINSENTRADA'
                    value={props.data.CSTCOFINSENTRADA} onChange={(event) => handleChangeTextInput(event, props.setData)}
                    inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
                />
                <div className="col-auto">Alíquota COFINS:</div>
                <TextField hiddenLabel className='col' size="small" name='ALIQUOTACOFINS'
                    value={props.data.ALIQUOTACOFINS} onChange={(event) => handleChangeTextInput(event, props.setData)}
                    inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
                />
            </div>
            <div className="row mb-3">
            <div className="col-auto">Alíquota FCP:</div>
                <TextField hiddenLabel className='col' size="small" name='ALIQUOTAFCP'
                    value={props.data.ALIQUOTAFCP} onChange={(event) => handleChangeTextInput(event, props.setData)}
                    inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
                />
                <div className="col-auto">Alíquota ISS:</div>
                <TextField hiddenLabel className='col' size="small" name='ALIQUOTAISS'
                    value={props.data.ALIQUOTAISS} onChange={(event) => handleChangeTextInput(event, props.setData)}
                    inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
                />
                <div className="col-auto">Alíquota ICMS reduzida:</div>
                <TextField hiddenLabel className='col' size="small" name='ALIQUOTAICMSREDUZIDA'
                    value={props.data.ALIQUOTAICMSREDUZIDA} onChange={(event) => handleChangeTextInput(event, props.setData)}
                    inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
                />
                <div className="col-auto">Alíquota MVA ST:</div>
                <TextField hiddenLabel className='col' size="small" name='MVAST'
                    value={props.data.MVAST} onChange={(event) => handleChangeTextInput(event, props.setData)}
                    inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
                />
                <div className="col-auto">V. Unit, BCIST:</div>
                <TextField hiddenLabel className='col' size="small" name='VALORUNITARIOBCICMSST'
                    value={props.data.VALORUNITARIOBCICMSST} onChange={(event) => handleChangeTextInput(event, props.setData)}
                    inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
                />
            </div>
        </div>
    )
}

export default TributacaoProduto