import { RelatorioFormasPag } from './interfacesPDF'
import { useGlobalState } from '../../../../globalState'
import TableSort from '../../../../utilities/TableSortTS'
import { useMemo } from 'react'
import { toFixedNumber, toMoneyFormat } from '../../../../utilities/helperFunctions'
import PrintFooter from "../../printFooter";

interface IProps {
    data: RelatorioFormasPag['data']
    date: { dataInicial: string, dataFinal: string }
    options?: { filiaisSelecionadas: string[] }
}

function ResumoFormasPagPDF(props: IProps) {
    const [empresaAtual] = useGlobalState('empresaAtual')

    const columns = useMemo(
        () => [
            {
                Header: " ",
                columns: [
                    {
                        Header: "Empresa",
                        accessor: "FANTASIA",
                        Footer:''
                    }, 
                    {
                        Header: "Lançamentos",
                        accessor: "QTDLANCAMENTOSEMPRESA",
                        Footer:''
                    }, 
                    {
                        Header: "%Lançamentos",
                        accessor: "PORCLANCAMENTOSEMPRESA",
                        Cell: ({ value }: { value: string }) => Number(value).toFixed(2),
                        disableSortBy: true,
                        Footer:''
                    },
                    {
                        Header: "Total",
                        accessor: "VALOREMPRESA",
                        Cell: ({ value }: { value: string }) => toMoneyFormat(value),  
                    },
                    {
                        Header: "%Total geral",
                        accessor: "PORCVALOREMPRESA",
                        Cell: ({ value }: { value: string }) => Number(value).toFixed(2),
                        disableSortBy: true,
                        Footer:''
                    },
                    
                ],
                Footer: ()=><></>
            }
        ], [])

    const formasPag = props.data.reduce((acc,item)=>{
        if(acc.findIndex(x=>x.NUMERO === item.NUMERO) === -1)acc.push(
            {NUMERO:item.NUMERO,NOME:item.NOME,QTDLANCAMENTOSFORMA:item.QTDLANCAMENTOSFORMA,
            VALORFORMA:item.VALORFORMA,PORCVALORFORMA:item.PORCVALORFORMA,PORCLANCAMENTOSFORMA:item.PORCLANCAMENTOSFORMA})
        return acc
        
    },[] as {NUMERO:number,NOME:string,QTDLANCAMENTOSFORMA:number,VALORFORMA:string,PORCVALORFORMA:string,PORCLANCAMENTOSFORMA:string}[])

    return (
        <div className='container'>
            <div className='pdf' id='section-to-print'>
                <h5>{empresaAtual.nome}</h5>
                <h6>Multiloja &gt; Resumo valores de venda &gt; Resumido &gt; Formas de pagamento - Periodo: {props.date.dataInicial} a {props.date.dataFinal}</h6>
                <hr />
                <p style={{ fontSize: '12px' }}>{props.options?.filiaisSelecionadas.map(item => item + ' | ')}</p>
                <hr />
                {formasPag.map(item=>
                    <>
                    <h6>{item.NOME}</h6>
                    <div className="table-responsive">
                     <TableSort columns={columns} data={props.data.filter(i=>i.NUMERO === item.NUMERO)}></TableSort>
                    </div>
                    <p className='fw-bold text-end'>
                        {`Total lançamentos: ${item.QTDLANCAMENTOSFORMA}(${toFixedNumber(item.PORCLANCAMENTOSFORMA)}%) | `}{`Total valor: ${toMoneyFormat(item.VALORFORMA)}(${toFixedNumber(item.PORCVALORFORMA)}%)`} </p>
                    </>
                    )}
                    <h6 className='fw-bold text-end'>Total: {toMoneyFormat(props.data[0].TOTALVALOR)}</h6>
                    <PrintFooter/>
            </div>
        </div>
    )
}

export default ResumoFormasPagPDF