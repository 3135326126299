import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import jsonUrl from './url.json';

const getLocalStorageData = (key: string): string | null => {
  const data = JSON.parse(window.localStorage.getItem(key) || 'null');
  return data === 'null' ? null : data;
};

const customAxios: AxiosInstance = axios.create({
  baseURL: jsonUrl.URL
});


customAxios.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  config.data = {
    ...config.data,
    empresa: getLocalStorageData('filial'),
  };
  const empresaID = localStorage.getItem('empresaID');
  const usuarioFirebird = getLocalStorageData('usuarioFirebird')
  if (empresaID && !config.headers['Authorization']) {
    config.headers['Authorization'] = `Bearer ${JSON.parse(empresaID)}`;
  }

  config.headers['Encrypted-Id'] = getLocalStorageData('id')

  return config;
});

export default customAxios;
