import { TextField } from "@mui/material"
import { handleChangeTextInput } from "../../../utilities/inputsUtilities"

interface ICombustiveisTab {
    CODIGOANP: string | undefined
    DESCRICAOANP: string  | undefined
}

interface IProps {
    data: ICombustiveisTab
    setData: React.Dispatch<React.SetStateAction<ICombustiveisTab | any>>
}

function CombustiveisProduto(props: IProps) {
    return (
        <div>
            <div className="row mb-3">
                <div className="col-sm-2">Código ANP:</div>
                <TextField hiddenLabel className='col' size="small" name='CODIGOANP'
                    value={props.data.CODIGOANP} onChange={(event) => handleChangeTextInput(event, props.setData)}
                    inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
                />
                <div className="col-sm-2">Descrição ANP:</div>
                <TextField hiddenLabel className='col' size="small" name='DESCRICAOANP'
                    value={props.data.DESCRICAOANP} onChange={(event) => handleChangeTextInput(event, props.setData)}
                    inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
                />
            </div>
        </div>
    )
}

export default CombustiveisProduto