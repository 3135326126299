import { SelectChangeEvent } from "@mui/material";

export const handleChangeTextInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, 
    setFormData: React.Dispatch<React.SetStateAction<any>>) => {
    const { name, value } = event.target;
    setFormData((formData:any) => ({
      ...formData,
      [name]: value,
    }));
  };
  
  export const handleChangeCheckInput = (event: React.ChangeEvent<HTMLInputElement>,
    setFormData: React.Dispatch<React.SetStateAction<any>>) => {
    const { name, checked } = event.target
    setFormData((formData:any) => ({ ...formData, [name]: checked ? "S" : "N" }))
}

export const handleChangeCheckInputBoolean = (event: React.ChangeEvent<HTMLInputElement>,
  setFormData: React.Dispatch<React.SetStateAction<any>>) => {
  const { name, checked } = event.target
  setFormData((formData:any) => ({ ...formData, [name]: checked ? true : false }))
}

export const handleChangeCheckInputNumber = (event: React.ChangeEvent<HTMLInputElement>,
  setFormData: React.Dispatch<React.SetStateAction<any>>) => {
  const { name, checked } = event.target
  setFormData((formData:any) => ({ ...formData, [name]: checked ? 1 : 0 }))
}

export const handleChangeSelectInput = (event: SelectChangeEvent<string>, setFormData: React.Dispatch<React.SetStateAction<any>>) => {
  if(!event)return
  const { name, value } = event.target;
  setFormData((formData:any) => ({ ...formData, [name]: value }));
}
