
import axios from '../../axiosConfig'
import { Spinner } from 'react-bootstrap'
import Loading from '../../utilities/loading'
import ErrorAlert from '../../utilities/errorAlert'
import React, { useEffect, useRef, useState } from 'react'
import InfoCaixa from './caixaResumo'
import SearchData from '../../utilities/searchs/searchData'
import { formatDate } from '../../utilities/helperFunctions'
import { useGlobalState } from '../../globalState'
import HandleEmpresas from '../../utilities/changeEmpresa'
import { Button, IconButton, Stack, Tooltip, Paper } from '@mui/material'
import { SwapVert, Search, Send, Article } from '@mui/icons-material'
import { ICaixa, ICaixaAberto, IDetalhesPDF, ICaixaPDF, IPropsResumo } from './interfaceCaixa'
import SelectCaixasTable from './selectCaixasTable'
import { showSnackbar } from '../../utilities/snackbarShow'


interface IProps {
    setPDFData: React.Dispatch<React.SetStateAction<ICaixaPDF['data'] | null>>
    setPDFType:  React.Dispatch<React.SetStateAction<string>>
}

function Caixa(props: IProps) {

    const [isLoading, setIsLoading] = useState(false)
    const [isFullLoading, setIsFullLoading] = useState(false)
    const [errorAlert, setErrorAlert] = useState('')
    ///////////////////////////////////////////////////////////////////
    const [showCaixaAberto, setShowCaixaAberto] = useState(false)
    const rawData = useRef<ICaixa[] | null>(null)
    const [dadosCaixaAberto, setDadosCaixaAberto] = useState<ICaixaAberto[] | null>(null)
    const [detailsInfo, setDetailsInfo] = useState<IPropsResumo['data'] | null>(null)
    const [selectedCaixa, setSelectedCaixa] = useState<{ id: number, numero: number }[]>([])
    const [showDetailsPDF, setShowDetailsPDF] = useState(false)
    /////////////////////////////////////////////////////////////////////
    const [inputFirstDate, setInputFirstDate] = useState(new Date())
    const [inputLastDate, setInputLastDate] = useState(new Date())
    const [isCustomDate, setIsCustomDate] = useState(false)
    /////////////////////////////////////////////////////////////////////
    const [empresaAtual] = useGlobalState('empresaAtual')
    const [filialAtual] = useGlobalState('filialAtual')

    const getCaixas = async () => {
        setIsLoading(true)
        setSelectedCaixa([])
        const postData = {
            dateToday: formatDate(inputLastDate, 'db'),
            dateBefore: formatDate(inputFirstDate, 'db'),
            empresa: filialAtual.NUMERO || null
        }
        axios.post(`caixa/data/listar`, postData,
        )
            .then(r => {
                rawData.current = r.data
                fixData()
                setErrorAlert('')
            })
            .catch(error => {
                if (!error.response?.status) setErrorAlert('Erro ao conectar com banco de dados')
                else setErrorAlert('Erro ao listar caixas')
            })
            .finally(() => setIsLoading(false))
    }

    const getCaixasAbertos = () => {
        axios
            .get('caixa/aberto', { headers: { 'Database-Type': 'PDV' } })
            .then(r => fixDataAberto(r.data))
            .catch(() => {
                setDadosCaixaAberto(null)
                showSnackbar('Falha ao consultar caixas abertos','error')
            })
    }

    const fixData = () => {
        rawData.current?.forEach((item, index, arr) => {
            arr[index].DATAABERTURA = item.DATAABERTURA.replaceAll('-', '/').split('/').reverse().join('/')
            arr[index].DATAFECHAMENTO = item.DATAFECHAMENTO.replaceAll('-', '/').split('/').reverse().join('/')
        })

    }

    const fixDataAberto = (caixas:ICaixaAberto[])=>{
        setDadosCaixaAberto(caixas.map(item => {
            item.DATAABERTURA = item.DATAABERTURA.replaceAll('-', '/').split('/').reverse().join('/')
            if (item.DATAFECHAMENTO) item.DATAFECHAMENTO = item.DATAFECHAMENTO.replaceAll('-', '/').split('/').reverse().join('/')
            return item
        }))
    }
    /////////////////////////////////////////////////////////////////////////////////

    const getCaixaInfo = (IDCaixas: number[]) => {

        let caixaInfoArray: IDetalhesPDF | null = null
        if (Array.isArray(IDCaixas) === true && IDCaixas.length > 1) {
            let numCaixas = rawData.current?.reduce((acc, item) => {
                if (IDCaixas.indexOf(item.ID) !== -1) acc.push(item.NUMERO)
                return acc
            }, [] as number[]) || IDCaixas
            let numArray = numCaixas.sort(function (a, b) { return a - b; });
            let firstDate = '', firstHour = '', lastDate = '', lastHour = ''

            rawData.current?.forEach(item => {
                if (item.NUMERO == numArray[0]) {
                    firstDate = item.DATAABERTURA
                    firstHour = item.HORAABERTURA
                }
                if (item.NUMERO == numArray[numArray.length - 1]) {
                    lastDate = item.DATAFECHAMENTO
                    lastHour = item.HORAFECHAMENTO
                }
            })

            caixaInfoArray = {
                IsMoreThanOneCaixa: true,
                numeros: numArray, firstDate: firstDate, firstHour: firstHour,
                lastDate: lastDate, lastHour: lastHour, SITUACAO: showCaixaAberto ? 'ABERTO' : 'FECHADO',
                showDetails: showDetailsPDF
            }
        } else {
            let caixaPDF = showCaixaAberto ? dadosCaixaAberto?.filter(caixa => {
                if (caixa.ID === IDCaixas[0]) return caixa.ID
            }) : rawData.current?.filter(caixa => {
                if (caixa.ID === IDCaixas[0]) return caixa.ID
            })

            if (caixaPDF) {
                caixaInfoArray = {
                    ...caixaPDF[0], IsMoreThanOneCaixa: false,
                    showDetails: showDetailsPDF, numero: [caixaPDF[0].NUMERO]
                }
            }
        }
        return caixaInfoArray
    }

    const getPerdasSobras = (IDCaixas: number[]) =>{
        if (Array.isArray(IDCaixas) === true && IDCaixas.length === 0) {
            showSnackbar('Nenhum caixa selecionado','error')
            return
        }
        const postData = {
            caixas: IDCaixas,
            empresa: filialAtual.NUMERO || null
        }
        axios.post('caixa/perdassobras',postData).then(r=>{
            props.setPDFData(r.data)
            props.setPDFType('perdassobras')
        })
    }


    ///////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////
    const handleShowPDF = (caixa: number[]) => {
        if (Array.isArray(caixa) === true && caixa.length === 0) {
            showSnackbar('Nenhum caixa selecionado','error')
            return
        }
        handlePDFInfo(caixa)
    }

    const handlePDFInfo = async (IDCaixas: number[]) => {

        const axiosConfig = {
            'Database-Type': showCaixaAberto ? 'PDV' : 'ADM'
        }
        const postData = {
            caixas: IDCaixas,
            empresa: filialAtual.NUMERO || null
        }


        const metodosPagamento = showCaixaAberto
            ? axios.post(`caixa/aberto/relatorio`, postData, { headers: axiosConfig }).then(r => r.data)
            : axios.post(`caixa/relatorio`, postData, { headers: axiosConfig }).then(r => r.data)
        const produtos = axios.post(`caixa/listar`, postData, { headers: axiosConfig })
            .then(r => r.data)
        const encerrantes = axios.post(`encerrantes/listar`, postData, { headers: axiosConfig })
            .then(r => r.data)
        const insercao = axios.post(`caixa/insercao`, postData, { headers: axiosConfig })
            .then(r => r.data)
        const prazo = axios.post(`caixa/relatorio/prazo`, postData, { headers: axiosConfig })
            .then(r => r.data)
        const cupom = axios.post(`caixa/cupom/total`, postData, { headers: axiosConfig })
            .then(r => r.data)

        ////////////////////////////////////////////////////////////////
        setIsFullLoading(true)
        await Promise.all([metodosPagamento, produtos, encerrantes, insercao, prazo, cupom])
            .then((result) => {
                //Verifica se é mais de um caixa e atribue as informaçoes
                const caixaInfoArray = getCaixaInfo(IDCaixas)
                if (caixaInfoArray === null) return
                props.setPDFData([...result, caixaInfoArray])
                props.setPDFType('pdf')
                setIsFullLoading(false)

            }).catch((err) => {
                console.error(err)
                setIsFullLoading(false)
                //setActualError(errorHandler(err))
            })
    }
    ///////////////////////////////////////////////////////////////////////////////

    const handleShowExtendedInfo = (id?: number[]) => {
        //CASO SEJA CHAMADA PELO BOTÃO DE VOLTAR É RETORNADA LOGO APÓS 
        if (!id) return setDetailsInfo(null)
        handleCaixaData(id)

    }

    const handleCaixaData = async (IDCaixas: number[]) => {
        if (Array.isArray(IDCaixas) === true && IDCaixas.length === 0) {
            showSnackbar('Nenhum caixa selecionado','error')
            return
        }
        setIsFullLoading(true)
        const axiosConfig = {
            'Database-Type': showCaixaAberto ? 'PDV' : 'ADM'
        }
        const postData = {
            caixas: IDCaixas
        }
        const vendasProdutos = axios.post(`caixa/listar`, postData, { headers: axiosConfig }).then(r => r.data)
        const vendasTipos = axios.post(`caixa/resumo`, postData, { headers: axiosConfig }).then(r => r.data)
        const cupom = axios.post(`caixa/cupom/total`, postData, { headers: axiosConfig }).then(r => r.data)
        await Promise.all([vendasProdutos, vendasTipos, cupom]).then(result => {
            const caixaInfoArray = getCaixaInfo(IDCaixas)
            if (caixaInfoArray === null) return
            let formatedData: IPropsResumo['data'] = { vendas: result[0], tipos: result[1], cupons: result[2], caixa: caixaInfoArray }
            setDetailsInfo(formatedData)
        }).finally(() => setIsFullLoading(false))
    }

    useEffect(() => {
        if(detailsInfo !== null)setDetailsInfo(null)
        getCaixas()
        getCaixasAbertos()
        setSelectedCaixa([])
       

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [empresaAtual, filialAtual])

    useEffect(() => {
        setSelectedCaixa([])
    }, [showCaixaAberto])



    return (
        <div className="container mt-1">
            {isFullLoading ? <Loading></Loading> : null}
            {errorAlert.length > 0
                ? <ErrorAlert error={errorAlert} clearError={() => setErrorAlert('')} />
                : null
            }

            <div className="d-flex title">
                <div className="p-1"><h3>Caixa | <span className='empresa-atual'>{empresaAtual.fantasia} - {empresaAtual?.cnpj}</span></h3></div>
                <HandleEmpresas></HandleEmpresas>
                {filialAtual.CNPJ && empresaAtual.cnpj !== filialAtual?.CNPJ?.trim() ?
                    <div className='ps-3'>
                        <p className='fs-6'>
                            Filial: {filialAtual.FANTASIA + " - " + filialAtual.CNPJ}
                        </p>
                    </div>
                    : null}
            </div>

            <hr />
            <main className={detailsInfo !== null ? 'collapse' : ''}>
                <Paper elevation={3} className='p-1 mb-2' sx={{ backgroundColor: '#f5f0f0' }}>
                    <Stack direction={{ lg: 'row', md: 'row', sm: isCustomDate ? 'row' : 'row', xs: isCustomDate ? 'column' : 'row' }} alignItems="center" spacing={1}  flexWrap="wrap">
                        <SearchData firstDate={inputFirstDate} lastDate={inputLastDate}
                            setFirstDate={setInputFirstDate} setLastDate={setInputLastDate}
                            callSomeFunction={getCaixas} setIsCustomDate={setIsCustomDate} />
                        <Tooltip title='Procurar data' arrow>
                            <IconButton onClick={() => getCaixas()} color='primary'
                                size='large' className='pl-2' aria-label="delete">
                                <Search fontSize="inherit" /></IconButton></Tooltip>
                        <br />
                        <Button color='success' variant='contained' endIcon={<Send />} onClick={() => handleShowPDF(selectedCaixa.map(item => item.id))}>PDF</Button>
                        <Button color='secondary' variant='contained' endIcon={<Article />}
                            onClick={() => handleShowExtendedInfo(selectedCaixa.map(item => item.id))}>resumo</Button>
 <Button color='secondary' variant='contained' endIcon={<Article />}
                            onClick={() =>getPerdasSobras(selectedCaixa.map(item => item.id))}>Perdas e sobras</Button>
                    </Stack>
                </Paper>
                <Paper elevation={3} className='p-1 mb-2' sx={{ backgroundColor: '#f5f0f0' }}>
                    <Stack direction={'row'} alignItems="center" spacing={1}>
                        <Button size='small' variant='contained' endIcon={<SwapVert />}
                            onClick={() => { setShowCaixaAberto(!showCaixaAberto) }}
                        >{showCaixaAberto ? "C.Aberto" : "C.Fechado"}</Button>
                        <div className="form-check">
                            <input className="form-check-input" checked={showDetailsPDF}
                                onChange={() => setShowDetailsPDF(!showDetailsPDF)}
                                type="checkbox" value="" id="flexCheckChecked" />
                            <div data-tooltip="Detalha as inserções e retiradas do caixa" data-tooltip-location="bottom">
                                <label className="form-check-label" htmlFor="flexCheckChecked" style={{ fontSize: '14px' }}>
                                    Detalhar movimentações
                                </label></div>
                        </div>

                    </Stack>
                </Paper>

                {isLoading ?

                    <div className="d-flex justify-content-center text-primary">
                        <Spinner variant="primary" animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div> : null}
                <div>
                    <div className='row'>
                        <div className='col-xl'>
                            {
                                showCaixaAberto ? <SelectCaixasTable data={dadosCaixaAberto || []} setSelectedCaixa={setSelectedCaixa} /> :
                                    <SelectCaixasTable data={rawData.current || []} setSelectedCaixa={setSelectedCaixa} />
                            }

                        </div>
                    </div>

                </div>
            </main>

            {detailsInfo !== null ? <InfoCaixa data={detailsInfo} gerarPDF={() => handleShowPDF(selectedCaixa.map(item => item.id))} return={() => handleShowExtendedInfo()} ></InfoCaixa> : null}
        </div>
    )
}
export default Caixa