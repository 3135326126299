import { useState } from 'react'
import axios from '../../../axiosConfig'
import SearchData from "../../../utilities/searchs/searchData"
import Loading from '../../../utilities/loading'
import { formatDate } from '../../../utilities/helperFunctions'
import { Button, Paper } from '@mui/material'
import { Send } from '@mui/icons-material'
import SearchEmpresas from '../../../utilities/searchs/searchEmpresas'
import { IEmpresa } from '../../../globalState'

interface IProps {
  showPDF: (error: boolean, errorMessageP?: string) => void
  setPDFData: React.Dispatch<React.SetStateAction<{}>>
}

function MultiEmpResumoValoresVenda(props: IProps) {
  const [firstDate, setFirstDate] = useState(new Date)
  const [lastDate, setLastDate] = useState(new Date)
  const [relatorio, setRelatorio] = useState('abertura')
  const [isSelectingEmpresas, setIsSelectingEmpresas] = useState(false)
  const [isFullLoading, setIsFullLoading] = useState(false)

  const HandleEmpresasResumo = (empresas: IEmpresa[]) => {
    setIsSelectingEmpresas(false)
    setIsFullLoading(true)
    Promise.all(empresas.map(item => {
      const postData = {
        dataInicial: `${formatDate(firstDate, 'db')}`, dataFinal: `${formatDate(lastDate, 'db')}`,
        empresaCustom: item.filiais[0].NUMERO
      }
      return axios.post(`caixa/resumo/valores/${relatorio}`, postData, { headers: { 'Authorization': `Bearer ${item.md5}` } }).then(r => r.data)
    })).then(r => {
      if (r.every(array => array.length === 0)) return props.showPDF(true, 'Sem registros para a data informada')
      let dataResumo = {
        data: r,
        date: { firstDate: formatDate(firstDate, 'front'), lastDate: formatDate(lastDate, 'front') },
        empresas: empresas.map(item => item.fantasia)
      }
      props.setPDFData(dataResumo)
      props.showPDF(false)
    })
      .catch(err => props.showPDF(true, 'Erro ao buscar informação'))
      .finally(() => setIsFullLoading(false))

  }

  return (
    <div>
      {isFullLoading ? <Loading></Loading> : null}
      <Paper elevation={3} className='p-2' sx={{ backgroundColor: '#f5f0f0' }}>
        <h4 className='relatorio-title'>Relatório resumo de valores de venda:</h4>
        <div className="form-check">
          <input onClick={() => {
            setRelatorio('abertura')
          }}
            className="form-check-input" type="radio" name="selectTipoRelatorio" id="radioAbertura" defaultChecked />
          <label className="form-check-label " htmlFor="radioAbertura">
            Abertura caixa
          </label></div>
        <div className="form-check">
          <input onClick={() => {
            setRelatorio('cupom')
          }}
            className="form-check-input" type="radio" name="selectTipoRelatorio" id="radioEmissao" />
          <label className="form-check-label " htmlFor="radioEmissao">
            Emissão Cupom
          </label></div>
        <hr></hr>
        <SearchData setFirstDate={setFirstDate} setLastDate={setLastDate}
          firstDate={firstDate} lastDate={lastDate} />
        <Button variant='contained' endIcon={<Send />} onClick={() => setIsSelectingEmpresas(true)}>Gerar</Button>
      </Paper>
      {isSelectingEmpresas ? <SearchEmpresas getResumo={HandleEmpresasResumo} close={() => setIsSelectingEmpresas(false)} /> : null}
    </div>
  )
}

export default MultiEmpResumoValoresVenda
