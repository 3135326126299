import { RelatorioMes } from './interfacesPDF'
import { useGlobalState } from '../../../../globalState'
import TableSort from '../../../../utilities/TableSortTS'
import {  useMemo } from 'react'
import { toMonthName, toFixedNumber, toMoneyFormat } from '../../../../utilities/helperFunctions'
import ResumoMesGraph from '../Graphs/resumoMesGraph'
import PrintFooter from "../../printFooter";


interface IProps {
    data: RelatorioMes['data']
    date: { dataInicial: string, dataFinal: string }
    options?: { filiaisSelecionadas: string[] }
    btnTable?:boolean
}

function ResumoMesPDF(props: IProps) {
    const [empresaAtual] = useGlobalState('empresaAtual')

    const meses = props.data.reduce((acc, item) => {
        if (acc.indexOf(item.MES) === -1) {
            acc.push(item.MES)
            acc.sort(function (a, b) { return a - b; })
            return acc
        } else {
            return acc
        }
    }, [] as number[])

    const calculateTotal = (mes: number) => {
        let tot = props.data.find(x => x.MES === mes)
        return toFixedNumber(tot?.TOTALMES || 0)
    }

    const columns = useMemo(
        () => [
            {
                Header: " ",
                columns: [
                    {
                        Header: "Empresa",
                        accessor: "FANTASIA",
                        Footer: ''
                    },
                    {
                        Header: "Porc %",
                        accessor: "PORCVALOR",
                        Cell: ({ value }: { value: string }) => Number(value).toFixed(2),
                        disableSortBy: true,
                        Footer: ''
                    },
                    {
                        Header: "Porc mês %",
                        accessor: "PORCMES",
                        Cell: ({ value }: { value: string }) => Number(value).toFixed(2),
                        disableSortBy: true,
                        Footer: ''
                    },
                    {
                        Header: "Total",
                        accessor: "TOTALEMPRESA",
                        Cell: ({ value }: { value: string }) => toMoneyFormat(value),
                    },
                ],
                Footer: () => <></>
            }
        ], [])
    return (
        <div className='container'>
            <div className='pdf' id='section-to-print'>
                <h5>{empresaAtual.nome}</h5>
                <h6>Multiloja &gt; Resumo valores de venda &gt; Resumido &gt; mês - Periodo: {props.date.dataInicial} a {props.date.dataFinal}</h6>
                <hr />
                {props.btnTable ? <div className=''><ResumoMesGraph  data={props.data} /> </div>: null}
            
                <p style={{ fontSize: '12px' }}>{props.options?.filiaisSelecionadas.map(item => item + ' | ')}</p>
                <hr />
                {meses.map(mes =>
                    <>
                        <h6 className='pl-4'>Mês: {toMonthName(mes)}</h6>
                        <div className="table-responsive">
                            <TableSort columns={columns} data={props.data.filter(item => item.MES === mes)}></TableSort>
                        </div>
                        <p className='fw-bold text-end'>Total: {toMoneyFormat(calculateTotal(mes))}</p>
                    </>
                )}
                <h6 className='fw-bold text-end'>Total: {toMoneyFormat(props.data[0].TOTALVALOR)}</h6>
                <PrintFooter/>
            </div>
        </div>
    )
}
export default ResumoMesPDF