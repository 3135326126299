import { useEffect } from 'react'
import {  Data } from '../Vendas/interfaceValoresVenda'
import { toMonthName,toMoneyFormat } from '../../../utilities/helperFunctions'

interface IProps {
    data:Data[]
    empresa:string
}

function TablePDF(props:IProps) {
    let mesAtual = 0
    let anoAtual = 0

    const appendTipo = (item:Data) => {
        const el = document.getElementById(`ta${item.ANO}${item.MES}${props.empresa}`)
        let tBody = el?.getElementsByTagName('tbody')[0]
        if (tBody) {
            tBody.innerHTML = tBody.innerHTML + `
        <tr>
            <td>${item.NUMERO}</td>
            <td>${item.NOME}</td>
            <td>${item.QUANTIDADELANCAMENTOS}</td>
            <td>${toMoneyFormat(item.VALOR)}</td>
            <td>${Number(item.PORCLANCAMENTOS).toFixed(2) +"%"}</td>
            <td>${Number(item.PORCVALOR).toFixed(2)+"%"}</td>
        </tr>
    ` }
    }

    useEffect(() => {
        props.data.map((item) => item.ANO !== anoAtual ? changeYearAndMonth(item)
            : (item.MES !== mesAtual)
                ?changeMonth(item)
                : appendTipo(item)
        )
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const changeYearAndMonth = (item:Data) => {
        anoAtual = item.ANO
        mesAtual = item.MES
        appendTipo(item)
    }
    const changeMonth = (item:Data)=>{
        mesAtual = item.MES
        appendTipo(item)
    }

    const totalLancamentos = () => {
        const quantidade =  props.data.reduce((acc,item) =>{
              acc += item.QUANTIDADELANCAMENTOS
              return acc
          },0)
          return quantidade
      }
  
      const totalVendas = ()=>{
          const vendas  =  props.data.reduce((acc,item) =>{
              acc += Number(item.VALOR)
              return acc
          },0)
          return toMoneyFormat(vendas)
      }

    return (
        <>
        <h6>{props.empresa}</h6>
                {props.data.map((item) =>
                    item.ANO !== anoAtual ?
                        <div className='table-responsive'>
                            <h5 className='pl-4'>{anoAtual = item.ANO}</h5>
                            <hr />
                            <table className="table table-sm table-striped" id={`ta${item.ANO}${item.MES}${props.empresa}`}>
                                <thead>
                                    <tr>
                                        <th scope="col">Número</th> <th scope="col">Forma de pagamento</th> <th scope="col">Qtd de lançamentos</th> <th scope="col">Valor</th><th scope="col">Qtd %</th><th scope="col">Valor %</th>
                                    </tr>
                                </thead>
                                <tbody  style={{ fontSize: '12px' }} id={`tr${item.ANO}${item.MES}`}>
                                </tbody>
                            </table>
                            <p className='text-end infoPDF'>{toMonthName(item.MES).toUpperCase()} - {mesAtual = item.MES} | 
                                Qtd Lançamentos: {item.TOTALLANCAMENTOS} - Total vendas: R${toMoneyFormat(item.TOTALVALOR)} </p>
                        </div>

                        : (item.MES !== mesAtual)
                            ? <div  className='table-responsive'>
                                
                                <table className="table table-sm table-striped" id={`ta${item.ANO}${item.MES}${props.empresa}`}>
                                    <thead>
                                        <tr>
                                            <th scope="col">Número</th> <th scope="col">Forma de pagamento</th> <th scope="col">Qtd de lançamentos</th> <th scope="col">Valor</th><th scope="col">Qtd %</th><th scope="col">Valor %</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ fontSize: '12px' }} id={`tr${item.ANO}${item.MES}`}>
                                    
                                    </tbody>
                                </table>
                                <p className='text-end infoPDF'>{toMonthName(item.MES).toUpperCase()} - {mesAtual = item.MES} | 
                                Qtd Lançamentos: {item.TOTALLANCAMENTOS} - Total vendas: R${toMoneyFormat(item.TOTALVALOR)} </p>
                            </div>
                            : null
                )}
                <br />
                <p className='infoPDF fw-bold pr-3 text-end'> Quantidade total de lançamentos: {totalLancamentos()} | 
                Valor total de vendas: {totalVendas()} </p>
                </>
    )

}

export default TablePDF