import * as React from 'react';
import {Snackbar,Alert, AlertProps} from '@mui/material'

const MuiAlert = React.forwardRef<HTMLDivElement, AlertProps>(function AlertP(
    props,
    ref,
  ) {
    return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  interface IProps{
    snackbarData:{message:string,severity:'success' | 'error' | 'info'}
    close:React.Dispatch<React.SetStateAction<{message:string,severity:'success' | 'error' | 'info'}>>
  }


  export default function CustomizedSnackbars(props:IProps) {
  
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
  
      props.close({message:'',severity:'success'})
    };

    return (
        <Snackbar open={props.snackbarData.message.length > 0} autoHideDuration={4000} onClose={handleClose}>
            <div>
          <MuiAlert onClose={handleClose} severity={props.snackbarData.severity || undefined} sx={{ width: '100%' }}>
          {props.snackbarData.message}
          </MuiAlert></div>
        </Snackbar>

    );
  }