
import TodasAPagar from './ContasAPagar/todasAPagar'
import Pagamentos from './ContasAPagar/pagamentos'
import Despesas from './ContasAPagar/despesas'
import DebitoTotal from './Clientes/debitoTotal'
import NotasEntrada from './Entrada/notasEntrada'
import EstoqueFinalMovimento from './Estoque/estoqueFinalMovimento'
import ResumoValoresVenda from './Vendas/resumoValoresVenda'
import MultiResumoValoresVenda from './multiLoja/MultiResumoValoresVenda'
import MultiEmpResumoValoresVenda from './multiEmpresa/MultiEmpResumoValoresVenda'
import Lucro from './Vendas/lucro.tsx'
import VendasDoDia from './Vendas/vendasDia'
import Abastecimento from './Vendas/abastecimento'
import EstoqueTanque from './Estoque/tanque'
import VendasProdutos from './Vendas/vendasProdutos'
import Afericao from './Estoque/afericao'
import Combustiveis from './multiLoja/combustiveis/combustiveis'
import VendasClientes from './multiLoja/vendaClientes/vendasClientes'
import ExportFormasPag from './exportacoes/formasPag';


function RelatoriosRoutes(props) {
    const components = {
        todasapagar: TodasAPagar,
        pagamentos: Pagamentos,
        despesas: Despesas,
        debitototal: DebitoTotal,
        notasentrada: NotasEntrada,
        estoquefinalmovimento: EstoqueFinalMovimento,
        resumovaloresvenda: ResumoValoresVenda,
        multilojaresumovaloresvenda: MultiResumoValoresVenda,
        lucro: Lucro,
        vendasdia: VendasDoDia,
        abastecimento: Abastecimento,
        multiempresaresumovaloresvenda: MultiEmpResumoValoresVenda,
        tanque: EstoqueTanque,
        vendasprodutos: VendasProdutos,
        afericao: Afericao,
        multilojacombustiveis:Combustiveis,
        exportformaspag:ExportFormasPag,
        multilojavendasclientes:VendasClientes
    };
    const Component = components[props.componentName];
    return (
        <div>
            {Component && <Component {...props} />}
        </div>
    )
}


export default RelatoriosRoutes
