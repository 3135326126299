import {NavLink, useLocation} from 'react-router-dom'

function CadastroNav(){
    const location = useLocation()

    return(
        <>
              <li className="nav-item-side">
      <NavLink to="/adm/clientes" className={location.pathname === "/clientes" ? "nav-link-side" : "nav-link-side grayscale"}>
                        <i className=" fas fa-id-badge"></i>
                        <span className="link-text">
                            Clientes
                        </span>
                </NavLink>
      </li>

      <li className="nav-item-side">
      <NavLink to="/adm/produtos" className={location.pathname === "/produtos" ? "nav-link-side" : "nav-link-side grayscale"}>
                        <i className="fas fa-box-open"></i>
                        <span className="link-text">
                            Produtos
                        </span>
                </NavLink>
      </li>
        </>
    )
}
export default CadastroNav