import React from "react";
import { useTable, Column, useSortBy } from "react-table";
import Snackbar from './snackbar'

/** 
 * There's some boilter plate here
 * Important part is useTable function
 * we add the useGlobalFilter hook
 * then we also add an input which has 
 * onChange={handleFilterInputChange}
 * */


interface TableProps {
  columns: Column[];
  data: any[];
}

export default function Table({
  columns,
  data
}: TableProps): React.ReactElement {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    footerGroups
  } = useTable(
    {
      columns,
      data,
      disableSortBy:false ,
    },
    useSortBy
  );
  const [snackbarData, setSnackbarData] = React.useState<
  { message: string, severity: 'success' | 'error' |'info'}>({ message: '', severity: 'success' })

    const callSnackbar = (isSortedDesc:boolean | undefined)=>{
      const message = 'Ordenação:'
      const add =  isSortedDesc === undefined ? 'Crescente' : isSortedDesc ? 'Desativada' : 'Decrescente'
      setSnackbarData({message:message+add,severity:'success'})
    }

  return (
    <>
     <Snackbar snackbarData={snackbarData} close={setSnackbarData} />
    <table   className="table table-striped table-sm" {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th scope="col" {...column.getHeaderProps(column.getSortByToggleProps())}  {...column.getHeaderProps(
                column.getSortByToggleProps({ title: 'Alterar ordenação' })
                
              )}
                className={column.isSorted ? column.isSortedDesc ? "sort-desc" : "sort-asc" : ""}
                onClick={(e)=>{
                   // @ts-ignore
                  column.getHeaderProps(column.getSortByToggleProps()).onClick(e);
                  callSnackbar(column.isSortedDesc)
                }}
                {...column.getHeaderProps({
                  style: { minWidth: column.minWidth, width: column.width },
                })}
                >
                {column.render("Header")}
                {column.canSort ? <i className="fa-solid fa-arrow-down-arrow-up no-print"></i> : null }
                {/* Render the columns filter UI */}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  // @ts-ignore
                  <td {...cell.getCellProps({className:cell.column.className})}>{cell.render("Cell")}</td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
      
      {/* <tfoot>
        {footerGroups.map(group => (
          <tr {...group.getFooterGroupProps()}>
            {group.headers.map(column => (
              <td className="fw-bold" {...column.getFooterProps()}>{column.render('Footer')}</td>
            ))}
          </tr>
        ))}
      </tfoot> */}
    </table>
    </>
  );

}
