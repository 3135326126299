import IClientes from "./clientesInterface";
import { isValidCNPJ, isValidCPF, isValidIE, isValidRG } from "../../utilities/helperFunctions";
import { showSnackbar } from "../../utilities/snackbarShow";

export const CheckFields = (item: IClientes): boolean => {
  if (item.NOME.trim() === '') {
    showSnackbar('Campo NOME não preenchido', 'error')
    return false
  }
  if (item.RAZAOSOCIAL.trim() === '') {
    showSnackbar('Campo RAZÃO SOCIAL não preenchido', 'error')
    return false
  }

  if (!isValidCPF(item.CNPJ?.trim() || '') && !isValidCNPJ(item.CNPJ?.trim() || '') && item.CNPJ?.trim() !== '') {
    showSnackbar('CPF/CNPJ inválido', 'error')
    return false
  }

  if (!isValidIE(item.INSCRICAOESTADUAL?.trim() || '', item.ESTADO?.trim())
    && !isValidRG(item.INSCRICAOESTADUAL?.trim()) && item.INSCRICAOESTADUAL?.trim() !== ''
    && item.INSCRICAOESTADUAL?.trim() !== 'ISENTO'){
      showSnackbar('IE/RG inválido', 'error')
      return false
    }
    return true
}