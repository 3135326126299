import { useMemo, useState, useEffect } from "react";
import TableSort from '../../../utilities/TableSort'
import {dateToString,fixDate, toFixedNumber, toMoneyFormat} from '../../../utilities/helperFunctions'
import { ShowEmpresa } from '../showEmpresa';
import PrintFooter from "../printFooter";

function TodasAPagarPDF(props) {
    const [dataTable,setDataTable] = useState([])

    useEffect(()=>{
        setDataTable(fixDate(props.data,['DATAEMISSAO','DATAVENCIMENTO']))
},[])

    const columns = useMemo(
        () => [
           { 
            Header: " ",
           
            columns: [
                {
                     Header: "Emp",
                    accessor: "NUMERO_EMPRESA", 
                },
                {
                    Header: "Fornecedor",
                    accessor: "NOME_FORNECEDOR"
                },
                  {
                    Header: "Descrição",
                    accessor: "OBSERVACAO"
                  },
                  {
                    Header: "Emissão",
                    accessor: "DATAEMISSAO",
                    Cell: ({value}) => dateToString(value),
                    sortType: 'datetime',     
                  },{
                    Header: "Vencimento",
                    accessor: "DATAVENCIMENTO",
                    Cell: ({value}) => dateToString(value),
                    sortType: 'datetime',     
                  },{
                    Header: "Documento",
                    accessor: "NUMERODOCUMENTO"
                  },{
                    Header: "Parc.",
                    accessor: "NUMEROPARCELA"
                  },{
                    Header: "Des/Acr.",
                    accessor: "VALORRESTANTE",
                    Cell: ({value}) => toMoneyFormat(value),
                  },
                  {
                    Header: "Valor",
                    accessor: "VALOR",
                    Cell: ({value}) => toMoneyFormat(value),
                  },    
            ],
            Footer:()=> <p className='infoPDF fw-bold pr-3 text-end'>Total de lançamentos: {props.data.length} | Total Des/Acr: R${totalDesAcr.toFixed(2)}  |  Total: R${toMoneyFormat(total)}</p>
        }
         ])
  


        
    const total = props.data.reduce((acc,{VALOR}) =>{
        return acc + Number(VALOR)
    },0)
    const totalDesAcr = props.data.reduce((acc,{VALORRESTANTE})=>{
        return acc + Number(VALORRESTANTE)
    },0)


    return (
        <div className='container'>

            <div className='pdf' id='section-to-print'>
            <ShowEmpresa/>
                <h6>Contas a pagar &gt; Todas as contas a pagar</h6>
                <hr />
                <br />
                <div className="table-responsive">
                <TableSort columns={columns} data={dataTable}></TableSort>
                </div>
                <PrintFooter/>
               

            </div></div>
    )

}
export default TodasAPagarPDF

