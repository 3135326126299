import { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import { useGlobalState } from './globalState';
import HomeDashboard from './home/homeDashboard';
import HomeCadastro from './home/homeADM';
import Cliente from './views/clientes/clientes';
import Produto from './views/produtos/produtos';
import Login from './views/login';
import Caixa from './views/caixa/index';
import Relatorios from './views/relatorios/index';
import NoMobile from './utilities/NoMobile';
import StartLoadScreen from './utilities/startLoadScreen';
import Usuarios from './views/restrito/usuarios';
import PerfilUsuario from './views/perfilUsuario/perfilUsuario';
import { LoginUsuarioFirebird } from './utilities/changeEmpresa/loginUsuarioFirebird';

//Abastecimentos caixas abertos


const DESKTOP_BREAKPOINT = 1024;

const NotFound = () => {
  return (
    <div className='container'>
      <h1>404 - Pagina não encontrada</h1>
      <p>Desculpe, a página que você esta procurando não existe</p>
    </div>
  );
};

export default function RoutesApp() {
  const [usuarioConectado] = useGlobalState('usuarioConectado')
  const [isDesktop, setIsDesktop] = useState(false);
  const [empresa] = useGlobalState('empresaAtual');
  const [isEmpresaDefined, setIsEmpresaDefined] = useState(!!empresa?.id);
  const navigate = useNavigate();

  const tokenExists = !!localStorage.getItem('id');
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= DESKTOP_BREAKPOINT);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const NoMobileRoutes = (
    <>
      <Route path="/adm/clientes" element={!isDesktop ? <NoMobile /> : <Cliente />} />
      <Route path="/adm/produtos" element={!isDesktop ? <NoMobile /> : <Produto />} />
      <Route path="/adm" element={!isDesktop ? <NoMobile /> :<HomeCadastro />} />
    </>
  );

  const CheckADM = () => {
    const usuarioExists = localStorage.getItem('usuarioFirebird');

    if (usuarioConectado.sistema === 0) return <></>
    if (!usuarioExists) {
      return (
        <>
          <Route path="/adm/clientes" element={!isDesktop ? <NoMobile /> :<LoginUsuarioFirebird empresa={empresa} return={() => navigate(usuarioConectado.dashboard === 1 ? '/dashboard' : '/login')} success={()=> navigate('/adm/clientes')}/>} />
          <Route path="/adm/produtos" element={!isDesktop ? <NoMobile /> :<LoginUsuarioFirebird empresa={empresa} return={() => navigate(usuarioConectado.dashboard === 1 ? '/dashboard' : '/login')}  success={()=> navigate('/adm/produtos')}/>} />
          <Route path="/adm" element={!isDesktop ? <NoMobile /> : <LoginUsuarioFirebird empresa={empresa} return={() => navigate(usuarioConectado.dashboard === 1 ? '/dashboard' : '/login')} success={()=> navigate('/adm')} />} />
        </>
      );
    }

    return NoMobileRoutes;
  };
  const CheckDashboard = () => {
    if (usuarioConectado.dashboard === 0) return <></>
    return (
      <>
        <Route path="/dashboard" element={<HomeDashboard />} />
        <Route path="/dashboard/caixas" element={<Caixa />} />
        <Route path="/dashboard/relatorios" element={<Relatorios />} />
      </>
    )
  }
  useEffect(() => {
    setIsEmpresaDefined(!!empresa?.id);
  }, [empresa?.id]);

  if (!isEmpresaDefined || !usuarioConectado.id) {
    return <Routes>
       <Route path="/login" element={<Login />} />
       <Route path="*" element={tokenExists ? <StartLoadScreen/> : <Navigate to="/login" />}/>
    </Routes>;
  }


  return (
    <Routes>
      {CheckADM()}
      {CheckDashboard()}
      {!tokenExists ? (
        <Route path="/" element={<Navigate to="/login" />} />
      ) : (
        <Route path="/" element={<Navigate to={ usuarioConectado.dashboard === 1 ? '/dashboard' : '/adm'} />} />
      )}
      { usuarioConectado.master === 1 && <Route path="/restrito" element={<Usuarios />} />}
      <Route path="/perfil" element={<PerfilUsuario />} />
      <Route path="/login" element={<Login />} />
      <Route path="*" element={tokenExists ? <NotFound /> : <Navigate to="/login" />} />
    </Routes>
  );
}
