import React from "react";

function NoMobile() {
  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "90vh", backgroundColor: "#f2f2f2" }}>
      <div style={{ textAlign: "center" }}>
        <h1 style={{ fontSize: "3rem" }}>Opa!</h1>
        <p style={{ fontSize: "1.5rem" }}>
         Essa página pode ser vista apenas em telas maiores. Por favor abra a página em um computador para ver o conteudo.
        </p>
      </div>
    </div>
  );
}

export default NoMobile;
