import * as React from 'react';
import axios from '../../axiosConfig'
import { Tabs, Tab } from "react-bootstrap"
import {
  Paper, TextField, Button, Stack, Select, MenuItem,
  FormControlLabel, Checkbox, Radio, InputBaseComponentProps
} from '@mui/material'
import { Send, Undo } from '@mui/icons-material'
import IClientes from './clientesInterface'
import SearchGrupos from '../../utilities/searchs/searchGrupoClientes'
import SearchRegioes from '../../utilities/searchs/searchRegioesClientes'
import SearchCondicaoPagamento from '../../utilities/searchs/searchCondicaoPagamento';
import SearchPlanoContas from '../../utilities/searchs/searchPlanoContas';
import SearchClasseNegociacao from '../../utilities/searchs/searchClasseNegociacao';
import Loading from '../../utilities/loading'
import { handleChangeTextInput, handleChangeCheckInput, handleChangeSelectInput } from '../../utilities/inputsUtilities';
import InputMask from 'react-input-mask'
import { CheckFields } from './validateCliente';
import { showSnackbar } from '../../utilities/snackbarShow';

interface IClienteUpdate {
  data: IClientes,
  return: Function,
  setClientes: React.Dispatch<React.SetStateAction<IClientes[] | undefined>>
}


function ClienteUpdate(props: IClienteUpdate) {
  const [formData, setFormData] = React.useState({ ...props.data });
  const [isFullLoading, setIsFullLoading] = React.useState(false)

  const handleChangeCheckPag = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked, id } = event.target
    const formDataValue = formData?.[name as keyof typeof formData];
    if (typeof formDataValue === "string") {
      let arrayPagamentos = [...formDataValue]
      arrayPagamentos[Number(id)] = checked ? "S" : "N"
      const newPagamentosLiberados = arrayPagamentos.join('')
      console.log(newPagamentosLiberados)
      setFormData(formData => ({ ...formData, [name as keyof typeof formData]: newPagamentosLiberados }));
    }
  }
  const handleChangeRadio = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setFormData(formData => ({ ...formData, [name]: Number(value) }))
  }, [])



  const getMaskCPF = () => {
    const numericValue = formData.CNPJ?.replace(/\D/g, '');
    if (numericValue && numericValue.length <= 11) {
      return '999.999.999-999'; // CPF mask
    } else {
      return '99.999.999/9999-99'; // CNPJ mask
    }
  };

  const getChanges = () => {
    const keysToCompare = Object.keys(formData);
    const alteredKeys = keysToCompare.filter((item) => formData[item as keyof typeof formData] !== props.data[item as keyof typeof formData]);

    if (alteredKeys.length === 0) {
      showSnackbar('Nenhuma informação alterada', 'error');
      return;
    }
    const alteredData: { [key: string]: any } = {}; // Initialize an empty object to store altered key-value pairs

    alteredKeys.forEach(item => {
      alteredData[item] = formData[item as keyof typeof formData];
    });

    return {
      formData: alteredData,
      numeroCliente: props.data.NUMERO
    };
  };

  const updateCliente = () => {
    setIsFullLoading(true)
    const updateData = getChanges()
    if (!updateData) {
      setIsFullLoading(false)
      return
    }
    if (!CheckFields(formData)) {
      setIsFullLoading(false)
      return
    }

    axios.patch('cliente', updateData,
    ).then(r => {
      showSnackbar('Registro atualizado')
      props.setClientes(r.data)
      props.return()
    }).catch(err => {
      showSnackbar('Erro ao atualizar registro', 'error')
    }).finally(() => setIsFullLoading(false))

  }


  return (

    <div>
      {isFullLoading ? <Loading /> : null}
      <div className=".arrow-left "><i onClick={() => props.return()} className="fad fa-arrow-to-left arrow-left "></i></div>
      <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
        <Tab eventKey="home" title="Dados pessoais">
          <Paper elevation={3} sx={{ backgroundColor: '#f5f0f0', p: 3 }}>
            <div className="row mb-3">
              <div className="col-sm-2">Nome:</div>
              <TextField hiddenLabel className='col' size="small" name='NOME'
                value={formData.NOME || ''} onChange={(event) => handleChangeTextInput(event, setFormData)}
                inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
              />
              <div className="col-sm-2">Numero:</div>
              <TextField hiddenLabel className='col' size="small" name='NUMERO' disabled
                value={formData.NUMERO || ''} />
            </div>
            <div className="row mb-3">
              <div className="col-sm-2">Razão social:</div>
              <TextField hiddenLabel className='col' size="small" name='RAZAOSOCIAL'
                value={formData.RAZAOSOCIAL || ''} onChange={(event) => handleChangeTextInput(event, setFormData)}
                inputProps={{ maxLength: 50, style: { textTransform: 'uppercase' } }} />
              <div className="col-sm-2">Codigo SAP:</div>
              <TextField hiddenLabel className='col' size="small" name='CODIGORETAGUARDA'
                value={formData.CODIGORETAGUARDA || ''} onChange={(event) => handleChangeTextInput(event, setFormData)} />
            </div>
            <div className="row mb-3">
              <div className="col-sm-2 ">CNPJ/CPF:</div>
              <InputMask
                mask={getMaskCPF()}
                maskChar="_"
                value={formData.CNPJ || ''}
                onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleChangeTextInput(event, setFormData)}
              >
                {(inputProps: InputBaseComponentProps | undefined) => (
                  <TextField
                    hiddenLabel
                    className="col"
                    size="small"
                    name="CNPJ"
                    inputProps={{ ...inputProps, style: { textTransform: "uppercase" } }}
                  />
                )}
              </InputMask>
              <div className="col-sm-2">Email:</div>
              <TextField hiddenLabel className='col' size="small" name='EMAIL'
                value={formData.EMAIL || ''} onChange={(event) => handleChangeTextInput(event, setFormData)} inputProps={{ maxLength: 100 }} />
            </div>
            <div className="row mb-3">
              <div className="col-sm-2">Contato:</div>
              <TextField hiddenLabel className='col' size="small" name='CONTATO'
                value={formData.CONTATO || ''} onChange={(event) => handleChangeTextInput(event, setFormData)} inputProps={{ maxLength: 50 }} />
              <div className="col-sm-2">Contato:</div>
              <TextField hiddenLabel className='col' size="small" name='CONTATO2'
                value={formData.CONTATO2 || ''} onChange={(event) => handleChangeTextInput(event, setFormData)} inputProps={{ maxLength: 50 }} />
            </div>
            <div className="row mb-3">
              <div className="col-sm-2">Tipo pessoa:</div>
              <Select className='col' value={formData.TIPOPESSOA.trim()} size='small' name='TIPOPESSOA'
                onChange={(event) => handleChangeSelectInput(event, setFormData)}>
                <MenuItem value={"F"}>Física</MenuItem>
                <MenuItem value={"J"}>Jurídica</MenuItem>
              </Select>
              <div className="col-sm-2">Estado:</div>
              <Select
                className='col'
                name='ESTADO'
                value={formData.ESTADO.trim() || ''}
                onChange={(event) => handleChangeSelectInput(event, setFormData)}
                inputProps={{ maxLength: 2 }}
                size='small'
              >
                <MenuItem value="">- Select state -</MenuItem>
                <MenuItem value="AC">AC</MenuItem>
                <MenuItem value="AL">AL</MenuItem>
                <MenuItem value="AP">AP</MenuItem>
                <MenuItem value="AM">AM</MenuItem>
                <MenuItem value="BA">BA</MenuItem>
                <MenuItem value="CE">CE</MenuItem>
                <MenuItem value="DF">DF</MenuItem>
                <MenuItem value="ES">ES</MenuItem>
                <MenuItem value="GO">GO</MenuItem>
                <MenuItem value="MA">MA</MenuItem>
                <MenuItem value="MT">MT</MenuItem>
                <MenuItem value="MS">MS</MenuItem>
                <MenuItem value="MG">MG</MenuItem>
                <MenuItem value="PA">PA</MenuItem>
                <MenuItem value="PB">PB</MenuItem>
                <MenuItem value="PR">PR</MenuItem>
                <MenuItem value="PE">PE</MenuItem>
                <MenuItem value="PI">PI</MenuItem>
                <MenuItem value="RJ">RJ</MenuItem>
                <MenuItem value="RN">RN</MenuItem>
                <MenuItem value="RS">RS</MenuItem>
                <MenuItem value="RO">RO</MenuItem>
                <MenuItem value="RR">RR</MenuItem>
                <MenuItem value="SC">SC</MenuItem>
                <MenuItem value="SP">SP</MenuItem>
                <MenuItem value="SE">SE</MenuItem>
                <MenuItem value="TO">TO</MenuItem>
              </Select>
            </div>
            <div className="row mb-3">
              <div className="col-sm-2">IE/RG:</div>
              <TextField hiddenLabel className='col' size="small" name='INSCRICAOESTADUAL'
                value={formData.INSCRICAOESTADUAL || ''} onChange={(event) => handleChangeTextInput(event, setFormData)} inputProps={{ maxLength: 18 }} />
              <div className="col-sm-2">Insc. Municipal:</div>
              <TextField hiddenLabel className='col' size="small" name='INSCRICAOMUNICIPAL'
                value={formData.INSCRICAOMUNICIPAL || ''} onChange={(event) => handleChangeTextInput(event, setFormData)} inputProps={{ maxLength: 18 }} />
              <FormControlLabel className='col' control={<Checkbox size='small' name='BLOQUEADO'
                checked={formData.BLOQUEADO[0] === "S"} id='0' onChange={handleChangeCheckPag} />}
                label="Bloqueado" />
            </div>
            <div className="row mb-3">
              <div className='col'><SearchGrupos itemName='Grupo' codigoItem={formData.NUMERO_GRUPO}
                setCodigoItem={(value: number) => setFormData(formData => ({ ...formData, 'NUMERO_GRUPO': value }))} /></div>
              <div className='col'> <SearchRegioes itemName='Regiões' codigoItem={formData.REGIAO_ATENDIMENTO}
                setCodigoItem={(value: number) => setFormData(formData => ({ ...formData, 'REGIAO_ATENDIMENTO': value }))} /></div>
            </div>
          </Paper>
        </Tab>
        <Tab eventKey="profile" title="Informações faturamente">
          <Paper elevation={3} sx={{ backgroundColor: '#f5f0f0', p: 3 }}>
            <div className="row mb-3">
              <div className="col ">
                <SearchCondicaoPagamento itemName='Condição Pagamento' codigoItem={formData.NUMERO_CONDPGTO}
                  setCodigoItem={(value: number) => setFormData(formData => ({ ...formData, 'NUMERO_CONDPGTO': value }))} /></div>
              <div className='col'><SearchClasseNegociacao itemName='Classe negociação' codigoItem={formData.NUMERO_CLASSENEGOCIACAO}
                setCodigoItem={(value: number) => setFormData(formData => ({ ...formData, 'NUMERO_CLASSENEGOCIACAO': value }))} /></div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <SearchPlanoContas itemName='Conta receber' codigoItem={formData.ACESSO_CONTACREDITO}
                  setCodigoItem={(value: string) => setFormData(formData => ({ ...formData, 'ACESSO_CONTACREDITO': value }))} />
              </div>
              <div className="col">
                <SearchPlanoContas itemName='Conta recebimento' codigoItem={formData.ACESSO_CONTADEBITO}
                  setCodigoItem={(value: string) => setFormData(formData => ({ ...formData, 'ACESSO_CONTADEBITO': value }))} />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-2">Msg nota fiscal:</div>
              <TextField hiddenLabel className='col' size="small" name='MSGNOTAFISCAL'
                value={formData.MSGNOTAFISCAL || ''} onChange={(event) => handleChangeTextInput(event, setFormData)} inputProps={{ maxLength: 100 }} />
              <div className="col-sm-2">Msg boleto:</div>
              <TextField hiddenLabel className='col' size="small" name='MSGBOLETO'
                value={formData.MSGBOLETO || ''} onChange={(event) => handleChangeTextInput(event, setFormData)} inputProps={{ maxLength: 100 }} />
            </div>
            <div className="row mb-3">
              <div className="col-sm-2">Enviar boleto por email:</div>
              <Select className='col' value={formData.ENVIAEMAILVENDA?.trim() || "N"} size='small' name='ENVIAEMAILVENDA'
                onChange={(event) => handleChangeSelectInput(event, setFormData)}>
                <MenuItem value={"S"}>Sim</MenuItem>
                <MenuItem value={"N"}>Não</MenuItem>
              </Select>
              <div className="col-sm-2">Imprimir boleto individual:</div>
              <Select className='col' value={formData.BOLETOINDIVIDUAL?.trim() || "N"} size='small' name='BOLETOINDIVIDUAL'
                onChange={(event) => handleChangeSelectInput(event, setFormData)}>
                <MenuItem value={"S"}>Sim</MenuItem>
                <MenuItem value={"N"}>Não</MenuItem>
              </Select>
            </div>
            <div className="row mb-3">
              <div className="col-sm-2">Imprimir boleto:</div>
              <Select className='col' value={formData.IMPRIMIRBOLETO?.trim() || "N"} size='small' name='IMPRIMIRBOLETO'
                onChange={(event) => handleChangeSelectInput(event, setFormData)}>
                <MenuItem value={"S"}>Sim</MenuItem>
                <MenuItem value={"N"}>Não</MenuItem>
              </Select>
              <div className="col-sm-2">Imprimir nota fiscal:</div>
              <Select className='col' value={formData.IMPRIMIRNOTAFISCAL?.trim() || "N"} size='small' name='BOLETOINDIVIDUAL'
                onChange={(event) => handleChangeSelectInput(event, setFormData)}>
                <MenuItem value={"S"}>Sim</MenuItem>
                <MenuItem value={"N"}>Não</MenuItem>
              </Select>
            </div>
            <div className="row mb-3">
              <div className="col-sm-2">Observação:</div>
              <TextField hiddenLabel className='col' size="small" name='OBSERVACAO' multiline rows={3}
                value={formData.OBSERVACAO || ''} onChange={(event) => handleChangeTextInput(event, setFormData)} inputProps={{ maxLength: 200 }} />
            </div>
          </Paper>
        </Tab>

        <Tab eventKey="contact" title="Frente de caixa" >
          <Paper elevation={3} sx={{ backgroundColor: '#f5f0f0', p: 3 }}>
            <h5 className='fw-bold'>Nota e cupom fiscal</h5><hr />
            <div className="row mb-3">
              <FormControlLabel className='col' control={<Checkbox size='small' name='NOTAREFERENTEOBRIGATORIA'
                checked={formData.NOTAREFERENTEOBRIGATORIA.trim() === "S"} onChange={(event) => handleChangeCheckInput(event, setFormData)} />}
                label="Emite nota fiscal referente a cupom" />
              <FormControlLabel className='col' control={<Checkbox size='small' name='LIBERAALTERACAODADOSCUPOM'
                checked={formData.LIBERAALTERACAODADOSCUPOM?.trim() === "S"} onChange={(event) => handleChangeCheckInput(event, setFormData)} />}
                label="Permite alteração cupom fiscal" />
              <FormControlLabel className='col' control={<Checkbox size='small' name='SOLICITAREQUISICAO'
                checked={formData.SOLICITAREQUISICAO?.trim() === "S"} onChange={(event) => handleChangeCheckInput(event, setFormData)} />}
                label=" Solicita numero de requisição" />
              <FormControlLabel className='col' control={<Checkbox size='small' name='ENVIAEMAILVENDA'
                checked={formData.ENVIAEMAILVENDA?.trim() === "S"} onChange={(event) => handleChangeCheckInput(event, setFormData)} />}
                label=" Envia email de venda" />
            </div>
            <h5 className='fw-bolder'>Forma de pagamentos habilitadas</h5> <hr />
            <div className="row mb-3">

              <FormControlLabel className='col' control={<Checkbox size='small' name='TIPOSPAGAMENTOSLIBERADOS'
                checked={formData.TIPOSPAGAMENTOSLIBERADOS[0] === "S"} id='0' onChange={handleChangeCheckPag} />}
                label="Crédito" />
              <FormControlLabel className='col' control={<Checkbox size='small' name='TIPOSPAGAMENTOSLIBERADOS'
                checked={formData.TIPOSPAGAMENTOSLIBERADOS[1] === "S"} id='1' onChange={handleChangeCheckPag} />}
                label="Débito" />
              <FormControlLabel className='col' control={<Checkbox size='small' name='TIPOSPAGAMENTOSLIBERADOS'
                checked={formData.TIPOSPAGAMENTOSLIBERADOS[2] === "S"} id='2' onChange={handleChangeCheckPag} />}
                label="Fininvest" />
              <FormControlLabel className='col' control={<Checkbox size='small' name='TIPOSPAGAMENTOSLIBERADOS'
                checked={formData.TIPOSPAGAMENTOSLIBERADOS[3] === "S"} id='3' onChange={handleChangeCheckPag} />}
                label="Cheque" />
              <FormControlLabel className='col' control={<Checkbox size='small' name='TIPOSPAGAMENTOSLIBERADOS'
                checked={formData.TIPOSPAGAMENTOSLIBERADOS[4] === "S"} id='4' onChange={handleChangeCheckPag} />}
                label="Dinheiro" />
              <FormControlLabel className='col' control={<Checkbox size='small' name='TIPOSPAGAMENTOSLIBERADOS'
                checked={formData.TIPOSPAGAMENTOSLIBERADOS[5] === "S"} id='5' onChange={handleChangeCheckPag} />}
                label="Antecipado" />
              <FormControlLabel className='col' control={<Checkbox size='small' name='TIPOSPAGAMENTOSLIBERADOS'
                checked={formData.TIPOSPAGAMENTOSLIBERADOS[6] === "S"} id='6' onChange={handleChangeCheckPag} />}
                label="Carta frete" />
            </div>

            <div className='row'>
              <div className="col">
                <h5 className='fw-bold'>Campos obrigatórios</h5><hr />
                <div className="row mb-3">

                  <FormControlLabel className='col' control={<Checkbox size='small' name='CAMPOSADICIONAISCUPOM'
                    checked={formData.CAMPOSADICIONAISCUPOM[0] === "S"} id='0' onChange={handleChangeCheckPag} />}
                    label="Placa" />
                  <FormControlLabel className='col' control={<Checkbox size='small' name='CAMPOSADICIONAISCUPOM'
                    checked={formData.CAMPOSADICIONAISCUPOM[1] === "S"} id='1' onChange={handleChangeCheckPag} />}
                    label="KM" />
                  <FormControlLabel className='col' control={<Checkbox size='small' name='CAMPOSADICIONAISCUPOM'
                    checked={formData.CAMPOSADICIONAISCUPOM[2] === "S"} id='2' onChange={handleChangeCheckPag} />}
                    label="Motoristas" />
                </div>
              </div>
              <div className="col">
                <h5 className='fw-bold'>Bloqueio automatico</h5><hr />
                <div className="row mb-3">

                  <FormControlLabel className='col' control={<Checkbox size='small' name='BLOQUEIOAUTOMATICO'
                    checked={formData.BLOQUEIOAUTOMATICO[0] === "S"} id='0' onChange={handleChangeCheckPag} />}
                    label="Contas a receber" />
                  <FormControlLabel className='col' control={<Checkbox size='small' name='BLOQUEIOAUTOMATICO'
                    checked={formData.BLOQUEIOAUTOMATICO[1] === "S"} id='1' onChange={handleChangeCheckPag} />}
                    label="Cupons" />
                </div>
              </div></div>
            <h5 className='fw-bolder'>Condição de pagamento</h5> <hr />
            <div className="row mb-3">
              <FormControlLabel className='col' control={<Radio size='small' name='CONDICAOPAGAMENTOVENDA' value={1}
                checked={formData.CONDICAOPAGAMENTOVENDA === 1} id='1' onChange={handleChangeRadio} />}
                label="Somente à vista" />
              <FormControlLabel className='col' control={<Radio size='small' name='CONDICAOPAGAMENTOVENDA' value={2}
                checked={formData.CONDICAOPAGAMENTOVENDA === 2} id='1' onChange={handleChangeRadio} />}
                label=" Somente a prazo" />
              <FormControlLabel className='col' control={<Radio size='small' name='CONDICAOPAGAMENTOVENDA' value={3}
                checked={formData.CONDICAOPAGAMENTOVENDA === 3} id='1' onChange={handleChangeRadio} />}
                label="Seleção no momento da venda" />
            </div>
            <hr />
            <div className='row'>
              <div className="col">

                <div className="">
                  <div className="">Mensagem cupom fiscal:</div>
                  <TextField hiddenLabel className='col' size="small" name='MENSAGEMCUPOMFISCAL'
                    value={formData.MENSAGEMCUPOMFISCAL || ''} onChange={(event) => handleChangeTextInput(event, setFormData)} inputProps={{ maxLength: 300 }} />

                  <div className="mb-3">
                    <div className="">Mensagem nota fiscal referente cupom:</div>
                    <TextField hiddenLabel className='col' size="small" name='MENSAGEMNOTAREFERENTE'
                      value={formData.MENSAGEMNOTAREFERENTE} onChange={(event) => handleChangeTextInput(event, setFormData)} inputProps={{ maxLength: 300 }} />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="row mb-3">
                  <div className="col-sm-2">Observação:</div><br />
                  <TextField hiddenLabel className='' name='OBSERVACAOFRENTECAIXA' multiline rows={3}
                    value={formData.OBSERVACAOFRENTECAIXA} onChange={(event) => handleChangeTextInput(event, setFormData)} inputProps={{ maxLength: 300 }} /> </div>
              </div>

            </div>
          </Paper>
        </Tab>
      </Tabs>
      <Stack direction='row' justifyContent={'end'} spacing={0.5} mt={1}>
        <Button variant='contained' size='small' color='secondary' endIcon={<Undo />}
          onClick={() => setFormData({ ...props.data })}
        >Restaurar</Button>
        <Button variant='contained' size='small' color='success' endIcon={<Send />}
          onClick={() => updateCliente()}
        >Aplicar</Button>
      </Stack>

    </div>

  )
}

export default ClienteUpdate