import { RelatorioDiaSemana, RelatorioFormasPag } from './interfacesPDF'
import { useGlobalState } from '../../../../globalState'
import TableSort from '../../../../utilities/TableSortTS'
import { useMemo } from 'react'
import { toFixedNumber,toMoneyFormat } from '../../../../utilities/helperFunctions'
import PrintFooter from "../../printFooter";

interface IProps {
    data: RelatorioDiaSemana['data']
    date: { dataInicial: string, dataFinal: string }
    options?: { filiaisSelecionadas: string[] }
}

function ResumoDiaSemanaPDF(props: IProps) {
    const [empresaAtual] = useGlobalState('empresaAtual')

    const columns = useMemo(
        () => [
            {
                Header: " ",
                columns: [
                    {
                        Header: "Empresa",
                        accessor: "FANTASIA",
                        Footer:''
                    }, 
                    {
                        Header: "Total",
                        accessor: "TOTALEMPRESA",
                        Cell: ({ value }: { value: string }) => toMoneyFormat(value)  
                    },
                    {
                        Header: "%Total",
                        accessor: "PORCDIASEMANAEMPRESA",
                        Cell: ({ value }: { value: string }) => Number(value).toFixed(2),
                        disableSortBy: true,
                        Footer:''
                    },
                    {
                        Header: "%Geral",
                        accessor: "PORCVALOR",
                        Cell: ({ value }: { value: string }) => Number(value).toFixed(2),
                        disableSortBy: true,
                        Footer:''
                    },
                    
                    
                ],
                Footer: ()=><></>
            }
        ], [])

    const formasPag = props.data.reduce((acc,item)=>{
        if(acc.findIndex(x=>x.DIASEMANA === item.DIASEMANA) === -1)acc.push(
            {DIASEMANA:item.DIASEMANA,PORCDIASEMANA:item.PORCDIASEMANA,TOTALDIASEMANA:item.TOTALDIASEMANA})
        return acc
        
    },[] as {DIASEMANA:string,PORCDIASEMANA:string,TOTALDIASEMANA:string}[])

    return (
        <div className='container'>
            <div className='pdf' id='section-to-print'>
                <h5>{empresaAtual.nome}</h5>
                <h6>Multiloja &gt; Resumo valores de venda &gt; Resumido &gt; Dia da semana - Periodo: {props.date.dataInicial} a {props.date.dataFinal}</h6>
                <hr />
                <p style={{ fontSize: '12px' }}>{props.options?.filiaisSelecionadas.map(item => item + ' | ')}</p>
                <hr />
                {formasPag.map(item=>
                    <>
                    <h6>{item.DIASEMANA}</h6>
                    <div className="table-responsive">
                     <TableSort columns={columns} data={props.data.filter(i=>i.DIASEMANA === item.DIASEMANA)}></TableSort>
                    </div>
                    <p className='fw-bold text-end'>
                       {` Total valor: ${toMoneyFormat(item.TOTALDIASEMANA)}(${toFixedNumber(item.PORCDIASEMANA)}%)`}</p>
                    </>
                    )}
                    <h6 className='fw-bold text-end'>Total: {toMoneyFormat(props.data[0].TOTALVALOR)}</h6>
                    <PrintFooter/>
            </div>
        </div>
    )
}

export default ResumoDiaSemanaPDF