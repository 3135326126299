import { IconButton, Stack } from '@mui/material'
import { Print, ArrowBack, TableView, InsertChart } from '@mui/icons-material'
import { createExcelFile } from "../../utilities/tableToExcel";

import TodasAPagarPDF from "./ContasAPagar/todasAPagarPDF"
import PagamentosPDF from "./ContasAPagar/pagamentosPDF"
import DespesasPDF from "./ContasAPagar/despesasPDF"
import DebitoTotalPDF from "./Clientes/debitoTotalPDF"
import NotasEntradaPDF from "./Entrada/notasEntradaPDF"
import EstoqueFinalMovimentoPDF from "./Estoque/estoqueFinalMovimentoPDF"
import ResumoValoresVendaPDF from "./Vendas/resumoValoresVendaPDF"
import MultilojaResumo from "./multiLoja/PDFs/index"
import LucroPDF from './Vendas/LucroPDFs/index.tsx'
import AbastecimentoPDF from "./Vendas/AbastecimentoPDFs/index"
import MultiEmpresaValoresVendaPDF from "./multiEmpresa"
import VendasDoDiaPDF from "./Vendas/vendasDiaPDF"
import EstoqueTanquePDF from "./Estoque/tanquePDF"
import VendasProdutosPDF from './Vendas/vendasProdutosPDF'
import AfericaoPDF from './Estoque/afericaoPDF'
import CombustiveisPDF from './multiLoja/combustiveis/PDFs';
import VendasClientesPDF from './multiLoja/vendaClientes/vendasClientesPDF';
import { useState } from 'react';

function PDFsRoutes(props) {
    const [btnTable, setBtnTable] = useState(true)//

    const components = {
        todasapagar: TodasAPagarPDF,
        pagamentos: PagamentosPDF,
        despesas: DespesasPDF,
        debitototal: DebitoTotalPDF,
        notasentrada: NotasEntradaPDF,
        estoquefinalmovimento: EstoqueFinalMovimentoPDF,
        resumovaloresvenda: ResumoValoresVendaPDF,
        multilojaresumovaloresvenda: MultilojaResumo,
        lucro: LucroPDF,
        vendasdia: VendasDoDiaPDF,
        abastecimento: AbastecimentoPDF,
        multiempresaresumovaloresvenda: MultiEmpresaValoresVendaPDF,
        tanque: EstoqueTanquePDF,
        vendasprodutos: VendasProdutosPDF,
        afericao: AfericaoPDF,
        multilojacombustiveis:CombustiveisPDF,
        multilojavendasclientes:VendasClientesPDF
       
    };
    const Component = components[props.componentName];

    return (
        <div>
            <Stack direction="row" alignItems="center" spacing={0.5} sx={{ ml: 10 }}  flexWrap="wrap">
                <div style={{ display: 'inline-block' }} data-tooltip="Voltar" data-tooltip-location="bottom" >
                    <IconButton size="large" color="success" onClick={() => props.close()}>
                        <ArrowBack fontSize="inherit" /></IconButton>
                </div><br />
                <div style={{ display: 'inline-block' }} data-tooltip="Imprimir" data-tooltip-location="bottom">
                    <IconButton size="large" color="primary" onClick={() => window.print()}>
                        <Print fontSize="inherit" /></IconButton> </div>
                <div style={{ display: 'inline-block' }} data-tooltip="Converter para excel" data-tooltip-location="bottom">
                    <IconButton size="large" color="secondary" onClick={() => createExcelFile()}>
                        <TableView fontSize="inherit" /></IconButton> </div>
                <div id='addBtn1'  style={{ display: 'inline-block' }} data-tooltip="Mostrar/Esconder gráfico quando disponível" data-tooltip-location="bottom">
                    <IconButton size="large" color="warning" onClick={() => setBtnTable(value => !value)}>
                        <InsertChart fontSize="inherit" /></IconButton>
                </div>
                <div id='agrupamentoOptions'  style={{ display: 'inline-block' }}>
                    
                </div>
            </Stack>
            <div>
                {Component && <Component {...props.PDFData}
                    btnTable={btnTable} />}
            </div>
        </div>
    )

}

export default PDFsRoutes