import ResumoGeralPDF from "./resumoGeralPDF"
import ResumoDiaPDF from "./resumoDiaPDF"
import ResumoMesPDF from "./resumoMesPDF"
import ResumoAnoPDF from "./resumoAnoPDF"
import ResumoDetalhado from "./resumoDetalhadoPDF"
import ResumoFormasPagPDF from "./resumoFormasPagPDF"
import ResumoDiaSemanaPDF from "./resumoDiaSemanaPDF"
import ResumoGrupoProdutoPDF from "./resumoGrupoProdutoPDF"
import {ResumoData} from './interfacesPDF'


function ResumoPDF(props:ResumoData){
   const PDF = ()=>{
    if(props.relatorio.tipo === 'relatorioDetalhado')return <ResumoDetalhado data={props.relatorio.data} date={props.date} options={props.options}/>
    if(props.relatorio.tipo === 'relatorioGeral')return <ResumoGeralPDF data={props.relatorio.data} date={props.date} options={props.options}/>
    if(props.relatorio.tipo === 'relatorioMes')return <ResumoMesPDF {...props} data={props.relatorio.data} date={props.date} options={props.options}/>
    if(props.relatorio.tipo === 'relatorioAno')return <ResumoAnoPDF data={props.relatorio.data} date={props.date} options={props.options}/>
    if(props.relatorio.tipo === 'relatorioDia')return <ResumoDiaPDF data={props.relatorio.data} date={props.date} options={props.options}/>
    if(props.relatorio.tipo === 'relatorioFormasPag')return <ResumoFormasPagPDF data={props.relatorio.data} date={props.date} options={props.options}/>
    if(props.relatorio.tipo === 'relatorioDiaSemana')return <ResumoDiaSemanaPDF data={props.relatorio.data} date={props.date} options={props.options}/>
    if(props.relatorio.tipo === 'relatorioGrupoProduto')return <ResumoGrupoProdutoPDF data={props.relatorio.data} date={props.date} options={props.options}/>
   }
   return (
    <div>{PDF()}</div>
   )

}
export default ResumoPDF