import { Tabs, Tab } from "react-bootstrap"
import {Paper} from '@mui/material'

function InfoClientes(props) {

  let Clientes = {...props.data}
  const handleNull = ()=>{
     //Troca campos Null por 一
    
      for(let[key,value] of Object.entries(Clientes)){
      if(value === null)Clientes[key] = '一'
    }
  }
  handleNull()


  return (


    <div>
      <div className=".arrow-left "><i onClick={() => props.return()} className="fad fa-arrow-to-left arrow-left "></i></div>
      <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
        <Tab eventKey="home" title="Dados pessoais">
        <Paper elevation={3} className='p-2' sx={{backgroundColor:'#f5f0f0'}}>
            <div className="row mb-3">
              <div className="col-sm-2">Nome:</div>
              <div className="col text-muted">{Clientes.NOME}</div>
              <div className="col-sm-2">Numero:</div>
              <div className="col text-muted">{Clientes.NUMERO}</div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-2">Razão social:</div>
              <div className="col text-muted">{Clientes.RAZAOSOCIAL}</div>
              <div className="col-sm-2">Codigo SAP:</div>
              <div className="col text-muted">{Clientes.CODIGORETAGUARDA}</div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-2 ">CNPJ/CPF:</div>
              <div className="col text-muted">{Clientes.CNPJ}</div>
              <div className="col-sm-2">Email:</div>
              <div className="col text-muted">{Clientes.EMAIL}</div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-2">Contato:</div>
              <div className="col text-muted">{Clientes.CONTATO}</div>
              <div className="col-sm-2">Contato:</div>
              <div className="col text-muted">{Clientes.CONTATO2}</div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-2">Tipo pessoa:</div>
              <div className="col text-muted">{Clientes.TIPOPESSOA.trim() === 'F' ? 'Fisica' : 'Juridica'}</div>
              <div className="col-sm-2">Estado:</div>
              <div className="col text-muted">{Clientes.ESTADO}</div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-2">IE/RG:</div>
              <div className="col text-muted">{Clientes.INSCRICAOESTADUAL}</div>
              <div className="col-sm-2">Insc. Municipal:</div>
              <div className="col text-muted">{Clientes.INSCRICAOMUNICIPAL}</div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-2">Grupo:</div>
              <div className="col text-muted">{Clientes.NUMERO_GRUPO + '-' + Clientes.NOME_GRUPO}</div>
              <div className="col-sm-2">Região At.:</div>
              <div className="col text-muted">{Clientes.REGIAO_ATENDIMENTO + '-' + Clientes.NOME_REGIAOATENDIMENTO}</div>
            </div>
      </Paper>
        </Tab>
        <Tab eventKey="profile" title="Informações faturamente">
        <Paper elevation={3} className='p-2' sx={{backgroundColor:'#f5f0f0'}}>
          <div className="row mb-3">
            <div className="col-sm-2">Cond. Pgto:</div>
            <div className="col text-muted">{Clientes.NUMERO_CONDPGTO + '-' + Clientes.DESC_CONDPGTO}</div>
            <div className="col-sm-2">Classe negociação:</div>
            <div className="col text-muted">{Clientes.NUMERO_CLASSENEGOCIACAO + '-' + Clientes.DESCRICAO_CLASSENEGOCIACAO}</div>
          </div>
          <div className="row mb-3">
            <div className="col-sm-2">Conta receber:</div>
            <div className="col text-muted">{Clientes.ACESSO_CONTACREDITO + '-' + Clientes.CONTACREDITO}</div>
            <div className="col-sm-2">Conta recebimento:</div>
            <div className="col text-muted">{Clientes.ACESSO_CONTADEBITO + '-' + Clientes.CONTADEBITO}</div>
          </div>
          <div className="row mb-3">
            <div className="col-sm-2">Msg nota fiscal:</div>
            <div className="col text-muted">{Clientes.MSGNOTAFISCAL}</div>
            <div className="col-sm-2">Msg boleto:</div>
            <div className="col text-muted">{Clientes.MSGBOLETO}</div>
          </div>
          <div className="row mb-3">
            <div className="col-sm-2">Enviar boleto por email:</div>
            <div className="col text-muted">{Clientes.ENVIAEMAILVENDA?.trim() === "S" ? 'SIM' : "NÃO"}</div>
            <div className="col-sm-2">Imprimir boleto individual:</div>
            <div className="col text-muted">{Clientes.BOLETOINDIVIDUAL?.trim() === "S" ? 'SIM' : "NÃO"}</div>
          </div>
          <div className="row mb-3">
            <div className="col-sm-2">Imprimir boleto:</div>
            <div className="col text-muted">{Clientes.IMPRIMIRBOLETO?.trim()  === "S" ? 'SIM' : "NÃO"}</div>
            <div className="col-sm-2">Imprimir nota fiscal:</div>
            <div className="col text-muted">{Clientes.IMPRIMIRNOTAFISCAL?.trim()  === "S" ? 'SIM' : "NÃO"}</div>
          </div>
          <div className="row mb-3">
            <div className="col-sm-2">Observação:</div>
            <div className="col text-muted">{Clientes.OBSERVACAO}</div>
         </div>
          </Paper>
        </Tab>
       
        <Tab eventKey="contact" title="Frente de caixa" >
        <Paper elevation={3} className='p-2' sx={{backgroundColor:'#f5f0f0'}}>
          <h5 className='fw-bold'>Nota e cupom fiscal</h5><hr />
          <div className="row mb-3">
            <div className="col-sm-auto"> <div className="form-check "> <input className="form-check-input green-checked" readOnly type="checkbox" value="" id="flexCheckChecked" checked={Clientes.NOTAREFERENTEOBRIGATORIA.trim() === 'S' ? true : false} />
              <label className="form-check-label" htmlFor="flexCheckChecked">
                Emite nota fiscal referente a cupom</label></div></div>
            <div className="col-sm-auto"> <div className="form-check "> <input className="form-check-input green-checked" readOnly type="checkbox" value="" id="flexCheckChecked" checked={Clientes.LIBERAALTERACAODADOSCUPOM === 'S' ? true : false} />
              <label className="form-check-label" htmlFor="flexCheckChecked">
                Permite alteração cupom fiscal</label></div></div>
            <div className="col-sm-auto"> <div className="form-check "> <input className="form-check-input green-checked" readOnly type="checkbox" value="" id="flexCheckChecked" checked={Clientes.SOLICITAREQUISICAO === 'S' ? true : false } />
              <label className="form-check-label" htmlFor="flexCheckChecked">
                Solicita numero de requisição</label></div></div>
            <div className="col-sm-auto"> <div className="form-check "> <input className="form-check-input green-checked" readOnly type="checkbox" value="" id="flexCheckChecked" checked={Clientes.ENVIAEMAILVENDA === 'S' ? true : false } />
              <label className="form-check-label" htmlFor="flexCheckChecked">
               Envia email de venda</label></div></div>
          </div>
          <h5 className='fw-bolder'>Forma de pagamentos habilitadas</h5> <hr/>
          <div className="row mb-3">
            <div className="col-sm-auto"> <div className="form-check "> <input className="form-check-input green-checked" readOnly type="checkbox" value="" id="flexCheckChecked" checked={Clientes.TIPOSPAGAMENTOSLIBERADOS[0] === 'S' ? true : false} />
              <label className="form-check-label" htmlFor="flexCheckChecked">
                Crédito</label></div></div>
            <div className="col-sm-auto"> <div className="form-check "> <input className="form-check-input green-checked" readOnly type="checkbox" value="" id="flexCheckChecked" checked={Clientes.TIPOSPAGAMENTOSLIBERADOS[1] === 'S' ? true : false} />
              <label className="form-check-label" htmlFor="flexCheckChecked">
               Débito</label></div></div>
            <div className="col-sm-auto"> <div className="form-check "> <input className="form-check-input green-checked" readOnly type="checkbox" value="" id="flexCheckChecked" checked={Clientes.TIPOSPAGAMENTOSLIBERADOS[2] === 'S' ? true : false} />
              <label className="form-check-label" htmlFor="flexCheckChecked">
               Fininvest</label></div></div>
            <div className="col-sm-auto"> <div className="form-check "> <input className="form-check-input green-checked" readOnly type="checkbox" value="" id="flexCheckChecked" checked={Clientes.TIPOSPAGAMENTOSLIBERADOS[3] === 'S' ? true : false} />
              <label className="form-check-label" htmlFor="flexCheckChecked">
              Cheque</label></div></div>
               <div className="col-sm-auto"> <div className="form-check "> <input className="form-check-input green-checked" readOnly type="checkbox" value="" id="flexCheckChecked" checked={Clientes.TIPOSPAGAMENTOSLIBERADOS[4] === 'S' ? true : false} />
              <label className="form-check-label" htmlFor="flexCheckChecked">
              Dinheiro</label></div></div>
            <div className="col-sm-auto"> <div className="form-check "> <input className="form-check-input green-checked" readOnly type="checkbox" value="" id="flexCheckChecked" checked={Clientes.TIPOSPAGAMENTOSLIBERADOS[5] === 'S' ? true : false} />
              <label className="form-check-label" htmlFor="flexCheckChecked">
               Antecipado</label></div></div>
            <div className="col-sm-auto"> <div className="form-check "> <input className="form-check-input green-checked" readOnly type="checkbox" value="" id="flexCheckChecked" checked={Clientes.TIPOSPAGAMENTOSLIBERADOS[6] === 'S' ? true : false} />
              <label className="form-check-label" htmlFor="flexCheckChecked">
               Carta frete</label></div></div>
          </div>
          
          <div className='row'>
            <div className="col">
            <h5 className='fw-bold'>Campos obrigatórios</h5><hr />
            <div className="row mb-3">
            <div className="col-sm-auto"> <div className="form-check "> <input className="form-check-input green-checked" readOnly type="checkbox" value="" id="flexCheckChecked" checked={Clientes.CAMPOSADICIONAISCUPOM[0] === 'S' ? true : false} />
              <label className="form-check-label" htmlFor="flexCheckChecked">
                Placa</label></div></div>
            <div className="col-sm-auto"> <div className="form-check "> <input className="form-check-input green-checked" readOnly type="checkbox" value="" id="flexCheckChecked" checked={Clientes.CAMPOSADICIONAISCUPOM[1] === 'S' ? true : false} />
              <label className="form-check-label" htmlFor="flexCheckChecked">
                KM</label></div></div>
            <div className="col-sm-auto"> <div className="form-check "> <input className="form-check-input green-checked" readOnly type="checkbox" value="" id="flexCheckChecked" checked={Clientes.CAMPOSADICIONAISCUPOM[2] === 'S' ? true : false} />
              <label className="form-check-label" htmlFor="flexCheckChecked">
                Motorista</label></div></div>
          </div>
            </div>
            <div className="col">
            <h5 className='fw-bold'>Bloqueio automatico</h5><hr />
            <div className="row mb-3">
            <div className="col-sm-auto"> <div className="form-check "> <input className="form-check-input green-checked" readOnly type="checkbox"  id="flexCheckChecked" checked={Clientes.BLOQUEIOAUTOMATICO[0] === 'S' ? true : false} />
              <label className="form-check-label" htmlFor="flexCheckChecked">
                Contas a receber</label></div></div>
            <div className="col-sm-auto"> <div className="form-check "> <input className="form-check-input green-checked" readOnly type="checkbox"  id="flexCheckChecked" checked={Clientes.BLOQUEIOAUTOMATICO[0] === 'S' ? true : false} />
              <label className="form-check-label" htmlFor="flexCheckChecked">
                Cupons</label></div></div>
          </div>
            </div></div>
            <h5 className='fw-bolder'>Condição de pagamento</h5> <hr />
          <div className="row mb-3">
            <div className="col-sm-auto"> <div className="form-check "> <input className="form-check-input green-checked" readOnly type="radio" value="" id="flexCheckChecked" checked={Clientes.CONDICAOPAGAMENTOVENDA === 1} />
              <label className="form-check-label" htmlFor="flexCheckChecked">
                Somente à vista</label></div></div>
            <div className="col-sm-auto"> <div className="form-check "> <input className="form-check-input green-checked" readOnly type="radio" value="" id="flexCheckChecked" checked={Clientes.CONDICAOPAGAMENTOVENDA === 2} />
              <label className="form-check-label" htmlFor="flexCheckChecked">
               Somente a prazo </label></div></div>
            <div className="col-sm-auto"> <div className="form-check "> <input className="form-check-input green-checked" readOnly type="radio" value="" id="flexCheckChecked" checked={Clientes.CONDICAOPAGAMENTOVENDA === 3} />
              <label className="form-check-label" htmlFor="flexCheckChecked">
               Seleção no momento da venda</label></div></div>
          </div>
<hr />
          <div className='row'>
            <div className="col">
            
            <div className="row mb-3">
            <div className="mb-3">
    <label htmlFor="exampleInputEmail1" className="form-label fw-bold">Mensagem cupom fiscal:</label>
    <input type="text" className="form-control" readOnly value={Clientes.MENSAGEMCUPOMFISCAL || ''} id="exampleInputEmail1" aria-describedby="emailHelp"/>
  </div>
  <div className="mb-3">
    <label htmlFor="exampleInput" className="form-label fw-bold">Mensagem nota fiscal referente cupom:</label>
    <input type="text" className="form-control" readOnly value={Clientes.MENSAGEMNOTAREFERENTE || ''} id="exampleInput"/>
  </div>
          </div>
            </div>
            <div className="col">
            <div className="form-group">
    <label htmlFor="exampleFormControlTextarea1" className='fw-bold'>Observação</label>
    <textarea className="form-control" readOnly value={Clientes.OBSERVACAO || ''} id="exampleFormControlTextarea1" rows="3"></textarea>
  </div>
            </div></div>
            </Paper>
        </Tab>
      </Tabs>
    </div>

  )
}

export default InfoClientes