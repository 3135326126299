import * as React from 'react';
import { useState } from 'react'
import axios from '../../../axiosConfig'
import { AxiosError } from 'axios';
import { formatDate } from '../../../utilities/helperFunctions'
import SearchData from "../../../utilities/searchs/searchData"
import { Button, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Paper } from '@mui/material'
import { Send } from '@mui/icons-material'
import Loading from '../../../utilities/loading'
import SearchFiliais from '../../../utilities/searchs/searchFiliais'
import { IfilialAtual, useGlobalState } from '../../../globalState'

interface IProps {
    showPDF: (error: boolean, errorMessageP?: string) => void
    setPDFData: React.Dispatch<React.SetStateAction<{}>>
}

function ExportFormasPag(props: IProps) {
    const [firstDate, setFirstDate] = useState(new Date)
    const [lastDate, setLastDate] = useState(new Date)
    const [tipo, setTipo] = useState('Formas')
    const [isFullLoading, setIsFullLoading] = useState(false)
    const [isSelectingFilial, setIsSelectingFilial] = useState(false)
    const [filialAtual] = useGlobalState('filialAtual')
    const [usedFiliais, setUsedFiliais] = useState<IfilialAtual[]>([filialAtual])


    function formatDateCSV(dateString: string): string {
        const [year, month, day] = dateString.split('-');
        return `${day}.${month}.${year}`;
      }

    function convertToCSV(data: any[]): string {
        const header = Object.keys(data[0]).join(';');
        const rows = data.map((row) => {
          const formattedDate = formatDateCSV(row.DATA_EMISSAO);
          const formattedValue = row.VALORLANCAMENTOS.replace('.',',')
          const rowValues = {...row, DATA_EMISSAO: formattedDate,VALORLANCAMENTOS:formattedValue};
          return Object.values(rowValues).join(';');
        });
        return header + '\n' + rows.join('\n');
      }
      function convertToCSVProdutos(data: any[]): string {
        const header = Object.keys(data[0]).join(';');
        const rows = data.map((row) => {
          const formattedDate = formatDateCSV(row.DATA_EMISSAO);
          const formattedName = row.SEGMENTO.trim()
          const rowValues = {...row, DATA_EMISSAO: formattedDate,SEGMENTO:formattedName};
          return Object.values(rowValues).join(';');
        });
        return header + '\n' + rows.join('\n');
      }



      

      function downloadCSV(data: any[], filename: string) {
        const csvData = tipo === 'Formas' ? convertToCSV(data) : convertToCSVProdutos(data);
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
      
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      }



    const getExport = (filiais:IfilialAtual[]) => {
        setIsSelectingFilial(false)
        setIsFullLoading(true)
        setUsedFiliais(filiais)
        const postData = {
            dataInicial: `${formatDate(firstDate, 'db')}`, dataFinal: `${formatDate(lastDate, 'db')}`,
            tipo: tipo,
            empresas: filiais.map(i => i.NUMERO),
        }
        axios.post('/relatorios/exportar', postData, )
            .then(r => {
                console.log(r.data)
                if (r.data.length < 1) return props.showPDF(true, 'Sem registros para a data informada')
                downloadCSV(r.data,tipo === 'Formas' ? 'Resumo_de_Formas.csv': 'Resumo_de_Produtos')
            }).catch(error => {
                const err = error as AxiosError
                console.log(err.response?.data)
            }).finally(() => setIsFullLoading(false))
    }

    return (
        <div>
            {isFullLoading ? <Loading /> : null}
            <Paper elevation={3} className='p-2' sx={{ backgroundColor: '#f5f0f0' }}>
                <h4 className='relatorio-title'>Exportações:</h4>
                <SearchData setFirstDate={setFirstDate} setLastDate={setLastDate}
                    firstDate={firstDate} lastDate={lastDate} />
                <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">Tipo</FormLabel>
                    <RadioGroup
                        row
                        name="row-radio-buttons-group"
                        value={tipo}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTipo(e.target.value)}
                    >
                        <FormControlLabel value="Formas" control={<Radio />} label="Resumo formas pagamento" />
                        <FormControlLabel value="Produtos" control={<Radio />} label="Resumo produtos" />
                    </RadioGroup>
                </FormControl>

                <br />
                <Button variant='contained' endIcon={<Send />} onClick={() => setIsSelectingFilial(true)}>Gerar</Button>
                {isSelectingFilial ? <SearchFiliais getFiliais={getExport}
                close={(filiais: IfilialAtual[]) => { setIsSelectingFilial(false); setUsedFiliais(filiais) }} lastSelectedFiliais={usedFiliais} /> : null}
            </Paper>
        </div>
    )
}

export default ExportFormasPag