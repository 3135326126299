import React from 'react';
import {Snackbar,Alert, AlertProps} from '@mui/material'
import ReactDOM from 'react-dom';


const MuiAlert = React.forwardRef<HTMLDivElement, AlertProps>(function AlertP(
    props,
    ref,
  ) {
    return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
  });

export function showSnackbar(message: string, severity: 'success' | 'error' | 'info' | 'warning' = 'success') {
  
    const snackbarContainer = document.createElement('div');
    document.body.appendChild(snackbarContainer);
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }ReactDOM.unmountComponentAtNode(snackbarContainer);}
  
    const snackbar = (
    <Snackbar open autoHideDuration={6000} onClose={handleClose}>
      <div>
          <MuiAlert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {message}
          </MuiAlert></div>
    </Snackbar>
  );

  ReactDOM.render(snackbar, snackbarContainer);
}
