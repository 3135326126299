import { useState } from "react"
import SearchData from "../../../utilities/searchs/searchData"
import Loading from '../../../utilities/loading'
import axios from '../../../axiosConfig'
import {formatDate} from '../../../utilities/helperFunctions'
import {Button,Paper} from '@mui/material'
import {Send} from '@mui/icons-material'

function NotasEntrada(props){
    const [firstDate,setFirstDate] = useState(new Date)
    const [lastDate,setLastDate] = useState(new Date)
    const [isFullLoading,setIsFullLoading] = useState(false)

    const getEntradas = ()=>{
        setIsFullLoading(true)
        const postData = {
            dataInicial:`${formatDate(firstDate,'db')}`,
            dataFinal:`${formatDate(lastDate,'db')}`
        }
        axios.post('/relatorios/entradas/notasentradas',postData)
        .then((r)=>{
            if(r.data.length < 1)return props.showPDF(true,'Sem notas de entradas')
           
            let data = {data:r.data,date:{firstDate:formatDate(firstDate,'front'),lastDate:formatDate(lastDate,'front')}}
            props.setPDFData(data)
            props.showPDF(false)
        }).catch(err=>props.showPDF(true, 'Erro ao buscar informação')).finally(()=>setIsFullLoading(false))
        


    }

    return(
        <div>
        {isFullLoading ? <Loading></Loading> : null}
        <Paper elevation={3} className='p-2' sx={{backgroundColor:'#f5f0f0'}}>
        <h4 className='relatorio-title'>Notas de entrada:</h4>
        <SearchData setFirstDate={setFirstDate} setLastDate={setLastDate} 
        firstDate={firstDate} lastDate={lastDate}/>
        <Button variant='contained' endIcon={<Send/>} onClick={()=>getEntradas()}>Gerar</Button>
        </Paper>
        
        </div>
       
    )
}

export default NotasEntrada