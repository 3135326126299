import { useState } from "react"
import { Modal } from 'react-bootstrap'
import { useGlobalState, setGlobalState, IEmpresa } from '../../globalState'
import { List, ListItemText, ListItemButton, ListItemIcon, Radio, Button, Typography, TextField, ListItemSecondaryAction } from '@mui/material'
import { Send, Close, ChangeCircle,Wifi, WifiOff } from '@mui/icons-material'
import HandleFiliais from "./filial"
import { LoginUsuarioFirebird } from "./loginUsuarioFirebird"
import { useLocation } from "react-router-dom"

function HandleEmpresas() {
  const [isSearching, setIsSearching] = useState(false)
  const [showFiliais, setShowFiliais] = useState(false)
  const [empresaAtual] = useGlobalState('empresaAtual')
  const [empresas] = useGlobalState('empresas')
  const [selectedEmpresa, setSelectedEmpresa] = useState(empresaAtual)
  const [isValidatingUser, setIsValidatingUser] = useState(false)
  const location = useLocation()


  const changeEmpresa = () => {
    window.localStorage.setItem('empresaID', JSON.stringify(selectedEmpresa.id))
    window.localStorage.setItem('filial', JSON.stringify(1))
    localStorage.removeItem('usuarioFirebird')
    setGlobalState('empresaAtual', selectedEmpresa)
  }

  const concluir = () => {
    if (selectedEmpresa.id !== empresaAtual.id) changeEmpresa()
    else setSelectedEmpresa(empresaAtual)
    setIsSearching(false)
  }

  const hasDuplicateConfigurations = (empresas: IEmpresa[], empresaAtual: IEmpresa) => {
    for (const empresa of empresas) {
      if (
        empresa.id !== empresaAtual.id &&
        empresa.host === empresaAtual.host &&
        empresa.porta === empresaAtual.porta &&
        empresa.caminho === empresaAtual.caminho
      ) {
        return true;
      }
    }
    return false;
  };

  return (
    <div className="me-auto">
      {showFiliais ? <HandleFiliais close={() => {
        setShowFiliais(false)
        setIsSearching(false)
      }}></HandleFiliais> : null}

      {isValidatingUser ?
        <LoginUsuarioFirebird return={() => setIsValidatingUser(false)} success={() => {
          setIsValidatingUser(false)
          setIsSearching(false)
        }} empresa={selectedEmpresa} />
        :
        <Modal
          animation={false}
          centered
          show={isSearching}
          backdrop="static"
          keyboard={true}
          size="lg"
        >
          <Modal.Body>
            <List component="nav" aria-label="nav">
              {empresas.map((empresa) =>
                <ListItemButton key={empresa.id} dense sx={{ paddingBottom: '0px', paddingTop: '0px' }}
                  selected={selectedEmpresa.id === empresa.id ? true : false}
                  onClick={() => setSelectedEmpresa(empresa)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") concluir()
                  }}
                >
                  <ListItemIcon>
                    <Radio checked={selectedEmpresa.id === empresa.id ? true : false} />
                  </ListItemIcon>
                  <ListItemText primary={empresa.fantasia}
                    primaryTypographyProps={{
                      fontSize: 16, fontWeight: '600'
                    }}
                    secondary={<>{empresa.cnpj}
                      <Typography sx={{ display: 'inline', fontSize: 14 }} color={"purple"}>
                        {empresa.filiais?.length > 1 && !hasDuplicateConfigurations(empresas, empresa) ? ` - Filiais: ${empresa.filiais?.length - 1}` : ``}
                      </Typography></>}
                    secondaryTypographyProps={{
                      color: 'orange', fontSize: 14
                    }} />
                  <ListItemSecondaryAction>
                    {empresa.filiais.length > 0 ? <Wifi color='success'/> : <WifiOff color='error' />}
                  </ListItemSecondaryAction>
                </ListItemButton>
              )}
            </List>
          </Modal.Body>
          <Modal.Footer style={{ padding: '0.15rem', backgroundColor: '#bdc3c7' }}>
            <>
              <Button variant='contained' color='error' endIcon={<Close />} sx={{ m: 0.5 }}
                onClick={() => {
                  setIsSearching(false)
                  setSelectedEmpresa(empresaAtual)
                }
                }>
                Fechar
              </Button>
              {location.pathname.includes('adm') &&
                <Button variant='contained' color='secondary' endIcon={<ChangeCircle />} sx={{ m: 0.5 }}
                  disabled={selectedEmpresa.id !== empresaAtual.id ? true : false}
                  onClick={() => {
                    setIsValidatingUser(true)
                  }
                  }>
                  Trocar usuario
                </Button>
              }
              <Button variant='contained' color='secondary' endIcon={<ChangeCircle />} sx={{ m: 0.5 }}
                disabled={empresaAtual?.filiais?.length > 1 && !hasDuplicateConfigurations(empresas, empresaAtual) ? false : true}
                onClick={() => {
                  setShowFiliais(true)
                }
                }>
                Selecionar Filial
              </Button>
              <Button variant='contained' color='success' endIcon={<Send />} sx={{ m: 0.5 }} onClick={() => concluir()}>
                Concluir
              </Button>
            </>
          </Modal.Footer>
        </Modal>}


      <div className="container-change-empresa"
        data-tooltip="Trocar empresa" data-tooltip-location="bottom">
        <div className="container-change-empresa-iconbutton">
          <i className="fa-solid fa-arrow-up-arrow-down" onClick={() => setIsSearching(true)}></i>
        </div>

      </div>

    </div>

  )
}

export default HandleEmpresas