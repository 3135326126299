import { GeralMes, IDate } from '../interfacesPDF'
import { useGlobalState } from '../../../../../globalState'
import { toMoneyFormat, toMonthName } from '../../../../../utilities/helperFunctions'
import TableSort from '../../../../../utilities/TableSortTS'
import {  useMemo } from 'react'
import PrintFooter from "../../../printFooter";

interface IProps {
    data: GeralMes[]
    date: IDate
    options?: { filiaisSelecionadas: string[] }
}

export default function GeralMesPDF(props: IProps) {
    const [empresaAtual] = useGlobalState('empresaAtual')

    const columns = useMemo(
        () => [
            {
                Header: " ",
                columns: [
                    {
                        Header: "Empresa",
                        accessor: "FANTASIA",
                        Footer: ''
                    },
                    {
                        Header: "P medio",
                        accessor: "PREMEDMES",
                        Cell: ({ value }: { value: string }) => toMoneyFormat(value,4),
                        Footer: ''
                    },
                    {
                        Header: "P medio periodo",
                        accessor: "PREMEDPERIODO",
                        Cell: ({ value }: { value: string }) => toMoneyFormat(value,4),
                        Footer: ''
                    },
                    {
                        Header: "Quantidade",
                        accessor: "QTDEMPRESAPROD",
                        Cell: ({ value }: { value: string }) => Number(value).toFixed(3),
                        Footer: ''
                    },
                    {
                        Header: "Total",
                        accessor: "VALORPRODEMPRESAPROD",
                        Cell: ({ value }: { value: string }) => toMoneyFormat(value),
                    },
                ],
                Footer: () => <></>
            }
        ], [])


    const products = props.data.reduce((acc: Record<string, Record<string, GeralMes[]>>, item) => {
        const month = toMonthName(item.MES)
        if (acc[month]) {
            if (acc[month][item.NOME_PRODUTO]) {
                acc[month][item.NOME_PRODUTO].push(item);
            } else {
                acc[month][item.NOME_PRODUTO] = [item];
            }
        } else {
            acc[month] = {
                [item.NOME_PRODUTO]: [item]
            }
        }
        return acc;
    }, {});
    console.log(products)


    return (
        <div className='container'>
            <div className='pdf' id='section-to-print'>
                <h5>{empresaAtual.nome}</h5>
                <h6>Multiloja &gt; Geral - Periodo: {props.date.dataInicial} a {props.date.dataFinal}</h6>
                <hr />

                <p style={{ fontSize: '12px' }}>{props.options?.filiaisSelecionadas.map(item => item + ' | ')}</p>
                <hr />
                {Object.keys(products).map(month => (
                    <div key={month}>
                        <h5 className='text-uppercase'>{month}</h5>
                        {Object.keys(products[month]).map(product => (
                            <div key={product}>
                                <h6>{product}</h6>
                                <TableSort columns={columns} data={products[month][product]}/>
                                <p className='text-end fw-bold'>Total produto | Quantidade {Number(products[month][product][0].TOTALQTDMESPROD).toFixed(3)} Valor: {toMoneyFormat(products[month][product][0].TOTALVALORMESPROD, 2)}</p>
                            </div>
                        ))}
                        <h6 className='text-end fw-bold'>Total mês {month} | Quantidade: {Number(products[month][Object.keys(products[month])[0]][0].TOTALQTDMES).toFixed(3)} Valor: {toMoneyFormat(Object.values(products[month]).reduce((acc, curr) => acc + Number(curr[0].TOTALVALORMESPROD), 0), 2)}</h6>
                        <hr />
                    </div>
                ))}

                <h6 className='text-end fw-bold'>Total | Quantidade: {Number(props.data[0].TOTALQTD).toFixed(3)} Valor:{toMoneyFormat(props.data[0].TOTALVALOR)}</h6>
                <PrintFooter/>
            </div>
        </div>
    )
}
