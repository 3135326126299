import { useGlobalState } from '../../../globalState'
import PrintFooter from "../printFooter";

interface IProps{
    data:{
        NUMERO_EMPRESA: number;
        FANTASIA?: any;
        BICO_BOMBA: number;
        DESCRICAO_PRODUTO: string;
        QUANTIDADE: string;
        DATAAFERICAO: string;
        HORA: string;
        NUMERO_CAIXA: number;
        NUMERO_FUNCIONARIO: number;
        NOME_FUNCIONARIO: string;
        DATALANCAMENTO: string;
        USUARIO: string;
        EXCLUIDO: string;
        DATAEXCLUSAO?: any;
        USUARIOEXCLUSAO?: any;
    }[]
    date: { firstDate: string, lastDate: string }
}

function AfericaoPDF(props:IProps){
    const [empresaAtual] = useGlobalState('empresaAtual')

    const convertDate = (date: string) => {
        return date.replaceAll('-', '/').split('/').reverse().join('/')
    }

    return(
        <div className='container'>
        <div className='pdf' id='section-to-print'>
            <h4>{empresaAtual.nome}</h4>
            <h5>Estoque &gt; Aferição</h5>
            <hr />
            <br />
            <div className="table-responsive">
            <table className="table table-sm">
                <thead>
                    <tr>
                        <th scope="col">Produto</th>
                        <th scope="col">Bico</th>
                        <th scope="col">Nº Caixa</th>
                        <th scope="col">Funcionario</th>
                        <th scope="col">Data medição</th>
                        <th scope="col">Hora</th>
                        <th scope="col">Quantidade</th>
                    </tr>
                </thead>
                <tbody>
                 {props.data.map(item=>
                    <tr>
                        <td>{item.DESCRICAO_PRODUTO}</td>
                        <td>{item.BICO_BOMBA}</td>
                        <td>{item.NUMERO_CAIXA}</td>
                        <td>{item.NOME_FUNCIONARIO}</td>
                        <td>{convertDate(item.DATAAFERICAO)}</td>
                        <td>{item.HORA}</td>
                        <td>{Number(item.QUANTIDADE).toFixed(3)}</td>
                    </tr>
                    )}

                </tbody>
                </table>
                </div>
                <h6 className='fw-bold text-end'>Quantidade de aferições: {props.data.length}</h6>
                <PrintFooter/>
                </div></div>
    )
}
export default AfericaoPDF