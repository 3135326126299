import * as React from 'react';
import { useState } from 'react'
import { useGlobalState } from '../../globalState'
import { Modal } from 'react-bootstrap'
import axios from '../../axiosConfig'
import {AxiosError } from 'axios';
import {TextField, IconButton,Button } from '@mui/material'
import { Search, Send, AddCircle, Edit } from '@mui/icons-material'

interface IProps{
    setCodigoBico:React.Dispatch<React.SetStateAction<number>>
}

interface IBomba {
    NUMERO: number,
    ID:number,
    DESCRICAO: string,
    PONTO:number,
    POSICAO:number,
    CANAL:number,
    BOMBA:number,
    ID_TANQUE:number,
    ID_ILHA:number,

}


function SearchBico(props:IProps){
    const [bomba,setBomba] = useState<IBomba[] | undefined>(undefined)
    const [bombaSelecionada, setBombaSelecionada] = useState<IBomba | undefined>(undefined)
    const [isSearching, setIsSearching] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')
    const [searchResults, setSearchResults] = useState<IBomba[] | undefined>(undefined)
    const [empresaAtual] = useGlobalState('empresaAtual')

    const getBicos = ()=>{
        axios.get('/bomba/listar',)
        .then(r => setBomba(r.data))
        .catch(error=>{
            const err = error as AxiosError
            console.log(err.response?.data)
        })
    }


    React.useEffect(()=>{
        getBicos()
    },[empresaAtual])

    React.useEffect(()=>{
        if(searchTerm.trim() === '')return setSearchResults(bomba)
        const result = bomba?.filter(item =>{
                return item.DESCRICAO.includes(searchTerm.toUpperCase().trim()) ||
                item.NUMERO.toString().includes(searchTerm.trim())
            
        })
        setSearchResults(result)
        

    },[searchTerm,bomba])

    return(
        <div>
        <Modal
                animation={false}
                centered
                show={isSearching}
                backdrop="static"
                keyboard={true}
                size='lg'
            >
                <Modal.Body>
                    <input type="text" className="form-control" placeholder='Pesquisar por bico'
                        value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <hr />
                    <div className='table-responsive' style={{maxHeight:'350px',overflow:'scroll'}}>
                    <table className="table table-secondary table-hover ">
                            <thead className="table-primary sticky-top">
                                <tr>
                                    <th scope="col">Bico</th>
                                    <th scope="col">Ponto</th>
                                    <th scope="col">Posição</th>
                                    <th scope="col">Canal</th>
                                    <th scope="col">Bomba</th>
                                    <th scope="col">Ilha</th>
                                    <th scope="col">Tanque</th>
                                    <th scope="col">Produto</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr className='tr-size' style={{cursor:'pointer'}} onClick={()=>{
                                        props.setCodigoBico(0)
                                        setBombaSelecionada(undefined)
                                        setIsSearching(false)
                                    }}>
                                        <td colSpan={8} className='text-center text-success fw-bold'>Selecionar Todos</td>
                                    </tr>
                                {searchResults?.map(bico=>
                                    <tr className={`tr-size ${bombaSelecionada?.ID === bico.ID && 'table-active'}`} style={{cursor:'pointer'}} onClick={()=>{
                                        props.setCodigoBico(bico.ID)
                                        setBombaSelecionada(bico)
                                    }}>
                                    <td className='text-primary fw-bold'>{bico.NUMERO}</td>
                                    <td>{bico.PONTO}</td>
                                    <td>{bico.POSICAO}</td>
                                    <td>{bico.CANAL}</td>
                                    <td>{bico.BOMBA}</td>
                                    <td>{bico.ID_ILHA}</td>
                                    <td>{bico.ID_TANQUE}</td>
                                    <td>{bico.DESCRICAO}</td>
                                </tr>
                                    )}
                                
                            </tbody>
                    </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant='contained' color='info' endIcon={<AddCircle/>} sx={{m:0.5}} 
          onClick={() => {
            setIsSearching(false)
            }}>
            Novo
          </Button>
                <Button variant='contained' color='info' endIcon={<Edit/>} sx={{m:0.5}} 
          onClick={() => {
            setIsSearching(false)
            }}>
            Alterar
          </Button>
          <Button variant='contained' color='success' endIcon={<Send/>} sx={{m:0.5}} 
          onClick={() => {
            setIsSearching(false)
            }}>
            Selecionar
          </Button>
                </Modal.Footer>
            </Modal>

            <div className='row'>
                <label className='col-auto col-form-label' htmlFor="select-produto">Bico:</label>
                <div className="col-auto"><TextField type="text" value={bombaSelecionada? bombaSelecionada.NUMERO + " - " + bombaSelecionada.DESCRICAO : 'Todos'} size='small'
                    InputProps={{ readOnly: true, }}
                    style={{ maxWidth: '200px' }} className="form-control" /></div>
                <div className="col-auto">
                    <div style={{ display: 'inline-block' }} data-tooltip="Visualizar todos" data-tooltip-location="right">
                        <IconButton size='large' color='primary' 
                        onClick={()=>setIsSearching(true)} >
                            <Search /></IconButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchBico