import React from 'react';
import { useGlobalState } from '../../../globalState'
import { toMoneyFormat, dateToString,toFixedNumber } from '../../../utilities/helperFunctions';
import TableSort from '../../../utilities/TableSortTS'
import PrintFooter from "../printFooter";

interface Vendas {
    NUMERO_GRUPOPRODUTO: number;
    TIPOPRODUTO: string;
    DESCRICAO: string;
    NUMERO_PRODUTO: number;
    DESCRICAO_PRODUTO: string;
    QUANTIDADE: string;
    TOTALCOMDESCACR: string;
    TOTALDESCACRAPLICADO: string;
}
interface IProps {
    data: [Vendas[],Vendas[]]
}


function VendasDoDiaPDF(props: IProps) {
    const [empresaAtual] = useGlobalState('empresaAtual')
    const grupoAberto = [...new Map(props.data[0].map((item) => [item['DESCRICAO'], item])).values()]
    const grupoFechado = [...new Map(props.data[1].map((item) => [item['DESCRICAO'], item])).values()]
    const totalAberto = props.data[0].reduce((acc,item)=> acc+= toFixedNumber(item.TOTALCOMDESCACR,3),0)
    const totalFechado = props.data[1].reduce((acc,item)=> acc+= toFixedNumber(item.TOTALCOMDESCACR,3),0)
    
    const columns = React.useMemo(
        () => [
            {
                Header: " ",
                columns: [
                    {
                        Header: "Codigo",
                        accessor: "NUMERO_PRODUTO",
                    },
                    {
                        Header: "Descrição",
                        accessor: "DESCRICAO_PRODUTO",
                        minWidth: 350
                    },
                    {
                        Header: "Qtde",
                        accessor: "QUANTIDADE",
                        Cell: ({ value }: { value: string }) => Number(value).toFixed(3),
                    },
                    {
                        Header: "Desc/acr aplicado",
                        accessor: "TOTALDESCACRAPLICADO",
                        Cell: ({ value }: { value: string }) => toMoneyFormat(value, 2),
                    },
                    {
                        Header: "Total",
                        accessor: "TOTALCOMDESCACR",
                        Cell: ({ value }: { value: string }) => toMoneyFormat(value, 2),
                    },

                ],
                Footer: () => <p className='infoPDF fw-bold pr-3 text-end'></p>
            }
        ], [])

        const calculateTotalLitrosA = props.data[0].reduce((acc,item)=>{
            if(item.TIPOPRODUTO.trim() === "C" )acc += toFixedNumber(item.QUANTIDADE,3)
            return acc
        },0)
        const calculateTotalLitrosF = props.data[1].reduce((acc,item)=>{
            if(item.TIPOPRODUTO.trim() === "C" )acc += toFixedNumber(item.QUANTIDADE,3)
            return acc
        },0)
        const calculateTotalVendaA = props.data[0].reduce((acc,item)=>{
            if(item.TIPOPRODUTO.trim() === "C" )acc += toFixedNumber(item.TOTALCOMDESCACR,3)
            return acc
        },0)
        const calculateTotalVendaF = props.data[1].reduce((acc,item)=>{
            if(item.TIPOPRODUTO.trim() === "C" )acc += toFixedNumber(item.TOTALCOMDESCACR,3)
            return  acc
        },0)


    return (
        <div className='container'>
            <div className='pdf' id='section-to-print'>
                <h4>{empresaAtual.nome}</h4>
                <h5>Vendas &gt; Vendas do dia - {dateToString(new Date())}</h5>
                <hr />
                <br />
                <h6>Caixas aberto:</h6>
                {grupoAberto.map(item =>
                    <>
                    <p className='fw-bold'>{item.DESCRICAO}</p>
                    <div className="table-responsive">
                    <TableSort columns={columns} data={props.data[0].filter(i=>item.NUMERO_GRUPOPRODUTO === i.NUMERO_GRUPOPRODUTO)}/>
                    </div>
                    {item.TIPOPRODUTO.trim() === "C" 
                    ? <p className='fw-bold text-end'>Total litros: {calculateTotalLitrosA.toFixed(3)} Total Valor: {toMoneyFormat(calculateTotalVendaA)}</p>
                    :null
                }
                    </>
                )}
                <p className='text-end fw-bold'>Total caixas abertos: {toMoneyFormat(totalAberto)}</p>
                <br /><hr /><br />
                <h6>Caixas fechado:</h6>
                {grupoFechado.map(item =>
                    <>
                    <p className='fw-bold'>{item.DESCRICAO}</p>
                    <div className="table-responsive">
                    <TableSort columns={columns} data={props.data[1].filter(i=>item.NUMERO_GRUPOPRODUTO === i.NUMERO_GRUPOPRODUTO)}/>
                    </div>
                    {item.TIPOPRODUTO.trim() === "C" 
                    ? <p className='fw-bold text-end'>Total litros: {calculateTotalLitrosF.toFixed(3)} Total Valor: {toMoneyFormat(calculateTotalVendaF)}</p>
                    :null
                }
                    </>
                )}
                <p className='text-end fw-bold'>Total caixas fechados: {toMoneyFormat(totalFechado)}</p>
                <h5 className='text-end fw-bold'>Total dia: {toMoneyFormat(totalAberto + totalFechado)}</h5>
                <PrintFooter/>
            </div>
        </div>
    )
}
export default VendasDoDiaPDF