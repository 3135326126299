import React from 'react';
import { Paper } from '@mui/material';
import { Chart } from 'react-google-charts';
import { totalLucro } from './interfaceLucro';
import { toMoneyFormat } from '../../../utilities/helperFunctions';

interface Props {
    data: totalLucro[];
    gruposProdutos: { DESCRICAO_GRUPOPRODUTO: string }[];
}

function LucroGrupoGraph({ data, gruposProdutos }: Props) {
    const chartData: Array<Array<string | number>> = [['Grupo', 'Pr. Méd.', 'Últ. Pr.']];
    data.forEach((grupo, index) => {
        const nomeGrupo = gruposProdutos[index].DESCRICAO_GRUPOPRODUTO;
        chartData.push([
            nomeGrupo,
            grupo.TOTAL_LUCROBRUTO_MEDPERIODO,
            grupo.TOTAL_LUCROBRUTO_PRECOULTIMO
        ]);
    });

    return (
        <Paper elevation={3} className='p-2 mt-2 no-print' sx={{
            overflow: 'scroll',
            xl: { width: '600px' },
            md: { width: '100%' },
            maxWidth: '100%',
            height: '800px',
            backgroundColor: '#f5f0f0'
        }}>
            <Chart
                width={'100%'}
                height={'100%'}
                chartType="ColumnChart"
                loader={<div>Carregando gráfico...</div>}
                data={chartData}
                options={{
                    title: 'Preço Médio e Último Preço por Grupo de Produtos',
                    legend: { position: 'top' },
                    chartArea: { width: '70%', height: '70%' },
                    bars: 'vertical',
                    hAxis: {
                        title: 'Grupo',
                    },
                    vAxis: {
                        title: 'Valor',
                        format: 'currency'
                    }
                }}
                rootProps={{ 'data-testid': '1' }}
            />
        </Paper>
    );
}

export default LucroGrupoGraph;
