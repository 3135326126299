import { useMemo, useState, useEffect } from "react";
import TableSort from '../../../utilities/TableSort'
import {dateToString,fixDate,toMoneyFormat} from '../../../utilities/helperFunctions'
import { ShowEmpresa } from '../showEmpresa';
import PrintFooter from "../printFooter";

function PagamentosPDF(props){
    const [dataTable,setDataTable] = useState([])

    useEffect(()=>{
        setDataTable(fixDate(props.data,['EMISSAO','VENCIMENTO','PAGAMENTO']))
},[])

    const columns = useMemo(
        () => [
           { 
            Header: " ",
           
            columns: [
                {
                     Header: "Emp",
                    accessor: "NUMERO_EMPRESA", 
                },
                {
                    Header: "Fornecedor",
                    accessor: "NOME_FORNECEDOR"
                },
                  {
                    Header: "Descrição",
                    accessor: "DESCRICAO"
                  },
                  {
                    Header: "Emissão",
                    accessor: "EMISSAO",
                    Cell: ({value}) => dateToString(value),
                    sortType: 'datetime',     
                  },{
                    Header: "Vencimento",
                    accessor: "VENCIMENTO",
                    Cell: ({value}) => dateToString(value),
                    sortType: 'datetime',     
                  },
                  {
                    Header: "Pagamento",
                    accessor: "PAGAMENTO",
                    Cell: ({value}) => dateToString(value),
                    sortType: 'datetime',     
                  },
                  {
                    Header: "Documento",
                    accessor: "NUMERODOCUMENTO"
                  },{
                    Header: "Parc.",
                    accessor: "NUMEROPARCELA"
                  },{
                    Header: "Des/Acr.",
                    accessor: "VALORDESCONTOACRESC",
                    Cell: ({value}) => toMoneyFormat(value),
                  },
                  {
                    Header: "Valor",
                    accessor: "VALOR",
                    Cell: ({value}) => toMoneyFormat(value),
                  },    
            ],
            Footer:()=> <p className='infoPDF fw-bold pr-3 text-end'>Total de lançamentos: {props.data.length} | Total Des/Acr: R${totalDesAcr.toFixed(2)}  |  Total: R${total.toFixed(2)}</p>
        }
         ],[])

    const total = props.data.reduce((acc,{VALOR}) =>{
        return acc + Number(VALOR)
    },0)
    const totalDesAcr = props.data.reduce((acc,{VALORDESCONTOACRESC})=>{
        return acc + Number(VALORDESCONTOACRESC)
    },0)


    return(
        <div className='container'>

            <div className='pdf' id='section-to-print'>
            <ShowEmpresa/>
                <h6>Contas a pagar &gt; Pagamentos</h6>
                <hr />
                <br />
                <div className="table-responsive">
                <TableSort columns={columns} data={dataTable}></TableSort>

               </div>
                <br />
                <p className='infoPDF fw-bold pr-3 text-end'>Total de lançamentos: {props.data.length}  | Total Des/Acr: R${totalDesAcr.toFixed(2)}  |  Total: R${total.toFixed(2)}</p>
                <PrintFooter/>
            </div></div>

    )

}

export default PagamentosPDF