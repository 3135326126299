import React, { useEffect, useState } from "react"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from "@mui/x-date-pickers";
import { ptBR } from 'date-fns/locale'
import { Select, MenuItem } from '@mui/material'

type DefaultDate = '1d' | '7d' | '10d' | '15d' | '30d' | 'custom' | 'o' | '1y' | 'm'

interface IProps {
    firstDate: Date
    setFirstDate: React.Dispatch<React.SetStateAction<Date>>
    lastDate: Date
    setLastDate: React.Dispatch<React.SetStateAction<Date>>
    callSomeFunction?: () => void
    dateStart?: DefaultDate
    setIsCustomDate?: React.Dispatch<React.SetStateAction<boolean>>
}

function SearchData(props: IProps) {
    const [defaultDate, setDefaultDate] = useState<DefaultDate>(props.dateStart || '1d')

    const dateMes = (date: Date) => {
        date = new Date(date.getFullYear(), date.getMonth(), 1)
        return date
    }
    function handleFirstDateChange(newValue: Date) {
        if (newValue > props.lastDate) {
            props.setLastDate(newValue);
        }props.setFirstDate(newValue);
    }

    function handleLastDateChange(newValue: Date) {
        if (newValue < props.firstDate) {
            props.setFirstDate(newValue);
        }props.setLastDate(newValue);
    }


    useEffect(() => {
        const date = new Date()
        props.setFirstDate(dateMes(date))
    }, [])

    useEffect(() => {
        if (props.setIsCustomDate) props.setIsCustomDate(defaultDate === 'custom' ? true : false)
        const date = new Date()
        if (defaultDate === '1d') props.setFirstDate(date)
        if (defaultDate === '7d') props.setFirstDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000))
        if (defaultDate === '10d') props.setFirstDate(new Date(Date.now() - 10 * 24 * 60 * 60 * 1000))
        if (defaultDate === '15d') props.setFirstDate(new Date(Date.now() - 15 * 24 * 60 * 60 * 1000))
        if (defaultDate === '30d') props.setFirstDate(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000))
        if (defaultDate === '1y') props.setFirstDate(new Date(Date.now() - 365 * 24 * 60 * 60 * 1000))
        if (defaultDate === 'o') {
            props.setFirstDate(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000))
            props.setLastDate(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000))
            return
        }
        if (defaultDate === 'm') {
            const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
            props.setFirstDate(firstDayOfMonth);
            props.setLastDate(date);
            return;
          }
        props.setLastDate(new Date())
    }, [defaultDate])

    useEffect(() => {
        if (defaultDate !== 'custom' && props.callSomeFunction) props.callSomeFunction()
    }, [props.lastDate, props.firstDate])

    return (
        <div className="mb-2">
            <div className="row">
                {defaultDate === 'custom'
                    ? <>
                        <div className="col-auto m-1">
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                                <DatePicker
                                    label='Início'
                                    value={props.firstDate}
                                    format="dd/MM/yyyy"
                                    onChange={(newValue) => {
                                        newValue &&
                                            handleFirstDateChange(newValue)
                                        if (defaultDate !== 'custom') setDefaultDate('custom')
                                    }
                                    }
                                    slotProps={{ textField: { size: 'small', className: 'small-DatePicker' } }}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className="col-auto m-1">
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                                <DatePicker
                                    label='Fim'
                                    value={props.lastDate}
                                    format="dd/MM/yyyy"
                                    onChange={(newValue) => {
                                        newValue &&
                                        props.setLastDate(newValue)
                                        if (defaultDate !== 'custom') setDefaultDate('custom')
                                    }}
                                    onAccept={(newValue)=>newValue && handleLastDateChange(newValue)}
                                    slotProps={{ textField: { size: 'small', className: 'small-DatePicker' } }}
                                />
                            </LocalizationProvider>
                        </div>
                    </> : null

                }

                <div className="col-auto m-1">
                    <Select value={defaultDate} size='small' label='Situações' onChange={e => {
                        setDefaultDate(e.target.value as DefaultDate)
                    }}>
                        <MenuItem value="1d">Hoje</MenuItem>
                        <MenuItem value="o">Ontem</MenuItem>
                        <MenuItem value="7d">7 dias</MenuItem>
                        <MenuItem value="10d">10 dias</MenuItem>
                        <MenuItem value="15d">15 dias</MenuItem>
                        <MenuItem value="30d">30 dias</MenuItem>
                        <MenuItem value="1y">1 ano</MenuItem>
                        <MenuItem value="m">Mês atual</MenuItem>
                        <MenuItem value="custom">Informar</MenuItem>
                    </Select>
                </div>
            </div>
        </div>
    );

}


export function SearchHour(props: IProps) {
    useEffect(() => {
        let date = new Date();
        props.setLastDate(new Date(date.setHours(23, 59)))
        props.setFirstDate(new Date(date.getFullYear(), date.getMonth(), 1))
    }, [])

    return (
        <div className="mb-2">
            <div className="row">
                <label className='col-auto col-form-label' htmlFor="selectF">
                    <span className="rounded-text rounded-green">De:</span></label>
                <div className="col-auto">
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                        <TimePicker
                            label="Time"
                            value={props.firstDate}
                            onChange={(newValue) => newValue && props.setFirstDate(newValue)}
                            slotProps={{ textField: { size: 'small', className: 'small-DatePicker' } }}
                        />
                    </LocalizationProvider>
                </div>
                <label className='col-auto col-form-label'
                    htmlFor="selectF" style={{ width: '30px' }}><span className="rounded-text rounded-purple">a:</span></label>
                <div className="col-auto">
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                        <TimePicker
                            label="Time"
                            value={props.lastDate}
                            onChange={(newValue) => newValue && props.setFirstDate(newValue)}
                            slotProps={{ textField: { size: 'small', className: 'small-DatePicker' } }}
                        />
                    </LocalizationProvider>
                </div>
            </div>
        </div>
    );
}

export default SearchData