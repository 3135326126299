import { RelatorioAno } from './interfacesPDF'
import { useGlobalState } from '../../../../globalState'
import TableSort from '../../../../utilities/TableSortTS'
import { useMemo } from 'react'
import { toFixedNumber,toMoneyFormat } from '../../../../utilities/helperFunctions'
import PrintFooter from "../../printFooter";
 

interface IProps {
    data: RelatorioAno['data']
    date: { dataInicial: string, dataFinal: string }
    options?: { filiaisSelecionadas: string[] }
}

function ResumoAnoPDF(props: IProps) {
    const [empresaAtual] = useGlobalState('empresaAtual')

    const anos = props.data.reduce((acc,item)=>{
        if(acc.indexOf(item.ANO) === -1){
            acc.push(item.ANO)
            acc.sort(function(a, b){return a - b;})
            return acc
        }else{
            return acc
        }
    },[] as number[])

    const calculateTotal =(ano:number) =>{
        let tot = props.data.find(x=>x.ANO === ano) 
       return toFixedNumber(tot?.TOTALANO || 0)
    }

    const columns = useMemo(
        () => [
            {
                Header: " ",
                columns: [
                    {
                        Header: "Empresa",
                        accessor: "FANTASIA",
                        Footer:''
                    },
                    {
                        Header: "Porc %",
                        accessor: "PORCVALOR",
                        Cell: ({ value }: { value: string }) => Number(value).toFixed(2),
                        disableSortBy: true,
                        Footer:''
                    },
                    {
                        Header: "Porc ano %",
                        accessor: "PORCANO",
                        Cell: ({ value }: { value: string }) => Number(value).toFixed(2),
                        disableSortBy: true,
                        Footer:''
                    },
                    {
                        Header: "Total",
                        accessor: "TOTALEMPRESA",
                        Cell: ({ value }: { value: string }) => toMoneyFormat(value),
                        
                    },
                ],
                Footer: ()=><></>
            }
        ], [])

    return (
        <div className='container'>
            <div className='pdf' id='section-to-print'>
                <h5>{empresaAtual.nome}</h5>
                <h6>Multiloja &gt; Resumo valores de venda &gt; Resumido &gt; ano - Periodo: {props.date.dataInicial} a {props.date.dataFinal}</h6>
                <hr />
                <p style={{ fontSize: '12px' }}>{props.options?.filiaisSelecionadas.map(item => item + ' | ')}</p>
                <hr />
                {anos.map(ano=>
                <>
                <h6 className='pl-4'>Ano: {ano}</h6>
                     <div className="table-responsive">
                     <TableSort columns={columns} data={props.data.filter(item=>item.ANO === ano)}></TableSort>
                 </div>
                 <p className='fw-bold text-end'>Total: {toMoneyFormat(calculateTotal(ano))}</p>
                 </>
                    )}
                      <h6 className='fw-bold text-end'>Total: {toMoneyFormat(props.data[0].TOTALVALOR)}</h6>
                      <PrintFooter/>
            </div>
        </div>
    )
}
export default ResumoAnoPDF