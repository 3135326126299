import { toMoneyFormat } from "../../utilities/helperFunctions"

function CaixaDetalhes(props) {

    const numeroCaixas = props.info.numeros || [props.info.numero]


    return (
        <div className='roww'>

            <div className='column mt-2'>
                <div className='c-right'>
                    <h4 className='text-center'>Débito:</h4>
                            {props.detalhes.map((item, i) =>
                                // eslint-disable-next-line
                                item.TIPO.trim() === 'R' ? <p className='pdf-desc-p' key={i}>{item.DESCRICAO} 一 {toMoneyFormat(item.VALOR)}  </p> : null
                            )}
                </div></div>
            <div className='column mt-2 '>
                <div className='c-left'>
                    <h4 className='text-center '>Crédito:</h4>
                            {props.detalhes.map((item, i) =>
                                // eslint-disable-next-line
                                item.TIPO.trim() === 'I' ? <p className='pdf-desc-p' key={i}>{item.DESCRICAO} 一 {toMoneyFormat(item.VALOR)}  </p> : null
                            )}
                </div></div>
        </div>
    )
}

export default CaixaDetalhes