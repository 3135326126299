import * as React from 'react';
import axios from '../../axiosConfig'
import { Tabs, Tab } from "react-bootstrap"
import { Paper, Button, Stack,} from '@mui/material'
import { Send, Undo } from '@mui/icons-material'
import Loading from '../../utilities/loading'
import GeralProduto from './tabs/geral';
import CodigoProduto from './tabs/codigos';
import TributacaoICMSProduto from './tabs/tributacaoICMS';
import TributacaoProduto from './tabs/tributacao';
import CombustiveisProduto from './tabs/combustiveis';
import MedicamentosProduto from './tabs/medicamentos';
import RastreioProduto from './tabs/rastreio';
import PrecoUpdateProduto from './tabs/precoUpdate';
import { IProduto } from './produtosInterface';
import { CheckFields } from './validateProduto';
import { showSnackbar } from '../../utilities/snackbarShow';

interface IProdutoUpdate {
  return: Function,
  item: IProduto,
  setProdutos: React.Dispatch<React.SetStateAction<IProduto[] | undefined>>
}


function ProdutoUpdate(props: IProdutoUpdate) {
  const [itemEdit,setItemEdit] = React.useState({...props.item})
  const [isFullLoading, setIsFullLoading] = React.useState(false)
  
  const getChanges = () => {
    const keysToCompare = Object.keys(itemEdit);
    const alteredKeys = keysToCompare.filter((item) => itemEdit[item as keyof typeof itemEdit] !== props.item[item as keyof typeof itemEdit]);

    if (alteredKeys.length === 0) {
        showSnackbar('Nenhuma informação alterada', 'error' );
        return;
    }
    const alteredData : { [key: string]: any } = {}; // Initialize an empty object to store altered key-value pairs
    
    alteredKeys.forEach(item => {
        alteredData[item] = itemEdit[item as keyof typeof itemEdit];
    });

    return {
        formData: alteredData,
        numero: props.item.CODIGO
    };
  }

  const updateProduto = () => {
    const check = CheckFields(itemEdit)
    if(!check)return
    setIsFullLoading(true)
    const updateData = getChanges()
    if (!updateData?.formData) {
      setIsFullLoading(false)
      return
    }
    axios.patch('/produtos', updateData,
    ).then(r => {
      showSnackbar('Registro editado', 'success' )
      props.setProdutos(r.data[0])
      props.return()
    }).catch(err => {
      showSnackbar('Erro ao Alterar registro', 'error' )
    }).finally(() => setIsFullLoading(false))
  }

  return (

    <div >
      {isFullLoading ? <Loading /> : null}

      <div className=".arrow-left "><i onClick={() => props.return()} className="fad fa-arrow-to-left arrow-left "></i></div>
      <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
        <Tab eventKey="home" title="Geral">
          <Paper elevation={3} sx={{ backgroundColor: '#f5f0f0', p: 3 }}>
            <GeralProduto data={itemEdit} setData={setItemEdit} />
          </Paper>
        </Tab>
        <Tab eventKey="profile" title="Códigos">
          <Paper elevation={3} sx={{ backgroundColor: '#f5f0f0', p: 3 }}>
            <CodigoProduto data={itemEdit} setData={setItemEdit} />
          </Paper>
        </Tab>

        <Tab eventKey="tributacao" title="Tributação" >
          <Paper elevation={3} sx={{ backgroundColor: '#f5f0f0', p: 3 }}>
            <TributacaoProduto data={itemEdit} setData={setItemEdit} />
            <TributacaoICMSProduto data={itemEdit} setData={setItemEdit} />
          </Paper>
        </Tab>
        <Tab eventKey="combustiveis" title="Combustíveis" >
          <Paper elevation={3} sx={{ backgroundColor: '#f5f0f0', p: 3 }}>
            <CombustiveisProduto data={itemEdit} setData={setItemEdit} />
          </Paper>
        </Tab>
        <Tab eventKey="medicamentos" title="Medicamentos" >
          <Paper elevation={3} sx={{ backgroundColor: '#f5f0f0', p: 3 }}>
            <MedicamentosProduto data={itemEdit} setData={setItemEdit} />
          </Paper>
        </Tab>
        <Tab eventKey="rastreio" title="Rastreio" >
          <Paper elevation={3} sx={{ backgroundColor: '#f5f0f0', p: 3 }}>
            <RastreioProduto produto={itemEdit.CODIGO}/>
          </Paper>
        </Tab>
        <Tab eventKey="precos" title="Precos" >
          <Paper elevation={3} sx={{ backgroundColor: '#f5f0f0', p: 3 }}>
            <PrecoUpdateProduto produto={itemEdit.CODIGO}/>
          </Paper>
        </Tab>
      </Tabs>

      <Stack direction='row' justifyContent={'end'} spacing={0.5} mt={1}>
      <Button variant='contained' size='small' color='secondary' endIcon={<Undo />}
          onClick={() => setItemEdit({ ...props.item })}
        >Restaurar</Button>
        <Button variant='contained' size='small' color='success' endIcon={<Send />}
          onClick={() => updateProduto()}
        >Aplicar</Button>
      </Stack>
    </div>

  )
}

export default ProdutoUpdate