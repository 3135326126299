import * as React from 'react';
import { useState } from 'react'
import axios from '../../../axiosConfig'
import { AxiosError } from 'axios';
import { formatDate } from '../../../utilities/helperFunctions'
import SearchData from "../../../utilities/searchs/searchData"
import { Button, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Paper } from '@mui/material'
import { Send } from '@mui/icons-material'
import Loading from '../../../utilities/loading'

interface IProps {
    showPDF: (error: boolean, errorMessageP?: string) => void
    setPDFData: React.Dispatch<React.SetStateAction<{}>>
}


function Lucro(props: IProps) {
    const [firstDate, setFirstDate] = useState(new Date)
    const [lastDate, setLastDate] = useState(new Date)
    const [periodo, setPeriodo] = useState('Abertura')
    const [isFullLoading, setIsFullLoading] = useState(false)

    const getLucro = () => {
        setIsFullLoading(true)
        const postData = {
            dataInicial: `${formatDate(firstDate, 'db')}`, dataFinal: `${formatDate(lastDate, 'db')}`,
            periodo: periodo
        }
        axios.post('/relatorios/lucro', postData, )
            .then(r => {
                if (r.data.length < 1) return props.showPDF(true, 'Sem registros para a data informada')
                props.setPDFData(
                    {
                        data: r.data,
                        date: { dataInicial: formatDate(firstDate, 'front'), dataFinal: formatDate(lastDate, 'front') },
                    })
                props.showPDF(false)
            }).catch(error => {
                const err = error as AxiosError
            }).finally(() => setIsFullLoading(false))
    }

    return (
        <div>
            {isFullLoading ? <Loading /> : null}
            <Paper elevation={3} className='p-2' sx={{ backgroundColor: '#f5f0f0' }}>
                <h4 className='relatorio-title'>Relatório de Lucro:</h4>
                <SearchData setFirstDate={setFirstDate} setLastDate={setLastDate}
                    firstDate={firstDate} lastDate={lastDate} />
                <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">Tipo período</FormLabel>
                    <RadioGroup
                        row
                        name="row-radio-buttons-group"
                        value={periodo}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPeriodo(e.target.value)}
                    >
                        <FormControlLabel value="Abertura" control={<Radio />} label="Abertura caixa" />
                        <FormControlLabel value="Emissao" control={<Radio />} label="Emissão cupom" />
                    </RadioGroup>
                </FormControl>

                <br />
                <Button variant='contained' endIcon={<Send />} onClick={() => getLucro()}>Gerar</Button>
            </Paper>
        </div>
    )
}

export default Lucro