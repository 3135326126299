import { useState } from "react"
import axios from '../../../../axiosConfig'
import SearchCliente from "../../../../utilities/searchs/searchCliente"
import SearchData from "../../../../utilities/searchs/searchData"
import Loading from '../../../../utilities/loading'
import {formatDate} from '../../../../utilities/helperFunctions'
import {Button,Paper} from '@mui/material'
import {Send} from '@mui/icons-material'
import { IClientes } from "../../../../utilities/typesFromDB"
import SearchFiliais from '../../../../utilities/searchs/searchFiliais'
import { IfilialAtual, useGlobalState } from '../../../../globalState'

interface IProps {
    showPDF: (error: boolean, errorMessageP?: string) => void
    setPDFData: React.Dispatch<React.SetStateAction<{}>>
    clientes:IClientes[]

}

function VendasClientes(props:IProps) {
    const [firstDate, setFirstDate] = useState(new Date)
    const [lastDate, setLastDate] = useState(new Date)
    const [numeroCliente, setNumeroCliente] = useState(props?.clientes[0]?.NUMERO)
    const [isFullLoading, setIsFullLoading] = useState(false)
    const [isSelectingFilial, setIsSelectingFilial] = useState(false)
    const [filialAtual] = useGlobalState('filialAtual')
    const [usedFiliais, setUsedFiliais] = useState< IfilialAtual[]>([filialAtual])



    const getRelatorio = (filiais: IfilialAtual[])=> {
        setIsSelectingFilial(false)
        setUsedFiliais(filiais)
        setIsFullLoading(true)
        const postData = {
            dataInicial:`${formatDate(firstDate,'db')}`,
            dataFinal:`${formatDate(lastDate,'db')}`,
            clienteInicial: Number(numeroCliente) === 0 ? 0 : numeroCliente,
            clienteFinal: Number(numeroCliente) === 0 ? 999999999 : numeroCliente,
            empresas: filiais.map(i => i.NUMERO),
        }
        axios.post('/relatorios/vendasclientes', postData)
            .then(r => {
                if (r.data.length < 1) return props.showPDF(true, 'Sem registros para a data informada')
                props.setPDFData({date: {
                    dataInicial: formatDate(firstDate, 'front'), dataFinal: formatDate(lastDate, 'front'),
                  },
                  data:r.data,
                  options: { filiaisSelecionadas: filiais.map(i => i.FANTASIA) }
                })
                props.showPDF(false)
            })
            .finally(() => setIsFullLoading(false))

    }

    return (
        <div>
            {isFullLoading ? <Loading></Loading> : null}
            <Paper elevation={3} className='p-2' sx={{backgroundColor:'#f5f0f0'}}>
            <h4 className='relatorio-title'>Relatorio vendas clientes:</h4>
            <SearchData setFirstDate={setFirstDate} setLastDate={setLastDate}
            firstDate={firstDate} lastDate={lastDate}/>
            <SearchCliente clientes={props.clientes} setNumeroCliente={setNumeroCliente}></SearchCliente>
            <Button variant='contained' endIcon={<Send/>} onClick={() => setIsSelectingFilial(true)}>Gerar</Button>
            </Paper>
            {isSelectingFilial ? <SearchFiliais getFiliais={getRelatorio}
                close={(filiais: IfilialAtual[]) => { setIsSelectingFilial(false); setUsedFiliais(filiais) }} lastSelectedFiliais={usedFiliais} /> : null}
        </div>
    )
}

export default VendasClientes