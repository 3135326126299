import React from 'react';
import { Chart } from 'react-google-charts';
import { Paper } from '@mui/material';
import { IAbastecimentos } from './AbastecimentoPDFs/interfacePDFs'; // Assuming this interface has the necessary fields

const options = {
    title: 'Bicos mais vendidos',
    backgroundColor: '#f5f0f0',
    chartArea: { top: 25, bottom: 50, right: 10, left: 60 },
    pointSize: 15,
};

interface Props {
    data: IAbastecimentos['data'];
}

function AbastecimentoGraph({ data }: Props) {
    
    const bicos = Array.from(new Set(data.map(item => item.ID_BOMBA)));

    const bicoSold = bicos.map(bico => {
        const totalSold = data.reduce((acc, item) => {
            if (item.ID_BOMBA === bico) {
                acc += parseFloat(item.TOTALLITROS.replace(',', '.'));
            }
            return acc;
        }, 0);
        return { bico, totalSold };
    });

    const sortedBicoSold = bicoSold.sort((a, b) => b.totalSold - a.totalSold).slice(0, 7);

    const bicoData = [['Bico', 'Total'], ...sortedBicoSold.map(({ bico, totalSold }) => [`${bico} - ${data.find(i => i.ID_BOMBA === bico)?.DESCRICAO_PRODUTO} `, totalSold])];

    return (
        <Paper elevation={3} className='p-2 mt-2 no-print' sx={{
            overflowX: 'auto',
            xl: { width: '600px' },
            md: { width: '100%' },
            maxWidth: '100%',
            height: '500px',
            backgroundColor: '#f5f0f0'
        }}>
            <Chart
                chartType="ColumnChart"
                width="100%"
                height="100%"
                data={bicoData}
                options={options}
            />
        </Paper>
    );
}

export default AbastecimentoGraph;
