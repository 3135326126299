import * as React from 'react';
import axios from '../../axiosConfig'
import { Tabs, Tab } from "react-bootstrap"
import {
  Paper, Button, Stack, Select, MenuItem,
} from '@mui/material'
import { Send } from '@mui/icons-material'
import Loading from '../../utilities/loading'
import GeralProduto from './tabs/geral';
import CodigoProduto from './tabs/codigos';
import TributacaoICMSProduto from './tabs/tributacaoICMS';
import TributacaoProduto from './tabs/tributacao';
import CombustiveisProduto from './tabs/combustiveis';
import MedicamentosProduto from './tabs/medicamentos';
import PrecoProduto from './tabs/preco';
import { CheckFields } from './validateProduto';
import { IProduto } from './produtosInterface';
import { showSnackbar } from '../../utilities/snackbarShow';

interface IProdutoCreate {
  return: Function,
  setProdutos: React.Dispatch<React.SetStateAction<IProduto[] | undefined>>
}


function ProdutoCreate(props: IProdutoCreate) {
  const [formGeral,setFormGeral] = React.useState({ CODIGO: 0,
    DESCRICAO: '',
    NOMEFANTASIA: '',
    ATIVO: 'S',
    TIPOPRODUTO: 'P',
    UNIDADEENTRADA: 'UN',
    UNIDADE: 'UN',
    UNIDADECONVERSAO: '1,000',
    CODIGO_GRUPOPRODUTO: 1,
    CODIGO_TIPOPRODUTO: 1,
    ALTERACAOGRUPO: 'N',
    COMISSIONADO: 'N',
    VENDAFRENTECAIXA:'N',
    BLOQUEARVENDAESTOQUEZERADO: 'N',
    ARREDONDAMENTOTRUNCAMENTO:'T',
    PRODUCAOPROPRIA:'T',
    ESTOQUEREGULADOR: '0,000',
    ACESSO_CONTACREDITO: '1.3.02.001',
    ACESSO_CONTADEBITO: '1.3.02.002',
})
const [formCodigo,setFormCodigo] = React.useState({
    CODIGOESTENDIDO:'',
    CODIGOBARRAS:'',
    CODIGO_PRODUTOSEFAZ:'0000',
    CODIGOFABRICANTE:'',
    CODIGO_NBMSH: '',
    CODIGOTICKET:'',
    CODIGOTIPOSERVICO:'',
    CODIGONATUREZARECEITA:'',
    CODIGO_SAP:'',
    CODIGO_CEST:'',
    CODIGOETIQUETA:'',
    CODIGOBARRASESTENDIDO:''
})
const [formICMS,setFormICMS] = React.useState({
    UF_AC:'0,00',
    UF_AL:'0,00',
    UF_AM:'0,00',
    UF_AP:'0,00',
    UF_BA:'0,00',
    UF_CE:'0,00',
    UF_ES:'0,00',
    UF_GO:'0,00',
    UF_MA:'0,00',
    UF_MG:'0,00',
    UF_MS:'0,00',
    UF_MT:'0,00',
    UF_PA:'0,00',
    UF_PB:'0,00',
    UF_PE:'0,00',
    UF_PI:'0,00',
    UF_PR:'0,00',
    UF_RJ:'0,00',
    UF_RN:'0,00',
    UF_RO:'0,00',
    UF_RR:'0,00',
    UF_RS:'0,00',
    UF_SC:'0,00',
    UF_SE:'0,00',
    UF_SP:'0,00',
    UF_TO:'0,00',
    UF_DF:'0,00',
})
const [formTributacao,setFormTributacao] = React.useState({
    CALCULAPIS: 'N',
    CALCULACOFINS: 'N',
    CSTPIS:'04',
    CSTCOFINS:'04',
    CSTPISENTRADA: '73',
    CSTCOFINSENTRADA: '73',
    ALIQUOTAPIS: '0,00',
    ALIQUOTACOFINS: '0,00',
    MVAST: '0,00',
    ALIQUOTAISS: '0,00',
    ALIQUOTAFCP: '0,00',
    ALIQUOTAICMSREDUZIDA: '0,00',
    ALIQUOTAICMS: '0,00',
    VALORUNITARIOBCICMSST: '0,00',
    CODIGO_ST: '000',
    CODIGO_STENTRADA: '000',
})

const [formCombustiveis,setFormCombustiveis] = React.useState({
    CODIGOANP: '',
    DESCRICAOANP: '',
})
const [formMedicamentos,setFormMedicamentos] = React.useState({
    CODIGO_ANVISA: '',
})
const [formPrecos,setFormPrecos] = React.useState({
  PRECO: '0,00',
})
  const [isFullLoading, setIsFullLoading] = React.useState(false)

  const createProduto = () => {

    const check = CheckFields({...formGeral,...formCodigo,...formICMS,...formTributacao,...formCombustiveis,...formPrecos} as unknown as IProduto)
    if(!check)return
    
    setIsFullLoading(true)
    axios.post('criar/produtos', { formData: {...formTributacao,...formMedicamentos,...formCombustiveis,...formCodigo,...formGeral,'DIAS_VENCIMENTO':'0','ATIVO':'S'},formICMS:formICMS,preco:formPrecos.PRECO }
    ).then(r => {
      showSnackbar('Registro criado', 'success' )
      props.return()
    }).catch(err => {
      showSnackbar('Erro ao criar registro', 'error' )
    }).finally(() => setIsFullLoading(false))
  }


  return (

    <div>
      {isFullLoading ? <Loading /> : null}

      <div className=".arrow-left "><i onClick={() => props.return()} className="fad fa-arrow-to-left arrow-left "></i></div>
      <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
        <Tab eventKey="home" title="Geral">
          <Paper elevation={3} sx={{ backgroundColor: '#f5f0f0', p: 3 }}>
          <GeralProduto data={formGeral} setData={setFormGeral}/>
          </Paper>
        </Tab>
        <Tab eventKey="profile" title="Códigos">
          <Paper elevation={3} sx={{ backgroundColor: '#f5f0f0', p: 3 }}>
          <CodigoProduto data={formCodigo} setData={setFormCodigo}/>
          </Paper>
        </Tab>

        <Tab eventKey="tributacao" title="Tributação" >
          <Paper elevation={3} sx={{ backgroundColor: '#f5f0f0', p: 3 }}>
          <TributacaoProduto data={formTributacao} setData={setFormTributacao}/>
          <TributacaoICMSProduto data={formICMS} setData={setFormICMS}/>
          </Paper>
        </Tab>
        <Tab eventKey="combustiveis" title="Combustíveis" >
          <Paper elevation={3} sx={{ backgroundColor: '#f5f0f0', p: 3 }}>
          <CombustiveisProduto data={formCombustiveis} setData={setFormCombustiveis}/>
          </Paper>
        </Tab>
        <Tab eventKey="medicamentos" title="Medicamentos" >
          <Paper elevation={3} sx={{ backgroundColor: '#f5f0f0', p: 3 }}>
          <MedicamentosProduto data={formMedicamentos} setData={setFormMedicamentos}/>
          </Paper>
        </Tab>
        <Tab eventKey="precos" title="precos" >
          <Paper elevation={3} sx={{ backgroundColor: '#f5f0f0', p: 3 }}>
          <PrecoProduto data={formPrecos} setData={setFormPrecos}/>
          </Paper>
        </Tab>
      </Tabs>
      
      <Stack direction='row' justifyContent={'end'} spacing={0.5} mt={1}>
        <Button variant='contained' size='small' color='success' endIcon={<Send />}
          onClick={() => createProduto()}
        >Aplicar</Button>
      </Stack>

    </div>

  )
}

export default ProdutoCreate