import { Modal } from 'react-bootstrap'
import Produtos from '../../views/produtos/produtos'
import { useEffect, useState } from 'react'
import { Button, IconButton, TextField } from '@mui/material'
import { Search, Send } from '@mui/icons-material'
import { IProduto } from '../../views/produtos/produtosInterface'

interface IProps {
    setCodigoItem: Function
    codigoItem?: number
}


function SearchProduto(props: IProps) {
    const [isSearching, setIsSearching] = useState(false)
    const [itemSelecionado, setItemSelecionado] = useState<IProduto | undefined>(undefined)

    useEffect(() => {
        props.setCodigoItem(itemSelecionado?.CODIGO)
    }, [itemSelecionado])

    return (
        <div>
            <Modal
                animation={false}
                centered
                show={isSearching}
                backdrop="static"
                keyboard={true}
                size='lg'
            >
                <Modal.Body>
                    <Produtos setProduto={setItemSelecionado}  codigoItem={props.codigoItem}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='contained' color='success' endIcon={<Send />} sx={{ m: 0.5 }}
                        onClick={() => {
                            setIsSearching(false)
                        }}>
                        Selecionar
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className='row'>
                <label className='col-auto col-form-label' htmlFor="select-produto">Produto:</label>
                <div className="col-auto"><TextField type="text" value={itemSelecionado ? itemSelecionado.CODIGO + " - " + itemSelecionado.DESCRICAO : '-'} size='small'
                    InputProps={{ readOnly: true, }}
                    style={{ maxWidth: '200px' }} className="form-control" /></div>
                <div className="col-auto">
                    <div style={{ display: 'inline-block' }} data-tooltip="Visualizar todos" data-tooltip-location="right">
                        <IconButton size='large' color='primary'
                            onClick={() => setIsSearching(true)} >
                            <Search /></IconButton>
                    </div>
                </div>
            </div>
        </div>
    )


}

export default SearchProduto