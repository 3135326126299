import * as React from 'react';
import { toMoneyFormat } from '../../../../utilities/helperFunctions'
import { IAbastecimentoPorHora } from './interfacePDFs'
import TableSort from '../../../../utilities/TableSortTS'
import { ShowEmpresa } from '../../showEmpresa';
import PrintFooter from "../../printFooter";
import { AbastecimentoHoraGraph } from '../abastecimentoHoraGraph';

interface IProps extends IAbastecimentoPorHora {
    date: {
        firstDate: string, lastDate: string
        firstHour: string, lastHour: string
    }
}

function AbastecimentoPDF(props: IProps) {

    const columns = React.useMemo(
        () => [
            {
                Header: " ",

                columns: [
                    {
                        Header: "Hora",
                        accessor: "HORA",
                    },
                    {
                        Header: "Abastecimentos",
                        accessor: "TOTALABASTECIMENTOS",
                    },
                    {
                        Header: "Qtde",
                        accessor: "TOTALLITROS",
                        Cell: ({ value }: { value: string }) => Number(value).toFixed(3),
                    },
                    {
                        Header: "Total",
                        accessor: "TOTALVALOR",
                        Cell: ({ value }: { value: string }) => toMoneyFormat(value),
                    },
                    {
                        Header: "Preço medio",
                        accessor: "PRECOLITROMEDIO",
                        Cell: ({ value }: { value: string }) => toMoneyFormat(value,3),
                    },
                    {
                        Header: "Media qtde",
                        accessor: "LITROSMEDIO",
                        Cell: ({ value }: { value: string }) => Number(value).toFixed(3),
                    },
                    {
                        Header: "Media valor",
                        accessor: "VALORMEDIO",
                        Cell: ({ value }: { value: string }) => toMoneyFormat(value,3),
                    },
                ],
                Footer: () => <p className='infoPDF fw-bold pr-3 text-end'></p>
            }
        ], [])


    return (

        <div className='container'>
            <div className='pdf' id='section-to-print'>

            <ShowEmpresa/>
                <h6>Vendas &gt; Abastecimento - Periodo: {props.date.firstDate} a {props.date.lastDate} |
                    {props.date.firstHour} - {props.date.lastHour}|
                </h6>
                {props.btnTable && <AbastecimentoHoraGraph data={props.data}/>}

                <div className="table-responsive">
                    <TableSort columns={columns} data={props.data}/>

                </div>
                <PrintFooter/>
            </div>
        </div>
    )

}
export default AbastecimentoPDF