import { useEffect, useState } from "react"
import axios from "../../axiosConfig"
import { useGlobalState } from "../../globalState"
import HandleEmpresas from "../../utilities/changeEmpresa"
import { IUser, IUserPermissao } from "../../utilities/typesFromDB"
import { Box, Button } from "@mui/material"
import { Visibility, AddCircle, Edit, Delete } from "@mui/icons-material"
import { showSnackbar } from "../../utilities/snackbarShow"
import AlertDialog from "../../utilities/dialog"
import UsuarioCreate from "./usuarioCreate"
import UsuarioUpdate from "./usuarioUpdate"
import { Spinner } from "react-bootstrap"
import UsuarioInfo from "./usuarioInfo"

type Actions = 'search' | 'create' | 'edit' | 'info'

function Usuarios() {
    const [empresaAtual] = useGlobalState('empresaAtual')
    const [usuarios, setUsuarios] = useState<IUser[] | undefined>()
    const [itemSelecionado, setItemSelecionado] = useState<IUser | undefined>()
    const [currentAction, setCurrentAction] = useState<Actions>('search')
    const [alertOpen, setAlertOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    const getUsuarios = () => {
        setIsLoading(true)
        axios.post('mysql/usuario').then(r => {
            console.log(r.data)
            setUsuarios(r.data)
        }).finally(() => setIsLoading(false))
    }
    const handleDelete = () => {
        itemSelecionado && axios.delete('')
    }

    useEffect(() => {
        getUsuarios()
    }, [empresaAtual])
    
    return (
        <div className="container mt-1">
            {currentAction === 'info' && <UsuarioInfo return={() => setCurrentAction('search')}  id={itemSelecionado?.id!}/>}
            {currentAction === 'create' && <UsuarioCreate return={() => { setCurrentAction('search'); getUsuarios() }} />}
            {currentAction === 'edit' && <UsuarioUpdate return={() => { setCurrentAction('search'); setItemSelecionado(undefined); getUsuarios() }} id={itemSelecionado?.id!} />}
            {currentAction === 'search' && <main>
                <AlertDialog open={alertOpen} close={setAlertOpen} confirm={() => handleDelete} />
                <div className="d-flex title">
                    <div className="p-2 "><h1>Usuários | <span className='empresa-atual'>{empresaAtual.fantasia} - {empresaAtual.cnpj}</span></h1></div>
                    <HandleEmpresas />
                    <div className="p-2 "><i className="fas fa-user-friends"></i></div>
                </div>

                <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Button variant='contained' color='success' endIcon={<Visibility />} sx={{ m: 0.5 }}
                        onClick={() => {
                            itemSelecionado ? setCurrentAction('info') : showSnackbar('Nenhum item foi selecionado', 'error')
                        }}>
                        Consultar
                    </Button>
                    <Button variant='contained' color='info' endIcon={<AddCircle />} sx={{ m: 0.5 }}
                        onClick={() => {
                            setCurrentAction('create')
                        }}>
                        Novo
                    </Button>
                    <Button variant='contained' color='info' endIcon={<Edit />} sx={{ m: 0.5 }}
                        onClick={() => {
                            itemSelecionado ? setCurrentAction('edit') : showSnackbar('Nenhum item foi selecionado', 'error')
                        }}>
                        Alterar
                    </Button>
                    {/* <Button variant='contained' color='error' endIcon={<Delete />} sx={{ m: 0.5, marginLeft: 'auto' }}
                        onClick={() => {
                            itemSelecionado ? setAlertOpen(true) : showSnackbar('Nenhum item foi selecionado', 'error')
                        }}>
                        Excluir
                    </Button> */}
                </Box>

                {isLoading &&
                    <div className="d-flex justify-content-center text-primary">
                        <Spinner variant="primary" animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>}

                <div className='table-responsive'>
                    <table className="table table-secondary table-hover">
                        <thead className="table-primary sticky-top">
                            <tr>
                                <th scope="col">ID</th>
                                <th scope="col">Nome</th>
                                <th scope="col">Email</th>
                                <th scope="col">Ativo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {usuarios?.map(dados =>
                                <tr key={dados.id} className={`tr-size ${itemSelecionado?.id === dados.id && 'table-active'}`} style={{ cursor: 'pointer' }}
                                    onClick={() => setItemSelecionado(dados)} >
                                    <th scope="row">{dados.id}</th>
                                    <td>{dados.name}</td>
                                    <td>{dados.email}</td>
                                    <td>{dados.ativo === 1 ? 'Sim' : 'Não' }</td>
                                </tr>
                            )}

                        </tbody>
                    </table></div>
            </main>}
        </div>
    )
}
export default Usuarios
