import { IconButton, Stack } from '@mui/material'
import { Print, ArrowBack } from '@mui/icons-material'
import CaixaDetalhes from './caixaDetalhesPDF'
import { toMoneyFormat, toFixedNumber } from '../../utilities/helperFunctions'
import { ICaixaPDF as IProps } from './interfaceCaixa'
import { useGlobalState } from '../../globalState'


function CaixaPDF(props: IProps) {
    //0-Metodos de pagamento|1-Produtos|2-encerrantes|3-Inserçoes e retiradas|4-A Prazos
    //5-Cupons|6-Informações do caixa
    const [nomeEmpresa] = useGlobalState('empresaAtual')

    let metodosPag = props.data[0]
    const produtos = props.data[1]
    const encerrantes = props.data[2]
    const caixaInfo = props.data[6]

    const insercao = props.data[3].reduce((acc, { VALOR, TIPO }) => {
        if (TIPO.trim() === 'I') acc = Number(acc) + Number(VALOR); acc.toFixed(2)
        return acc
    }, 0)
    const retirada = props.data[3].reduce((acc, { VALOR, TIPO }) => {
        if (TIPO.trim() === 'R') acc = Number(acc) + Number(VALOR); acc.toFixed(2)
        return acc
    }, 0)
    const cheques = caixaInfo.SITUACAO === 'ABERTO' ?  props.data[0].reduce((acc, { VALORCOMTROCO, NUMERO }) => {
        if (NUMERO === 0) acc += Number(VALORCOMTROCO)
        return acc
    }, 0)
    : props.data[0].reduce((acc, { CONCIMENOSTAXA, NUMERO }) => {
        if (NUMERO === 0) acc += Number(CONCIMENOSTAXA)
        return acc
    }, 0)
    const cupons = props.data[5][0]
    const prazo = Number(props.data[4][0].VALOR)
    const pagamentoAntecipado = caixaInfo.SITUACAO === 'ABERTO' ? metodosPag.reduce((acc, item) => {
        if (item.NOME === 'ANTECIPADO') acc += Number(item.VALORCOMTROCO)
        return acc
    }, 0) : metodosPag.reduce((acc, item) => {
        if (item.NOME === 'ANTECIPADO') acc += Number(item.CONCIMENOSTAXA)
        return acc
    }, 0) 

 
    /////////////////////////////////////////////
    const calculateTotalSaldos = (data: IProps['data']['0']) => {
        const result = data.reduce((acc, { CONCIMENOSTAXA }) => {

            return acc + Number(CONCIMENOSTAXA)
        }, 0)
        return result.toFixed(2)
    }
    const totalSaldo = calculateTotalSaldos(metodosPag)
    /////////////////////////////////////////////

    const calculateTotalVendas = (produtos: IProps['data']['1']) => {
        const result = produtos.reduce((acc, { VALORNEGOCIACAO }) => {
            return acc + Number(VALORNEGOCIACAO)
        }, 0)
        return result.toFixed(2)
    }
    const totalVendas = calculateTotalVendas(produtos)

    const getGrupoProdutos = (produtos: IProps['data']['1']) => {
        const DESCRICAO = produtos.reduce((acc, item) => {
            if (acc.indexOf(item.DESCRICAO) === -1) acc.push(item.DESCRICAO)
            return acc
        }, [] as string[])


        const QUANTIDADE = produtos.reduce((acc, item) => {
            acc[item.DESCRICAO] = Number(item.QUANTIDADE) + acc[item.DESCRICAO] || Number(item.QUANTIDADE)
            return acc
        }, {} as { [key: string]: number })

        const VALORNEGOCIACAO = produtos.reduce((acc, item) => {
            acc[item.DESCRICAO] = Number(item.VALORNEGOCIACAO) + acc[item.DESCRICAO] || Number(item.VALORNEGOCIACAO)
            return acc
        }, {} as { [key: string]: number })

        const grupoInfo = DESCRICAO.map((item, i) => {
            let grupoObject = { DESCRICAO: DESCRICAO[i], QUANTIDADE: QUANTIDADE[item], VALORNEGOCIACAO: VALORNEGOCIACAO[item] }

            return grupoObject
        })


        return grupoInfo
    }
    const grupoProdutos = getGrupoProdutos(produtos)

    const calculateProductTypeValue = (produtos: IProps['data']['1']) => {
        const result = produtos.reduce((acc, produto) => {
            if (produto.TIPOPRODUTO.trim() === 'C') {
                acc['combustivel'] = (Number(acc['combustivel']) + Number(produto.VALORNEGOCIACAO) || Number(produto.VALORNEGOCIACAO)).toFixed(2)
            }
            else if (produto.TIPOPRODUTO.trim() === 'P') {
                acc['produto'] = (Number(acc['produto']) + Number(produto.VALORNEGOCIACAO) || Number(produto.VALORNEGOCIACAO)).toFixed(2)

            } else {
                acc['servico'] = (Number(acc['servico']) + Number(produto.VALORNEGOCIACAO) || Number(produto.VALORNEGOCIACAO)).toFixed(2)
            }
            return acc
        }, {} as { combustivel: string, produto: string, servico: string })

        return result
    }
    /////////////////////////////////////////////


    ////////////////////////////////////////////

    return (
        <div className='container'>
            <Stack direction="row" alignItems="center" spacing={0.5} >
                <div style={{ display: 'inline-block' }} data-tooltip="Voltar" data-tooltip-location="bottom" >
                    <IconButton size="large" color="success" onClick={() => props.back()}>
                        <ArrowBack fontSize="inherit" /></IconButton>
                </div><br />
                <div style={{ display: 'inline-block' }} data-tooltip="Imprimir" data-tooltip-location="bottom">
                    <IconButton size="large" color="primary" onClick={() => window.print()}>
                        <Print fontSize="inherit" /></IconButton> </div></Stack>
            <div className='pdf' id='section-to-print'>
                <div>
                    <h5>{nomeEmpresa.nome}</h5>
                    <h6>Relatorio de controle de movimento de caixa</h6>
                    <hr />
                    {caixaInfo.IsMoreThanOneCaixa
                        ?
                        <div>
                            <p className='caixa-info '>Caixas: {caixaInfo.numeros.map(numeros => ' - ' + numeros)} </p>
                            <p className='caixa-info'>Abertura: {caixaInfo.firstDate}ㅤ{caixaInfo.firstHour}
                                ㅤFechamento: {caixaInfo.lastDate} {caixaInfo.lastHour}
                            </p></div>
                        :
                        <div>
                            <p className='caixa-info '>Caixa: {caixaInfo.NUMERO} ㅤㅤ Ilha: {caixaInfo.NUMERO_ILHA} ㅤㅤSituação: {caixaInfo.SITUACAO}</p>
                            <p className='caixa-info'>Abertura: {caixaInfo.DATAABERTURA}ㅤ{caixaInfo.HORAABERTURA}ㅤFechamento: {caixaInfo.DATAFECHAMENTO} ㅤ{caixaInfo.HORAFECHAMENTO}
                                ㅤㅤResponsavel: {caixaInfo.NOME_FUNCIONARIO}
                            </p></div>}

                    <hr />
                    <div style={{ overflowX: 'scroll' }}>
                        <div className='roww'>
                            <div className="column">
                                <div className='c-right'>


                                    <p className='text-center text-center-pdf fw-bold'>Resumo de vendas</p>

                                    {calculateProductTypeValue(produtos).combustivel ?
                                        <p className="infoPDF">Combustivel: {toMoneyFormat(calculateProductTypeValue(produtos).combustivel || 0)}</p> : null}
                                    <p className="infoPDF">Produtos: {toMoneyFormat(calculateProductTypeValue(produtos).produto || 0)}</p>
                                    <p className="infoPDF">Serviços: {toMoneyFormat(calculateProductTypeValue(produtos).servico || 0)}</p>
                                    <p className='infoPDF'> Cupons: {cupons.QUANTIDADE} - Média: {toMoneyFormat(+totalVendas / cupons.QUANTIDADE || 0) || '0,00'} - Total vendas: {toMoneyFormat(totalVendas)} </p>
                                    <p className="infoPDF fw-bold">Total a vista: {toMoneyFormat(+totalVendas - prazo)}</p>

                                    <p className='text-center text-center-pdf'>Créditos</p>
                                    <p className='infoPDF'>Total Inserção: {toMoneyFormat(insercao)} </p>
                                    <p className='infoPDF'>Total Cheques Emitidos: {toMoneyFormat(cheques)}</p>

                                    <p className='infoPDF fw-bold '>Total Recebimento: {toMoneyFormat(props.data[3].reduce((acc, m) => m.CLASSIFICACAO?.trim() === 'RB' ? (Number(acc) + toFixedNumber(m.VALOR)) : toFixedNumber(acc), 0))}
                                    </p>

                                    <p className='infoPDF fw-bold text-decoration-underline pr-3'>Total: {toMoneyFormat(insercao + cheques)}</p>

                                    <p className='text-center text-center-pdf'>Débitos</p>
                                    <p className='infoPDF fw-bold'>Total Prazo: {toMoneyFormat(prazo)} Cupons:{props.data[4][0].QTDCUPONS} Media:{toMoneyFormat(props.data[4][0].MEDIACUPOM)}</p>
                                    <p className='infoPDF'>Total Pagamento antecipado: {toMoneyFormat(pagamentoAntecipado)}</p>
                                    <p className='infoPDF'>Total Retirada: {toMoneyFormat(retirada)}</p>

                                    <p className='infoPDF'>Total Despesas: {toMoneyFormat(props.data[3].reduce((acc, m) => m.CLASSIFICACAO?.trim() === 'DP' ? (Number(acc) + toFixedNumber(m.VALOR)) : toFixedNumber(acc), 0))}
                                    </p>


                                    <p className='infoPDF fw-bold text-decoration-underline pr-3'>Total: {toMoneyFormat(prazo + retirada)}</p>
                                    <hr />
                                    <p className='infoPDF'>Sub-Total: R${toMoneyFormat(+totalVendas - retirada - prazo + insercao)}</p>

                                    <p className='text-center text-center-pdf'>Saldos caixa</p>

                                    {caixaInfo.SITUACAO === 'ABERTO'
                                        ? <table className="table table-sm">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Tipo</th>
                                                    <th scope="col">Quantidade</th>
                                                    <th scope="col">Total%</th>
                                                    <th scope="col">Original</th>
                                                    <th scope="col">Total com troco</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {metodosPag.map((dados, i) =>
                                                    <tr className='small-text-table' key={i}>
                                                        <th scope="row">
                                                            {dados.NOME}
                                                        </th>
                                                        <td>
                                                            {dados.QUANTIDADELANCAMENTOS}
                                                        </td>
                                                        <td>
                                                            {Number(dados.PORCCOMTROCO).toFixed(2)}%
                                                        </td>
                                                        <td>
                                                            {toMoneyFormat(dados.VALORORIGINAL)}
                                                        </td>
                                                        <td>
                                                            {toMoneyFormat(dados.VALORCOMTROCO)}
                                                        </td>
                                                        
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>

                                        : <table className="table table-sm">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Tipo</th>
                                                    <th scope="col">Quantidade</th>
                                                    <th scope="col">Total%</th>
                                                    <th scope="col">Original</th>
                                                    <th scope="col">Conciliação</th>
                                                    <th scope="col">C-Taxa</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {metodosPag.map((dados , i) =>
                                                
                                                    <tr className='small-text-table' key={i}>
                                                        <th scope="row">
                                                            {dados.NOME}
                                                        </th>
                                                        <td>
                                                            {dados.QUANTIDADELANCAMENTOS}
                                                        </td>
                                                        <td>
                                                            {Number(dados.PORCCONCILIADO).toFixed(2)}%
                                                        </td>
                                                        <td>
                                                            {toMoneyFormat(dados.VALORORIGINAL)}
                                                        </td>
                                                        <td>
                                                            {toMoneyFormat(dados.VALORCONCILIADO)}
                                                        </td>
                                                        <td>
                                                            {toMoneyFormat(dados.CONCIMENOSTAXA)}({Number(dados.VALORTAXA).toFixed(2)}%)
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>}


                                    <p className='infoPDF fw-bold text-decoration-underline pr-3'>Total Saldos: {toMoneyFormat(totalSaldo)}</p>
                                    {caixaInfo.SITUACAO === 'ABERTO' ?
                                        <p className='infoPDF fw-bold text-decoration-underline pr-3'>Saldo em dinheiro {toMoneyFormat(Number(insercao - retirada) + Number(caixaInfo.SITUACAO === 'ABERTO' ? metodosPag[0].VALORCOMTROCO : metodosPag[0]?.CONCIMENOSTAXA || 0))}</p>
                                        : null}
                                    {caixaInfo.SITUACAO !== 'ABERTO' ?
                                        <p className="infoPDF">(-)Perdas/(+)Sobras: {toMoneyFormat(+totalSaldo - (+totalVendas - retirada - prazo + insercao))}</p>
                                        : null}
                                </div>
                            </div>




                            <div className="column">
                                <div className='c-left'>
                                    {encerrantes.length !== 0 ? <div>
                                        <p className='text-center text-center-pdf fw-bold'>Encerrantes</p>
                                        <table className="table table-sm">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Bico</th>
                                                    <th scope="col">E.Inicial</th>
                                                    <th scope="col">E.Final</th>
                                                    <th scope="col">Saida</th>
                                                    <th scope="col">Aferição</th>
                                                    <th scope="col">Vendas</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {encerrantes.map((dados, i) =>
                                                    <tr className='small-text-table' key={i}>
                                                        <th scope="row">{dados.NUMERO}</th>
                                                        <td>{Number(dados.ENCERRANTEINICIAL).toFixed(3)}</td>
                                                        <td>{Number(dados.ENCERRANTEFINAL).toFixed(3)}</td>
                                                        <td>{Number(+dados.ENCERRANTEFINAL - +dados.ENCERRANTEINICIAL).toFixed(3)}</td>
                                                        <td>{Number(dados.AFERICAO).toFixed(3)}</td>
                                                        <td>{Number(dados.VENDAS).toFixed(3)}</td>

                                                    </tr>
                                                )}

                                            </tbody>
                                        </table></div> : null}
                                    <p className='text-center text-center-pdf fw-bold'>Vendas no turno</p>
                                    <table className="table table-sm">
                                        <thead>
                                            <tr>
                                                <th scope="col">Nº</th>

                                                <th scope="col">Produto</th>
                                                <th scope="col">P. Medio</th>
                                                <th scope="col">Quantidade</th>
                                                <th scope="col">Valor</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {grupoProdutos.map((grupo, i) =>
                                                <>

                                                    {produtos.map((dados, index) =>
                                                        dados.DESCRICAO === grupo.DESCRICAO ?
                                                            <tr className='small-text-table' key={index}>
                                                                <td>{dados.NUMERO_PRODUTO}</td>
                                                                <th scope="row">{dados.DESCRICAO_PRODUTO}</th>
                                                                <td>{toMoneyFormat(dados.PRECOMEDIO, 4)}</td>
                                                                <td>{Number(dados.QUANTIDADE).toFixed(3)}</td>
                                                                <td>{toMoneyFormat(dados.VALORNEGOCIACAO, 2)}</td>

                                                            </tr> : null
                                                    )}<tr key={i}>
                                                        <td colSpan={3}>一{'>'}{grupo.DESCRICAO}</td>

                                                        <td>{Number(grupo.QUANTIDADE).toFixed(2)}</td>
                                                        <td>{toMoneyFormat(grupo.VALORNEGOCIACAO)}</td>
                                                    </tr> <br />
                                                </>
                                            )}




                                        </tbody>
                                    </table>
                                    <p className='infoPDF fw-bold text-decoration-underline pr-3'> Total vendas: {toMoneyFormat(totalVendas)}</p>

                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    {props.data[6].showDetails === true ?
                        <CaixaDetalhes info={props.data[6]} detalhes={props.data[3]} />
                        : null}

                </div></div>
            <br />
            <button className="btn btn-success" onClick={() => window.print()}>Imprimir</button>


        </div>

    )
}

export default CaixaPDF