import { useEffect, useState } from "react"
import { Tabs, Tab } from "react-bootstrap"
import axios from '../../axiosConfig'
import {Paper} from '@mui/material'
import { toMoneyFormat } from "../../utilities/helperFunctions"
import { useGlobalState } from "../../globalState"

function InfoProdutos(props) {
    const [empresaAtual] = useGlobalState('empresaAtual')
    const [precos,setPrecos] = useState([])

  useEffect(()=>{
    axios.get(`produto/precos/${props.data.CODIGO}`).then((r)=>{
      setPrecos(r.data)
  }).catch(err => console.log(err))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  const handleNull = ()=>{
    //Troca campos Null por 一
    for(let[key,value] of Object.entries(props.data)){
      if(value === null)props.data[key] = '一'
    }
  }
  handleNull()

    return (
      <div>
      <div className=".arrow-left "><i onClick={()=>props.return()} className="fad fa-arrow-to-left arrow-left "></i></div>
      <Tabs defaultActiveKey="Gerais" id="uncontrolled-tab-example" className="mb-3">
        <Tab eventKey="Gerais" title="Gerais">
        <Paper elevation={3} className='p-2' sx={{backgroundColor:'#f5f0f0'}}>
            <div className="row mb-3">
              <div className="col-sm-2">Descrição:</div>
              <div className="col text-muted">{props.data.DESCRICAO}</div>
              <div className="col-sm-2">Nome fantasia:</div>
              <div className="col text-muted">{props.data.NOMEFANTASIA}</div>
            </div>

            <div className="row mb-3">
              <div className="col-sm-2">Codigo:</div>
              <div className="col text-muted">{props.data.CODIGO}</div>
              <div className="col-sm-2">Ativo:</div>
              <div className="col text-muted">{props.data.ATIVO?.trim() === "S" ? 'SIM' : "NÃO"}</div>
            </div>

              
            <div className="row mb-3">
              <div className="col-sm-2">Tipo:</div>
              <div className="col text-muted">{props.data.TIPOPRODUTO.trim()  === 'C'? 'Combustivel' : props.data.TIPOPRODUTO.trim() === 'P'? 'Produto' : 'Serviço' }</div>
              <div className="col-sm-2">Estoque minímo:</div>
              <div className="col text-muted">{Number(props.data.ESTOQUEREGULADOR).toFixed(empresaAtual.dec.quantidade)}</div>
            </div>

            <div className="row mb-3">
              <div className="col-sm-2">Tipo produto:</div>
              <div className="col text-muted">{ props.data.CODIGO_TIPOPRODUTO + ' - ' + props.data.DESCRICAO_TIPO}</div>
              <div className="col-sm-2">Grupo produto:</div>
              <div className="col text-muted">{props.data.CODIGO_GRUPOPRODUTO + ' - ' + props.data.DESCRICAO_GRUPO}</div>
            </div>

            <div className="row mb-3">
              <div className="col-sm-2">Permite alteração grupo:</div>
              <div className="col text-muted">{props.data.ALTERACAOGRUPO?.trim() === "S" ? 'SIM' : "NÃO"}</div>
              <div className="col-sm-2">Comissionado:</div>
              <div className="col text-muted">{props.data.COMISSIONADO?.trim() === "S" ? 'SIM' : "NÃO"}</div>
            </div>

            <div className="row mb-3">
              <div className="col-sm-2">Venda frente de caixa:</div>
              <div className="col text-muted">{props.data.VENDAFRENTECAIXA?.trim() === "S" ? 'SIM' : "NÃO"}</div>
              <div className="col-sm-2">Arredondamento:</div>
              <div className="col text-muted">{props.data.ARREDONDAMENTOTRUNCAMENTO.trim() === 'T' ? 'Truncamento' : 'Arredondamento' }</div>
            </div>

            <div className="row mb-3">
              <div className="col-sm-2">Bloquear estoque zerado:</div>
              <div className="col text-muted">{props.data.BLOQUEARVENDAESTOQUEZERADO?.trim() === "S" ? 'SIM' : "NÃO"}</div>
              <div className="col-sm-2">Produção:</div>
              <div className="col text-muted">{props.data.PRODUCAOPROPRIA?.trim() === 'P' ? 'Própria' : 'Terceiros'}</div>
            </div>

            <div className="row mb-3">
              <div className="col-sm-2">Unidade venda:</div>
              <div className="col text-muted">{props.data.UNIDADE}</div>
              <div className="col-sm-2">Unidade compra:</div>
              <div className="col text-muted">{props.data.UNIDADEENTRADA}</div>
              <div className="col-sm-2">Itens embalagem:</div>
              <div className="col text-muted">{Number(props.data.UNIDADECONVERSAO).toFixed(2)}</div>
            </div>
            </Paper>
           
        </Tab>
        <Tab eventKey="Codigos" title="Códigos">
        <Paper elevation={3} className='p-2' sx={{backgroundColor:'#f5f0f0'}}>
        <div className="row mb-3">
              <div className="col-sm-2">Cód Estendido:</div>
              <div className="col text-muted">{props.data.CODIGOESTENDIDO}</div>
              <div className="col-sm-2">Cód Barras:</div>
              <div className="col text-muted">{props.data.CODIGOBARRAS}</div>
        </div>

        <div className="row mb-3">
              <div className="col-sm-2">Cód Fabricante:</div>
              <div className="col text-muted">{props.data.CODIGOFABRICANTE}</div>
              <div className="col-sm-2">Cód NCM:{props.data.CODIGO_PRODUTOSEFAZ}</div>
              <div className="col text-muted">{props.data.CODIGOBARRAS}</div>
        </div>
        <div className="row mb-3">
              <div className="col-sm-2">ANP:</div>
              <div className="col text-muted">{props.data.CODIGOANP + ' 一 ' + props.data.DESCRICAOANP}</div>
              <div className="col-sm-2">Cód Ticket:</div>
              <div className="col text-muted">{props.data.CODIGOTICKET}</div>
        </div>

        <div className="row mb-3">
              <div className="col-sm-2">Cód Tp. Serviço:</div>
              <div className="col text-muted">{props.data.CODIGOTIPOSERVICO}</div>
              <div className="col-sm-2">Cód Natureza da receita:</div>
              <div className="col text-muted">{props.data.CODIGONATUREZARECEITA}</div>
        </div>

        <div className="row mb-3">
              <div className="col-sm-2">Cód SEFAZ:</div>
              <div className="col text-muted">{props.data.CODIGO_PRODUTOSEFAZ}</div>
              <div className="col-sm-2">Cód SAP:</div>
              <div className="col text-muted">{props.data.CODIGO_SAP}</div>
        </div>

        <div className="row mb-3">
              <div className="col-sm-2">Cód CEST:</div>
              <div className="col text-muted">{props.data.CODIGO_CEST}</div>
              <div className="col-sm-2">Cód Etiqueta:</div>
              <div className="col text-muted">{props.data.CODIGOETIQUETA}</div>
        </div>


            <div className="form-group">
    <label htmlFor="exampleFormControlTextarea1" className='fw-bold'>Código de barras estendido</label>
    <textarea className="form-control" readOnly value={props.data.CODIGOBARRASESTENDIDO} id="exampleFormControlTextarea1" rows="3"></textarea>
  </div>
      </Paper>

        </Tab>
        <Tab eventKey="Tributacao" title="Tributação">
        <Paper elevation={3} className='p-2' sx={{backgroundColor:'#f5f0f0'}}>
        <div className="row mb-3">
              <div className="col-sm-2">Codigo ST Entrada:</div>
              <div className="col text-muted">{props.data.CODIGO_STENTRADA + ' 一 ' + props.data.DESCRICAO_CSTENTRADA}</div>
              <div className="col-sm-2">Codigo ST Saída:</div>
              <div className="col text-muted">{props.data.CODIGO_ST + ' 一 ' + props.data.DESCRICAO_CST}</div>
        </div>
        <div className="row mb-3">
          <div className="col-sm-2">Alíquota IPI:</div>
          <div className="col text-muted">{Number(props.data.ALIQUOTAIPI).toFixed(2)}</div>
        </div>
        <hr />
        <h5>Por empresa:</h5>
        <div className="row mb-3">
              <div className="col-sm-2">Calcula PIS:</div>
              <div className="col text-muted">{props.data.CALCULAPIS?.trim() === "S" ? 'SIM' : "NÃO"}</div>
              <div className="col-sm-2">CST PIS:</div>
              <div className="col text-muted">{props.data.CSTPIS}</div>
              <div className="col-sm-2">CST PIS entrada:</div>
              <div className="col text-muted">{props.data.CSTPISENTRADA}</div>
              <div className="col-sm-2">Alíquota PIS:</div>
              <div className="col text-muted">{Number(props.data.ALIQUOTAPIS).toFixed(2)}</div>
            </div>

            <div className="row mb-3">
              <div className="col-sm-2">Calcula COFINS:</div>
              <div className="col text-muted">{props.data.CALCULACOFINS?.trim() === "S" ? 'SIM' : "NÃO"}</div>
              <div className="col-sm-2">CST COFINS:</div>
              <div className="col text-muted">{props.data.CSTCOFINS}</div>
              <div className="col-sm-2">CST COFINS entrada:</div>
              <div className="col text-muted">{props.data.CSTCOFINSENTRADA}</div>
              <div className="col-sm-2">Alíquota COFINS:</div>
              <div className="col text-muted">{Number(props.data.ALIQUOTACOFINS).toFixed(2)}</div>
            </div>

            <div className="row mb-3">
              <div className="col-sm-2">Alíquota FCP:</div>
              <div className="col text-muted">{Number(props.data.ALIQUOTAFCP).toFixed(2)}</div>
              <div className="col-sm-2">Alíquota ISS:</div>
              <div className="col text-muted">{Number(props.data.ALIQUOTAISS).toFixed(2)}</div>
              <div className="col-sm-2">Alíquota ICMS reduzida:</div>
              <div className="col text-muted">{Number(props.data.ALIQUOTAICMS).toFixed(2)}</div>
              <div className="col-sm-2">Alíquota MVA ST:</div>
              <div className="col text-muted">{Number(props.data.MVAST).toFixed(2)}</div>
            </div>

      <hr />
         <div className="row mb-3">
              <div className="col-sm-2">AC:<span className='text-muted'>{Number(props.data.UF_AC).toFixed(2)}</span></div>
              <div className="col-sm-2">AL:<span className='text-muted'>{Number(props.data.UF_AL).toFixed(2)}</span></div>
              <div className="col-sm-2">AM:<span className='text-muted'>{Number(props.data.UF_AM).toFixed(2)}</span></div>
              <div className="col-sm-2">AP:<span className='text-muted'>{Number(props.data.UF_AP).toFixed(2)}</span></div>
              <div className="col-sm-2">BA:<span className='text-muted'>{Number(props.data.UF_BA).toFixed(2)}</span></div>
              <div className="col-sm-2">CE:<span className='text-muted'>{Number(props.data.UF_CE).toFixed(2)}</span></div>
              <div className="col-sm-2">ES:<span className='text-muted'>{Number(props.data.UF_ES).toFixed(2)}</span></div>
              <div className="col-sm-2">GO:<span className='text-muted'>{Number(props.data.UF_GO).toFixed(2)}</span></div>
              <div className="col-sm-2">MA:<span className='text-muted'>{Number(props.data.UF_MA).toFixed(2)}</span></div>
              <div className="col-sm-2">MG:<span className='text-muted'>{Number(props.data.UF_MG).toFixed(2)}</span></div>
              <div className="col-sm-2">MS:<span className='text-muted'>{Number(props.data.UF_MS).toFixed(2)}</span></div>
              <div className="col-sm-2">MT:<span className='text-muted'>{Number(props.data.UF_MT).toFixed(2)}</span></div>
              <div className="col-sm-2">PA:<span className='text-muted'>{Number(props.data.UF_PA).toFixed(2)}</span></div>
              <div className="col-sm-2">PB:<span className='text-muted'>{Number(props.data.UF_PB).toFixed(2)}</span></div>
              <div className="col-sm-2">PE:<span className='text-muted'>{Number(props.data.UF_PE).toFixed(2)}</span></div>
              <div className="col-sm-2">PI:<span className='text-muted'>{Number(props.data.UF_PI).toFixed(2)}</span></div>
              <div className="col-sm-2">PR:<span className='text-muted'>{Number(props.data.UF_PR).toFixed(2)}</span></div>
              <div className="col-sm-2">RJ:<span className='text-muted'>{Number(props.data.UF_RJ).toFixed(2)}</span></div>
              <div className="col-sm-2">RN:<span className='text-muted'>{Number(props.data.UF_RN).toFixed(2)}</span></div>
              <div className="col-sm-2">RO:<span className='text-muted'>{Number(props.data.UF_RO).toFixed(2)}</span></div>
              <div className="col-sm-2">RR:<span className='text-muted'>{Number(props.data.UF_RR).toFixed(2)}</span></div>
              <div className="col-sm-2">RS:<span className='text-muted'>{Number(props.data.UF_RS).toFixed(2)}</span></div>
              <div className="col-sm-2">SC:<span className='text-muted'>{Number(props.data.UF_SC).toFixed(2)}</span></div>
              <div className="col-sm-2">SE:<span className='text-muted'>{Number(props.data.UF_SE).toFixed(2)}</span></div>
              <div className="col-sm-2">SP:<span className='text-muted'>{Number(props.data.UF_SP).toFixed(2)}</span></div>
              <div className="col-sm-2">TO:<span className='text-muted'>{Number(props.data.UF_TO).toFixed(2)}</span></div>
              <div className="col-sm-2">DF:<span className='text-muted'>{Number(props.data.UF_DF).toFixed(2)}</span></div>
            </div>
            </Paper>

        </Tab>
        <Tab eventKey="Resumos" title="Resumos">
        <Paper elevation={3} className='p-2' sx={{backgroundColor:'#f5f0f0'}}>
          <div className="row mb-3">
              <div className="col-sm-2">Data cadastro:</div>
              <div className="col text-muted">{props.data.DATACADASTRO?.replaceAll('-', '/').split('/').reverse().join('/')}</div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-2">Estoque atual:</div>
              <div className="col text-muted">{Number(props.data.ESTOQUE).toFixed(empresaAtual.dec.quantidade)}</div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-2">Última venda:</div>
              <div className="col text-muted">{props.data.ULTIMAVENDA}</div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-2">Última compra:</div>
              <div className="col text-muted">{props.data.ULTIMACOMPRA?.replaceAll('-', '/').split('/').reverse().join('/')}</div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-2">Último custo:</div>
              <div className="col text-muted">{toMoneyFormat(props.data.ULTIMOCUSTO,3)}</div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-2">Custo atual:</div>
              <div className="col text-muted">{toMoneyFormat(props.data.CUSTOATUAL,3)}</div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-2">Data custo atual:</div>
              <div className="col text-muted">{props.data.DATACUSTOATUAL?.replaceAll('-', '/').split('/').reverse().join('/')}</div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-2">Última atualização:</div>
              <div className="col text-muted">{props.data.DATAATUALIZACAO?.replaceAll('-', '/').split('/').reverse().join('/')}</div>
            </div>
        </Paper>
        </Tab>
        <Tab eventKey="preco" title="Preço venda">
          
        <div className="table-responsive">
        <table className="table table-secondary table-hover">
                        <thead className="table-primary ">
                            <tr>
                                <th scope="col">Descricao</th>
                                <th scope="col">Preço venda</th>
                                <th scope="col">A vista</th>
                                <th scope="col">A prazo</th>
                                <th scope="col">Antecipado</th>
                            </tr>
                        </thead>
                        <tbody>
                          {precos.map((preco)=>
                          <tr>
                          <td>{preco.DESCRICAO}</td> 
                          <td>{Number(preco.PRECOVENDA).toFixed(empresaAtual.dec.valor)}</td>
                          <td>{preco.TIPOVISTA}</td>
                          <td>{preco.TIPOPRAZO}</td>
                          <td>{preco.TIPOANTECIPADO}</td>
                        </tr>
                          
                          )}
                          
                        </tbody>
              </table></div>
        </Tab>
        </Tabs>
       </div>
  
  
  
 
    )
  }
  
  export default InfoProdutos