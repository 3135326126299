import { formatDate } from '../../../utilities/helperFunctions'
import SearchData from '../../../utilities/searchs/searchData'
import { useState } from "react"
import axios from '../../../axiosConfig'
import Loading from '../../../utilities/loading'
import { Button, Paper } from '@mui/material'
import { Send } from '@mui/icons-material'

function Despesas(props) {
    const [firstDate, setFirstDate] = useState(new Date)
    const [lastDate, setLastDate] = useState(new Date)
    const [isFullLoading, setIsFullLoading] = useState(false)

    const getDespesas = () => {
        const postData = {
            dataPagamentoInicial: `${formatDate(firstDate, 'db')}`,
            dataPagamentoFinal: `${formatDate(lastDate, 'db')}`,

        }
        setIsFullLoading(true)
        axios.post('/fornecedores/despesas', postData, )

            .then((r) => {
                if (r.data.length < 1) return props.showPDF(true, 'Sem despesas')
                let dataDespesas = { data: r.data, date: { firstDate: formatDate(firstDate, 'front'), lastDate: formatDate(lastDate, 'front') } }
                props.setPDFData(dataDespesas)
                props.showPDF(false)
            }).catch(err => {
                console.log(err)
            }).finally(() => setIsFullLoading(false))
    }


    return (
        <div>

            {isFullLoading ? <Loading></Loading> : null}
            <Paper elevation={3} className='p-2' sx={{ backgroundColor: '#f5f0f0' }}>
                <h4 className='relatorio-title'>Despesas</h4>
                <SearchData firstDate={firstDate} lastDate={lastDate}
                    setFirstDate={setFirstDate} setLastDate={setLastDate}></SearchData>
                <Button variant='contained' endIcon={<Send />} onClick={() => getDespesas()}>Gerar</Button>
            </Paper>
        </div>

    )
}

export default Despesas