import { toFixedNumber,toMoneyFormat } from '../../../../utilities/helperFunctions'
import TotalAbastecimentosPDF from './totalAbastecimentoPDF'
import {IAbastecimentos} from './interfacePDFs'
import { ShowEmpresa } from '../../showEmpresa';
import PrintFooter from "../../printFooter";
import AbastecimentoGraph from '../abastecimentoGraph';

interface IProps extends IAbastecimentos {
    date: {
        firstDate: string, lastDate: string
        firstHour: string, lastHour: string
    }
}


function AbastecimentoBicoPDF(props:IProps) {

    const bicos = props.data.reduce((acc,item)=>{
        if(acc.indexOf(item.ID_BOMBA) === -1){
            acc.push(item.ID_BOMBA)
            acc.sort(function(a, b){return a - b;})
            return acc
        }else{
            return acc
        }
    },[] as number[])

    const getTotalQuantidade = (bico:number)=>{
       const total =  props.data.reduce((acc,item)=>{
            if(item.ID_BOMBA === bico) {
                acc += toFixedNumber(item.TOTALLITROS,3)
                return acc
            }else return acc
        },0)
        return total
    }
    const getTotalBico = (bico:number)=>{
        const total =  props.data.reduce((acc,item)=>{
             if(item.ID_BOMBA === bico) {
                acc++
                return acc
             }else return acc
         },0)
         return total
     }

    const getTotal = (bico:number)=>{
        const total =  props.data.reduce((acc,item)=>{
             if(item.ID_BOMBA === bico) {
                 acc += toFixedNumber(item.TOTALDINHEIRO,2)
                 return acc
             }else return acc
         },0)
         return total
     }

    return(
        
            <div className='container'>
            <div className='pdf' id='section-to-print'>

            <ShowEmpresa/>
                <h6>Vendas &gt; Abastecimento - Periodo: {props.date.firstDate} a {props.date.lastDate} |
                {props.date.firstHour} - {props.date.lastHour}|
                </h6>
                {props.btnTable && <AbastecimentoGraph data={props.data}/>}
                <div className="table-responsive">
                    <table className="table table-sm">
                        <thead className='thead-wrap'>
                            <tr>
                                <th scope="col">Bico</th>
                                <th scope="col">Produto</th>
                                <th scope="col">Vl. unit</th>
                                <th scope="col">Qtde</th>
                                <th scope="col">Total</th>
                                <th scope="col">Encerrante</th>
                                <th scope="col">Data</th>
                                <th scope="col">Hora</th>
                                <th scope="col">Registro</th>
                            </tr>
                        </thead>
                            <tbody>
                                {bicos.map(bico =>
                                <>
                                    <tr>
                                        <td className='fw-bold'>{props.data.find(i=>i.ID_BOMBA === bico)?.NUMERO_BICO}</td>
                                        <td className='fw-bold' colSpan={8}>{props.data.find(i=>i.ID_BOMBA === bico)?.DESCRICAO_PRODUTO}</td>   
                                    </tr>
                                    {props.data.map(item=>
                                    item.ID_BOMBA === bico ?
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td>{Number(item.PRECOUNITARIO).toFixed(3)}</td>
                                            <td>{Number(item.TOTALLITROS).toFixed(3)}</td>
                                            <td>{toMoneyFormat(item.TOTALDINHEIRO)}</td>
                                            <td>{item.ENCERRANTEREAL}</td>
                                            <td>{item.DATAABASTECIMENTO.replaceAll('-', '/').split('/').reverse().join('/')}</td>
                                            <td>{item.HORAABASTECIMENTO}</td>
                                            <td>{item.REGISTRO}</td>
                                        </tr> : null
                                    )}
                                    <tr>
                                        <td className='fw-bold' colSpan={3}>Totais do bico: {getTotalBico(bico)}</td>
                                        <td className='fw-bold' colSpan={1}>{(getTotalQuantidade(bico).toFixed(3))}</td>
                                        <td className='fw-bold' colSpan={5}>{(toMoneyFormat(getTotal(bico)))}</td>
                               
                                    </tr>
                                    </>
                                    )}
                            </tbody></table>
                          
                        </div>
                        <TotalAbastecimentosPDF data={props.data}/>
                        <PrintFooter/>
                </div>
                </div>
    )

}
export default AbastecimentoBicoPDF