import { Button, Paper } from '@mui/material'
import { Send } from '@mui/icons-material'
import axios from '../../../axiosConfig'
import Loading from '../../../utilities/loading'
import React from 'react'

interface IProps {
    showPDF: (error: boolean, errorMessageP?: string) => void
    setPDFData: React.Dispatch<React.SetStateAction<{}>>
  }

function VendasDoDia(props:IProps) {
    const [isFullLoading, setIsFullLoading] = React.useState(false)

    const getVendasDoDia = async () => {
        setIsFullLoading(true)
        const dataPDV = axios.post('relatorios/vendas/dia', { banco: 'PDV' },
            { headers: { 'Database-Type': 'PDV' } }).then(r => r.data)
        const dataRET = axios.post('relatorios/vendas/dia', { banco: 'RET' },
            { headers: { 'Database-Type': 'RET' } }).then(r => r.data)
        await Promise.all([dataPDV,dataRET]).then(r=> {
            if (r[0].length < 1 && r[1].length < 1 ) return props.showPDF(true, 'Sem vendas encontradas')
            props.setPDFData({data:r})
            props.showPDF(false)
        }
            )
        .catch(err=> props.showPDF(true, 'Erro ao buscar informação'))
        .finally(() => setIsFullLoading(false))
    }

    return (
        <div>
             {isFullLoading ? <Loading /> : null}
            <Paper elevation={3} className='p-2' sx={{ backgroundColor: '#f5f0f0' }}>
                <p>*Serão computadas as vendas dos caixas abertos e fechados no relatório</p>
                <Button variant='contained' endIcon={<Send />} onClick={() => getVendasDoDia()}>Gerar</Button>

            </Paper>
        </div>
    )
}

export default VendasDoDia