import { useGlobalState } from '../globalState'
import HandleEmpresas from '../utilities/changeEmpresa'
import {  Paper } from '@mui/material'

function Home() {

  const [empresaAtual] = useGlobalState('empresaAtual')
  const [filialAtual] = useGlobalState('filialAtual')

  if (empresaAtual === undefined || filialAtual === undefined) return <div></div>
  return (

    <div>

      <Paper elevation={3} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.75)' }}>
        <div className="d-flex title">
          <div className="p-2 me-auto"> <span className='empresa-atual'>{empresaAtual?.fantasia} - {empresaAtual?.cnpj}</span></div>
          <div><HandleEmpresas /></div>
        </div>
      </Paper>
      {filialAtual.CNPJ && empresaAtual?.cnpj !== filialAtual?.CNPJ?.trim() ?
        <div className='ps-3'>
          <p className='fs-6'>
            {filialAtual.FANTASIA + " - " + filialAtual.CNPJ}
          </p>
        </div>
        : null}

    </div>
  )
}

export default Home