import { VendasMulti as IProps } from '../Vendas/interfaceValoresVenda'
import TablePDF from './tablePDF'
import { toMoneyFormat } from '../../../utilities/helperFunctions'

function MultiEmpresaValoresVendaPDF(props: IProps) {

    const getLancamentosEmpresas = () => {
        return props.data.reduce((acc, item) => {
            acc += item.reduce((acc2, item2) => {
                acc2 += item2.QUANTIDADELANCAMENTOS
                return acc2
            }, 0)
            return acc
        }, 0)

    }
    const getTotalEmpresas = () => {
        return props.data.reduce((acc, item) => {
            acc += item.reduce((acc2, item2) => {
                acc2 += Number(item2.VALOR)
                return acc2
            }, 0)
            return acc
        }, 0)
    }
    

    return (
        <div className='container'>
            <div className='pdf' id='section-to-print'>
                <h6>{props.empresas.map(item => item + ' | ')}</h6>
                <h6>Resumo vendas - Periodo: {props.date.firstDate} a {props.date.lastDate}</h6>
                <br />
                {props.data.map((item, i) => item.length > 0 ?
                    <TablePDF data={item} empresa={props.empresas[i]} />
                    : null)}
                           <hr />
                <p className='infoPDF fw-bold pr-3 text-end'>Lançamentos todas empresas: {getLancamentosEmpresas()} |
                     total todas empresas: {toMoneyFormat(getTotalEmpresas())} </p>
            </div>
        </div>
    )
}
export default MultiEmpresaValoresVendaPDF