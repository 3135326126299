import {Alert} from '@mui/material'
import React from 'react'

interface IProps{
    error: string
    clearError: ()=>void
}

function ErrorAlert(props:IProps){

    return(
        <Alert severity="error" onClose={() => props.clearError()}>{props.error}</Alert>
    )
}

export default ErrorAlert