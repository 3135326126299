import React from 'react';
import { useGlobalState } from '../../../globalState'
import { toMoneyFormat, toFixedNumber } from '../../../utilities/helperFunctions';
import TableSort from '../../../utilities/TableSortTS'
import PrintFooter from "../printFooter";
import VendasProdutosGraph from './vendasProdutosGraph';

interface Data {
    NUMERO_GRUPOPRODUTO: number;
    TIPOPRODUTO: string;
    DESCRICAO: string;
    NUMERO_PRODUTO: number;
    DESCRICAO_PRODUTO: string;
    QUANTIDADE: string;
    PRECO_MEDIO: string;
    TOTALCOMDESCACR: string;
    TOTALDESCACRAPLICADO: string;
}

export interface IProps {
    data: Data[]
    date: { firstDate: string, lastDate: string }
    btnTable?:boolean
}

function VendasProdutosPDF(props: IProps) {
    const grupos = [...new Map(props.data.map((item) => [item['DESCRICAO'], item])).values()]
    const [empresaAtual] = useGlobalState('empresaAtual')

    const columns = React.useMemo(
        () => [
            {
                Header: " ",
                columns: [
                    {
                        Header: "Codigo",
                        accessor: "NUMERO_PRODUTO",
                    },
                    {
                        Header: "Descrição",
                        accessor: "DESCRICAO_PRODUTO",
                        minWidth: 350
                    },
                    {
                        Header: "Qtde",
                        accessor: "QUANTIDADE",
                        Cell: ({ value }: { value: string }) => Number(value).toFixed(3),
                    },
                    {
                        Header: "Preço medio",
                        accessor: "PRECO_MEDIO",
                        Cell: ({ value }: { value: string }) => toMoneyFormat(value, 4),
                    },
                    {
                        Header: "Desc/acr aplicado",
                        accessor: "TOTALDESCACRAPLICADO",
                        Cell: ({ value }: { value: string }) => toMoneyFormat(value, 2),
                    },
                    {
                        Header: "Total",
                        accessor: "TOTALCOMDESCACR",
                        Cell: ({ value }: { value: string }) => toMoneyFormat(value, 2),
                    },

                ],
                Footer: () => <p className='infoPDF fw-bold pr-3 text-end'></p>
            }
        ], [])

    const calculateTotalVendaG = (grupo: number) => props.data.reduce((acc, item) => {
        if (item.NUMERO_GRUPOPRODUTO === grupo) acc += toFixedNumber(item.TOTALCOMDESCACR, 3)
        return acc
    }, 0)
    const calculateTotalQuantidadeG = (grupo: number) => props.data.reduce((acc, item) => {
        if (item.NUMERO_GRUPOPRODUTO === grupo) acc += toFixedNumber(item.QUANTIDADE, 3)
        return acc
    }, 0)

    const TotalVenda = props.data.reduce((acc, item) => {
        acc += toFixedNumber(item.TOTALCOMDESCACR, 3)
        return acc
    }, 0)

    return (
        <div className='container'>
            <div className='pdf' id='section-to-print'>
                <h4>{empresaAtual.nome}</h4>
                <h6>Vendas &gt; Vendas produtos - Periodo: {props.date.firstDate} a {props.date.lastDate}</h6>
                <hr />
                {props.btnTable && <VendasProdutosGraph data={props.data}/>}
                {grupos.map(item =>
                    <>
                        <p className='fw-bold'>{item.DESCRICAO} </p>
                        <div className='table-responsive'>
                            <TableSort columns={columns} data={props.data.filter(i => item.NUMERO_GRUPOPRODUTO === i.NUMERO_GRUPOPRODUTO)} />
                        </div>
                        <p className='fw-bold text-end'>Total grupo: {toMoneyFormat(calculateTotalVendaG(item.NUMERO_GRUPOPRODUTO))}</p>
                    </>
                )}
                <h6 className='fw-bold text-end'>Total: {toMoneyFormat(TotalVenda)}</h6>
                <PrintFooter/>
            </div></div>
    )

}
export default VendasProdutosPDF