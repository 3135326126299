import { Modal } from 'react-bootstrap'
import { useState } from "react"
import { useGlobalState, setGlobalState } from '../../globalState'
import { List, ListItemText, ListItemButton, ListItemIcon, Radio, Button } from '@mui/material'
import { Send, HomeWork, Close } from '@mui/icons-material'

function HandleFiliais(props) {

  const [empresaAtual] = useGlobalState('empresaAtual')
  const [selectedFilial, setSelectedFilial] = useState(empresaAtual)

  return (
    <div>
      <Modal
        animation={false}
        centered
        show={true}
        backdrop="static"
        keyboard={true}
      >
        <Modal.Body style={{ padding: 0, overflowY: 'scroll', maxHeight: '400px' }}>
          <List component="nav" aria-label="main mailbox folders">
            {empresaAtual?.filiais?.map((filial) =>

              <ListItemButton key={filial.FANTASIA + filial.NUMERO} dense sx={{ paddingBottom: '0px', paddingTop: '0px' }}
                disabled={filial.HABILITARRETORNO.trim() === "M" ? true : false}
                selected={filial.NUMERO === selectedFilial.NUMERO ? true : false}
                onClick={() => setSelectedFilial(filial)}
              >
                <ListItemIcon >
                  <Radio checked={filial.NUMERO === selectedFilial.NUMERO ? true : false} />
                </ListItemIcon>
                <ListItemText primary={filial.FANTASIA}
                  primaryTypographyProps={{
                    fontSize: 14, fontWeight: '600'
                  }} secondary={filial.CNPJ}
                  secondaryTypographyProps={{
                    color: filial.HABILITARRETORNO.trim() === "M" ? 'purple' : 'orange'
                  }} />

              </ListItemButton>

            )}
          </List>
        </Modal.Body>
        <Modal.Footer style={{ padding: '0.15rem', backgroundColor: '#bdc3c7' }}>
          <Button variant='contained' color='error' endIcon={<Close />} sx={{ m: 0.5 }}
            onClick={() => {
              props.close()
            }
            }>
            Fechar
          </Button>
          <Button variant='contained' color='secondary' endIcon={<HomeWork />} sx={{ m: 0.5 }}
            onClick={() => {
              setGlobalState('filialAtual', empresaAtual.filiais.find(x => x.HABILITARRETORNO.trim() === "M"))
              props.close()
            }
            }>
            Usar matriz
          </Button>
          <Button variant='contained' color='success' endIcon={<Send />} sx={{ m: 0.5 }}
            onClick={() => {
              setGlobalState('filialAtual', selectedFilial)
              window.localStorage.setItem('filial',JSON.stringify(selectedFilial.NUMERO || null))
              props.close()
            }
            }>
            Concluir
          </Button>
        </Modal.Footer>


      </Modal>
    </div>
  )
}

export default HandleFiliais