import { RelatorioGeral } from './interfacesPDF'
import { useGlobalState } from '../../../../globalState'
import TableSort from '../../../../utilities/TableSortTS'
import { useMemo } from 'react'
import { toMoneyFormat } from '../../../../utilities/helperFunctions'
import { Stack } from '@mui/material'
import PrintFooter from "../../printFooter";


interface IProps {
    data: RelatorioGeral['data']
    date: { dataInicial: string, dataFinal: string }
    options?: { filiaisSelecionadas: string[] }
}

function ResumoGeralPDF(props: IProps) {
    const [empresaAtual] = useGlobalState('empresaAtual')


    const columns = useMemo(
        () => [
            {
                Header: " ",

                columns: [
                    {
                        Header: "Empresa",
                        accessor: "FANTASIA",
                    },
                    {
                        Header: "Porc %",
                        accessor: "PORCVALOR",
                        Cell: ({ value }: { value: string }) => Number(value).toFixed(2),
                        disableSortBy: true
                    },
                    {
                        Header: "Total",
                        accessor: "TOTALEMPRESA",
                        Cell: ({ value }: { value: string }) => toMoneyFormat(value),
                    },
                ],
                Footer: () => <p className='infoPDF fw-bold pr-3 text-end'>Total de lançamentos: {props.data.length} | Total Des/Acr: R${''}  |  Total: R${''}</p>
            }
        ], [])

    return (
        <div className='container'>
            <div className='pdf' id='section-to-print'>
                <h5>{empresaAtual.nome}</h5>
                <h6>Multiloja &gt; Resumo valores de venda &gt; Resumido - Periodo: {props.date.dataInicial} a {props.date.dataFinal}</h6>
                <hr />
                <p style={{ fontSize: '12px' }}>{props.options?.filiaisSelecionadas.map(item => item + ' | ')}</p>
                <hr />
                <div className="table-responsive">
                    <TableSort columns={columns} data={props.data}></TableSort>
                </div>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >
                    <h6 className=''>Empresas:{props.data.length}</h6>
                    <h6 className='fw-bold text-end'>Total: {toMoneyFormat(props.data[0].TOTALVALOR)}</h6>
                </Stack>
                <PrintFooter/>
            </div>
        </div>
    )
}
export default ResumoGeralPDF