import * as React from 'react';
import { DataGrid, GridColDef,GRID_CHECKBOX_SELECTION_COL_DEF,ptBR } from '@mui/x-data-grid';
import { ICaixa, ICaixaAberto } from './interfaceCaixa';

const columns: GridColDef[] = [
    {...GRID_CHECKBOX_SELECTION_COL_DEF,width:40,headerClassName:'table-header-gray',
    description: 'Selecionar/Remover todos caixas',},
    { field: 'NUMERO', headerName: 'Número', width: 70 ,headerClassName:'table-header-gray'},
    { field: 'NOME_FUNCIONARIO', headerName: 'Funcionario',flex:1,minWidth:130 ,headerClassName:'table-header-gray'},
    { field: 'USUARIO', headerName: 'Usuario', flex:1 ,minWidth: 130 ,headerClassName:'table-header-gray'},
    {
      field: 'DATAABERTURA',
      headerName: 'Data de abertura',
      type: 'number',
      width: 160,headerClassName:'table-header-gray'
    },
    {
      field: 'HORAABERTURA',
      headerName: 'Hora de abertura',
      sortable: false,
      width: 160,headerClassName:'table-header-gray'
    }
  ];

  interface IProps{
    data:ICaixa[] | ICaixaAberto[],
    setSelectedCaixa: React.Dispatch<React.SetStateAction<{ id: number, numero: number }[]>>
  }

  export default function SelectCaixasTable(props:IProps) {
    return (
      <div style={{ height: 500, width: '100%' }}>
        <DataGrid
          rows={props.data}
          columns={columns}
          rowsPerPageOptions={[]}
          checkboxSelection
          onSelectionModelChange={itm=>{
            const caixas = itm.map((id)=> {
              return {id:Number(id),numero: props.data.find((item)=>item.ID === id)?.NUMERO||0}   
              })
              props.setSelectedCaixa(caixas)
            
          }}
          getRowId={(row:any)=>row.ID}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        />
      </div>
    );
  }
  