import * as React from 'react';
import { useState } from 'react'
import axios from '../../../axiosConfig'
import { AxiosError } from 'axios';
import { formatDate, formatHour } from '../../../utilities/helperFunctions'
import SearchData from "../../../utilities/searchs/searchData"
import { SearchHour } from '../../../utilities/searchs/searchData'
import SearchProdutos from '../../../utilities/searchs/searchProdutos'
import SearchBico from '../../../utilities/searchs/searchBico'
import { Button, TextField, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel,Paper } from '@mui/material'
import { Send } from '@mui/icons-material'
import Loading from '../../../utilities/loading'

interface IProps {
    showPDF: (error: boolean, errorMessageP?: string) => void
    setPDFData: React.Dispatch<React.SetStateAction<{}>>
}
function Abastecimento(props: IProps) {
    const [firstDate, setFirstDate] = useState(new Date)
    const [lastDate, setLastDate] = useState(new Date)
    const [firstHour, setFirstHour] = useState(new Date)
    const [lastHour, setLastHour] = useState(new Date)
    const [codigoProduto, setCodigoProduto] = useState(0)
    const [codigoBico, setCodigoBico] = useState(0)
    const [litrosInicial, setLitrosInicial] = useState<string>('')
    const [litrosFinal, setLitrosFinal] = useState<string>('')
    const [isFullLoading, setIsFullLoading] = useState(false)
    const [agrupadoPor, setAgrupadoPor] = useState('bico')
    const [tipoCaixa,setTipoCaixa] = useState('ADM')

    const getAbastecimento = () => {
        setIsFullLoading(true)
        console.log(`${formatHour(firstDate)}`)
        const postData = {
            dataInicial: `${formatDate(firstDate, 'db')}`, dataFinal: `${formatDate(lastDate, 'db')}`,
            horaInicial: `${formatHour(firstHour)}`, horaFinal: `${formatHour(lastHour)}`,
            bicoInicial: codigoBico === 0 ? 1 : codigoBico, bicoFinal: codigoBico === 0 ? 999 : codigoBico,
            codigoInicial: codigoProduto === 0 ? 1 : codigoProduto, codigoFinal: codigoProduto === 0 ? 99999 : codigoProduto,
            litrosInicial: litrosInicial === '' ? 0 : Number(litrosInicial),
            litrosFinal: litrosFinal === '' ? 999 : Number(litrosFinal),
            tipo:agrupadoPor
        }
        axios.post('/relatorios/abastecimento', postData, { headers: { 'Database-Type': tipoCaixa } } )
            .then(r => {
                if (r.data.length < 1) return props.showPDF(true, 'Sem registros para a data informada')
                console.log(r.data)
                props.setPDFData({
                    data: r.data,
                    date: {
                        firstDate: formatDate(firstDate, 'front'), lastDate: formatDate(lastDate, 'front'),
                        firstHour: `${formatHour(firstHour)}`, lastHour: `${formatHour(lastHour)}`
                    },
                    options:{agrupamento:agrupadoPor}

                })
            }).catch(error => {
                const err = error as AxiosError
                console.log(err.response?.data)
            }).finally(() => setIsFullLoading(false))
    }

    return (
        <div>
            {isFullLoading ? <Loading /> : null}
            <Paper elevation={3} className='p-2' sx={{backgroundColor:'#f5f0f0'}}>
            <h4 className='relatorio-title'>Relatório de abastecimento:</h4>
            <FormControl>
                <FormLabel id="row-radio-buttons-group-label">Caixa:</FormLabel>
                <RadioGroup
                    row
                    name="row-radio-buttons-group"
                    value={tipoCaixa}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTipoCaixa(e.target.value)}
                >
                    <FormControlLabel value='ADM' control={<Radio />} label="Fechado" />
                    <FormControlLabel value='PDV' control={<Radio />} label="Aberto" />
                </RadioGroup></FormControl>

            <SearchData setFirstDate={setFirstDate} setLastDate={setLastDate}
                firstDate={firstDate} lastDate={lastDate} />
            <SearchHour setFirstDate={setFirstHour} setLastDate={setLastHour}
                firstDate={firstHour} lastDate={lastHour} />
            <SearchProdutos setCodigoProduto={setCodigoProduto} />
            <SearchBico setCodigoBico={setCodigoBico} />

            <div className='row'>
                <label className='col-auto col-form-label' htmlFor="select-produto">Quantidade:</label>
                <div className="col-auto"><TextField type="text" value={litrosInicial} size='small' placeholder='0,000'
                    style={{ maxWidth: '100px' }} autoComplete='off' className="form-control" onChange={(e) => {
                        setLitrosInicial(e.target.value)
                    }} /></div>
                <label className='col-auto col-form-label' htmlFor="select-produto">a:</label>
                <div className="col-auto"><TextField type="text" value={litrosFinal} size='small' placeholder='999,999'
                    style={{ maxWidth: '100px' }} autoComplete='off' className="form-control" onChange={(e) => {
                        setLitrosFinal(e.target.value)
                    }} /></div>

            </div>
            <FormControl>
                <FormLabel id="row-radio-buttons-group-label">Agrupado por</FormLabel>
                <RadioGroup
                    row
                    name="row-radio-buttons-group"
                    value={agrupadoPor}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAgrupadoPor(e.target.value)}
                >
                    <FormControlLabel value='bico' control={<Radio />} label="Bico" />
                    <FormControlLabel value='hora' control={<Radio />} label="Hora" />
                </RadioGroup></FormControl>

            <br />
            <Button variant='contained' endIcon={<Send />} onClick={() => getAbastecimento()}>Gerar</Button>
            </Paper>
        </div>
    )
}
export default Abastecimento